import { Form, Modal } from "react-bootstrap-v5";
import ModelFooter from "../../shared/components/modelFooter";
import { useEffect, useState } from "react";
import { getFormattedMessage } from "../../shared/sharedMethod";
import ReactDataTable from "../../shared/table/ReactDataTable";
import { useDispatch } from "react-redux";
import CommonTable from "../../shared/table/CommonTable";
import goBackIcon from "../../assets/images/go-back.svg";
const PriceLevelHistoryModal = (props) => {
  const { priceHistoryShow, title, onClickPriceHistory, priceHistoryList, pricehistoryrow } =
    props;
  console.log("PriceLevelHistoryModal ::: priceHistoryList", priceHistoryList);
  console.log("PricehistoryRow", pricehistoryrow);
  // const itemValue = priceHistoryList?.sort((a, b) => {
  //   // Convert 'updatedOn' strings to Date objects
  //   const dateA = new Date(a.attributes.updatedOn);
  //   const dateB = new Date(b.attributes.updatedOn);

  //   // Sort in descending order
  //   return dateB - dateA;
  // }).map((items) => {
  //   return {
  //     mrp: items?.attributes?.mrp.toFixed(2),
  //     prev_price: items?.attributes?.salesPrice.toFixed(2),
  //     new_price: items?.attributes?.salesPrice.toFixed(2),
  //     changed_by: items?.attributes?.updatedBy,
  //     entry_from: items?.attributes?.entryFrom,
  //     changed_on: items?.attributes?.updatedOn ?? 0,
  //   };
  // });

  const sortedData = priceHistoryList.filter(item => item?.attributes?.mrp == pricehistoryrow?.mrp && item?.attributes?.batchNo == pricehistoryrow?.batchNo).sort((a, b) => new Date(b.attributes.updatedOn) - new Date(a.attributes.updatedOn));

  // // Find price change history
  // const priceChangeHistory = [];
  // for (let i = 0; i < sortedData.length - 1; i++) {
  //   const current = sortedData[i];
  //   const previous = sortedData[i + 1];

  //   if (current.attributes.salesPrice !== previous.attributes.salesPrice) {
  //     priceChangeHistory.push({
  //       mrp: current.attributes.mrp.toFixed(2),
  //       prev_price: Number(previous.attributes.salesPrice * (1 + (previous.attributes.tax / 100))).toFixed(2).toString(),
  //       // prev_price: previous.attributes.salesPrice.toFixed(2) ,
  //       // new_price: current.attributes.salesPrice.toFixed(2),
  //       new_price: Number(current.attributes.salesPrice * (1 + (current.attributes.tax / 100))).toFixed(2).toString(),
  //       changed_by: current.attributes.updatedBy,
  //       entry_from: current.attributes.entryFrom,
  //       changed_on: current.attributes.updatedOn
  //     });
  //   }
  // }

  // const sortedData = priceListHistory.sort((a, b) => new Date(b.attributes.updatedOn) - new Date(a.attributes.updatedOn));

  // Find price change history
  const priceChangeHistory = [];

  // If only one record exists, show one row with no previous price
  if (sortedData.length === 1) {
    priceChangeHistory.push({
      mrp: sortedData[0].attributes.mrp.toFixed(2),
      prev_price: "-", // No previous price available
      new_price: Number(sortedData[0].attributes.salesPrice * (1 + (sortedData[0].attributes.tax / 100))).toFixed(2).toString(),
      changed_by: sortedData[0].attributes.updatedBy,
      entry_from: sortedData[0].attributes.entryFrom,
      changed_on: sortedData[0].attributes.updatedOn
    });
  } else {
    // Loop through the sorted data to find price changes
    for (let i = 0; i < sortedData.length; i++) {
      const current = sortedData[i];
      const previous = sortedData[i + 1]; // Get the previous record

      // If there is no previous price, leave prev_price empty
      if (!previous) {
        priceChangeHistory.push({
          mrp: current.attributes.mrp.toFixed(2),
          prev_price: "-", // No previous price available
          new_price: Number(current.attributes.salesPrice * (1 + (current.attributes.tax / 100))).toFixed(2).toString(),
          changed_by: current.attributes.updatedBy,
          entry_from: current.attributes.entryFrom,
          changed_on: current.attributes.updatedOn
        });
      } else if (current.attributes.salesPrice !== previous.attributes.salesPrice) {
        // If salesPrice has changed, log the change
        priceChangeHistory.push({
          mrp: current.attributes.mrp.toFixed(2),
          prev_price: Number(previous.attributes.salesPrice * (1 + (previous.attributes.tax / 100))).toFixed(2).toString(),
          new_price: Number(current.attributes.salesPrice * (1 + (current.attributes.tax / 100))).toFixed(2).toString(),
          changed_by: current.attributes.updatedBy,
          entry_from: current.attributes.entryFrom,
          changed_on: current.attributes.updatedOn
        });
      }
    }
  }


  const itemValue = priceChangeHistory;
  useEffect(() => {
    console.log("priceHistoryList", priceHistoryList);
  }, [priceHistoryList])
  const columns = [
    {
      name: "MRP",
      // right: true,
      width: "16%",
      cell: (row) => row?.mrp,
    },

    {
      name: "Prev.Price",
      width: "16%",
      cell: (row) => row?.prev_price,
    },
    {
      name: "New Price",
      width: "16%",
      cell: (row) => row?.new_price,
    },
    {
      name: "Changed By",
      width: "16%",
      cell: (row) => row.changed_by,
    },
    {
      name: "Changed On",
      width: "16%",
      cell: (row) => row?.changed_on,
    },
    {
      name: "Entry From",
      width: "16%",
      cell: (row) => row?.entry_from,
    },
  ];
  return (
    <Modal
      dialogClassName="modal-lg  modal-price-history"
      show={priceHistoryShow}
      onHide={() => onClickPriceHistory(false)}
    >
      <Modal.Header className="modal-header-price-history">
        <Modal.Title className="custom-modal-header" style={{ marginLeft: "42%" }}>{title}</Modal.Title>
        <button
          onClick={() => onClickPriceHistory(false)}
          className="btn back-btn-custom"
          style={{ cursor: "pointer" }}
        >
          <img className="goBackIcon" src={goBackIcon} />
          {getFormattedMessage("globally.back-btn")}
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className="custom-modal-header">{priceHistoryList?.length > 0 ? priceHistoryList[0]?.itemName : ""}</p>
        <CommonTable subHeader={false} items={itemValue} columns={columns} priceHistoryList={true} />
      </Modal.Body>
    </Modal>
  );
};
export default PriceLevelHistoryModal;
