import { useEffect } from "react";
import { getFormattedMessage } from "../../shared/sharedMethod";
import HsnCodeForm from "./HsnCodeForm";
import { fetchSpecificHsnCode } from "../../store/action/hsnCodeAction";
import { connect } from "react-redux";
import { useParams } from "react-router";

const EditHsnCode = (props) => {

   const{hsnCode,show,handleClose,fetchSpecificHsnCode,hsnCodes} =props;

   console.log("Hsncode :",hsnCode) 
  

    return(
        <div>
             {hsnCode && <HsnCodeForm show={show} 
                        handleClose={handleClose} singleHsnCode={hsnCode} 
                        title={getFormattedMessage('hsnCode.edit.title')} /> }

        </div>
    )
}

export default connect(null)(EditHsnCode)