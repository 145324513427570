import apiConfig from "../../config/apiConfig";
import { apiBaseURL, hsnCodeActionType, hsnCodeFormActionType, toastType } from "../../constants";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setLoading } from "./loadingAction";
import { setSavingButton } from "./saveButtonAction";
import { addToast } from "./toastAction";
import { addInToTotalRecord, removeFromTotalRecord } from "./totalRecordAction";

export const fetchAllHsnCodes =
  (filter = {}, isLoading = true) =>
    async (dispatch) => {
      if (isLoading) {
        dispatch(setLoading(true));
      }

      let url = apiBaseURL.HSN_CODES;
      console.log(url)

      apiConfig
        .get(url)
        .then((response) => {
          console.log("Resonse", response)

          dispatch({
            type: hsnCodeActionType.FETCH_ALL_HSN_CODE,
            payload: response?.data?.data,
          });


          if (isLoading) {
            dispatch(setLoading(false));
          }

        })
        .catch(({ response }) => {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
        });
    };



export const addHsnCode = (hsnCode, handleClose,handleHsnClose) => async (dispatch) => {
  console.log(hsnCode)
  
  dispatch(setSavingButton(true));

  await apiConfig
    .post(apiBaseURL.HSN_CODES, hsnCode)
    .then((response) => {
      console.log(response)
      
      dispatch({
        type: hsnCodeActionType.ADD_HSN_CODE,
        payload: response?.data?.data,
      });

      if (response?.data?.success === true) {
        dispatch(
          addToast({
            text: response?.data?.message,
            type: toastType?.SUCCESS,
          })
        );
        handleClose && handleClose(false);
        handleHsnClose && handleHsnClose(false);
        dispatch(fetchAllHsnCodes());
      }
      else {
        dispatch(
          addToast({
            text: response?.data?.message,
            type: toastType?.ERROR,
          })
        );
      }
      dispatch(addInToTotalRecord(1));
      // dispatch(setSavingButton(false));
    })
    .catch(({ response }) => {
      dispatch(setSavingButton(false));
      response &&
        dispatch(
          addToast({
            text: response?.data?.message,
            type: toastType.ERROR,
          })
        );
    });
};



export const fetchSpecificHsnCode =
  (id, isLoading = true) =>

    async (dispatch) => {
      if (isLoading) {
        dispatch(setLoading(true));
      }
      console.log("HsnCodes Id =>", id)
      apiConfig
        .get(apiBaseURL.HSN_CODES + "?id=" + id)

        .then((response) => {
          console.log(response)
          dispatch({
            type: hsnCodeActionType.FETCH_HSN_CODE,
            payload: response?.data?.data,
          });
          if (isLoading) {
            dispatch(setLoading(false));
          }
        })
        .catch(({ response }) => {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
        });
    };



export const editHsnCode =

  (id, hsnCodes, handleClose) => async (dispatch) => {
    
    //   console.log("handleClose=>",handleClose)
    apiConfig
      .post(apiBaseURL.HSN_CODES, hsnCodes)
      .then((response) => {

        if (response?.data?.success === true) {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType?.success
            })
          );
          handleClose(false);
          dispatch({
            type: hsnCodeFormActionType.FORM_CLOSE,
            payload: false,
          });
        }
        else {
          dispatch(
            addToast({ text: response?.data?.message, type: toastType?.ERROR })
          )
          dispatch({
            type: hsnCodeFormActionType.FORM_CLOSE,
            payload: false,
          });
        }



        dispatch(fetchAllHsnCodes());
        dispatch(setSavingButton(false));
        dispatch(addInToTotalRecord(1));
      })
      .catch(({ response }) => {

        dispatch(
          addToast({ text: response?.data?.message, type: toastType.ERROR })
        );
      });
  };



  export const deleteHsnCode = (id) => async (dispatch) => {
    apiConfig
      .delete(apiBaseURL.HSN_CODES + "?id=" + id)
      .then((response) => {
        console.log(response)
        // dispatch({
        //   type: hsnCodeActionType.DELETE_HSN_CODE,
        //   payload: id
        // });
        if (response?.data?.success === true) {
          dispatch(removeFromTotalRecord(1));
          dispatch(fetchAllHsnCodes());
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType?.success
            })
          );
         
        } else {
          dispatch(
            addToast({ text: response?.data?.message, type: toastType?.ERROR })
          );
        }
        
      })
      .catch(({ response }) => {
        dispatch(
          addToast({ text: response?.data?.message, type: toastType?.ERROR })
        );
      });
  };
  

export const hsnCodeDropdown = (base_unit_value) => async (dispatch) => {
  apiConfig
    .get(apiBaseURL.HSN_CODES)
    .then((response) => {
      dispatch({
        type: hsnCodeActionType.FETCH_ALL_HSN_CODE,
        payload: response?.data?.data,
      });
    })
    .catch(({ response }) => {
      dispatch(
        addToast({ text: response.data.message, type: toastType.ERROR })
      );
    });
};
