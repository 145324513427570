import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MasterLayout from '../../MasterLayout';
import TopProgressBar from '../../../shared/components/loaders/TopProgressBar';
import { getFormattedMessage, placeholderText } from '../../../shared/sharedMethod';
import TabTitle from '../../../shared/tab-title/TabTitle';
import { useNavigate } from 'react-router';
import { prepareGstArray } from "../../../shared/prepareArray/prepareGstArray";
import GstSummaryTab from './GstSummaryTab';
import SummaryTab from './SummaryTab';

const GstSummary = (props) => {
    const { allConfigData, gstrReport } = props;
    const [closingStockValue, setclosingStockValue] = useState({ label: getFormattedMessage("unit.filter.all.label"), value: null });

    const [key, setKey] = useState('sales');

    // const array = posClsoingstock
    // const newFirstElement = { attributes: { name: getFormattedMessage("report-all.warehouse.label") }, id: null }
    // const newArray = [newFirstElement].concat(array)

    const [formcode, setFormCode] = useState("R03");
    const navigate = useNavigate();
    useEffect(() => {
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            console.log("Parsed Form Data:", parsedFormData);
            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
                console.log("Form Code Items:", formCodeItems);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);

    return (


        <div className='gstreportDiv' style={{ backgroundColor: "white", height: "100%" }}>
            <MasterLayout>
                <TopProgressBar />
                <TabTitle title={placeholderText('GST Summary Report')} />

                <h1 style={{ textAlign: 'center', color: 'green' }}> GST Summary</h1>
                <Tabs defaultActiveKey='sales' id='uncontrolled-tab-example' onSelect={(k) => setKey(k)}
                    className='p-3 mb-2 custom-bg-color text-white  mb-5 payrolltabs' >

                    <Tab eventKey='sales' title={getFormattedMessage('summary.title')}
                        tabClassName=' me-15'>

                        <div className='w-100 mx-auto border-radius-50'>
                            <SummaryTab key={key} />
                        </div>

                    </Tab>

                    <Tab eventKey='stockMovement' title={getFormattedMessage('gst.summary.title')}
                        tabClassName=' me-15'
                    >
                        <div className='w-100 mx-auto'>
                            <GstSummaryTab key={key} />
                        </div>
                    </Tab>
                </Tabs>
            </MasterLayout >
        </div >

    )
};

const mapStateToProps = (state) => {
    const { allConfigData, gstrReport } = state;
    return { allConfigData, gstrReport }
};
export default connect(mapStateToProps, { })(GstSummary);

