import {
  getFormattedMessage,
  placeholderText,
} from "../../shared/sharedMethod";
import { connect } from "react-redux";
import { useNavigate } from "react-router"
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import HeaderTitle from "../header/HeaderTitle"
import SalaryStructure from "./SalaryStructure"
import MasterLayout from "../MasterLayout"
import { useEffect, useState } from "react";

const CreateSalaryStructure=()=>{

  const [formcode, setFormCode] = useState("HR02");
    const navigate =useNavigate();
    useEffect(() => {
      ;
      const storedFormData = localStorage.getItem("UserFormCode");
  
      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
  
        console.log("Parsed Form Data:", parsedFormData);
        if (parsedFormData.length > 0) {
          const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permissionAdd);
          console.log("Form Code Items:", formCodeItems);
          if(!formCodeItems.length > 0){
              navigate("/app/dashboard");
          }
        } else {
          navigate("/app/dashboard");
        }
      } 
    }, []);
  return(
    <div>
     <MasterLayout>
         <TopProgressBar/>
         {/* <HeaderTitle title={getFormattedMessage("salaryStructure.title")} to='/app/salary'/> */}
         <SalaryStructure />
     </MasterLayout>
    </div>
  )
}
export default CreateSalaryStructure