import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import TabTitle from "../../shared/tab-title/TabTitle"
import ReactDataTable from "../../shared/table/ReactDataTable"
import MasterLayout from "../MasterLayout"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { Button } from "react-bootstrap-v5"
import { connect } from "react-redux"
import { useEffect, useState } from "react"
import { fetchPosSalesListing, fetchSingleSale } from "../../store/action/salesAction"
import ActionButton from "../../shared/action-buttons/ActionButton"
import { getFormattedMessage, placeholderText } from "../../shared/sharedMethod"
import { useNavigate } from "react-router";
import Loader from '../loader/Loader';
import DeleteWholeSales from "./DeleteWholeSales"
import { Container, Modal } from "react-bootstrap"
import { fetchCompanyConfig } from '../../store/action/companyConfigAction';
import moment from "moment"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PdfGenerator from "./pdfgenerator3";
import A5Modal from "../printModals/A5Modal"
import A501Modal from "../printModals/A501Modal"
import A401Modal from "../printModals/A401Modal"
import A4Modal from "../printModals/A4Modal"
import A4CompositeModal from "../printModals/A4CompositeModal"

const PosSalesListing = (props) => {
    const { companyConfig, fetchCompanyConfig, fetchPosSalesListing, sales, isLoading, allConfigData, totalRecord, saleSingle, fetchSingleSale } = props;
    const navigate = useNavigate();
    const [possales, setPosSales] = useState();
    const [filterSales, setFilterSales] = useState([]);
    const [isDelete, setIsDelete] = useState(null);
    const [deleteModel, setDeleteModel] = useState(false);
    const [group, setGroup] = useState();
    const [printModal, setPrintModal] = useState(false);
    const [base64, setBase64] = useState('');
    const [company, setCompany] = useState({
        companyName: '',
        address: '',
        phoneNo: '',
        companyLogo: '',
        city: '',
        state: '',
    });
    const [formcode, setFormCode] = useState("T03");

    useEffect(() => {
        fetchPosSalesListing();
        fetchCompanyConfig();
    }, [])

    useEffect(() => {

        setPosSales(sales);
        setFilterSales(sales);
    }, [sales]);

    useEffect(() => {
        console.log("SingleSale", saleSingle);
        let grouped = groupByTaxPercentage(saleSingle?.attributes?.sales2);
        setGroup(grouped);
        console.log("grouped", grouped);
    }, [saleSingle]);

    useEffect(() => {
        console.log("company config", companyConfig);
        if (companyConfig) {
            setCompany({
                companyName: companyConfig?.companyName,
                address: `${companyConfig?.attributes?.address1 || ''}, ${companyConfig?.attributes?.address2 || ''}, ${companyConfig?.attributes?.city || ''}`,
                phoneNo: companyConfig?.attributes?.phoneNo,
                companyLogo: companyConfig?.attributes?.companyLogo || 'path/to/default/logo.png',
            });
        }
        urlToBase64(companyConfig?.attributes?.digitalSignature)
    }, [companyConfig]);

    useEffect(() => {
        ;
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            console.log("Parsed Form Data:", parsedFormData);
            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
                console.log("Form Code Items:", formCodeItems);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);


    const handleSearchData = (e) => {
        const { name, value } = e.target;
        console.log("hi name", name);
        console.log("hi value", value);
        const filtered_Sales = value.length > 0
            ? possales.filter((item) => {
                const customerName = item?.attributes?.customerName?.toLowerCase() || "";
                const invNo = item?.attributes?.invNo?.toString().toLowerCase() || "";
                const mobileNo = item?.attributes?.mobileNo?.toString().toLowerCase() || "";

                return customerName.includes(value.toLowerCase()) ||
                    invNo.includes(value.toLowerCase()) ||
                    mobileNo.includes(value.toLowerCase());
            })
            : possales;

        setFilterSales(filtered_Sales);
    };

    const itemsValue = filterSales?.length > 0 &&
        filterSales?.map(item => ({
            //    entryNo: item?.attributes?.slNo,
            id: item?.txNo,
            invNo: item?.attributes?.invNo,
            customerName: item?.attributes?.customerName,
            supplierMobile: item?.attributes?.mobileNo,
            city: item?.attributes?.city,
            paymentType: (item?.attributes?.paymentMode).toUpperCase(),
            billAmount: parseFloat(item?.attributes?.billAmount).toFixed(2),
            sales: filterSales
        }));

    console.log("itemsValue", filterSales);

    const onClickDeleteModel = (isDelete = null) => {
        setDeleteModel(!deleteModel);
        setIsDelete(isDelete);
    };

    const goToEditProduct = (item) => {
        const id = item.id
        console.log(id);
        navigate(`/app/wholesale/edit/${id}`);
    };

    const onPrintModal = (item) => {
        const id = item.id;
        // alert(id);
        fetchSingleSale(id);
        setPrintModal(true);
    };

    const handleClose = () => {
        setPrintModal(false);
        // setPrint(true);
    }

    const groupByTaxPercentage = (products) => {
        return products?.reduce((acc, product) => {
            const { tax, taxAmount } = product;
            if (!acc[tax]) {
                acc[tax] = { items: 0, tax_amount: 0 };
            }
            acc[tax].items += 1;
            acc[tax].tax_amount += parseFloat(taxAmount);
            return acc;
        }, {});
    };

    const getCurrentDateTimeInIST = () => {
        const now = new Date();
        const offset = 5.5; // IST is UTC+5:30
        const istDate = new Date(now.getTime() + offset * 3600 * 1000);
        return istDate.toISOString().replace(/T/, ' ').replace(/\..+/, '');
    };

    const handleGeneratePDF = () => {
        const input = document.getElementById('beauteSlip');

        // A5 dimensions in mm for landscape
        let A5WidthMm = 210; // Width in landscape mode
        let A5HeightMm = 148; // Height in landscape mode

        // Convert mm to pixels for canvas dimensions
        const mmToPx = 3.7795275591; // 1 mm = 3.7795275591 pixels (approximation for 96 dpi)
        const A5WidthPx = A5WidthMm * mmToPx;
        const A5HeightPx = A5HeightMm * mmToPx;

        html2canvas(input, {
            scale: 2, // Increase scale for better resolution
            width: A5WidthPx,
            height: A5HeightPx,
            useCORS: true,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.7);
            const pdf = new jsPDF('l', 'mm', [A5WidthMm, A5HeightMm]); // 'l' for landscape

            const imgWidth = A5WidthMm;
            const imgHeight = canvas.height * imgWidth / canvas.width;

            const positionX = 0;
            const positionY = (A5HeightMm - imgHeight) / 2;
            pdf.addImage(imgData, 'JPEG', positionX, positionY, imgWidth, imgHeight);
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const iframe = document.createElement('iframe');
            iframe.style.position = 'absolute';
            iframe.style.width = '0';
            iframe.style.height = '0';
            iframe.style.border = 'none';
            document.body.appendChild(iframe);

            iframe.onload = () => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
            };

            iframe.contentWindow.onafterprint = () => {
                document.body.removeChild(iframe);
                URL.revokeObjectURL(pdfUrl);
            };

            iframe.src = pdfUrl;
            // pdf.save(`Invoice_Report_${getCurrentDateTimeInIST()}.pdf`);
        });
    };

    // const handleGeneratePDFA4 = async () => {
    //     const A4_HEIGHT = 841.89; // A4 page height in points
    //     const A4_WIDTH = 595.28;   // A4 page width in points
    //     const WIDTH_MARGIN = 10;    // Left and right margins
    //     const HEIGHT_MARGIN = 10;   // Top and bottom margins
    //     const PAGE_HEIGHT = A4_HEIGHT - 2 * HEIGHT_MARGIN;

    //     /** create pdf instance */
    //     const pdf = new jsPDF('p', 'pt', 'a4');  // orientation, unit, format

    //     /** convert html to canvas */
    //     const body = document.getElementById('beauteSlip');
    //     const canvas = await html2canvas(body, { scale: 2 });  // Higher scale for better quality

    //     /** calculate the imgWidth, imgHeight to print on PDF 
    //      *  so it can scale in equal proportions*/
    //     const canvasWidth = canvas.width;
    //     const canvasHeight = canvas.height;
    //     const imgWidth = A4_WIDTH - 2 * WIDTH_MARGIN;
    //     const imgHeight = (imgWidth / canvasWidth) * canvasHeight;

    //     /** convert canvas to image */
    //     const pageImg = canvas.toDataURL('image/png', 1.0);

    //     let position = HEIGHT_MARGIN; // Initial position for the image on the first page

    //     // if (imgHeight > PAGE_HEIGHT) {  // need multi-page pdf
    //     //   let heightUnprinted = imgHeight;

    //     //   while (heightUnprinted > 0) {
    //     //     pdf.addImage(pageImg, 'PNG', WIDTH_MARGIN, -position, imgWidth, imgHeight);

    //     //     // draw the margin top and margin bottom if needed
    //     //     pdf.setFillColor(255, 255, 255);
    //     //     pdf.rect(0, 0, A4_WIDTH, HEIGHT_MARGIN, 'F'); // margin top
    //     //     pdf.rect(0, A4_HEIGHT - HEIGHT_MARGIN, A4_WIDTH, HEIGHT_MARGIN, 'F'); // margin bottom 

    //     //     heightUnprinted -= PAGE_HEIGHT;  
    //     //     position += PAGE_HEIGHT; // move down to the next vertical placement

    //     //     // add another page if there's more content to print
    //     //     if (heightUnprinted > 0){
    //     //        pdf.addPage();
    //     //     }
    //     //   }
    //     // }
    //     //  else {
    //     // print single page pdf
    //     pdf.addImage(pageImg, 'PNG', WIDTH_MARGIN, position, imgWidth, imgHeight);
    //     // }

    //     // Add page numbers at the bottom of each page
    //     const totalPages = pdf.internal.getNumberOfPages();
    //     for (let i = 1; i <= totalPages; i++) {
    //         pdf.setPage(i);
    //         // pdf.addPage();
    //     }
    //     const pdfBlob = pdf.output('blob');
    //     const pdfUrl = URL.createObjectURL(pdfBlob);
    //     const iframe = document.createElement('iframe');
    //     iframe.style.position = 'absolute';
    //     iframe.style.width = '0';
    //     iframe.style.height = '0';
    //     iframe.style.border = 'none';
    //     document.body.appendChild(iframe);

    //     iframe.onload = () => {
    //         iframe.contentWindow.focus();
    //         iframe.contentWindow.print();
    //     };

    //     iframe.contentWindow.onafterprint = () => {
    //         document.body.removeChild(iframe);
    //         URL.revokeObjectURL(pdfUrl);
    //     };

    //     iframe.src = pdfUrl;


    //     // pdf.save(`Invoice_Report_${getCurrentDateTimeInIST()}.pdf`);
    // };

    const handleGeneratePDFA4 = async () => {
        const A4_HEIGHT = 841.89; // A4 page height in points
        const A4_WIDTH = 595.28;   // A4 page width in points
        const WIDTH_MARGIN = 10;    // Left and right margins
        const HEIGHT_MARGIN = 10;   // Top and bottom margins
        const PAGE_HEIGHT = A4_HEIGHT - 2 * HEIGHT_MARGIN;

        /** create pdf instance */
        const pdf = new jsPDF('p', 'pt', 'a4');  // orientation, unit, format

        /** Ensure all images are loaded, including the signature image */
        const loadImages = (body) => {
            const imgElements = body.querySelectorAll('img');
            const imgPromises = Array.from(imgElements).map(img => {
                return new Promise((resolve, reject) => {
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = resolve;
                        img.onerror = () => reject(`Image failed to load: ${img.src}`);
                    }
                });
            });
            return Promise.all(imgPromises);
        };

        /** get the html element */
        const body = document.getElementById('beauteSlip');

        /** Wait for all images to load */
        try {
            await loadImages(body);  // Ensure the signature image and other images are loaded
        } catch (error) {
            console.error(error);
            return;  // Abort if an image fails to load
        }


        /** convert html to canvas */
        const canvas = await html2canvas(body, {
            scale: 2,   // Higher scale for better quality
            useCORS: true,
            allowTaint: false, // Use CORS to load cross-origin images
        });

        /** calculate the imgWidth, imgHeight to print on PDF 
         *  so it can scale in equal proportions */
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        const imgWidth = A4_WIDTH - 2 * WIDTH_MARGIN;
        const imgHeight = (imgWidth / canvasWidth) * canvasHeight;

        /** convert canvas to image */
        const pageImg = canvas.toDataURL('image/png', 1.0);

        let position = HEIGHT_MARGIN; // Initial position for the image on the first page

        // If the image height is larger than the page height, you can add multi-page logic here
        pdf.addImage(pageImg, 'PNG', WIDTH_MARGIN, position, imgWidth, imgHeight);

        // Add page numbers at the bottom of each page
        // const totalPages = pdf.internal.getNumberOfPages();
        // for (let i = 1; i <= totalPages; i++) {
        //     pdf.setPage(i);
        //     pdf.setFontSize(10);
        //     pdf.text(`Page ${i} of ${totalPages}`, A4_WIDTH / 2, A4_HEIGHT - HEIGHT_MARGIN / 2, { align: 'center' });
        // }

        // Create blob and URL for the PDF
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);

        // Set the iframe src and print when loaded
        iframe.onload = () => {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
        };

        // Cleanup after printing
        iframe.contentWindow.onafterprint = () => {
            document.body.removeChild(iframe);
            URL.revokeObjectURL(pdfUrl);
        };

        // Set the iframe source to the PDF URL
        iframe.src = pdfUrl;

        // Alternatively, save the PDF directly
        // pdf.save(`Invoice_Report_${getCurrentDateTimeInIST()}.pdf`);
    };



    const columns = [
        {
            name: getFormattedMessage('inv.No.title'),
            selector: row => row.invNo,
            sortField: 'invNo',
            sortable: true,
        },
        {
            name: getFormattedMessage('globally.input.customerName.label'),
            selector: row => row.customerName,
            sortField: 'customerName',
            sortable: true,
        },
        {
            name: getFormattedMessage('globally.input.supplierMobile.label'),
            selector: row => row.supplierMobile,
            sortField: 'supplierMobile',
            sortable: true,
        },
        {
            name: getFormattedMessage('globally.input.city.label'),
            selector: row => row.city,
            sortField: 'city',
            sortable: true,
        },
        {
            name: getFormattedMessage('globally.input.paymentType.label'),
            selector: row => row.paymentType,
            sortField: 'paymentType',
            sortable: true,
        },
        {
            name: getFormattedMessage('globally.input.billAmount.label'),
            selector: row => row.billAmount,
            sortField: 'billAmount',
            sortable: true,
        },
        {
            name: getFormattedMessage('react-data-table.action.column.label'),
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: row => <ActionButton item={row}
                // isViewIcon={true}
                isEditMode={true}
                isPrint={true}
                goToEditProduct={goToEditProduct}
                onClickDeleteModel={onClickDeleteModel}
                onPrintModal={onPrintModal}
                isSales={true}
            //goToEditProduct={goToEditSuppliers} isEditMode={true}
            // onClickDeleteModel={onClickDeleteModel} 
            />
        }
    ];

    const ButtonValue = getFormattedMessage('Sales.create.title')
    const to = '#/app/wholesale/create'

    const urlToBase64 = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                setBase64(reader.result)  // Set the Base64 string in state
            };
        } catch (error) {
            console.error('Error converting URL to Base64:', error);
        }
    };

    const formatNumber = (num) => {
        // If the absolute value of the number is less than 1 and has only one decimal place, multiply by 0.1
        if (Math.abs(num) < 1 && num?.toFixed(1).split('.')[1].length === 1) {
            num = num * 0.1;
        }
        // Return the number with exactly two decimal places
        return num?.toFixed(2);
    }

    return (
        <>
            <MasterLayout>
                <Loader />
                <TopProgressBar />
                <TabTitle title={placeholderText('sales.title')} />
                <div>
                    <h1 className="text-white fw-bolder">List of Sales</h1>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3 searchBox">
                        <div className="position-relative d-flex width-320">
                            <input
                                className="form-control ps-8"
                                type="search"
                                name="searchData"
                                id="search"
                                placeholder={placeholderText(
                                    "react-data-table.searchbarInv.placeholder"
                                )}
                                aria-label="Search"
                                onChange={(e) => handleSearchData(e)}
                                autoComplete="off"
                            />
                            <span className="position-absolute d-flex align-items-center top-0 bottom-0 left-0 text-gray-600 ms-3">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6 text-end order-2 ">
                        <Button type='button' variant='primary' className='crt_product' href={to}>{ButtonValue}</Button>
                    </div>
                </div>
                <ReactDataTable
                    columns={columns}
                    items={itemsValue ? itemsValue : []}
                    totalRows={itemsValue?.length}
                    isLoading={isLoading}
                    isUnitFilter
                    subHeader={false}
                />
                <DeleteWholeSales onClickDeleteModel={onClickDeleteModel} deleteModel={deleteModel} onDelete={isDelete} />
            </MasterLayout>
            <Modal
                size="xl"
                show={printModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="printModal"
            >
                <Modal.Header className="row" closeButton>
                </Modal.Header>
                <Modal.Body>
                    {companyConfig?.attributes?.printmodel == "A5" &&
                        <A5Modal company={company} saleSingle={saleSingle} group={group} />
                    }
                    {companyConfig?.attributes?.printmodel == "A5-01" &&
                        <A501Modal saleSingle={saleSingle} company={company} group={group} />
                    }
                    {companyConfig?.attributes?.printmodel == "A4-01" &&
                        // <A4Modal saleSingle={saleSingle} group={group} company={company} companyConfig={companyConfig} />
                        <A401Modal saleSingle={saleSingle} company={company} group={group} />
                    }
                    {companyConfig?.attributes?.printmodel == "A4" &&
                        <A4Modal saleSingle={saleSingle} group={group} company={company} companyConfig={companyConfig} />
                    }
                    {companyConfig?.attributes?.printmodel == "A4 - Composite" &&
                        <A4CompositeModal company={company} saleSingle={saleSingle} group={group} companyConfig={companyConfig} />
                    }
                </Modal.Body>
                <Modal.Footer style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {companyConfig?.attributes?.printmodel == "A5" && <Button variant="success" onClick={handleGeneratePDF}>Print</Button>}
                    {companyConfig?.attributes?.printmodel == "A4" && <Button variant="success" onClick={handleGeneratePDFA4}>Print</Button>}
                    {companyConfig?.attributes?.printmodel == "A4 - Composite" && <Button variant="success" onClick={handleGeneratePDFA4}>Print</Button>}
                    {companyConfig?.attributes?.printmodel == "A5-01" && <Button variant="success" onClick={handleGeneratePDFA4}>Print</Button>}
                    {companyConfig?.attributes?.printmodel == "A4-01" && <Button variant="success" onClick={handleGeneratePDFA4}>Print</Button>}
                </Modal.Footer>
            </Modal >
            {/* {printModal && <PdfGenerator/>} */}
        </>
    )
}

const mapStateToProps = (state) => {
    const { sales, isLoading, allConfigData, totalRecord, saleSingle, companyConfig } = state;
    return { sales, isLoading, allConfigData, totalRecord, saleSingle, companyConfig }
}

export default connect(mapStateToProps, { fetchPosSalesListing, fetchCompanyConfig, fetchSingleSale })(PosSalesListing)

