import {
    faEye,
    faFileExcel,
    faFilePdf,
    faFilter,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";
import { connect, useDispatch } from "react-redux";
import ClosingStockReportForm from "../closingStockReport/ClosingStockReportForm";
import ClosingStockField from "../closingStockReport/ClosingStockField";
import { fetchAcYear } from "../../../store/action/acYearAction";
import { Button, InputGroup } from "react-bootstrap-v5";
import ReactSelect from "../../../shared/select/reactSelect";
import { placeholderText } from "../../../shared/sharedMethod";
import { fetchGstrReport } from "../../../store/action/gstrReportAction";
import { generatePDF, printPDF } from "../pdfUtil";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import Loader from "../../loader/Loader";
import moment from "moment";
import { prepareGstArray } from "../../../shared/prepareArray/prepareGstArray";
import { addToast } from "../../../store/action/toastAction";
// import * as XLSX from 'xlsx';

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const B2bReport = (props) => {

    const { stockMovement, acYear, fetchAcYear, fetchGstrReport, gstrReport, fetchCompanyConfig, companyConfig, setFromDate, setDateto } = props;

    const today = new Date();
    const formattedDate = formatDate(today);
    const numOfDaysAdded = 0;
    const date = today.setDate(today.getDate() + numOfDaysAdded);
    const defaultValue = new Date(date).toISOString().split("T")[0]; // YYYY-MM-dd

    const formatDecimal = (cell, formatterParams, onRendered) => {
        const value = cell.getValue();

        return value?.toFixed(2);
    };
    const dispatch = useDispatch();
    const [filterGstr, setFilterGstr] = useState([]);
    const [fieldShow, setFieldShow] = useState(false);
    const handleFieldClose = () => setFieldShow(!fieldShow);
    const [showField, setShowField] = useState();
    const [minYear, setMinYear] = useState();
    const [maxYear, setMaxYear] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);
    const year = useMemo(() => acYear, [acYear]);
    const [label, setLabel] = useState(false);
    const [fetchRangeValue, setFetchRangeValue] = useState({
        category1Name: "",
        category2Name: "",
        category3Name: "",
        unitName: ""
    });
    const paySlipRef = useRef(null);
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearRange = `${currentYear}-${nextYear}`;
    const [selectedYearRange, setSelectedYearRange] = useState({
        value: yearRange,
        label: yearRange,
    });
    const [totalData, setTotalData] = useState({});

    const [range, setRange] = useState({
        category1Name: "",
        category2Name: "",
        category3Name: "",
        unitName: "",
    });

    const [dates, setDate] = useState({
        fromDate: defaultValue,
        toDate: defaultValue,
    });
    // const [excelData, setExcelData] = useState([]);

    // // Example array data to bind to Excel cells
    // const arrayData = [
    //     ['1234567890', 'ABC Pvt Ltd', 'INV001', '2023-10-10', '50000', 'TN', 'No', '18%', 'Regular', '', '18%', '45000'],
    //     ['9876543210', 'XYZ Pvt Ltd', 'INV002', '2023-10-11', '75000', 'KA', 'No', '18%', 'Regular', '', '18%', '60000']
    //     // Add more rows as needed
    // ];

    // useEffect(() => {
    //     
    //     fetch(`${process.env.PUBLIC_URL}/assets/GSTR1_Excel_Workbook_Template_V2.0.xlsx`) // Replace with your file path
    //         .then(response => response.arrayBuffer())
    //         .then(data => {
    //             const workbook = XLSX.read(data, { type: 'array' });
    //             const worksheet = workbook.Sheets['b2b,sez,de']; // Targeting sheet named 'b2b'
    //             const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //             const updatedJsonData = bindDataToCells(jsonData); // Bind custom data
    //             setExcelData(updatedJsonData); // Store the updated data
    //             console.log('Excel data:', updatedJsonData);
    //         })
    //         .catch(error => console.error('Error reading Excel file:', error));
    // }, []);

    // const bindDataToCells = (worksheetData) => {
    //     const headers = worksheetData[3];  // Assuming row 4 is the headers in the Excel (index 3)
    //     const rows = worksheetData.slice(4); // Data rows (starting from row 5 in the Excel)
    //     const updatedData = rows.map((row, index) => arrayData[index] || row); // Replace rows with arrayData
    //     return [worksheetData[0], worksheetData[1], worksheetData[2], headers, ...updatedData];
    // };

    // const downloadUpdatedExcel = () => {
    //     const worksheet = XLSX.utils.aoa_to_sheet(excelData); // Convert back to sheet
    //     const newWorkbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'b2b');
    //     XLSX.writeFile(newWorkbook, 'updated_file.xlsx'); // Download the file
    //   };

    const columns = [
        { title: "GSTIN / UIN of Recipient", field: "customerRegno", headerSort: false },
        {
            title: "Reciever  Name", field: "customerName", headerSort: false, bottomCalc: () => 'Total', // Custom text for the bottom of this column
            bottomCalcFormatter: (cell) => {
                return '<strong>Total</strong>'; // Display 'Total' in bold
            }
        },
        { title: "Invoice Number", field: "invNo", headerSort: false, hozAlign: "center", headerHozAlign: "center" },
        { title: "Invoice Date", field: "invDate", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        {
            title: "Invoice Value", field: "netTotal", headerSort: false, hozAlign: "right", headerHozAlign: "right", bottomCalc: (values, data) => {
                // Filter unique values
                const uniqueValues = [...new Set(values)];

                // Custom calculation logic
                let total = 0;
                uniqueValues.forEach(value => {
                    total += parseFloat(value) || 0; // Ensure values are parsed as numbers
                });

                return total.toFixed(2); // Return total with two decimal places
            }
        },
        { title: "Place Of Supply", field: "placeOfSupply", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "Reverse Charges", field: "reverseCharges", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "Applicable % of Tax Rate", field: "applicableTaxRate", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "Invoice Type", field: "invoiceType", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "E-Commerce GSTIN", field: "ecommerceGstin", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "Rate", field: "rate", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        {
            title: "Taxable Value", field: "taxableValue", headerSort: false, hozAlign: "right", headerHozAlign: "right", bottomCalc: (values, data) => {
                // Custom calculation logic
                let total = 0;
                values.forEach(value => {
                    total += parseFloat(value) || 0; // Ensure values are parsed as numbers
                });
                return total.toFixed(2); // Return total with two decimal places
            }
        },
        {
            title: "Cess Amount", field: "cessAmount", headerSort: false, hozAlign: "right", headerHozAlign: "right", bottomCalc: (values, data) => {
                // Custom calculation logic
                let total = 0;
                values.forEach(value => {
                    total += parseFloat(value) || 0; // Ensure values are parsed as numbers
                });
                return total.toFixed(2); // Return total with two decimal places
            }
        }
    ];

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [inputValue, setInputValue] = useState(0);
    const companyDetails = {
        companyName: companyConfig?.companyName || 'Company Name',
        address: `${companyConfig?.attributes?.address1 || ''} , ${companyConfig?.attributes?.address2 || ''}`,
        phoneNumber: companyConfig?.attributes?.phoneNo || 'Phone Number'
    };

    const fromDate = useRef();
    const toDate = useRef();

    const loadValues = (filter) => {
        return "?fromDate=" + filter + "&toDate=" + filter;
    }

    useEffect(() => {
        fetchAcYear();
        fetchCompanyConfig();
        fetchGstrReport(loadValues(defaultValue));
        setFromDate(defaultValue);
        setDateto(defaultValue);
    }, []);

    useEffect(() => {
        
        console.log("GSTR Report =>", gstrReport);
        setFilterGstr(gstrReport);
        // processData(gstrReport?.attributes?.b2b);

        const uniqueCustomerRegnos = new Set();
        const uniqueInvNos = new Set();

        // Iterate through the data
        gstrReport[0]?.attributes?.b2b?.forEach(item => {
            uniqueCustomerRegnos.add(item.customerRegno);
            uniqueInvNos.add(item.invNo);
        });

        // Get the counts
        const customerRegnoCount = uniqueCustomerRegnos.size;
        const invNoCount = uniqueInvNos.size;

        console.log("customerRegnoCount =>", customerRegnoCount);
        console.log("invNoCount =>", invNoCount);
        let invtotal = reduceInvoiceTotals(gstrReport[0]?.attributes?.b2b)?.toFixed(2)

        setTotalData({
            "totalRecipient": customerRegnoCount,
            "invoice": invNoCount,
            "totalTaxValue": gstrReport[0]?.attributes?.b2b?.reduce((accumulator, currentValue) => accumulator + currentValue?.taxableValue, 0),
            "totalCessValue": gstrReport[0]?.attributes?.b2b?.reduce((accumulator, currentValue) => accumulator + currentValue?.cessAmount, 0),
            // "totalInvoiceValue": gstrReport[0]?.attributes?.b2b?.reduce((accumulator, currentValue) => accumulator + currentValue?.netTotal, 0)
            "totalInvoiceValue": invtotal
        }
        );
    }, [gstrReport]);

    const reduceInvoiceTotals = (invoices) => {
        const invoiceTotals = invoices?.reduce((acc, invoice) => {
            const { invNo, netTotal } = invoice;
            if (!acc[invNo]) {
                acc[invNo] = parseFloat(netTotal);
            }
            return acc;
        }, {});

        if (invoiceTotals) {
            const totalValue = Object.values(invoiceTotals)?.reduce((sum, value) => sum + value, 0);
            return totalValue;
        }
    }

    const processData = (data) => {
        
        // Use Sets to store unique values
        const uniqueCustomerRegnos = new Set();
        const uniqueInvNos = new Set();

        // Iterate through the data
        data?.forEach(item => {
            uniqueCustomerRegnos.add(item.customerRegno);
            uniqueInvNos.add(item.invNo);
        });

        // Get the counts
        const customerRegnoCount = uniqueCustomerRegnos.size;
        const invNoCount = uniqueInvNos.size;

        setTotalData({
            "totalRecipient": customerRegnoCount,
            "invoice": invNoCount
        }
        );

        return {
            "totalRecipient": customerRegnoCount,
            "invoice": invNoCount
        };
    }

    const handleChange = (selectedOption) => {
        setSelectedYearRange(selectedOption);
    };

    useEffect(() => {
        console.log("selectedYearRange =>", selectedYearRange);
        setMinYear(selectedYearRange?.label?.split("-")[0] + "-04-01");
        setMaxYear(selectedYearRange?.label?.split("-")[1] + "-03-31");
    }, [selectedYearRange]);

    const handleClick = (e) => {
        setFieldShow(true);
    };

    const onClick = () => {
        setLabel(true)
        setShow(true)
        setFetchRangeValue(range)
        console.log("fetchRangeValue =>", fetchRangeValue)
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setDate({ ...dates, [name]: value });
        setFromDate(name === "fromDate" ? value : dates.fromDate);
        setDateto(name === "toDate" ? value : dates.toDate);
    }

    const fetchData = () => {
        let values = "?fromDate=" + dates?.fromDate + "&toDate=" + dates?.toDate;
        if (moment(dates?.fromDate).isAfter(dates?.toDate)) {
            dispatch(addToast({ text: 'Invalid date range.!', type: 'error' }));
        } else {
            fetchGstrReport(values);
        }
    }

    const sortInvoices = (b2b) => {
        return b2b.sort((a, b) => {
            // Compare invDate (convert date strings to Date objects)
            const dateA = new Date(a.invDate);
            const dateB = new Date(b.invDate);
            if (dateA - dateB !== 0) {
                return dateA - dateB;
            }

            // Compare invNo (alphanumeric comparison)
            if (a.invNo !== b.invNo) {
                return a.invNo.localeCompare(b.invNo);
            }

            // Compare customerRegno (alphanumeric comparison)
            return a.customerRegno.localeCompare(b.customerRegno);
        });
    }

    const itemsValue = filterGstr?.length > 0 &&
        sortInvoices(filterGstr[0]?.attributes?.b2b)?.map(data => ({
            customerName: data?.customerName,
            customerRegno: data?.customerRegno,
            customerMobile: data?.customerMobile,
            invNo: data?.invNo,
            reverseCharges: data?.reverseCharges,
            rate: parseFloat(data?.rate).toFixed(2),
            invDate: moment(data?.invDate).format("DD-MMM-YYYY"),
            taxableValue: parseFloat(data?.taxableValue).toFixed(2),
            placeOfSupply: data?.placeOfSupply,
            reverseCharges: data?.reverseCharges,
            applicableTaxRate: data?.applicableTaxRate,
            invoiceType: data?.invoiceType,
            ecommerceGstin: data?.ecommerceGstin,
            cessAmount: parseFloat(data?.cessAmount).toFixed(2),
            netTotal: parseFloat(data?.netTotal).toFixed(2)
        })) || [];

    console.log("ITEMSVALUE =>", itemsValue);
    const handlePrintPDF = () => {
        printPDF(paySlipRef, companyDetails, 'Landscape', 'b2bReport',"GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C", itemsValue, columns);
    };

    const handleExportToPDF = () => {
        generatePDF(paySlipRef, companyDetails, 'Landscape', 'b2bReport',"GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C", itemsValue, columns)
            .then(pdf => {
                pdf.save('b2bReport.pdf');
            })
            .catch(error => {
                console.error('Error generating PDF:', error);
            });
    };

    const generateJSONFile = (data, filename) => {
        const jsonString = JSON.stringify(data);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleGenerateJSON = () => {
        let data = gstrReport[0]?.attributes?.b2b || {}
        generateJSONFile(prepareGstArray(gstrReport[0]?.attributes), 'b2bReport.json');
    }
    return (
        <>
            <Loader />
            <div style={{ backgroundColor: "white", height: "100%" }}>

                {fieldShow && <ClosingStockField setShowField={setShowField} fieldShow={fieldShow}
                    handleFieldClose={handleFieldClose} Title={"Stock Movement Report Form"} />}

                <div className="row">
                    <div className="col-md-5">
                        {/* <h2 style={{ color: "green" }}>
                            {"GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C"}
                        </h2> */}
                        <div className="row" style={{ marginTop: "-10px" }}>
                            <div className="col-md-4">
                                <h4 className="mt-2">From Date</h4>
                                <input
                                    id1="dateInput"
                                    type="date"
                                    className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
                                    ref={fromDate}
                                    defaultValue={defaultValue}
                                    name="fromDate"
                                    onChange={onChange}
                                ></input>
                            </div>
                            <div className="col-md-4 mt-2">
                                <h4>To Date</h4>
                                <input
                                    id2="dateRequired2"
                                    type="date"
                                    className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                                    ref={toDate}
                                    defaultValue={defaultValue}
                                    name="toDate"
                                    onChange={onChange}
                                    min={dates?.fromDate}
                                ></input>
                            </div>
                            <div className="col-md-4 gstrGenerateBtn">
                                <button
                                    type="button"
                                    className="btn btn-success "
                                    onClick={() => fetchData()}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5"></div>
                    <div className="col-md-2 ">
                        <button
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                borderRadius: "10px",
                                width: "220px",
                                height: "60px",
                                gap: "13px",
                                background: "white",
                                marginTop: "-20px",
                                fontSize: "11px",
                                marginLeft: "-20px"
                            }}
                            ref={paySlipRef}
                        >
                            <FontAwesomeIcon
                                icon={faPrint}
                                className="fa-2x search-icon"
                                style={{ color: "black", paddingTop: "8px" }}
                                onClick={handlePrintPDF}
                            ></FontAwesomeIcon>

                            <FontAwesomeIcon
                                icon={faFileExcel}
                                className="fa-2x search-icon "
                                style={{
                                    color: "green",
                                    paddingLeft: "10px",
                                    paddingTop: "7px",
                                }}
                            //   onClick={downloadUpdatedExcel}
                            ></FontAwesomeIcon>

                            <FontAwesomeIcon
                                icon={faFilePdf}
                                className="fa-2x search-icon"
                                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                                onClick={handleExportToPDF}
                            ></FontAwesomeIcon>
                            {/* <span onClick={handleGenerateJSON}>Json</span> */}
                        </button>
                    </div>
                </div>

                {/* <div className="row" style={{ marginTop: "-10px" }}>
                    <div className="col-md-2">
                        <h4 className="mt-2">From Date</h4>
                        <input
                            id1="dateInput"
                            type="date"
                            className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
                            ref={fromDate}
                            defaultValue={defaultValue}
                            name="fromDate"
                            onChange={onChange}
                        ></input>
                    </div>
                    <div className="col-md-2 mt-2">
                        <h4>To Date</h4>
                        <input
                            id2="dateRequired2"
                            type="date"
                            className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                            ref={toDate}
                            defaultValue={defaultValue}
                            name="toDate"
                            onChange={onChange}
                        ></input>
                    </div>
                    <div className="col-md-2 gstrGenerateBtn">
                        <button
                            type="button"
                            className="btn btn-success "
                            onClick={() => fetchData()}
                        >
                            Generate
                        </button>
                    </div>
                </div> */}
                <h2
                    style={{
                        // color: "green",
                        fontSize: "17px",
                        fontWeight: "700"
                    }}
                >
                    {"GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C"}
                </h2>
                <div className="row">
                    <div className="col-md-9"></div>
                    <div
                        className="col-md-3"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "-50px",
                        }}
                    >
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 w-100 tabulator-container gstrTable">
                        <div className="summary-row">
                            <span>No.of Recipients: <span className="fw-bold">{totalData?.totalRecipient ? totalData?.totalRecipient : 0}</span></span>
                            <span>No.of Invoices: <span className="fw-bold">{totalData?.invoice ? totalData?.invoice : 0}</span></span>
                            <span>Total Inv.Value: <span className="fw-bold">{totalData?.totalInvoiceValue ? parseFloat(totalData?.totalInvoiceValue).toFixed(2) : 0}</span></span>
                            <span>Total Taxable: <span className="fw-bold">{totalData?.totalTaxValue ? parseFloat(totalData?.totalTaxValue).toFixed(2) : 0}</span></span>
                            <span>Total Cess: <span className="fw-bold">{totalData?.totalCessValue ? parseFloat(totalData?.totalCessValue).toFixed(2) : 0}</span></span>
                        </div>
                        <ReactTabulator
                            columns={columns}
                            data={itemsValue}
                            // ref={pdfRef}
                            options={{
                                columnHeaderVertAlign: "bottom",
                                layout: 'fitDataTable',
                                // responsiveLayout: "collapse",
                                placeholder: "No records found",
                                height: "420px",
                                // footerElement: `<div style='width:100%;text-align: left; padding: 10px; border: 1px solid rgb(99, 166, 77); border-radius: 5px; display: flex; justify-content: space-between; align-items: center;'>
                                //         <div style='padding-left: 10px;'>Total</div>
                                //         <div style='padding-right: 10px;'>
                                //         ${totalData?.totalInvoiceValue ? parseFloat(totalData?.totalInvoiceValue).toFixed(2) : '0.00'}
                                //         </div>
                                //         <div style='padding-right: 10px;'>
                                //         ${totalData?.totalTaxValue ? parseFloat(totalData?.totalTaxValue).toFixed(2) : '0.00'}
                                //         </div>
                                //         <div style='padding-right: 10px;'>
                                //         ${totalData?.totalCessValue ? parseFloat(totalData?.totalCessValue).toFixed(2) : '0.00'}
                                //         </div>
                                // </div>`
                            }}
                        />
                    </div>
                </div>

                {show ? <ClosingStockReportForm
                    stockMovement={stockMovement}
                    show={show} handleClose={handleClose}
                    // setItemValues={setItemValues}
                    singleStock={itemsValue}
                    setRange={setRange}
                    Title={"Stock Movement Report Form"}
                // search={search}
                /> : null}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { stockMovement, acYear, gstrReport, companyConfig } = state;
    return { stockMovement, acYear, gstrReport, companyConfig }
}

export default connect(mapStateToProps, { fetchAcYear, fetchGstrReport, fetchCompanyConfig })(B2bReport);
