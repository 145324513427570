import { orderConfigActionType } from '../../constants';

export default (state = [], action) => {
    switch (action.type) {
        case orderConfigActionType.FETCH_ORDER_CONFIG:
            return action.payload;
        // case employeeActionType.FETCH_EMPLOYEE:
        //     return [action.payload]
        case orderConfigActionType.ADD_ORDER_CONFIG:
            return action.payload;
        case orderConfigActionType.EDIT_ORDER_CONFIG:
            return action.payload;
        // case employeeActionType.DELETE_EMPLOYEE:
        //     return state.filter(item => item.id !== action.payload);
        default:
            return state;
    }
};