import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { editCustomer } from '../../store/action/customerAction';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Select from 'react-select';
import AddOrderForm from './AddOrderForm';
import { fetchOrderStatus } from '../../store/action/orderStatusAction';
import Loader from '../loader/Loader';
import { getFormattedMessage, placeholderText } from '../../shared/sharedMethod';
import { fetchCompanyConfig } from "../../store/action/companyConfigAction";
import { addOrderConfig, fetchSingleOrder, updateOrderConfig } from '../../store/action/orderConfigAction';
import { orderSingleActionType } from '../../constants';
import { fetchChannels } from '../../store/action/channelAction';
import TabTitle from '../../shared/tab-title/TabTitle';

const SalesOrderForm = (props) => {
    const { fetchOrderStatus, orderStatus, companyConfig, fetchCompanyConfig, fetchChannels, addOrderConfig, singleOrder, updateOrderConfig, fetchSingleOrder } = props;
    const navigate = useNavigate();
    const orderStatusData = useSelector(state => state.orderStatus);
    const channelData = useSelector(state => state.channel);
    const [channelOption, setChannelOption] = useState([]);
    const [show, setShow] = useState(false);
    const [steps, setSteps] = useState([]);
    const [formhead, setFormHeader] = useState('Orders');
    const options = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
    ]
    const dispatch = useDispatch();
    const [orderData, setOrderData] = useState({
        "configId": 0,
        "companyId": 1,
        "channelId": "",
        "channelCurrentSlno": 1,
        "formHeader": "",
        "showMrp": true,
        "showItemDescription": "",
        "imageUpload": "",
        "statusRemark": "",
        "showtax": true,
        "enableGeoLocation": "",
        "showAdvance": false,
        "showStockStatus": true,
        "showRecentSalesOrder": true,
        "showPriceLevel": true
    });

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
                    'linear-gradient(45deg, #28A745, #0099FB)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    // 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
                    'linear-gradient(45deg, #28A745, #0099FB)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor: '#eaeaf0',
            borderRadius: 1,
            ...theme.applyStyles('dark', {
                backgroundColor: theme.palette.grey[800],
            }),
        },
    }));

    const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[700],
        }),
        variants: [
            {
                props: ({ ownerState }) => ownerState.active,
                style: {
                    // backgroundImage:
                    //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
                    backgroundImage: 'linear-gradient(45deg, #28A745, #0099FB)',
                    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
                },
            },
            {
                props: ({ ownerState }) => ownerState.completed,
                style: {
                    backgroundImage:
                        'linear-gradient(45deg, #28A745, #0099FB);',
                },
            },
        ],
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            // 1: <SettingsIcon />,
            // 2: <GroupAddIcon />,
            // 3: <VideoLabelIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    useEffect(() => {

        if (orderStatusData?.length > 0) {
            // setSteps(orderStatusData?.data);
            console.log("orderStatusData", orderStatusData?.data);
            setSteps(orderStatusData?.sort((a, b) => a.attributes.sequenceNo - b.attributes.sequenceNo)?.map(item => item.attributes.statusName));
        } else {
            setSteps([]);
        }
    }, [orderStatusData]);
    // const steps = orderStatusData ? orderStatusData?.map(item => item.statusName) : [];

    useEffect(() => {
        fetchOrderStatus(1, 1, 0);
        fetchCompanyConfig();
        fetchChannels();
        console.log("companyConfig in order", companyConfig);
    }, []);

    useEffect(() => {
        console.log("channelData", channelData);

        if (channelData?.length > 0) {
            let data = channelData?.map(item => {
                return { label: item?.attributes?.channelDescription, value: item?.channelId }
            })
            setChannelOption(data);
        }
    }, [channelData]);

    useEffect(() => {
        console.log("orderStatusData", orderStatusData);
    }, [orderStatusData]);

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        let data = { ...orderData };
        data["formHeader"] = formhead;
        data["companyId"] = companyConfig?.data?.attributes?.companyId;
        let response = addOrderConfig(data);
        if (response?.data?.success === true) {
            navigate("/app/salesOrder");
        }
    }

    const handleUpdate = () => {
        let data = { ...orderData };
        data["formHeader"] = formhead ? formhead : "Orders";
        console.log(data);
        let response = updateOrderConfig(data);
        if (response?.data?.success === true) {
            navigate("/app/salesOrder");
        }
    }

    const handleChange = (e, name) => {
        console.log(e);
        console.log(name);
        let order = { ...orderData };
        order[name] = e.value;
        // fetchSingleOrder(e.value, 1, 1);
        // fetchOrderStatus(1, e.value, 0);
        setOrderData(order);
    }

    useEffect(() => {
        console.log("OrderData", orderData);
    }, [orderData]);

    useEffect(() => {
        console.log("singleOrder", singleOrder);
        setFormHeader(singleOrder?.attributes?.formHeader);
        let order = { ...orderData };
        if (singleOrder?.attributes?.formHeader) {
            let data = {
                "configId": singleOrder?.configId,
                "companyId": singleOrder?.attributes?.companyId,
                "channelId": singleOrder?.attributes?.channelId,
                "formHeader": singleOrder?.attributes?.formHeader,
                "showMrp": true,
                "showItemDescription": singleOrder?.attributes?.showItemDescription,
                "imageUpload": singleOrder?.attributes?.imageUpload,
                "statusRemark": singleOrder?.attributes?.statusRemark,
                "showtax": true,
                "enableGeoLocation": singleOrder?.attributes?.enableGeoLocation,
                // "showAdvance": singleOrder?.attributes?.showAdvance,
                "showAdvance": false,
                "showStockStatus": singleOrder?.attributes?.showStockStatus,
                "showRecentSalesOrder": singleOrder?.attributes?.showRecentSalesOrder,
                "showPriceLevel": singleOrder?.attributes?.showPriceLevel
            }
            setOrderData(data);
        }
    }, [singleOrder]);

    const handleBack = () => {
        dispatch({
            type: orderSingleActionType.FETCH_SINGLE_ORDER,
            payload: [],
        });
    }

    const colorStyles = {
        control: (styles, { isFocused }) => ({
          ...styles,
          backgroundColor: "linear-gradient(rgba(220, 64, 87, 1), rgba(118, 34, 47, 1))", // Background color for the dropdown
          borderColor: isFocused ? "#2684FF" : "#ddd", // Change border color when focused
          boxShadow: isFocused ? "0 0 0 1px #2684FF" : "none", // Add shadow on focus
          "&:hover": { borderColor: "#2684FF" }, // Hover state
        }),
        option: (styles, { isFocused, isSelected }) => ({
          ...styles,
        //   backgroundColor: isSelected
        //     ? "#2684FF" // Background color for the selected option
        //     : isFocused
        //     ? "#f0f8ff" // Background color for the focused option
        //     : undefined,
        //   color: isSelected ? "#fff" : "#333", // Text color
        //   "&:active": {
        //     backgroundColor: "#2684FF", // Active state
        //     color: "#fff",
        //   },
        }),
        singleValue: (styles) => ({
          ...styles,
          color: "#333", // Text color for the selected value
        }),
      };
    return (
        <>
            <Loader />
            <TabTitle title={placeholderText("Order Config")} />
            <div className="d-md-flex align-items-center justify-content-between mb-5">
                <h1 className="mb-0 create-title">Order Configuration</h1>
                <div className="text-end mt-4 mt-md-0">
                    <button className="btn btn-outline-primary me-2 save-btn" style={{ width: "auto" }} onClick={singleOrder?.attributes ? handleUpdate : handleSubmit}>
                        {singleOrder?.attributes ? getFormattedMessage("globally.update-btn") : getFormattedMessage("globally.save-btn")}
                    </button>
                    <Link to={"/app/salesOrder"} onClick={handleBack} className="btn btn-outline-primary back-btn">
                        {getFormattedMessage("globally.back-btn")}
                    </Link>
                </div>
            </div>
            <div className='card h-100'>
                <div className='card-body salesOrder'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='row mb-6'>
                                <label className='form-label text-center align-items-baseline col-6'>Form Header</label>
                                <div className='col-6'>
                                    <input type='text' className='form-control' placeholder='Enter Form Header' value={formhead} onChange={(e) => setFormHeader(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="w-25" style={{ float: "inline-end" }}>
                                <div class="">
                                    <Select options={channelOption} className='channel' name='mrp' value={channelOption.filter(obj => obj.value === orderData.channelId)} onChange={(e) => handleChange(e, "channelId")} 
                                    // styles={colorStyles} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <div class="col-2 d-flex flex-column">
                            <label>Order Stages</label>
                            <button class="btn btn-primary mt-2 w-50" onClick={() => setShow(true)}
                                style={{
                                    background: "rgba(0, 153, 251, 1)",
                                    border: "none",
                                    borderRadius: "8px"
                                }}>Add New</button>
                    </div>
                    <div className='col-6'>
                        <Stack sx={{ width: '100%' }} spacing={4}>
                            <Stepper alternativeLabel activeStep={steps?.length} connector={<ColorlibConnector />}>
                                {steps?.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Stack>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-4'>
                        {/* <div className='row mb-6'>
                                <label className='form-label text-center align-items-baseline col-6'>MRP Applicable?</label>
                                <div className='col-6'>
                                    <Select options={options} className='select-box' name='mrp' value={options.filter(obj => obj.value === orderData.showMrp)} onChange={(e) => handleChange(e, "showMrp")} />
                                </div>
                            </div> */}
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Item Description</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' name='itemDescription' value={options.filter(obj => obj.value === orderData.showItemDescription)} onChange={(e) => handleChange(e, "showItemDescription")} />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Upload Image</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' name='imageUpload' value={options.filter(obj => obj.value === orderData.imageUpload)} onChange={(e) => handleChange(e, "imageUpload")} />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Status Remarks</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' name='statusRemark' value={options.filter(obj => obj.value === orderData.statusRemark)} onChange={(e) => handleChange(e, "statusRemark")} />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Show Tax</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' defaultValue={{ value: 1, label: 'Yes' }} isDisabled name='showtax' />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Enable Geo-Location</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' name='geoLocation' value={options.filter(obj => obj.value === orderData.enableGeoLocation)} onChange={(e) => handleChange(e, "enableGeoLocation")} />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Collect Advance</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' name='advanceCollection' value={options.filter(obj => obj.value === orderData.showAdvance)} onChange={(e) => handleChange(e, "showAdvance")} isDisabled={true} />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Stock Status</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' name='advanceCollection' value={options.filter(obj => obj.value === orderData.showStockStatus)} onChange={(e) => handleChange(e, "showStockStatus")} />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Recent Sales Orders</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' name='advanceCollection' value={options.filter(obj => obj.value === orderData.showRecentSalesOrder)} onChange={(e) => handleChange(e, "showRecentSalesOrder")} />
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='form-label text-center align-items-baseline col-6'>Price Level</label>
                            <div className='col-6'>
                                <Select options={options} className='orderConfig select-box' name='advanceCollection' value={options.filter(obj => obj.value === orderData.showPriceLevel)} onChange={(e) => handleChange(e, "showPriceLevel")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
            { orderStatusData?.length > 0 && <AddOrderForm show={show} handleClose={handleClose} orderData={orderStatusData?.filter((item) => (item?.attributes?.isPrimary))} channelId={orderData.channelId} />
}
{/* <AddOrderForm show={show} handleClose={handleClose} orderData={orderStatusData?.filter((item) => (item?.statusId === 1 || item?.statusId === 2))} channelId={orderData?.channelId}/> */ }
        </>
    )
};

const mapStateToProps = (state) => {
    const { employee, despartments, designations, companyConfig, singleOrder } = state;
    return { employee, despartments, designations, companyConfig, singleOrder };
};

export default connect(mapStateToProps, { fetchOrderStatus, fetchCompanyConfig, addOrderConfig, updateOrderConfig, fetchChannels, fetchSingleOrder })(SalesOrderForm);
