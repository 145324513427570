import apiConfig from "../../config/apiConfig";
import { apiBaseURL, lowStockActionType, toastType } from "../../constants";
import { setLoader } from "./loaderAction";
import { setLoading } from "./loadingAction";
import { addToast } from "./toastAction";

export const fetchAllLowStocks =
  (value,filter = {}, isLoading = true) =>
    async (dispatch) => {
      if (isLoading) {
        dispatch(setLoading(true));
        dispatch(setLoader(true));
      }

      let url = apiBaseURL.LOW_STOCK +value ;
      console.log(url)

     await apiConfig
        .get(url)
        .then((response) => {
          console.log("Resonse", response)

          dispatch({
            type: lowStockActionType.FETCH_LOW_STOCK,
            payload: response?.data?.data,
          });


          if (isLoading) {
            dispatch(setLoading(false));
            dispatch(setLoader(false));
          }

        })
        .catch(({ response }) => {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
        });
    };



export const fetchLowStockReportFilter =
    (values,filter = {}, isLoading = true) =>
    async (dispatch) => {
        if (isLoading) {
            dispatch(setLoading(true));
        }
        let url = apiBaseURL.CLOSINGSTOCKREPORT+values;
        console.log(url)
       
        apiConfig
            .get(url)
            .then((response) => {
                console.log("REPONSE =>",response)
                dispatch({  
                    type: lowStockActionType.FETCH_LOW_STOCK_FILTER,
                    payload: response?.data?.data,
                });
                
                if (isLoading) {
                    dispatch(setLoading(false));
                }
            })
            .catch(({ response }) => {
                dispatch(
                    addToast({
                        text: response?.data?.message,
                        type: toastType.ERROR,
                    })
                );
            });
    };
