import {setLoading} from './loadingAction';
import apiConfig from '../../config/apiConfig';
import {toastType, purchaseSlno, apiBaseURL} from '../../constants';
import {addToast} from './toastAction';

export const fetchpurchaseSlNo = () => async (dispatch) => {
    apiConfig.get(apiBaseURL.PURCHASE_SL).then((response) => {
        dispatch({
            type: purchaseSlno.FETCH_PURCHASE_SLNO,
            payload: response.data.data,
        })
    })
    .catch(({ response }) => {
        dispatch(
            addToast({
                text: response?.message,
                type: toastType.ERROR,
            })
        );
    });
}