import apiConfig from "../../config/apiConfig";
import { apiBaseURL, saleActionType, toastType, cartItem, saleSingleActionType } from "../../constants";
import { addToast } from "./toastAction";
import {
    addInToTotalRecord,
    removeFromTotalRecord,
    setTotalRecord,
} from "./totalRecordAction";
import { setLoading } from "./loadingAction";
import requestParam from "../../shared/requestParam";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { callSaleApi } from "./saleApiAction";
import { setSavingButton } from "./saveButtonAction";
import { setLoader } from "./loaderAction";

export const fetchSales =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            dispatch(setLoader(true));
            if (isLoading) {
                // dispatch(setLoading(true));
            }
            const admin = true;
            let url = apiBaseURL.SALES;
            if (
                !_.isEmpty(filter) &&
                (filter.page ||
                    filter.pageSize ||
                    filter.search ||
                    filter.order_By ||
                    filter.created_at ||
                    filter.customer_id)
            ) {
                url += requestParam(filter, admin, null, null, url);
            }
            await apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: saleActionType.FETCH_SALES,
                        payload: response.data.data,
                    });
                    if (response?.data?.success) {
                        dispatch(
                            setTotalRecord(
                                response.data.meta.total !== undefined &&
                                    response.data.meta.total >= 0
                                    ? response.data.meta.total
                                    : response.data.data.total
                            )
                        );
                        dispatch(callSaleApi(false));
                        if (isLoading) {
                            // dispatch(setLoading(false));
                        }
                        dispatch(setLoader(false));
                    } else {
                        dispatch(setLoader(false));
                        // dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

export const fetchPosSalesListing =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            // dispatch(setLoader(true));
            if (isLoading) {
                // dispatch(setLoading(true));
            }
            let url = apiBaseURL.SALES;
            console.log(url)

            apiConfig
                .get(url)
                .then((response) => {
                    console.log("Response => ", response)
                    if (response?.data?.success) {
                        dispatch({
                            type: saleActionType.FETCH_SALES_LISTING,
                            payload: response?.data?.data,
                        });

                        if (isLoading) {
                            dispatch(setLoading(false));
                        }
                        // dispatch(setLoader(true));
                    } else {
                        // dispatch(setLoader(true));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

export const fetchSale =
    (saleId, singleSale, isLoading = true) =>
        async (dispatch) => {
            if (isLoading) {
                dispatch(setLoading(true));
            }
            await apiConfig
                .get(apiBaseURL.SALES + "/" + saleId + "/edit", singleSale)
                .then((response) => {
                    dispatch({
                        type: saleActionType.FETCH_SALE,
                        payload: response?.data?.data,
                    });
                    if (isLoading) {
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

export const fetchSingleSale =
    (saleId, singleSale, isLoading = true) =>
        async (dispatch) => {
            dispatch(setLoader(true));
            if (isLoading) {
                // dispatch(setLoading(true));
            }
            await apiConfig
                .get(apiBaseURL.SALES + "?salestxNo=" + saleId)
                .then((response) => {
                    dispatch({
                        type: saleSingleActionType.FETCH_SINGLE_SALE,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        if (isLoading) {
                            // dispatch(setLoading(false));
                        }
                        dispatch(fetchPosSalesListing());
                        dispatch(setLoader(false));
                    }
                    else {
                        dispatch(setLoader(false));
                        dispatch(fetchPosSalesListing());
                        dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

// export const addSale = (sale, wholesale, update, navigate) => async (dispatch) => {
//     
//     dispatch(setSavingButton(true));
//     await apiConfig
//         .post(apiBaseURL.SALES, sale)
//         .then((response) => {
//             dispatch({
//                 type: saleActionType.ADD_SALE,
//                 payload: response.data.data,
//             });
//             if (response?.data?.success == true) {
//                 dispatch({ type: cartItem.CART_ITEMS, payload: [] });
//                 dispatch(
//                     addToast({
//                         text: getFormattedMessage("sale.success.create.message"),
//                     })
//                 );
//                 dispatch(fetchPosSalesListing());
//                 // navigate("/app/wholesale");
//                 if (wholesale) {
//                     window.location.href = "#/app/wholesale";
//                 } 
//                 if(update){
//                     window.location.href = "#/app/posSales";
//                 }
//                 dispatch(addInToTotalRecord(1));
//                 dispatch(setSavingButton(false));
//             } else {
//                 dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));
//                 dispatch(fetchPosSalesListing());
//             }
//             return true;
//         })
//         .catch(({ response }) => {
//             dispatch(setSavingButton(false));
//             // dispatch(
//             //     addToast({ text: response?.data?.message, type: toastType.ERROR })
//             // );
//             return response;
//         });
// };

export const addSale = (sale, wholesale, update, navigate) => async (dispatch) => {
    dispatch(setSavingButton(true));
    try {
        const response = await apiConfig.post(apiBaseURL.SALES, sale);
        
        dispatch({
            type: saleActionType.ADD_SALE,
            payload: response.data.data,
        });

        if (response?.data?.success === true) {
            dispatch({ type: cartItem.CART_ITEMS, payload: [] });
            dispatch(
                addToast({
                    text: getFormattedMessage(response?.data?.message),
                })
            );
            dispatch(fetchPosSalesListing());

            if (wholesale) {
                window.location.href = "#/app/wholesale";
            } 
            if (update) {
                window.location.href = "#/app/posSales";
            }

            dispatch(addInToTotalRecord(1));
        } else {
            dispatch(
                addToast({ text: response?.data?.message, type: toastType.ERROR })
            );
            dispatch(fetchPosSalesListing());
        }
        
        dispatch(setSavingButton(false));
        return response;  // Return the response
    } catch (error) {
        dispatch(setSavingButton(false));
        dispatch(
            addToast({ text: error.response?.data?.message, type: toastType.ERROR })
        );
        throw error;  // Re-throw the error so it can be caught in the calling function
    }
};


export const editSale = (saleId, sale, navigate) => async (dispatch) => {
    dispatch(setSavingButton(true));
    await apiConfig
        .patch(apiBaseURL.SALES + "/" + saleId, sale)
        .then((response) => {
            dispatch(
                addToast({
                    text: getFormattedMessage("sale.success.edit.message"),
                })
            );
            navigate("/app/sales");
            dispatch({
                type: saleActionType.EDIT_SALE,
                payload: response.data.data,
            });
            dispatch(setSavingButton(false));
        })
        .catch(({ response }) => {
            dispatch(setSavingButton(false));
            dispatch(
                addToast({ text: response.data.message, type: toastType.ERROR })
            );
        });
};

export const deleteSale = (userId) => async (dispatch) => {
    await apiConfig
        .delete(apiBaseURL.SALES + "/" + userId)
        .then(() => {
            dispatch(removeFromTotalRecord(1));
            dispatch({ type: saleActionType.DELETE_SALE, payload: userId });
            dispatch(
                addToast({
                    text: getFormattedMessage("sale.success.delete.message"),
                })
            );
        })
        .catch(({ response }) => {
            response &&
                dispatch(
                    addToast({
                        text: response.data.message,
                        type: toastType.ERROR,
                    })
                );
        });
};
