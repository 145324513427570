import { useState } from "react";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import MasterLayout from "../MasterLayout"
import { Button } from "react-bootstrap-v5";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { addEmpDepartment } from "../../store/action/empDepartmentAction";
import { useNavigate } from "react-router";
import { Filters } from "../../constants";
import { connect } from "react-redux";
import { title } from "faker/lib/locales/az";
import ZoneForm from "./ZoneForm";
import { addZone } from "../../store/action/ZoneAction";

const zoneCreate =  (props) => {

    const {show,handleClose,addZone,title} =props;
    

    const navigate =useNavigate();

    const addZoneData = (formValue) => {
        addZone(formValue, handleClose, Filters.OBJ);
    }
   

    return(
        <div>
            <ZoneForm show={show} handleClose={handleClose} addZoneData={addZoneData}
            title={getFormattedMessage('Zone.create.title')}/>
        </div>
    )
}


export default connect(null,{addZone}) (zoneCreate)