import {
  faEye,
  faFileExcel,
  faFilePdf,
  faFilter,
  faPrint,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactTabulator } from "react-tabulator";
import { fetchAllNilStocks } from "../../../store/action/nilStockReportAction";
import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ClosingStockField from "../closingStockReport/ClosingStockField";
import ClosingStockReportForm from "../closingStockReport/ClosingStockReportForm";
import {
  generateClosingStockReportExcel,
  generatePDF,
  printPDF,
} from "../pdfUtil";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import jsPDF from "jspdf";
import Loader from "../../loader/Loader";
import FilterModal from "../../priceList/FilterModal";

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const NilStockReport = (props) => {
  const {
    fetchAllNilStocks,
    nilStock,
    orientation,
    fetchCompanyConfig,
    companyConfig,
  } = props;
  const today = new Date();
  const formattedDate = formatDate(today);

  const formatDecimal = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();

    return value?.toFixed(2);
  };

  const [filterNilStock, setFilterNilStock] = useState([]);
  const [fieldShow, setFieldShow] = useState(false);
  const handleFieldClose = () => setFieldShow(!fieldShow);
  const [showField, setShowField] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);

  const [label, setLabel] = useState(false);
  const [fetchRangeValue, setFetchRangeValue] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });

  const [range, setRange] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });

  useEffect(() => {
    ;
    setFilterNilStock(nilStock);
  }, [nilStock]);

  useEffect(() => {
    fetchAllNilStocks();
  }, []);

  const paySlipRef = useRef(null);

  useEffect(() => {
    fetchCompanyConfig();
  }, [fetchCompanyConfig]);

  
  const [showFilterModals, setShowFilterModals] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    brand: "ALL",
    category: "ALL",
    group: "ALL",
  });
  const [filterTextShow, setFilterTextShow] = useState(false);
  const showFilterModal = () => {
    console.log("showFilterModal");
    setShowFilterModals(!showFilterModals);
  };
  const selectedFilterSetting = (
    filtered_brand,
    filtered_group,
    filtered_category
  ) => {
    setSelectedFilter({
      brand: filtered_brand,
      category: filtered_category,
      group: filtered_group,
    });

    setFilterTextShow(
      filtered_brand || filtered_group || filtered_category ? true : false
    );
  };
  console.log("selectedFilter", selectedFilter);

  const companyDetails = {
    companyName: companyConfig?.companyName || "Company Name",
    address: `${companyConfig?.attributes?.address1 || ""} , ${
      companyConfig?.attributes?.address2 || ""
    }`,
    phoneNumber: companyConfig?.attributes?.phoneNo || "Phone Number",
  };

  const handleExportToPDF = () => {
    generatePDF(
      paySlipRef,
      companyDetails,
      "Portrait",
      "Nil Stock Report",
      selectedFilter,
      itemsValue,
      columns
    )
      .then((pdf) => {
        pdf.save("Nil Stock Report.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  const handlePrintPDF = () => {
    printPDF(
      paySlipRef,
      companyDetails,
      "Portrait",
      "Nil Stock Report",
      selectedFilter,
      itemsValue,
      columns
    );
  };

  const reportDetails = {
    title: "Nil Stock Report",
    // dateRange: formattedDate
  };

  const handleExportExcel = () => {
    generateClosingStockReportExcel(companyDetails, reportDetails, itemsValue);
  };

  
  // const columns = [
  //   { title: "Code", dataKey: "code", field: "code", headerSort: false, width: "13%" },
  //   { title: "Product Name", dataKey: "productName", field: "productName", headerSort: false, width: '50%' },
  //   { title: "MRP", dataKey: "mrp", field: "mrp", headerSort: false, hozAlign: "right", headerHozAlign: "right", formatter: formatDecimal, width: "12%" },
  //   {
  //     title: "Stock", // Main column title
  //     field: "stock", // Field containing both stock and unit
  //     hozAlign: "center", // Center align the entire column
  //     headerHozAlign: "center", // Center align the header
  //     headerSort: false,
  //     formatter: formatStockColumn, // Custom formatter for stock and unit
  //     width: "25%"
  //   }
  // ];

  const columns = [
    ...(showField?.showCode || showField == undefined  ? [{ title: "Code", field: "code", headerSort: false, width: "13%" }] : [] ),
    {
      title: "Product Name",
      field: "productName",
      headerSort: false,
      width: "50%",
    },
    {
      title: "MRP",
      field: "mrp",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: formatDecimal,
      width: "12%",
    },
    // {
    //   title: "Stock",headerHozAlign:"center",
    //   columns: [
    //     { title: "Stock", datakey :"stock",field: "stock", hozAlign: "center", headerHozAlign: "center", width: "12%"},
    //     { title: "UnitName", datakey :"unitName", field: "unitName", hozAlign: "center", headerHozAlign: "center", width: "12%" }
    //   ],
    //   headerFilter: true
    // }
    {
      title: "Stock",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      field:"stocks",
      // width: "25%",
      height: "10px",
      formatter: function (cell) {
        // Access the row data
        const rowData = cell.getRow().getData();
        const stock = rowData.stock;
        const unitName = rowData.unitName;

        return `<span style="border-right: 2px solid #dddddd;
    padding-right: 3%;
    display: inline-flex;
    height: 43px;
    margin-top: -8px;
    /* padding: 0% 0%; */
    width: 50%;
    justify-content: center;
    align-items: center;">${stock}</span><span style="display: inline-flex;width: 50%; justify-content: center;
    align-items: center;">${unitName}</span>`;
      },
    },
  ];

  const handleSearchData = (e) => {
    const { name, value } = e.target;
    console.log("hi name", name);
    console.log("hi value", value);
    const filtered_NilStock =
      value.length > 0
        ? nilStock?.filter((item) => {
            const itemCode = item?.attributes?.itemCode?.toLowerCase() || "";
            const itemName =
              item?.attributes?.itemName?.toString().toLowerCase() || "";

            return (
              itemCode.includes(value.toLowerCase()) ||
              itemName.includes(value.toLowerCase())
            );
          })
        : nilStock;

    setFilterNilStock(filtered_NilStock);
  };

  const itemsValue =
    filterNilStock?.length > 0 
      ? (selectedFilter.brand!== "ALL" || selectedFilter?.category !=="ALL" ||  selectedFilter?.group !=="ALL" 
        ? filterNilStock.filter(item => {
          let shouldInclude = true;

          // Check brand filter
          if (selectedFilter?.brand !== "ALL" && selectedFilter?.brand !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category1Name === selectedFilter.brand;
          }
  
          // Check category filter
          if (selectedFilter?.category !== "ALL" && selectedFilter?.category !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category2Name === selectedFilter.category;
          }
  
          // Check group filter
          if (selectedFilter?.group !== "ALL" && selectedFilter?.group !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category3Name === selectedFilter.group;
          }
  
          return shouldInclude;  
        }) 
        : filterNilStock 
       ).map(nilStock => {
        return {
        code: nilStock?.attributes?.itemCode,
        productName: nilStock?.attributes?.itemName,
        mrp: nilStock?.attributes?.mrp,
        stock: nilStock?.attributes?.stock,
        unitName: nilStock?.attributes?.unitName,
      };
    })
  : [];

  const totalSalesValue = (data) => {
    return new Intl.NumberFormat("en-IN", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(data.reduce((acc, curr) => acc + parseFloat(curr.stock), 0));
  };

  const handleClick = (e) => {
    setFieldShow(true);
  };

  const onClick = () => {
    setLabel(true);
    setShow(true);
    setFetchRangeValue(range);
    console.log("fetchRangeValue =>", fetchRangeValue);
  };



  return (
    <>
      <Loader />
      <div style={{ backgroundColor: "white", height: "100%" }}>
        {fieldShow && (
          <ClosingStockField
            setShowField={setShowField}
            fieldShow={fieldShow}
            handleFieldClose={handleFieldClose}
            Title={"Show Field"}
            // nilStock={nilStock}
             nilStock={columns}
          />
        )}
        <div className="row">
          <div className="col-md-5">
            <h2 style={{ color: "green" }}>
              Nil Stock Report on {formattedDate}
            </h2>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-2 ">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                borderRadius: "10px",
                width: "220px",
                height: "60px",
                gap: "13px",
                background: "white",
                marginTop: "-20px",
                fontSize: "11px",
                marginLeft: "-20px",
              }}
              ref={paySlipRef}
            >
              <FontAwesomeIcon
                icon={faPrint}
                className="fa-2x search-icon"
                style={{ color: "black", paddingTop: "8px" }}
                //  onClick={exportToPrintPDF}
                onClick={handlePrintPDF}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFileExcel}
                className="fa-2x search-icon "
                style={{
                  color: "green",
                  paddingLeft: "10px",
                  paddingTop: "7px",
                }}
                onClick={handleExportExcel}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFilePdf}
                className="fa-2x search-icon"
                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                onClick={handleExportToPDF}
              ></FontAwesomeIcon>
            </button>
          </div>
        </div>

        <div className="row">
        <div className="col-md-5 mb-3 searchBox mt-1">
        <div className="position-relative d-flex width-420"  style={{ height: "45px" }}>

          <input
            className="form-control ps-8"
            type="search"
            name="searchData"
            id="search"
            placeholder="Search Product Name (or) Product Code"
            aria-label="Search"
            onChange={(e) => handleSearchData(e)}
          />
          <span className="position-absolute d-flex align-items-center top-0 bottom-0 left-0 text-gray-600 ms-3">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
      </div>

        </div>

        <div className="row" >
        <div className=" col-md-8">
              <span
                className="m-0 fw-bold"
                style={{ fontWeight: 500, fontSize: "15px" }}
              >
                <span style={{ fontWeight: "normal" }}>Applied Filters: </span>
                {selectedFilter.brand &&
                  `Brand : ${selectedFilter.brand ?? "ALL"},`}
                {selectedFilter.category &&
                  `Category : ${selectedFilter.category ?? "ALL"},`}
                {selectedFilter.group &&
                  `Group : ${selectedFilter?.group ?? "ALL"}`}
              </span>
            </div>
<div className="col-md-1"></div>
          <div
            className="col-md-3"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-35px",
            }}
          >
            <button
              style={{
                marginTop: "20px",
                border: "none",
                borderRadius: "10px 0 0 10px",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                color: "blue",
                marginLeft: "20px",
              }}
              onClick={showFilterModal}
            >
              <FontAwesomeIcon
                icon={faFilter}
                className="fa-2x search-icon"
                style={{ fontSize: "15px" }}
              />
            </button>
            <button
              style={{
                marginTop: "21px",
                border: " none",
                borderRadius: "0 10px 10px 0",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                marginLeft: "-13px",
                marginRight: "5px",
                color: "blue",
              }}
              onClick={showFilterModal}
            >
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Filter
              </span>
            </button>

            <button
              style={{
                marginTop: "20px",
                border: "none",
                borderRadius: "10px 0 0 10px",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                color: "blue",
              }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={faEye}
                className="fa-2x search-icon"
                style={{ fontSize: "15px" }}
              />
            </button>
            <button
              style={{
                marginTop: "21px",
                border: "none",
                borderRadius: "0 10px 10px 0",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                marginLeft: "-13px",
                marginRight: "35px",
                color: "blue",
              }}
              onClick={handleClick}
            >
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Show Fields
              </span>
            </button>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 w-100 tabulator-container nilStock gstrTable">
            <ReactTabulator
              columns={columns}
              data={itemsValue}
              options={{
                columnHeaderVertAlign: "bottom",
                layout: "fitColumns",
                responsiveLayout: "collapse",
                placeholder: "No records found",
                height: "420px",
            //     footerElement: `<div style='width:100%;text-align: left; padding: 10px; border: 1px solid rgb(99, 166, 77); border-radius: 5px; display: flex; justify-content: space-between; align-items: center;'>
            //         <div style='padding-left: 10px;'>Total</div>
            //         <div style='padding-right: 10px;'>
            //         ${totalSalesValue(itemsValue)}
            //         </div>
            // </div>`,
              }}
            />
          </div>
        </div>

        <FilterModal
          showFilterModals={showFilterModals}
          showFilterModal={showFilterModal}
          selectedFilterSetting={selectedFilterSetting}
          selectedFilter={selectedFilter}
          title="Apply Filter"
        />

        {/* {show ? (
          <ClosingStockReportForm
            nilStock={nilStock}
            show={show}
            handleClose={handleClose}
            // setItemValues={setItemValues}
            singleStock={itemsValue}
            setRange={setRange}
            Title={"Apply Filter"}
            //  search={search}
          />
        ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { nilStock, companyConfig } = state;
  return { nilStock, companyConfig };
};

export default connect(mapStateToProps, {
  fetchAllNilStocks,
  fetchCompanyConfig,
})(NilStockReport);
