import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { Button, Image, Badge } from "react-bootstrap-v5";
import MasterLayout from "../MasterLayout";
import { fetchProducts } from "../../store/action/productAction";
import ReactDataTable from "../../shared/table/ReactDataTable";
// import DeleteProduct from "./DeleteProduct";
import TabTitle from "../../shared/tab-title/TabTitle";
// import ProductImageLightBox from "./ProductImageLightBox";
import user from "../../assets/images/brand_logo.png";
import ViewKanban from "@mui/icons-material/ViewKanban";
import {
  getFormattedDate,
  getFormattedMessage,
  placeholderText,
  currencySymbolHendling,
} from "../../shared/sharedMethod";
import ActionButton from "../../shared/action-buttons/ActionButton";
import { fetchFrontSetting } from "../../store/action/frontSettingAction";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
// import ImportProductModel from "./ImportProductModel";
import { productExcelAction } from "../../store/action/productExcelAction";
import SearchComponent from "../../shared/components/SearchComponent";
import { fetchPriceHistory, fetchPriceListSpecific } from "../../store/action/priceListAction";
import Loader from '../loader/Loader';
import folderImg from '../../assets/images/Folder.png';
import { useNavigate } from "react-router";
import DeleteOrder from "./DeleteOrder";
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "react-router-dom";
import { fetchSingleOrder } from '../../store/action/orderConfigAction';
import { fetchSalesOrders, fetchSingleSalesOrder } from "../../store/action/salesOrderAction";
import ChangeStatus from "./ChangeStatus";
import { fetchCompanyConfig } from "../../store/action/companyConfigAction";
import { fetchSalesOrderLog } from "../../store/action/salesOrderLog";
import SalesKanban from "./SalesKanban";
import SweetAlert from "react-bootstrap-sweetalert";

const SalesOrderList = (props) => {
  const {
    fetchProducts,
    fetchSalesOrders,
    products,
    totalRecord,
    isLoading,
    frontSetting,
    fetchFrontSetting,
    productExcelAction,
    productUnitId,
    allConfigData,
    priceHistoryList,
    priceListing,
    fetchSingleOrder,
    singleOrder,
    salesOrderList,
    fetchCompanyConfig,
    companyConfig,
    fetchSalesOrderLog,
    salesOrderLog,
    fetchSingleSalesOrder,
    singleSalesOrder
  } = props;
  const [deleteModel, setDeleteModel] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [isDelete, setIsDelete] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [lightBoxImage, setLightBoxImage] = useState([]);
  const [product, setProduct] = useState();
  const [filterProduct, setFilterProduct] = useState([]);
  const [priceHistoryModalShow, setPriceHistoryModalShow] = useState(false);
  const [priceList, setPriceList] = useState([]);
  const [showPriceList, setShowPriceList] = useState(false);
  const [importProduct, setimportProduct] = useState(false);
  const [show, setShow] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [convertShow, setConvertShow] = useState(false);
  const [item, setItem] = useState();
  const [formcode, setFormCode] = useState("T04");
  const [parsedFormData, setParsedFormData] = useState();
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setimportProduct(!importProduct);
  };

  const [isWarehouseValue, setIsWarehouseValue] = useState(false);

  useEffect(() => {
    if (isWarehouseValue === true) {
      productExcelAction(setIsWarehouseValue, true, productUnitId);
    }
  }, [isWarehouseValue]);

  useEffect(() => {
    fetchProducts(true);
  }, []);

  useEffect(() => {
    console.log("salesOrderList", salesOrderList);
    // setFilterProduct(products);
    setFilterProduct(salesOrderList ? salesOrderList : []);
  }, [salesOrderList]);

  const onExcelClick = () => {
    setIsWarehouseValue(true);
  };

  useEffect(() => {
    fetchFrontSetting();
    fetchSingleOrder(1, 1, 1);
    fetchSalesOrders();
    fetchCompanyConfig();
  }, []);

  useEffect(() => {
    console.log("salesOrder1", salesOrderList);
  }, [salesOrderList]);

  //   const onClickDeleteModel = (isDelete = null) => {
  //     setDeleteModel(!deleteModel);
  //     setIsDelete(isDelete);
  //   };

  const onClickPriceHistory = useCallback((items) => {
    setShowPriceList(!showPriceList);
    if (!showPriceList) {
      Dispatch(fetchPriceListSpecific(true, items?.id));
    }
  }, [showPriceList, Dispatch]);

  useEffect(() => {
    if (priceHistoryList) {
      setPriceList(priceHistoryList);
    }
  }, [priceHistoryList]);

  useEffect(() => {
    const storedFormData = localStorage.getItem("UserFormCode");
    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      setParsedFormData(parsedFormData?.filter((item) => item?.attributes?.formCode == 'T04' && item?.attributes?.visibility).length > 0 ? true : false);
      console.log("Parsed Form Data:", parsedFormData);
      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
        console.log("Form Code Items:", formCodeItems);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  const goToEditProduct = (item) => {

    console.log(item);
    const id = item.id;
    window.location.href = "#/app/salesOrderForm/edit/" + id;
  };

  const onClickDeleteModel = (isDelete = null) => {
    setDeleteModel(!deleteModel);
    setIsDelete(isDelete);
  };

  const goToProductDetailPage = (item) => {
    console.log(item);
    const id = item.id;
    window.location.href = "#/app/salesOrderForm/view/" + id;
  };

  const columns = [
    {
      name: getFormattedMessage("Images"),
      sortField: "name",
      sortable: false,
      cell: (row) => {
        const imageUrl = row.images ? row.images : null;
        return imageUrl ? (
          <div className="d-flex align-items-center">
            <Button
              type="button"
              className="btn-transparent me-2 d-flex align-items-center justify-content-center"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(!isOpen);
                setLightBoxImage(row.images.imageUrls);
              }}
            >
              <Image
                src={folderImg}
                height="50"
                width="50"
                alt="Product Image"
                className="image image-circle image-mini cursor-pointer"
              />
            </Button>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="me-2">
              <Image
                src={folderImg}
                height="50"
                width="50"
                alt="Product Image"
                className="image image-circle image-mini"
              />
            </div>
          </div>
        );
      },
    },
    {
      name: getFormattedMessage("Order Id"),
      selector: (row) => row.orderId,
      className: "order-id",
      sortField: "orderId",
      sortable: true,
      wrap: true
    },
    {
      name: getFormattedMessage("Date"),
      selector: row => row.date,
      sortField: 'date',
      sortable: false,
    },
    {
      name: getFormattedMessage("Customer Name"),
      selector: row => row.customer_name,
      sortField: 'customer_name',
      sortable: false,
    },
    {
      name: getFormattedMessage("Mobile no"),
      selector: row => row.mobile,
      sortField: 'mobile_no',
      sortable: false,
    },
    {
      name: getFormattedMessage('Order Value'),
      selector: row => row.orderValue,
      sortField: 'order_value',
      sortable: false,
    },
    {
      name: getFormattedMessage('Status'),
      selector: row => row.status,
      sortField: 'status',
      sortable: false,
      cell: (row) => (
        <Badge pill bg="primary">
          {row.status}
        </Badge>
      ),
    },
    {
      name: getFormattedMessage("react-data-table.action.column.label"),
      right: true,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      // width: "120px",
      cell: (row) => (
        <ActionButton
          // isViewIcon={true}
          goToDetailScreen={goToProductDetailPage}
          item={row}
          goToEditProduct={goToEditProduct}
          isEditMode={!row?.billStatus == 1 ? true : false}
          isEditMode2={row?.billStatus == 2 ? true : false}
          // isEditMode={true}
          // isDeleteMode={true}
          isEditIcon={false}
          onClickDeleteModel={onClickDeleteModel}
          onPrintModal={onPrintModal}
          onConvert={onConvert}
          isPrint1={true}
          // isViewPriceHistory={true}
          // onClickPriceHistory={() => onClickPriceHistory(row)}
          module="salesOrder"
        />
      ),
    },
  ];

  const onConvert = (item) => {
    const id = item.id;
    // alert(id);
    setOrderId(id);
    setConvertShow(true);
  }

  const onPrintModal = (item) => {
    const id = item.id;
    // alert(id);
    // fetchSingleSale(id);
    console.log("ITEM_SELECTED", item);
    setItem(item);
    fetchSalesOrderLog(id, companyConfig?.attributes?.companyId ? companyConfig?.attributes?.companyId : 1);
    fetchSingleSalesOrder(id);
    setShow(true);
  };

useEffect(() => {
  console.log("item", item);
},[item]);    

  const handleSearchData = (e) => {
    const { name, value } = e.target;
    console.log("hi name", name);
    console.log("hi value", value);
    const filtered_pro =
      value.length > 0
        ? salesOrderList.filter((item) => {
          const customerName = item?.attributes?.customerName?.toLowerCase() || "";
          const invNo = item?.attributes?.orderId?.toString().toLowerCase() || "";
          const mobileNo = item?.attributes?.mobileNo?.toString().toLowerCase() || "";

          return customerName.includes(value.toLowerCase()) ||
            invNo.includes(value.toLowerCase()) ||
            mobileNo.includes(value.toLowerCase());
        })
        : salesOrderList;

    console.log("filtered_pro", filtered_pro);
    setFilterProduct(filtered_pro);
  };
  // const currencySymbol =
  //   frontSetting && frontSetting.value && frontSetting.value.currency_symbol;
  const itemsValue =
    // currencySymbol &&
    filterProduct &&
    filterProduct?.map((product) => {
      return {
        id: product?.txNo,
        orderId: product?.attributes?.orderId,
        date: moment(product?.attributes?.date).format("DD-MM-YYYY"),
        customer_name: product?.attributes?.customerName,
        mobile: product?.attributes?.mobileNo,
        orderValue: "(" + product?.attributes?.itemIdCount + ") | " + parseFloat(product?.attributes?.orderValue).toFixed(2),
        status: product?.attributes?.status,
        isActive: product?.attributes?.isActive == true ? "Yes" : "No",
        images: product?.attributes?.product_image,
        isInvoiceGenerated: product?.attributes?.isInvoiceGenerated,
        billStatus: product?.attributes?.billStatus,
        count: product?.attributes?.itemIdCount
      };
    });
  console.log("product_image", itemsValue);

  const convert = () => {
    fetchSingleSalesOrder(orderId);
    // navigate(`/app/wholesale/create`);
    window.location.href = `#/app/wholesale/create`;
    setConvertShow(false);
  };
  return (
    <>
      <MasterLayout>
        <Loader />
        <div className="row">
          <div className="mb-2 col-lg-6">
            <h1 className="fw-bolder text-white">List of Orders</h1>
          </div>
          <div className="mb-2 col-lg-6" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link to={''} className="btn imp_product" type="button" style={{ padding: "5px", marginRight: "1%" }} onClick={() => setShowKanban(!showKanban)}><ViewKanban /></Link>
            <Link to={singleOrder?.attributes ? `/app/salesOrder/edit/1` : '/app/salesOrder/create'} className="btn imp_product" type="button" style={{ padding: "5px", marginRight: "1%" }}><SettingsIcon /></Link>
          </div>
        </div>
        <TopProgressBar />
        <TabTitle title={placeholderText("Sales Order")} />
        <SearchComponent
          handleSearchData={handleSearchData}
          ButtonValue={getFormattedMessage("New Order")}
          placeholder={"Search Orders"}
          to="#/app/salesOrderForm/create"
        />
        {showKanban ? <SalesKanban itemsValue={itemsValue ? itemsValue : []} /> :
          <ReactDataTable
            columns={columns}
            items={itemsValue != undefined ? itemsValue : []}
            // onChange={onChange}
            isLoading={isLoading}
            ButtonValue={getFormattedMessage("New Order")}
            // totalRows={totalRecord}
            to="#/app/salesOrderForm/create"
            isShowFilterField
            isUnitFilter
            title={getFormattedMessage("product.input.product-unit.label")}
            // buttonImport={true}
            goToImport={handleClose}
            importBtnTitle={"product.import.title"}
            isExport
            onExcelClick={onExcelClick}
            subHeader={false}
          />
        }
        <DeleteOrder onClickDeleteModel={onClickDeleteModel} deleteModel={deleteModel} onDelete={isDelete} />
        {isOpen && lightBoxImage.length !== 0 && (
          <ProductImageLightBox
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            lightBoxImage={lightBoxImage}
          />
        )}
        {importProduct && (
          <ImportProductModel handleClose={handleClose} show={importProduct} />
        )}
      </MasterLayout>
      <ChangeStatus show={show} handleClose={() => setShow(false)} salesOrderLog={salesOrderLog} singleSalesOrder={singleSalesOrder} singleOrder={singleOrder} singleItem={item}/>
      {convertShow && (
        <SweetAlert
          confirmBtnBsStyle='success mb-3 fs-5 rounded'
          cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
          confirmBtnCssClass='alert_yes'
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          title={`Do you want to convert this Sales Order to Sales?`}
          // onConfirm={onConfirm}
          // onCancel={onCancel}
          showCancel
          focusCancelBtn
          customButtons={
            <>
              <button id="cancel-button" onClick={() => setConvertShow(false)} className="btn btn-secondary">
                No
              </button>

              <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} onClick={(e) => convert()}>
                Yes
              </button>
            </>
          }
        // customIcon={remove} 
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    products,
    totalRecord,
    isLoading,
    frontSetting,
    productUnitId,
    allConfigData,
    priceHistoryList,
    priceListing,
    singleOrder,
    salesOrderList,
    companyConfig,
    salesOrderLog,
    singleSalesOrder
  } = state;
  return {
    products,
    totalRecord,
    isLoading,
    frontSetting,
    productUnitId,
    allConfigData,
    priceHistoryList,
    priceListing,
    singleOrder,
    salesOrderList,
    companyConfig,
    salesOrderLog,
    singleSalesOrder
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  fetchSalesOrders,
  fetchFrontSetting,
  productExcelAction,
  fetchSingleOrder,
  fetchCompanyConfig,
  fetchSalesOrderLog,
  fetchSingleSalesOrder
})(SalesOrderList);
