import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Image, Table } from "react-bootstrap-v5";
import { useParams } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import MasterLayout from "../MasterLayout";
import TabTitle from "../../shared/tab-title/TabTitle";
import { fetchProduct } from "../../store/action/productAction";
import HeaderTitle from "../header/HeaderTitle";
import user from "../../assets/images/brand_logo.png";
import {
    getFormattedMessage,
    placeholderText,
    currencySymbolHendling,
} from "../../shared/sharedMethod";
import Spinner from "../../shared/components/loaders/Spinner";
import { faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import { fetchEmployee } from "../../store/action/employeeAction";
import { fetchDepartment } from "../../store/action/departmentAction";
import { fetchDesignation } from "../../store/action/designationAction";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from "moment";
import ProfileTab from "./ProfileTab";
import Customer from "../../assets/images/Customer.png";
import LedgerReport from "./LedgerReport";

const CustomerDetail = (props) => {
    const { employee, fetchEmployee, isLoading, frontSetting, allConfigData, designations, despartments, fetchDepartment, fetchDesignation, singleEmployee } =
        props;
    const { id } = useParams();
    const product = singleEmployee;
    const [mainTabValue, setMainTabValue] = useState('1');
    const [profileValue, setProfileValue] = useState('1');

    const handleMainTabChange = (event, newValue) => {
        setMainTabValue(newValue);
    };

    const handleProfileTabChange = (event, newValue) => {
        setProfileValue(newValue);
    };

    useEffect(() => {
        //api call
    }, []);

    const sliderImage = product &&
        product?.attributes &&
        product?.attributes?.empImgUrl !== "" && [
            product?.attributes?.empImgUrl,
        ]; // product?.attributes?.product_image?.imageUrls?.map((img) => img);

    return (
        <MasterLayout>
            <TopProgressBar />
            {/* <HeaderTitle
                title={getFormattedMessage("Customer Details")}
                to="/app/posCustomer"
            /> */}
            <div className="card card-body">
                <div className="d-flex mb-4 row">
                    <div className="col-lg-4 col-md-6  col-sm-12 d-flex">
                        <Image src={Customer} className="img-fluid" style={{ height: '50px', width: '50px' }} />
                        <div>
                            <h3 className="fw-bolder ">
                                {"customer Name"}
                            </h3>
                            <span className="ms-2">{"[Customer code]"}</span>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-sm-12 customer-main-tab">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={mainTabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleMainTabChange} aria-label="lab API tabs example">
                                        <Tab label="Profile" value="1" style={{ color: 'black' }} />
                                        <Tab label="Account" value="2" style={{ color: 'black' }} />
                                        <Tab label="Invoices" value="3" style={{ color: 'black' }} />
                                        <Tab label="Reports" value="4" style={{ color: 'black' }} />
                                    </TabList>
                                </Box>
                            </TabContext>
                        </Box>
                        <span style={{ float: "inline-end"}}><FontAwesomeIcon icon={faPenToSquare} style={{ color:"blue", marginRight:"5px"}}/>Edit</span>
                    </div>
                </div>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={profileValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleProfileTabChange} aria-label="lab API tabs example">
                                <Tab label="Profile" value="1" style={{ color: 'black' }} />
                                <Tab label="Ledger" value="4" style={{ color: 'black' }} />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <ProfileTab isLoading={isLoading} />
                        </TabPanel>
                        <TabPanel value="4">
                            <LedgerReport/>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </MasterLayout>
    );
};

const mapStateToProps = (state) => {
    const { employee, isLoading, frontSetting, allConfigData, despartments, designations, singleEmployee } = state;
    return { employee, isLoading, frontSetting, allConfigData, despartments, designations, singleEmployee };
};

export default connect(mapStateToProps, { fetchEmployee, fetchDepartment, fetchDesignation })(CustomerDetail);
