import { lowStockActionType } from "../../constants";

export default (state = [],action) => {
    switch(action.type) {
        case lowStockActionType.FETCH_LOW_STOCK:
            return action.payload
        case lowStockActionType.FETCH_LOW_STOCK_FILTER  :
            return action.payload;  
        default :
            return state
    }
}