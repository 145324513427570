import apiConfig from "../../config/apiConfig";
import { apiBaseURL, openingStockActionType, productActionType, toastType } from "../../constants";
import { addToast } from "./toastAction";

import { setLoading } from "./loadingAction";
import { removeFromTotalRecord } from "./totalRecordAction";
import { fetchAllProducts } from "./productAction";


// export const fetchOpeningStock =
// (productId, singleProduct, isLoading = true) =>
//   async (dispatch) => {
//     
//     if (isLoading) {
//       dispatch(setLoading(true));
//     }
//     await apiConfig
//       .get(apiBaseURL.PRODUCTS_STOCK + "?itemId=" + productId)
//       .then((response) => {
//         console.log("URL :",apiBaseURL.PRODUCTS_STOCK + "?itemId=" + productId )
//         dispatch({
//           type: openingStockActionType.FETCH_OPENING_STOCK,
//           payload: response?.data?.data,
//         });
//         if (isLoading) {
//           dispatch(setLoading(false));
//         }
//       })
//       .catch(({ response }) => {
//         dispatch(
//           addToast({
//             text: response?.data?.message,
//             type: toastType.ERROR,
//           })
//         );
//       });
//   };



export const fetchOpeningStock =
    (productId,filter = {}, isLoading = true) =>
    async (dispatch) => {
        if (isLoading) {
            dispatch(setLoading(true));
        }

        let url =apiBaseURL.PRODUCTS_STOCK + "?itemId=" + productId
        console.log(url)
        // if (
        //     !_.isEmpty(filter) &&
        //     (filter.page ||
        //         filter.pageSize ||
        //         filter.search ||
        //         filter.order_By ||
        //         filter.created_at)
        // ) {
        //     url += requestParam(filter, null, null, null, url);
        // }
        apiConfig
            .get(url)
            .then((response) => {
               console.log("Resonse",response)

                dispatch({
                    type: openingStockActionType.FETCH_OPENING_STOCK,
                    payload: response?.data?.data,
                });
          
                
               if (isLoading) {
                dispatch(setLoading(false));
            }   
               
            })
            .catch(({ response }) => {
                dispatch(
                    addToast({
                        text: response?.data?.message,
                        type: toastType.ERROR,
                    })
                );
            });
    };


    // export const deleteOpeningStock =
    // (values, isLoading = true) =>
    // async (dispatch) => {
    //     if (isLoading) {
    //         dispatch(setLoading(true));
    //     }
    //     apiConfig
    //         .get(apiBaseURL.PRODUCTS_STOCK + values)
            
    //         // console.log(apiBaseURL.CUSTOMERS + "?customerId=" + customerId)
    //         .then((response) => {
    //             console.log(response)
    //             dispatch({
    //                 type: openingStockActionType.DELETE_OPENING_STOCK,
    //                 payload: response?.data?.message,
    //             });
    //             if (isLoading) {
    //                 dispatch(setLoading(false));
    //             }
    //         })
    //         .catch(({ response }) => {
    //             dispatch(
    //                 addToast({
    //                     text: response?.data?.message,
    //                     type: toastType.ERROR,
    //                 })
    //             );
    //         });
    // };

    export const deleteOpeningStock = (values, productId, isLoading = true) => async (dispatch) => {
      
        apiConfig
        .delete(apiBaseURL.PRODUCTS_STOCK + values)
          .then((response) => {
            console.log("res===>", response);
            if (!response?.data?.success == true) {
                dispatch({
                    type: openingStockActionType.DELETE_OPENING_STOCK,
                    payload: response?.data?.message,
                });
                dispatch(
                  addToast({ text: response?.data?.message, type: toastType.ERROR })
                );
                if (isLoading) {
                    dispatch(setLoading(false));
                }
            } else {
              // Dispatch success action
              // dispatch({
              //   type: openingStockActionType.DELETE_OPENING_STOCK,
              //   payload: response?.data?.message,
              // });
              dispatch(fetchOpeningStock(productId, isLoading));
              dispatch(
                addToast({ text: response?.data?.message, type: toastType.success })
              );
              // Fetch the updated stock list after successful deletion
          
            }
          })
          .catch(({ response }) => {
            dispatch(
              addToast({ text: response?.data?.message, type: toastType.ERROR })
            );
          });
    };
    