import BeauteSoft from "./BeauteSoft";

const EmployeeSummary=()=>{
  return(
<div>
  {/* <BeauteSoft/> */}
</div>

  )
}
export default EmployeeSummary;