import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { Button, InputGroup, Table } from 'react-bootstrap-v5';
import { searchPurchaseProduct } from '../../store/action/purchaseProductAction';
import { addPurchase, editPurchase } from '../../store/action/purchaseAction';
import { fetchAllProducts } from '../../store/action/productAction';
import { posAllProduct } from '../../store/action/pos/posAllProductAction';
import { preparePurchaseProductArray } from '../../shared/prepareArray/preparePurchaseArray';
import { getFormattedMessage, getFormattedOptions } from '../../shared/sharedMethod';
import { calculateCartTotalAmount, calculateCartTotalTaxAmount } from '../../shared/calculation/calculation';
import { addToast } from '../../store/action/toastAction';
import { saleStatusOptions, toastType, cartItem, singleSalesOrderActionType } from '../../constants';
import ProductCartList from '../../frontend/components/cart-product/ProductCartList';
import CartItemMainCalculation from '../../frontend/components/cart-product/CartItemMainCalculation';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import ProductSearchbar from '../../frontend/components/product/ProductSearchbar';
import { addSale } from '../../store/action/salesAction';
import Loader from '../loader/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { fetchSalesInvNo } from '../../store/action/invoiceAction';
import CreateCustomersWholeSale from './CreateCustomersWholeSale';
import { fetchPriceLevel } from "../../store/action/priceLevelListAction";
import { setLoader } from '../../store/action/loaderAction';
import { fetchMultipleSalesOrderNo } from '../../store/action/multipleSalesOrderAction';
import { fetchMultipleSalesOrderDetails } from '../../store/action/multipleOrderDetailsAction';
import { fetchCompanyConfig } from '../../store/action/companyConfigAction';
import { fetchSingleOrder } from '../../store/action/orderConfigAction';

const WholeSaleForm = (props) => {
    const {
        addPurchaseData,
        id,
        editPurchase,
        customProducts,
        singlePurchase,
        warehouses,
        suppliers,
        ledger,
        fetchAllProducts,
        posAllProducts,
        addPurchase,
        addSale,
        fetchSalesInvNo,
        posAllProduct,
        singleSale,
        singleOrder,
        salesInvoice,
        products, frontSetting, allConfigData, sales,
        fetchPriceLevel,
        fetchMultipleSalesOrderNo,
        fetchMultipleSalesOrderDetails,
        fetchSingleOrder,
        singleSalesOrder,
        multipleSalesOrderNo, fetchCompanyConfig, companyConfig
    } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();
    const [flag, setFlag] = useState(1);
    const [newCost, setNewCost] = useState('');
    const [updateProducts, setUpdateProducts] = useState([]);
    const [customerShow, setCustomerShow] = useState(false);
    const [newCustomer, setNewCustomer] = useState();
    const [purchase, setPurchase] = useState();
    const [backAlert, setBackAlert] = useState(false);
    const [customerAlert, setCustomerAlert] = useState(false);
    const [generateAlert, setGenerateAlert] = useState(false);
    const [priceLvlAlert, setPriceLvlAlert] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [invNo, setInvNo] = useState(0);
    const [warehouseDef, setWarehouseDef] = useState({});
    const [priceLvlDef, setPriceLvlDef] = useState();
    const [paymentMethod, setPaymentMethod] = useState({ label: "CREDIT", value: "Credit" });
    const cartitems = useSelector((state) => state.cartItems);
    const priceLevel = useSelector((state) => state.priceLevel);
    const cartcalc = useSelector((state) => state.cartValues);
    const salesOrdersNo = useSelector((state) => state.multipleSalesOrderNo);
    const multipleSalesOrderDetail = useSelector((state) => state.multipleSalesOrderDetail);
    const [salesOrderNoOption, setSalesOrderNoOption] = useState([]);
    const [userId, setUserId] = useState(null);
    const [selectedOrderNoOptions, setSelectedOrderNoOptions] = useState([]);
    const [generated, setGenerated] = useState(false);
    const [customerCreate, setCustomerCreate] = useState(false);
    const [selectedOrderNoOptionsPrev, setSelectedOrderNoOptionsPrev] = useState([]);
    const [purchaseValue, setPurchaseValue] = useState({
        date: singlePurchase ? moment(singlePurchase.date).toDate() : new Date(),
        warehouse_id: singlePurchase ? singlePurchase.warehouse_id : '',
        supplier_id: singlePurchase ? singlePurchase.supplier_id : '',
        tax_rate: singlePurchase ? singlePurchase.tax_rate.toFixed(2) : '0.00',
        tax_amount: singlePurchase ? singlePurchase.tax_amount.toFixed(2) : '0.00',
        discount: singlePurchase ? singlePurchase.discount.toFixed(2) : '0.00',
        shipping: singlePurchase ? singlePurchase.shipping.toFixed(2) : '0.00',
        grand_total: singlePurchase ? singlePurchase.grand_total : '0.00',
        notes: singlePurchase ? singlePurchase.notes : '',
        status_id: singlePurchase ? singlePurchase.status_id : { label: getFormattedMessage("status.filter.received.label"), value: 1 },
    });
    const [errors, setErrors] = useState({
        date: '',
        warehouse_id: '',
        supplier_id: '',
        details: '',
        tax_rate: '',
        discount: '',
        shipping: '',
        status_id: ''
    });

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const [formcode, setFormCode] = useState("T03");
    // const navigate = useNavigate();
    useEffect(() => {
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            console.log("Parsed Form Data:", parsedFormData);
            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
                console.log("Form Code Items:", formCodeItems);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);

    useEffect(() => {
        fetchSalesInvNo();
        fetchPriceLevel();
        fetchCompanyConfig();
        fetchSingleOrder(1, 1, 1);
    }, []);

    useEffect(() => {
        console.log("salesOrdersNo", salesOrdersNo);
        if (salesOrdersNo?.length > 0) {
            let data = salesOrdersNo?.map((item) => {
                return { label: item?.attributes?.orderId, value: item?.attributes?.orderId };
            });
            setSalesOrderNoOption(data);
        } else {
            setSalesOrderNoOption([]);
        }
    }, [salesOrdersNo]);

    useEffect(() => {
        console.log("singleSalesOrder", singleSalesOrder);
        let purchaseData = { ...purchase };
        if (singleSalesOrder?.attributes) {
            fetchMultipleSalesOrderNo(singleSalesOrder?.attributes?.customerId, "");
            purchaseData["suppliername"] = singleSalesOrder?.attributes?.customerName;
            purchaseData["suppliermobile"] = singleSalesOrder?.attributes?.mobileNo;
            purchaseData["supplieraddress"] = singleSalesOrder?.attributes?.address;
            purchaseData["city"] = singleSalesOrder?.attributes?.city;
            purchaseData["state"] = singleSalesOrder?.attributes?.state;
            purchaseData["suppliergstin"] = singleSalesOrder?.attributes?.gstNo;
            purchaseData["payment_Type"] = singleSalesOrder?.attributes?.salesOrder4[0]?.paymentType;
            purchaseData["bill_Amount"] = singleSalesOrder?.attributes?.orderValue;
            purchaseData["salesOrderNo"] = singleSalesOrder?.attributes?.orderNo;
            // purchaseData["decimal"] = singleSalesOrder?.attributes?.decimalPoints;
            // purchaseData["invoice_Date"] = moment(singleSalesOrder?.attributes?.invDate).format('YYYY-MM-DD');
            // setPaymentMethod({ label: singleSalesOrder?.attributes?.salesOrder4[0]?.paymentType?.toUpperCase(), value: capitalizeFirstLetter(singleSalesOrder?.attributes?.salesOrder4[0]?.paymentType) });
            setPaymentMethod({ label: 'CREDIT', value: "Credit" });
            let salesOrderoption = singleSalesOrder?.attributes?.orderNo?.split(",")?.map((item) => { return { label: item, value: item } })
            setGenerated(salesOrderoption ? true : false);
            setSelectedOrderNoOptions((prev) => {
                // setSelectedOrderNoOptionsPrev(salesOrderoption);
                return salesOrderoption
            });
            // setInvNo(singleSalesOrder?.attributes?.invNo);
            let customer = ledger.filter((item) => item?.id === singleSalesOrder?.attributes?.customerId);
            let pricelvl = priceLevel.filter((item) => item?.pricelevelno === singleSalesOrder?.attributes?.priceLevelNo);
            if (pricelvl.length > 0) {
                setPriceLvlDef({ label: pricelvl[0]?.attributes?.pricelevelname, value: pricelvl[0]?.pricelevelno });
                // posAllProduct(pricelvl[0]?.pricelevelno);
            } else {
                setPriceLvlDef({ label: "Regular", value: 0 });
            }
            purchaseData["supplier"] = { label: (customer[0]?.attributes?.salutation != "" ? (customer[0]?.attributes?.salutation + ".") : "") + customer[0]?.attributes?.ledgerName, value: customer[0]?.id };
            purchaseData["supplierId"] = customer[0]?.id;
            let newProduct = {};
            let cartItems = singleSalesOrder?.attributes?.salesOrder2?.map((items) => {
                return {
                    name: items?.itemName,
                    id: items?.itemId,
                    description: items?.itemDesc,
                    quantity: items?.pack_count > 1 ? 0 : items?.qty,
                    salesQty: items?.pack_count > 1 ? items?.qty : 0,
                    netSalesRate: items?.netSalesRate,
                    netAmount: items?.netAmount,
                    pack_count: items?.pack_count,
                    tax_amount: items?.taxAmount,
                    taxAmount: items?.taxAmount,
                    taxPercentage: items?.tax,
                    grossAmount: items?.grossAmount,
                    calculation: {
                        totalQty: items?.qty,
                        basicAmount: items?.basicAmount,
                        discAmount: items?.discAmount,
                        totalDiscAmount: items.totalDiscAmount,
                        grossAmount: items?.grossAmount,
                        taxAmount: items?.taxAmount,
                        rateWithTax: items?.rateWithTax,
                        netAmount: items?.netAmount
                    },
                    unit: items?.salesUnitName,
                    sales_unit_name: items?.salesUnitName,
                    purchase_unit_name: items?.purchaseUnitName,
                    // stock: product?.stock,
                    // code: product?.code,
                    purchaseRate: items?.rate,
                    discount: items?.discPercent,
                    lessRs: items?.lessAmount,
                    mrp: items?.mrp,
                    product_price: items?.mrp,
                    decimal: items?.decimalPoints,
                    lineId: items?.slNo,
                }
            });
            console.log(cartItems);

            dispatch({ type: cartItem.CART_ITEMS, payload: [...cartItems] });
            setPurchase(purchaseData);
        }
    }, [singleSalesOrder]);

    useEffect(() => {
        console.log("WholeSale All Product", posAllProducts);
        console.log(cartitems);
        let CartData = [];
        // cartitems?.map((item) => {
        //     if (posAllProducts?.length > 0) {
        //         console.log("Filtered Product", item);
        //         console.log("Filtered Product", posAllProducts?.filter((product) => product?.items_id == item?.id && product?.attributes?.mrp == item?.mrp));
        //         let data = priceLvlCalculation(item, posAllProducts?.filter((product) => product?.items_id == item?.id && product?.attributes?.mrp == item?.mrp)[0]);
        //         CartData.push(data);
        //     }
        // });
        processCartItems();
    }, [posAllProducts]);

    const processCartItems = async () => {
        if (flag != 1) {
            setIsProcess(true); // Set the flag to true at the start
            dispatch(setLoader(true));
            let CartData = [];
            for (const item of cartitems || []) {
                if (posAllProducts?.length > 0) {
                    console.log("Filtered Product", item);
                    const filteredProduct = posAllProducts?.filter(
                        (product) =>
                            product?.items_id == item?.id &&
                            product?.attributes?.mrp == item?.mrp
                    )[0];
                    console.log("Filtered Product", filteredProduct);

                    // Await the calculation if it's asynchronous
                    let data = priceLvlCalculation(item, filteredProduct);
                    CartData.push(data);
                }
            }
            setIsProcess(false); // Set the flag to false after all items are processed
            dispatch(setLoader(false));
            dispatch
            console.log("CartData", CartData);
            dispatch({ type: cartItem.CART_ITEMS, payload: CartData });
        }
    };

    const priceLvlCalculation = (item, product) => {
        let calc = {};
        debugger;
        if (item && product) {
            let totalQty = parseFloat(((item?.quantity ? parseFloat(item?.quantity).toFixed(item?.decimal ? item?.decimal : 0) : 0) * (product?.attributes?.pack_count ? product?.attributes?.pack_count : 0)) + (item?.salesQty ? parseFloat(item?.salesQty) : 0)).toFixed(item?.decimal ? item?.decimal : 0);
            calc['totalQty'] = totalQty;
            // let netRate = netRatevalue && netRatevalue != "" ? netRatevalue : 0;
            let netRate = parseFloat((product?.attributes?.salesPrice) * (1 + (product?.attributes?.tax / 100))).toFixed(2);
            calc['netRate'] = netRate;
            let basicRate = netRate / (1 + (item?.taxPercentage ? item?.taxPercentage : product?.attributes?.tax ? product?.attributes?.tax : 0) / 100);
            calc['basicRate'] = basicRate;
            let basic = (totalQty ? totalQty : 0) * (basicRate ? basicRate : 0);
            calc['basicAmount'] = basic;
            let discAmount = (basic) * (parseFloat(item?.discount != "" && !isNaN(item?.discount) ? item?.discount : 0) / 100);
            calc['discAmount'] = discAmount;
            let totalDiscAmount = (discAmount ? discAmount : 0) + parseFloat(item?.lessRs != "" && !isNaN(item?.lessRs) ? item?.lessRs : 0);
            calc['totalDiscAmount'] = totalDiscAmount;
            let grossAmount = (basic - totalDiscAmount);
            calc['grossAmount'] = grossAmount;
            let taxAmount = (grossAmount * ((product?.attributes?.tax ? product?.attributes?.tax : 0) / 100));
            calc['taxAmount'] = taxAmount;
            let netSalesRate = ((basic - totalDiscAmount) + taxAmount) / totalQty;
            calc['netSalesRate'] = netSalesRate;
            let netAmount = (grossAmount + taxAmount);
            calc['netAmount'] = netAmount;
        }
        console.log("PriceLVL Calculation", calc);

        let data = {
            "name": item?.name,
            "id": item?.id,
            "quantity": item?.quantity,
            "salesQty": item?.salesQty,
            "netSalesRate": calc?.netSalesRate,
            "netAmount": calc?.netAmount,
            "pack_count": item?.pack_count,
            "tax_amount": calc?.taxAmount,
            "taxAmount": calc?.taxAmount,
            "taxPercentage": item?.taxPercentage,
            "grossAmount": calc?.grossAmount,
            "calculation": calc,
            "unit": item?.unit,
            "sales_unit_name": item?.sales_unit_name,
            "purchase_unit_name": item?.purchase_unit_name,
            "stock": item?.stock,
            "code": item?.code,
            "purchaseRate": calc?.basicRate,
            "discount": item?.discount,
            "lessRs": item?.lessRs,
            "mrp": item?.mrp,
            "product_price": item?.mrp,
            "decimal": item?.decimal,
            "lineId": item?.lineId,
            "description": item?.description,
        }
        return data;
    }

    useEffect(() => {
        console.log("SalesInvoice", salesInvoice);
        let purchaseData = { ...purchase };
        purchaseData["invoice_Number"] = salesInvoice?.attributes?.salesInvNo;
        setInvNo(salesInvoice?.attributes?.salesInvNo);
        setPurchase(purchaseData);
    }, [salesInvoice])

    useEffect(() => {
        // console.log(warehouses?.map((warehouse) => ({ label: warehouse?.attributes?.warehouseName, value: warehouse?.id }))[0]);
        // console.log(warehouses[0]?.attributes?.warehouseName);
        setWarehouseDef(warehouses?.map((warehouse) => ({ label: warehouse?.attributes?.warehouseName, value: warehouse?.id }))[0]);
        if (warehouses) {
            // setWarehouseDef()
            // console.log
        }
    }, [warehouses]);

    useEffect(() => {
        console.log("SALES", sales);
        let purchaseData = { ...purchase };
        if (sales?.length > 0) {
            if (sales[sales?.length - 1]?.attributes && !singleSale?.attributes) {
                console.log(sales[sales?.length - 1]?.attributes?.invNo);
                let nextNumber = incrementNumber(sales[sales?.length - 1]?.attributes?.invNo);
                console.log(nextNumber);
                // purchaseData["invoice_Number"] = nextNumber;
                // setInvNo(nextNumber);
                // setPurchase(purchaseData);
            } else {

            }
        }
        setPurchase(purchaseData);
    }, [sales]);

    useEffect(() => {
        console.log("PURCHASE", purchase);
    }, [purchase]);

    const incrementNumber = (str) => {
        // Extract the numeric part of the string between '#' and '/'
        let prefix = str.substring(0, str.indexOf('/'));
        let numberPart = prefix.replace('', '');

        // Convert the extracted part to a number and increment it
        let incrementedNumber = (parseInt(numberPart, 10) + 1).toString().padStart(3, '0');

        // Return the new string with the incremented number
        return `${incrementedNumber}${str.substring(str.indexOf('/'))}`;
    }

    const capitalizeFirstLetter = (string) => {
        let val = string.toLowerCase();
        console.log(val.charAt(0).toUpperCase() + val.slice(1));
        return val.charAt(0).toUpperCase() + val.slice(1);
    }

    useEffect(() => {
        if (newCustomer != undefined) {
            let purchaseData = { ...purchase };
            let supplier = ledger?.filter((ledger) => ledger.id == newCustomer?.id);
            purchaseData["supplier"] = { label: (supplier[0]?.attributes?.salutation != "" ? (supplier[0]?.attributes?.salutation + ".") : "") + supplier[0]?.attributes?.ledgerName, value: supplier[0]?.id };
            // purchaseData["supplierId"] = supplier[0]?.id;
            purchaseData["suppliername"] = supplier[0]?.attributes?.ledgerName;
            purchaseData["supplieraddress"] = supplier[0]?.attributes?.address + " " + supplier[0]?.attributes?.area;
            purchaseData["city"] = supplier[0]?.attributes?.city;
            purchaseData["state"] = supplier[0]?.attributes?.state;
            purchaseData["suppliermobile"] = supplier[0]?.attributes?.mobileNo1;
            purchaseData["suppliergstin"] = supplier[0]?.attributes?.regNo;
            setPurchase(purchaseData);
        }
    }, [ledger]);

    useEffect(() => {
        console.log("singleSale", singleSale);
        let purchaseData = { ...purchase };
        if (singleSale?.attributes) {
            fetchMultipleSalesOrderNo(singleSale?.attributes?.customerId, "");
            purchaseData["invoice_Number"] = singleSale?.attributes?.invNo;
            purchaseData["suppliername"] = singleSale?.attributes?.customerName;
            purchaseData["suppliermobile"] = singleSale?.attributes?.customerMobile;
            purchaseData["supplieraddress"] = singleSale?.attributes?.customerAddress;
            purchaseData["city"] = singleSale?.attributes?.city;
            purchaseData["state"] = singleSale?.attributes?.state;
            purchaseData["suppliergstin"] = singleSale?.attributes?.customerRegNo;
            purchaseData["payment_Type"] = singleSale?.attributes?.paymentType;
            purchaseData["bill_Amount"] = singleSale?.attributes?.billAmount;
            purchaseData["decimal"] = singleSale?.attributes?.decimalPoints;
            purchaseData["invoice_Date"] = moment(singleSale?.attributes?.invDate).format('YYYY-MM-DD');
            setPaymentMethod({ label: singleSale?.attributes?.paymentType?.toUpperCase(), value: capitalizeFirstLetter(singleSale?.attributes?.paymentType) });
            setInvNo(singleSale?.attributes?.invNo);
            let customer = ledger.filter((item) => item?.id === singleSale?.attributes?.customerId);
            let pricelvl = priceLevel.filter((item) => item?.pricelevelno === singleSale?.attributes?.priceLevelNo);
            if (pricelvl.length > 0) {
                setPriceLvlDef({ label: pricelvl[0]?.attributes?.pricelevelname, value: pricelvl[0]?.pricelevelno });
                // posAllProduct(pricelvl[0]?.pricelevelno);
            } else {
                setPriceLvlDef({ label: "Regular", value: 0 });
            }
            purchaseData["supplier"] = { label: (customer[0]?.attributes?.salutation != "" ? (customer[0]?.attributes?.salutation + ".") : "") + customer[0]?.attributes?.ledgerName, value: customer[0]?.id };
            purchaseData["supplierId"] = customer[0]?.id;
            let newProduct = {};
            let salesOrderoption = singleSale?.attributes?.salesOrderNo != "" ? singleSale?.attributes?.salesOrderNo?.split(",")?.map((item) => { return { label: item, value: item } }) : [];
            setGenerated(salesOrderoption ? true : false);
            if (saleStatusOptions.length > 0) {
                setSelectedOrderNoOptions((prev) => {
                    // setSelectedOrderNoOptionsPrev(salesOrderoption);
                    return salesOrderoption
                });
            }
            let cartItems = singleSale?.attributes?.sales2?.map((items) => {
                return {
                    name: items?.itemName,
                    id: items?.itemId,
                    quantity: items?.pack_count > 1 ? 0 : items?.qty,
                    salesQty: items?.pack_count > 1 ? items?.qty : 0,
                    netSalesRate: items?.netSalesRate,
                    description: items?.itemDesc,
                    netAmount: items?.netAmount,
                    pack_count: items?.pack_count,
                    tax_amount: items?.taxAmount,
                    taxAmount: items?.taxAmount,
                    taxPercentage: items?.tax,
                    grossAmount: items?.grossAmount,
                    calculation: {
                        totalQty: items?.qty,
                        basicAmount: items?.basicAmount,
                        discAmount: items?.discAmount,
                        totalDiscAmount: items.totalDiscAmount,
                        grossAmount: items?.grossAmount,
                        taxAmount: items?.taxAmount,
                        rateWithTax: items?.rateWithTax,
                        netAmount: items?.netAmount
                    },
                    unit: items?.salesUnitName,
                    sales_unit_name: items?.salesUnitName,
                    purchase_unit_name: items?.purchaseUnitName,
                    // stock: product?.stock,
                    // code: product?.code,
                    purchaseRate: items?.rate,
                    discount: items?.discPercent,
                    lessRs: items?.lessAmount,
                    mrp: items?.mrp,
                    product_price: items?.mrp,
                    decimal: items?.decimalPoints,
                    lineId: items?.slNo,
                }
            });
            console.log(cartItems);

            dispatch({ type: cartItem.CART_ITEMS, payload: [...cartItems] });
            setPurchase(purchaseData);
        }
    }, [singleSale]);

    useEffect(() => {
        console.log("CART ITEMS", cartitems);
    }, [cartitems]);

    useEffect(() => {
        console.log("LEDGER", ledger);
    }, [ledger]);

    useEffect(() => {
        console.log("priceLevel", priceLevel);
    }, [priceLevel])

    useEffect(() => {
        let date = moment(new Date()).format('YYYY-MM-DD');
        let purchaseData = { ...purchase };
        purchaseData["Date"] = date;
        purchaseData["invoice_Date"] = date;
        setPurchase(purchaseData);
    }, []);

    useEffect(() => {
        posAllProduct();
        dispatch({ type: cartItem.CART_ITEMS, payload: [] });
        let data = localStorage.getItem("loginUserArray");
        console.log(JSON.parse(data)['id']);
        setUserId(JSON.parse(data)['id']);
    }, []);

    const handleDate = (e) => {
        let date = moment(e).format('YYYY-MM-DD');
        // setPurchaseValue((prev) => ({ ...prev, date }));
        let purchaseData = { ...purchase };
        purchaseData["Date"] = date;
        setPurchase(purchaseData);
    }

    const handleInvoiceDate = (e) => {
        let date = moment(e).format('YYYY-MM-DD');
        // setPurchaseValue((prev) => ({ ...prev, date }));
        let purchaseData = { ...purchase };
        purchaseData["invoice_Date"] = date;
        setPurchase(purchaseData);
    }

    const handleInvoiceNumber = (e) => {
        const allowedCharacters = /^[a-zA-Z0-9\/\-]*$/;
        let purchaseData = { ...purchase };
        let newValue = '';
        for (let char of e) {
            if (allowedCharacters.test(char)) {
                newValue += char;
            }
        }
        console.log(newValue)
        e = newValue;
        purchaseData["invoice_Number"] = newValue;
        setPurchase(purchaseData);
    }

    const handlePayment = (e) => {
        let purchaseData = { ...purchase };
        setPaymentMethod(e);
        if (e.value === "Credit") {
            // purchaseData["supplierId"] = "";
            // purchaseData["suppliername"] = "";
            // purchaseData["supplieraddress"] = "";
            // purchaseData["suppliermobile"] = "";
            // purchaseData["suppliergstin"] = "";
            // if (purchase.supplier) {
            //     handleCombo(purchase.supplier);
            // }
        } else if (e.value === "Cash") {
            let supplier = ledger?.filter((item) => item.attributes.underGroup == "CASH-IN-HAND");
            purchaseData["supplierId"] = supplier[0]?.id;
        } else if (e.value === "Card" || e.value === "Upi") {
            let supplier = ledger?.filter((item) => item.attributes.underGroup == "BANK" && item.attributes.forSales == true);
            purchaseData["supplierId"] = supplier[0]?.id;
        }
        setPurchase(purchaseData);
    }

    const handleCombo = (e) => {
        console.log(e);
        let purchaseData = { ...purchase };
        if (typeof (e) === "object") {
            let supplier = ledger?.filter((item) => item.id == e.value);
            if (selectedOrderNoOptions?.length > 0) {
                // setCustomerAlert(true);
            } else {
                fetchMultipleSalesOrderNo(e.value, "");
            }
            purchaseData["supplier"] = e;
            // purchaseData["supplierId"] = supplier[0]?.id;
            purchaseData["suppliername"] = supplier[0]?.attributes?.ledgerName;
            purchaseData["supplieraddress"] = supplier[0]?.attributes?.address + (supplier[0]?.attributes?.area ? ", " + supplier[0]?.attributes?.area : "");
            purchaseData["city"] = supplier[0]?.attributes?.city;
            purchaseData["state"] = supplier[0]?.attributes?.state;
            purchaseData["suppliermobile"] = supplier[0]?.attributes?.mobileNo1;
            purchaseData["suppliergstin"] = supplier[0]?.attributes?.regNo;
            if (paymentMethod?.value === "Credit") {
                let supplier = ledger?.filter((item) => item.id == e.value);
                purchaseData["supplierId"] = supplier[0]?.id;
            }
            setPurchase(purchaseData);
            console.log(purchase)
        } else {
            purchaseData["suppliername"] = e;
            setPurchase(purchaseData);
            console.log(purchase)
        }
    };

    const handleCustomer = (e, key) => {
        const { value } = e.target;
        let purchaseData = { ...purchase };
        if (paymentMethod.value != "Credit") {
            purchaseData[key] = value;
            setPurchase(purchaseData);
        }
    }

    const statusFilterOptions = getFormattedOptions(saleStatusOptions)
    const statusDefaultValue = statusFilterOptions.map((option) => {
        return {
            value: option.id,
            label: option.name
        }
    })

    const validation = () => {
        let isValid = true;
        if (products['suppliername'] == '') {
            isValid = false;
            dispatch(addToast({
                text: "Enter Supplier Name",
                type: toastType.ERROR
            }))
        }
        return isValid;
    };

    const setCustomer = (item) => {
        console.log("new customer", item);
        setNewCustomer(item);
    }

    const onSubmitPurchase = async () => {
        let valid = validation();
        if (cartitems.length > 0 && valid) {
            let purchaseVal = {
                txNo: singleSale?.attributes ? singleSale?.txNo : 0,
                counterId: 1,
                invDate: purchase?.invoice_Date ? purchase?.invoice_Date : "",
                customerId: purchase?.supplierId ? purchase?.supplierId : purchase?.supplier?.value,
                customerName: purchase?.suppliername ? purchase?.suppliername : "",
                city: purchase?.city ? purchase?.city : "",
                state: purchase?.state ? purchase?.state : "",
                customerAddress: purchase?.supplieraddress ? purchase?.supplieraddress : "",
                customerMobile: purchase?.suppliermobile ? purchase?.suppliermobile : "",
                customerRegNo: purchase?.suppliergstin ? purchase?.suppliergstin : "",
                salesValue: cartitems?.reduce((a, b) => a + (b?.netAmount || 0), 0),
                less: cartcalc[0]?.less ? cartcalc[0]?.less : 0.00,
                roundOff: cartcalc[0]?.round ? cartcalc[0]?.round : 0.00,
                netTotal: cartcalc[0]?.bill ? cartcalc[0]?.bill : 0.00,
                received: 0,
                paymentType: paymentMethod?.label ? paymentMethod?.label : "",
                billedBy: singleSale?.attributes?.billedBy ? singleSale?.attributes?.billedBy : userId,
                remarks: "",
                updatedBy: userId,
                salesOrderNo: selectedOrderNoOptions?.map((item) => item.value).join(','),
                priceLevelNo: priceLvlDef?.value ? priceLvlDef?.value : 0,
                sales2: cartitems?.map((items, ind) => {
                    return {
                        txno: 0,
                        slno: ind + 1,
                        lineId: 1,
                        itemId: items.id,
                        mrp: items.mrp,
                        batchNo: "",
                        qty: items.calculation.totalQty,
                        rate: items.purchaseRate,
                        basicAmount: items.calculation.basicAmount,
                        discPercent: items.discount,
                        discAmount: items.calculation.discAmount,
                        lessAmount: items.lessRs,
                        totalDiscAmount: items.calculation.totalDiscAmount,
                        grossAmount: items.calculation.grossAmount,
                        tax: items.taxPercentage,
                        taxAmount: items.calculation.taxAmount,
                        rateWithTax: parseFloat(items.calculation.netRate ? items.calculation.netRate : items.calculation.rateWithTax).toFixed(2),
                        netSalesRate: items.netSalesRate,
                        netAmount: items.calculation.netAmount,
                        itemDesc: items.description
                    }
                })
                ,
                sales4: [
                    {

                        txno: 0,
                        slno: 1,
                        paymentType: paymentMethod?.label ? paymentMethod?.label : "",
                        referenceNo: "",
                        amount: cartcalc[0]?.bill ? cartcalc[0]?.bill : 0.00,
                    }
                ],
                xMode: singleSale?.attributes ? "U" : "S"
            }
            // let res = addPurchase(purchaseVal);
            addSale(purchaseVal, true);
            // console.log(res);
            // window.location.reload();
            console.log(purchaseVal);
        } else {
            dispatch(
                addToast({
                    text: 'Cart is empty!.',
                    type: toastType.ERROR,
                })
            );
        }
    }

    const handleWheel = (e) => {
        e.target.blur();
    };

    const keyDown = (e) => {
        console.log(e.key);
        if (['e', 'E', '+', '-'].includes(e.key)) {
            e.preventDefault();
        }
        if (e.key === "ArrowDown") {
            e.preventDefault();
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
        }
    };

    const mobilKeydown = (e) => {
        if (['e', 'E', '+', '-', '.'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const keyDown1 = (e, name) => {
        console.log(e.key);
        if (e.key === "ArrowDown" || e.key === "Enter" || e.key === "Tab") {
            e.preventDefault();
            if (name === "invoiceDate") {
                document.getElementById('payment-method').getElementsByTagName("input")[0].focus();
                document.getElementById('payment-method').getElementsByTagName("input")[0].select();
            }
            if (name === "payment-method") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "warehouse") {
                // document.getElementById('supplier').focus();
                if (paymentMethod.value != "Credit") {
                    document.getElementById('supplier_input').focus();
                    document.getElementById('supplier_input').select();
                } else {
                    document.getElementById('supplier').getElementsByTagName("input")[0].focus();
                }
            }
            if (name === "supplier") {
                document.getElementById('address').focus();
                document.getElementById('address').select();
            }
            if (name === "supplier_input") {
                document.getElementById('address').focus();
                document.getElementById('address').select();
            }
            if (name === "address") {
                document.getElementById('city').focus();
                document.getElementById('city').select();
            }
            if (name === "city") {
                document.getElementById('state').focus();
                document.getElementById('state').select();
            }
            if (name === "state") {
                document.getElementById('mobile').focus();
                document.getElementById('mobile').select();
            }
            if (name === "mobile") {
                document.getElementById('gst').focus();
                document.getElementById('gst').select();
            }
            if (name === "gst") {
                document.getElementById('pricelevel').getElementsByTagName("input")[0].focus();
                document.getElementById('pricelevel').getElementsByTagName("input")[0].select();
            }
            if (name === "pricelevel") {
                document.getElementById('salesOrderNo').getElementsByTagName("input")[0].focus();
                document.getElementById('salesOrderNo').getElementsByTagName("input")[0].select();
            }

        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            if (name === "payment-method") {
                document.getElementById('invoiceDate').focus();
                document.getElementById('invoiceDate').select();
            }
            if (name === "warehouse") {
                document.getElementById('payment-method').getElementsByTagName("input")[0].focus();
                document.getElementById('payment-method').getElementsByTagName("input")[0].select();
            }
            if (name === "supplier") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "supplier_input") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "address") {
                if (paymentMethod.value != "Credit") {
                    document.getElementById('supplier_input').focus();
                    document.getElementById('supplier_input').select();
                } else {
                    document.getElementById('supplier').getElementsByTagName("input")[0].focus();
                    document.getElementById('supplier').getElementsByTagName("input")[0].select();
                }
            }
            if (name === "gst") {
                document.getElementById('mobile').focus();
                document.getElementById('mobile').select();
            }
            if (name === "mobile") {
                document.getElementById('state').focus();
                document.getElementById('state').select();
            }
            if (name === "state") {
                document.getElementById('city').focus();
                document.getElementById('city').select();
            }
            if (name === "city") {
                document.getElementById('address').focus();
                document.getElementById('address').select();
            }
            if (name === "salesOrderNo") {
                document.getElementById('pricelevel').getElementsByTagName("input")[0].focus();
                document.getElementById('pricelevel').getElementsByTagName("input")[0].select();
            }
            if (name === "pricelevel") {
                document.getElementById('gst').focus();
                document.getElementById('gst').select();
            }
        }
    };

    const onCancel = () => {
        setBackAlert(false);
        setPriceLvlAlert(false);
        setCustomerAlert(false);
        setGenerateAlert(false);
        debugger
        // setPurchase((prevData) => ({
        //     ...prevData,
        //     supplier: prevData.supplier,
        //     suppliername: prevData.suppliername,
        //     supplieraddress: prevData.supplieraddress,
        //     city: prevData.city,
        //     state: prevData.state,
        //     suppliermobile: prevData.suppliermobile,
        //     suppliergstin: prevData.suppliergstin,
        //     supplierId:  prevData.supplierId ,
        // }));
    }

    const onCancelPriceLvl = () => {
        setPriceLvlAlert(false);
        setPriceLvlDef(null);
    }

    // const customerClear = () => {
    //     dispatch({ type: cartItem.CART_ITEMS, payload: [] });
    //     setSelectedOrderNoOptions((prev) => {
    //         // setSelectedOrderNoOptionsPrev(prev);
    //         return []
    //     });
    //     fetchMultipleSalesOrderNo(purchase?.supplier?.value, "");
    //     if(customerCreate){
    //         dispatch(setLoader(true));
    //         setCustomerShow(true) 
    //     }
    //     setCustomerCreate(false);
    //     setCustomerAlert(false);
    //     setGenerated(false)
    // }

    const customerClear = async () => {
        dispatch({ type: cartItem.CART_ITEMS, payload: [] });

        setSelectedOrderNoOptions((prev) => {
            // setSelectedOrderNoOptionsPrev(prev);
            return [];
        });

        try {
            await fetchMultipleSalesOrderNo(purchase?.supplier?.value, "");
            if (customerCreate) {
                dispatch(setLoader(true));
                setCustomerShow(true); // Set the state after the API call is completed
            }
        } catch (error) {
            console.error("Error fetching sales order numbers:", error);
        }

        setCustomerCreate(false);
        setCustomerAlert(false);
        setGenerated(false);
    };


    const cancelKey = (e) => {
        if (e.key == "ArrowLeft") {
            e.preventDefault();
            document.getElementById('confirm-button').focus();
        } else if (e.key == "Enter") {
            console.log(document.activeElement.id);
            onCancel();
        }
    }

    const paymentKeyPress = (e) => {
        if (e.key == "Enter") {
            document.getElementById('confirm-button').focus();
        } else if (e.key == "ArrowRight") {
            document.getElementById('cancel-button').focus();
        }
    }

    const handlePriceLevel = (e) => {
        setPriceLvlDef(e);
        setPriceLvlAlert(true);
        // posAllProduct(e.value);
    }

    const priceLevelSubmit = () => {
        if (priceLvlDef?.value == 0) {
            posAllProduct();
        } else {
            posAllProduct(priceLvlDef?.value);
        }
        setPriceLvlAlert(false);
        setFlag(flag + 1);
    };

    const customStyles = {
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: '32px', // Adjust the height as needed
            overflowY: 'auto', // Adds scroll for overflow
        }),
        multiValue: (provided) => ({
            ...provided,
            display: 'flex',
        }),
    };

    const handleSalesOrderChange = (selected) => {
        // if((generated && selected?.length == 0)){
        if ((generated && selected?.length == 0)) {
            setCustomerAlert(true);
        } else {
            setSelectedOrderNoOptions((prev) => {
                // setSelectedOrderNoOptionsPrev(prev);
                return selected || []
            }
            ); // Update state with selected values
        }
        console.log('Selected Options:', selected); // Log for debugging
    };

    const generateOrderData = () => {
        let data = selectedOrderNoOptions?.map((item) => item.value);
        console.log(data);
        fetchMultipleSalesOrderDetails(data?.join(','));
        setGenerated(true);
        setPriceLvlDef({ label: "Regular", value: "0" });
        setGenerateAlert(false);
    };

    useEffect(() => {
        console.log("multipleSalesOrderDetail", multipleSalesOrderDetail);
        if (multipleSalesOrderDetail?.attributes) {
            let cartItems = multipleSalesOrderDetail?.attributes?.salesOrder2?.map((items, ind) => {
                return {
                    name: items?.itemName,
                    id: items?.itemId,
                    description: items?.itemDesc,
                    quantity: items?.pack_count > 1 ? 0 : items?.qty,
                    salesQty: items?.pack_count > 1 ? items?.qty : 0,
                    netSalesRate: items?.netSalesRate,
                    netAmount: items?.netAmount,
                    pack_count: items?.pack_count,
                    tax_amount: items?.taxAmount,
                    taxAmount: items?.taxAmount,
                    taxPercentage: items?.tax,
                    grossAmount: items?.grossAmount,
                    calculation: {
                        totalQty: items?.qty,
                        basicAmount: items?.basicAmount,
                        discAmount: items?.discAmount,
                        totalDiscAmount: items.totalDiscAmount,
                        grossAmount: items?.grossAmount,
                        taxAmount: items?.taxAmount,
                        rateWithTax: items?.rateWithTax,
                        netAmount: items?.netAmount
                    },
                    unit: items?.salesUnitName,
                    sales_unit_name: items?.salesUnitName,
                    purchase_unit_name: items?.purchaseUnitName,
                    // stock: product?.stock,
                    // code: product?.code,
                    purchaseRate: items?.rate,
                    discount: items?.discPercent,
                    lessRs: items?.lessAmount,
                    mrp: items?.mrp,
                    product_price: items?.mrp,
                    decimal: items?.decimalPoints,
                    lineId: ind,
                }
            });
            console.log(cartItems);

            dispatch({ type: cartItem.CART_ITEMS, payload: [...cartItems] });
        }
        dispatch(setLoader(false));

    }, [multipleSalesOrderDetail]);

    const clearOption = () => {
        // alert("cleared");
        setSalesOrderNoOption([]);
    }

    useEffect(() => {
        setSalesOrderNoOption([]);
    }, []);

    useEffect(() => {
        console.log("singleOrder", singleOrder);
    }, [singleOrder])

    return (
        <>
            <Loader />
            <div className="d-md-flex align-items-center justify-content-between mb-5">
                <h1 className="mb-0 create-title">{getFormattedMessage("Sales")}</h1>
                <div className="text-end mt-4 mt-md-0">
                    {singleSale?.attributes ? <Button className="btn btn-outline-primary me-2 save-btn" style={{ width: '50%' }} onClick={() => onSubmitPurchase()}>{getFormattedMessage("Update")}</Button>
                        : <Button className="btn btn-outline-primary me-2 save-btn" style={{ width: '46%' }} onClick={() => onSubmitPurchase()}>{getFormattedMessage("globally.save-btn")}</Button>}
                    <button className="btn btn-outline-primary back-btn" onClick={() => {
                        setBackAlert(true);
                    }}>
                        {getFormattedMessage("Back")}
                    </button>
                </div>
            </div>
            <div className='row wholeSaleForm' style={{ background: 'white', padding: '2%', borderRadius: '8px' }}>
                <div className='col-4' style={{ borderRight: '2px solid deepskyblue', borderRadius: '8px' }} >
                    <div>
                        <Form.Label style={{ color: '#28A745', fontWeight: '600' }}>Invoice Details:</Form.Label>
                        <Form className='row'>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="exampleForm.ControlInput1">
                                <label style={{ fontStyle: 'italic' }}>Invoice No.</label>
                                <Form.Control type="text" placeholder="Invoice Number" onChange={(e) => handleInvoiceNumber(e.target.value)} value={invNo} disabled autoComplete='off' />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="invoiceDate">
                                <label style={{ fontStyle: 'italic' }}>Invoice Date</label>
                                <Form.Control type="date" placeholder="Entry Date" value={purchase?.invoice_Date} onChange={(e) => handleInvoiceDate(e.target.value)} onKeyDown={(e) => keyDown1(e, "invoiceDate")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="">
                                <label style={{ fontStyle: 'italic' }}>Payment Mode</label>
                                <Select
                                    errors={errors["sales_unit_id"]}
                                    value={paymentMethod}
                                    options={[{ label: 'CASH', value: "Cash" }, { label: 'CREDIT', value: "Credit" }, { label: 'CARD', value: "Card" }, { label: 'UPI', value: "Upi" }]}
                                    id="payment-method"
                                    onChange={(e) => handlePayment(e)}
                                    onKeyDown={(e) => keyDown1(e, "payment-method")}
                                    autoFocus
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="exampleForm.ControlInput1">
                                <label style={{ fontStyle: 'italic' }}>Warehouse</label>
                                <Select
                                    className="position-relative"
                                    value={warehouseDef}
                                    options={warehouses?.map((warehouse) => ({ label: warehouse?.attributes?.warehouseName, value: warehouse.id }))}
                                    errors={errors["sales_unit_id"]}
                                    id="warehouse"
                                    onChange={(e) => setWarehouseDef(e)}
                                    onKeyDown={(e) => keyDown1(e, "warehouse")}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <hr style={{ color: '#eaeaea', height: '1px', opacity: '0.75' }}></hr>
                    <div>
                        <Form.Label style={{ color: '#28A745', fontWeight: '600' }}>Customer Details:</Form.Label>
                        <Form className='row'>
                            <Form.Group className="mb-3 customer_wholesale" controlId="exampleForm.ControlInput1">
                                <label style={{ fontStyle: 'italic' }}>Customer Name</label>
                                <InputGroup className="mb-3">
                                    {paymentMethod?.value === "Credit" ?
                                        <Select
                                            className="position-relative"
                                            errors={errors["sales_unit_id"]}
                                            options={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "customers").map((data) => ({ label: (data?.attributes?.salutation != "" ? (data?.attributes?.salutation + ".") : "") + data?.attributes?.ledgerName, value: data?.id }))?.sort((a, b) => {
                                                if (a.label < b.label) return -1;
                                                if (a.label > b.label) return 1;
                                                return 0;
                                            })}
                                            id="supplier"
                                            onChange={(e) => handleCombo(e)}
                                            onKeyDown={(e) => keyDown1(e, "supplier")}
                                            onMenuOpen={() => {
                                                if (selectedOrderNoOptions?.length > 0) { setCustomerAlert(true) }
                                            }}
                                            value={purchase?.supplier ? purchase?.supplier : null}
                                            placeholder="Select Customers"
                                        />
                                        :
                                        <Combobox
                                            id='supplier'
                                            hideEmptyPopup
                                            // data={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "supplier").map((data) => ({id: data?.id, name: data?.attributes?.ledgerName}))}
                                            data={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "customers").map((data) => ({ value: data?.id, label: (data?.attributes?.salutation != "" ? (data?.attributes?.salutation + ".") : "") + data?.attributes?.ledgerName }))?.sort((a, b) => {
                                                if (a.label < b.label) return -1;
                                                if (a.label > b.label) return 1;
                                                return 0;
                                            })}
                                            placeholder='Select Customers'
                                            dataKey={"value"}
                                            value={purchase?.suppliername ? purchase?.suppliername : null}
                                            textField={"label"}
                                            onChange={(e) => handleCombo(e)}
                                            onKeyDown={(e) => keyDown1(e, "supplier_input")}
                                            onClick={() => {
                                                if (selectedOrderNoOptions?.length > 0) { setCustomerAlert(true) }
                                            }}
                                        // value={purchase?.suppliername ? purchase?.suppliername : null}
                                        />
                                        // <Form.Control type="text" placeholder="Supplier" onChange={(e) => handleCombo(e.target.value)} />
                                    }
                                    <Button variant="outline-secondary" id="button-addon1" style={{ color: 'white', backgroundColor: 'rgb(40 167 69)' }}
                                        onClick={() => {
                                            if (selectedOrderNoOptions?.length > 0) {
                                                setCustomerAlert(true)
                                            } else {
                                                setCustomerShow(true);
                                                setCustomerCreate(true);
                                            }
                                        }}
                                    >
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="address">
                                <label style={{ fontStyle: 'italic' }}>Address</label>
                                <Form.Control type="text" placeholder="Customer Address" value={purchase?.supplieraddress} onChange={(e) => handleCustomer(e, "supplieraddress")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "address")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="city">
                                <label style={{ fontStyle: 'italic' }}>City</label>
                                <Form.Control type="text" placeholder="Customer City" value={purchase?.city} onChange={(e) => handleCustomer(e, "city")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "city")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="state">
                                <label style={{ fontStyle: 'italic' }}>State</label>
                                <Form.Control type="text" placeholder="Customer State" value={purchase?.state} onChange={(e) => handleCustomer(e, "state")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "state")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="mobile" >
                                <label style={{ fontStyle: 'italic' }}>Mobile No.</label>
                                <Form.Control type="number" placeholder="Mobile No." value={purchase?.suppliermobile} onChange={(e) => handleCustomer(e, "suppliermobile")} onWheel={(e) => handleWheel(e)} onKeyDown={(e) => { keyDown1(e, "mobile"); mobilKeydown(e) }} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="gst" >
                                <label style={{ fontStyle: 'italic' }}>GST No.</label>
                                <Form.Control type="text" placeholder="GST No." value={purchase?.suppliergstin} onChange={(e) => handleCustomer(e, "suppliergstin")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "gst")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="pricelevel" >
                                <label style={{ fontStyle: 'italic' }}>Price Level</label>
                                <Select
                                    className="position-relative"
                                    value={priceLvlDef ? priceLvlDef : { label: "Regular", value: "0" }}
                                    options={[
                                        { label: "Regular", value: "0" }, // Default option
                                        ...priceLevel
                                            ?.filter((item) => item?.attributes?.isActive)
                                            ?.map((priceLvl) => ({
                                                label: priceLvl?.attributes?.pricelevelname,
                                                value: priceLvl?.pricelevelno,
                                            })),
                                    ]}
                                    errors={errors["sales_unit_id"]}
                                    id="pricelevel"
                                    onChange={(e) => handlePriceLevel(e)}
                                    onKeyDown={(e) => keyDown1(e, "pricelevel")}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="salesOrderNo" >
                                <label style={{ fontStyle: 'italic' }}>Sales Order No.</label>
                                {/* <Form.Control type="text" placeholder="Sales Order No." value={purchase?.salesOrderNo} onChange={(e) => handleCustomer(e, "salesOrderNo")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "gst")} /> */}
                                <Select
                                    id='salesOrderNo'
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    value={selectedOrderNoOptions}
                                    // defaultValue={}
                                    menuPlacement={"top"}
                                    isMulti
                                    options={salesOrderNoOption}    
                                    isDisabled={salesOrderNoOption?.length === 0}
                                    styles={customStyles}
                                    onKeyDown={(e) => { keyDown1(e, "salesOrderNo"); e.preventDefault(); }}
                                    onChange={handleSalesOrderChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="" >

                            </Form.Group>
                            {selectedOrderNoOptions?.length > 0 && <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="gst" >
                                <button id='generate-button' type='button' className="btn btn-success" style={{ float: "inline-end" }}
                                    onClick={() => { setGenerateAlert(true) }}>Generate</button>
                            </Form.Group>}
                        </Form>
                    </div>
                </div>
                <div className='col-8 right-content purchase'>
                    <div className=" items-container purchase-search">
                        <ProductSearchbar
                            purchase={true}
                            mode={'sales'}
                            singleOrder={singleOrder}
                        />
                    </div>
                    <div className='left-content custom-card p-3' style={{ background: 'white' }}>
                        <div className="main-table overflow-auto" style={{ height: "40%", minHeight: 'calc(100vh - 470px)', maxHeight: '523px' }}>
                            <Table className="mb-0">
                                <thead className="position-sticky top-0">
                                    <tr>
                                        <th style={{ padding: '0px 10px' }}>
                                            {getFormattedMessage(
                                                "pos-product.title"
                                            )}
                                        </th>
                                        {companyConfig?.attributes?.mrpWiseStock && <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-mrp.title"
                                            )}
                                        </th>}
                                        <th
                                            className={
                                                updateProducts &&
                                                    updateProducts.length
                                                    ? "text-end"
                                                    : "text-end"
                                            }
                                        >
                                            {getFormattedMessage(
                                                "pos-qty.title"
                                            )}
                                        </th>
                                        {/* <th
                                            className={
                                                updateProducts &&
                                                    updateProducts.length
                                                    ? "text-center"
                                                    : "text-center"
                                            }
                                            style={{width: "200%"}}
                                        >
                                            {getFormattedMessage(
                                                "pos-qty.title"
                                            )}
                                        </th> */}
                                        <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-rate.title"
                                            )}
                                        </th>
                                        {/* <th>
                                            {getFormattedMessage(
                                                "pos-disc.title"
                                            )}
                                        </th>
                                        <th>
                                            {getFormattedMessage(
                                                "pos-net-price.title"
                                            )}
                                        </th> */}
                                        <th className="text-end">
                                            {/* {getFormattedMessage(
                                            "pos-amount.title"
                                        )} */}
                                            Disc
                                        </th>
                                        <th className="text-end">
                                            {/* {getFormattedMessage(
                                            "pos-amount.title"
                                        )} */}
                                            Tax%
                                        </th>
                                        <th className="text-end">
                                            {/* {getFormattedMessage(
                                            "pos-amount.title"
                                        )} */}
                                            N/P
                                        </th>
                                        <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-amount.title"
                                            )}
                                        </th>
                                        <th className="text-end">

                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="border-0">
                                    {cartitems && cartitems.length ? (
                                        cartitems.map(
                                            (updateProduct, index) => {
                                                return (
                                                    <ProductCartList
                                                        singleProduct={
                                                            updateProduct
                                                        }
                                                        key={index + 1}
                                                        index={index}
                                                        posAllProducts={
                                                            posAllProducts
                                                        }
                                                        newCost={newCost}
                                                        allConfigData={
                                                            allConfigData
                                                        }
                                                        setUpdateProducts={
                                                            setUpdateProducts
                                                        }
                                                        purchase={true}
                                                        sales={true}
                                                        mode={'sales'}
                                                        singleOrder={singleOrder}
                                                    />
                                                );
                                            }
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={10}
                                                className="custom-text-center text-gray-900 fw-bold py-5"
                                            >
                                                {getFormattedMessage(
                                                    "sale.product.table.no-data.label"
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <hr />
                        <CartItemMainCalculation
                            cartItems={cartitems}
                            singleSale={singleSale}
                            singleSalesOrder={singleSalesOrder}
                            multipleSalesOrderDetail={multipleSalesOrderDetail}
                            purchase={true}
                        />
                    </div>
                </div>
            </div>
            <CreateCustomersWholeSale show={customerShow} handleClose={() => setCustomerShow(false)} setCustomer={setCustomer} companyConfig={companyConfig} />
            {backAlert && (
                <SweetAlert
                    confirmBtnBsStyle='success mb-3 fs-5 rounded'
                    cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
                    confirmBtnCssClass='alert_yes'
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={"Back to Sales Listing Page.?"}
                    showCancel
                    focusCancelBtn
                    customButtons={
                        <>
                            <button id="cancel-button" onClick={() => onCancel()} className="btn btn-secondary" onKeyDown={(e) => cancelKey(e)}>
                                No
                            </button>

                            <Link to={'/app/wholesale'} onClick={() => {
                                dispatch({ type: cartItem.CART_ITEMS, payload: [] });
                                clearOption();
                                setSalesOrderNoOption([]);
                                setSelectedOrderNoOptions((prev) => {
                                    // setSelectedOrderNoOptionsPrev(prev);
                                    return []
                                });
                                dispatch({
                                    type: singleSalesOrderActionType.FETCH_SINGLE_SALES_ORDER,
                                    payload: [],
                                });
                            }} style={{ marginRight: "5%" }}
                                onKeyDown={(e) => paymentKeyPress(e)}>
                                <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} onClick={() => clearOption()}>
                                    Yes
                                </button>
                            </Link>
                        </>
                    }
                />
            )}
            {customerAlert && (
                <SweetAlert
                    confirmBtnBsStyle='success mb-3 fs-5 rounded'
                    cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
                    confirmBtnCssClass='alert_yes'
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={"Changing the Customer, will clear the cart.! Proceed.?"}
                    showCancel
                    focusCancelBtn
                    customButtons={
                        <>
                            <button id="cancel-button" onClick={() => onCancel()} className="btn btn-secondary" onKeyDown={(e) => cancelKey(e)}>
                                No
                            </button>

                            {/* <Link to={'/app/wholesale'} onClick={() => {
                                dispatch({ type: cartItem.CART_ITEMS, payload: [] });
                            }} style={{ marginRight: "5%" }}
                                onKeyDown={(e) => paymentKeyPress(e)}> */}
                            <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} onClick={() => customerClear()}>
                                Yes
                            </button>
                            {/* </Link> */}
                        </>
                    }
                />
            )}

            {generateAlert && (
                <SweetAlert
                    confirmBtnBsStyle='success mb-3 fs-5 rounded'
                    cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
                    confirmBtnCssClass='alert_yes'
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={"Do you want to convert the selected Sales Order(s) to Sales?"}
                    showCancel
                    focusCancelBtn
                    customButtons={
                        <>
                            <button id="cancel-button" onClick={() => onCancel()} className="btn btn-secondary" onKeyDown={(e) => cancelKey(e)}>
                                No
                            </button>

                            {/* <Link to={'/app/wholesale'} onClick={() => {
                                dispatch({ type: cartItem.CART_ITEMS, payload: [] });
                            }} style={{ marginRight: "5%" }}
                                onKeyDown={(e) => paymentKeyPress(e)}> */}
                            <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} onClick={() => generateOrderData()}>
                                Yes
                            </button>
                            {/* </Link> */}
                        </>
                    }
                />
            )}

            {priceLvlAlert && (
                <SweetAlert
                    confirmBtnBsStyle='success mb-3 fs-5 rounded'
                    cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
                    confirmBtnCssClass='alert_yes'
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={`Do you want to change the price level to ${priceLvlDef?.label}?`}
                    // onConfirm={onConfirm}
                    // onCancel={onCancel}
                    showCancel
                    focusCancelBtn
                    customButtons={
                        <>
                            <button id="cancel-button" onClick={() => onCancelPriceLvl()} className="btn btn-secondary" onKeyDown={(e) => cancelKey(e)}>
                                No
                            </button>

                            <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} onClick={(e) => priceLevelSubmit()}>
                                Yes
                            </button>
                        </>
                    }
                // customIcon={remove} 
                />
            )}
        </>
    )
};

const mapStateToProps = (state) => {
    const { purchaseProducts, products, frontSetting, allConfigData, posAllProducts, cartValues, sales, salesInvoice, singleSalesOrder, multipleSalesOrderNo, companyConfig, singleOrder } = state;
    return { customProducts: preparePurchaseProductArray(products), purchaseProducts, products, frontSetting, allConfigData, posAllProducts, cartValues, sales, salesInvoice, singleSalesOrder, multipleSalesOrderNo, companyConfig, singleOrder }
};

export default connect(mapStateToProps, { editPurchase, fetchAllProducts, searchPurchaseProduct, posAllProduct, addPurchase, addSale, fetchSalesInvNo, fetchPriceLevel, fetchMultipleSalesOrderNo, fetchMultipleSalesOrderDetails, fetchCompanyConfig, fetchSingleOrder })(WholeSaleForm);
