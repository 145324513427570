import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { InputGroup, Button } from "react-bootstrap-v5";
import MultipleImage from "./MultipleImage";
import { fetchUnits, unitDropdown } from "../../store/action/unitsAction";
import { fetchTax } from "../../store/action/taxAction";
import { Link } from "react-router-dom";
import {
  fetchAllProductCategories,
  productCategoryDropdown,
  addProductCategory,
} from "../../store/action/productCategoryAction";
import {
  fetchAllBrands,
  brandDropdown,
  addBrand,
} from "../../store/action/brandsAction";
import {
  fetchAllProductGroups,
  productGroupDropdown,
  addProductGroup,
} from "../../store/action/productGroupsAction";
import {
  editProduct,
  fetchProduct,
  addProduct,
  fetchProducts,
} from "../../store/action/productAction";
import {
  addHsnCode,
  fetchAllHsnCodes,
  hsnCodeDropdown,
} from "../../store/action/hsnCodeAction";
import {
  decimalValidate,
  getFormattedMessage,
  getFormattedOptions,
  placeholderText,
} from "../../shared/sharedMethod";
import ModelFooter from "../../shared/components/modelFooter";
import ReactSelect from "../../shared/select/reactSelect";
import { fetchAllWarehouses } from "../../store/action/warehouseAction";
import { fetchAllSuppliers } from "../../store/action/supplierAction";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDown,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UnitsForm from "../units/UnitsForm";
import BrandsForm from "../brands/BrandsFrom";
import ProductCategoryForm from "../productCategory/ProductCategoryForm";
import ProductGroupsForm from "../product-group/ProductGroupsForm";
import { addUnit } from "../../store/action/unitsAction";
import CreateTaxSetup from "../posTaxSetup/CreateTaxSetup";
import TaxSetupForm from "../posTaxSetup/TaxSetupForm";
import { addTaxSetup, fetchTaxSetup } from "../../store/action/TaxSetupAction";
import Loader from "../loader/Loader";
import Select from "react-select";
import ReactDataTable from "../../shared/table/ReactDataTable";
import { apiBaseURL } from "../../constants";
import {
  deleteOpeningStock,
  fetchOpeningStock,
} from "../../store/action/openingStockAction";
import { filter } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import HsnCodeForm from "../hsncodes/HsnCodeForm";
import { deleteItemImage } from "../../store/action/productImageAction";

const ProductForm = (props) => {
  const {
    addProductData,
    id,
    editProduct,
    addProduct,
    singleProduct,
    brands,
    brandDropdown,
    brand,
    fetchAllBrands,
    productGroupDropdown,
    productGroup,
    fetchAllProductGroups,
    fetchAllProductCategories,
    fetchAllHsnCodes,
    productCategories,
    productCategoryDropdown,
    units,
    unit,
    unitDropdown,
    fetchUnits,
    addUnit,
    addBrand,
    addProductCategory,
    addProductGroup,
    productGroups,
    taxs,
    taxSetup,
    fetchTax,
    fetchTaxSetup,
    fetchProducts,
    to,
    title,
    isLoading,
    deleteOpeningStock,
    openingStock,
    hsnCodes,
    flag,
    deleteItemImage,
  } = props;

  console.log("Opening Stock =>", openingStock);

  const navigate = useNavigate();
  const [productValue, setProductValue] = useState({
    items_id: 0,
    name: "",
    name_print: "",
    name_tamil: "",
    code_barcode: "",
    category1_id: { value: 1, label: "" },
    category2_id: { value: 1, label: "" },
    category3_id: { value: 1, label: "" },
    category4_id: { value: 1, label: "" },
    purchase_unit_id: "",
    sales_unit_id: "",
    pack_count: "",
    tax: 0,
    stock_alert: 0,
    isactive: 1,
    product_image: "",
    remarks: "",
    created_by: 1,
    updated_by: 1,
  });

  const [openingStockValue, setOpeningStockValue] = useState({
    itemId: 0,
    mrp: "",
    batchNo: "",
    purchaseCost: "",
    stock: "",
    warehouseId: "",
    salesPrice: "",
  });

  // const [priceListValue,setPriceListValue] = useState({
  //   salesPrice:""
  // })
  const dispatch = useDispatch();
  const [removedImage, setRemovedImage] = useState([]);
  const [isClearDropdown, setIsClearDropdown] = useState(true);
  const [isDropdown, setIsDropdown] = useState(true);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [checked, setChecked] = useState(false);
  const [disable, setDisable] = useState(false);
  const [brandIsOpen, setBrandOpen] = React.useState(false);
  const [catIsOpen, setCatOpen] = React.useState(false);
  const [groupIsOpen, setGroupOpen] = React.useState(false);
  const [taxIsOpen, setTaxOpen] = React.useState(false);
  const [purchaseIsOpen, setPurchaseOpen] = React.useState(false);
  const [salesIsOpen, setSalesOpen] = React.useState(false);
  const [hsnIsOpen, setHsnOpen] = React.useState(false);
  const [category, setCategory] = useState(false);
  const [unitsBind, setUnitsBind] = useState(false);
  const [taxBind, setTaxBind] = useState(false);
  const [hsnBind, setHsnBind] = useState(false);
  const [sortedtax, setSortedTax] = useState([]);
  const taxes = useSelector((state) => state.taxSetup);
  const allProducts = useSelector((state) => state.products);
  // const [disable, setDisable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [Decimal, setDecimal] = useState(0);
  const [stockDelete, setStockDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const [errors, setErrors] = useState({
    items_id: 0,
    name: "",
    name_print: "",
    name_tamil: "",
    code_barcode: "",
    category1_id: "",
    category2_id: "",
    category3_id: "",
    purchase_unit_id: "",
    sales_unit_id: "",
    pack_count: "",
    tax: 0,
    stock_alert: 0,
    isactive: 1,
    product_image: "",
    remarks: "",
    created_by: 1,
    updated_by: 1,
  });

  const [formcode, setFormCode] = useState("M01");
  // const navigate = useNavigate();
  useEffect(() => {
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      console.log("Parsed Form Data:", parsedFormData);
      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter(
          (item) =>
            item?.attributes?.formCode == formcode &&
            item?.attributes?.visibility
        );
        console.log("Form Code Items:", formCodeItems);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  useEffect(() => {
    // fetchProducts();
    fetchAllBrands();
    fetchAllProductCategories();
    fetchAllProductGroups();
    fetchAllHsnCodes();
    fetchUnits();
    fetchTaxSetup();
    // if(singleProduct){
    //   fetchOpeningStock(id)
    // }
  }, []);

  useState(() => {
    if (!singleProduct) {
      console.log("PRODUCTS", allProducts);
      console.log(
        allProducts[allProducts.length - 1]?.attributes?.code_barcode
      );
      // const match = allProducts[allProducts.length - 1]?.attributes?.code_barcode?.match(/^(\D+)(\d+)$/);
      // if (match) {
      //   const prefix = match[1]; // The prefix (non-digit characters)
      //   const num = parseInt(match[2], 10); // The numeric part
      //   // Increment the numeric part
      //   const incrementedNum = num + 1;
      //   // Pad the number with leading zeros if necessary
      //   const newNum = incrementedNum.toString().padStart(match[2].length, '0');
      //   // Combine the prefix and the new number
      //   const newCode = `${prefix}${newNum}`;
      //   console.log(newCode); // Outputs: VINFO0012
      //   setProductValue({ ...productValue, code_barcode: newCode });
      // } else {
      //   if (typeof (parseInt(allProducts[allProducts.length - 1]?.attributes?.code_barcode)) && !isNaN(parseInt(allProducts[allProducts.length - 1]?.attributes?.code_barcode))) {
      //     setProductValue({ ...productValue, code_barcode: parseInt(allProducts[allProducts.length - 1]?.attributes?.code_barcode) + 1 });
      //   }
      // }
      if (allProducts[allProducts.length - 1]?.attributes) {
        setProductValue({
          ...productValue,
          code_barcode:
            parseInt(allProducts[allProducts.length - 1]?.items_id) + 1,
        });
      }
    }
  }, [allProducts]);

  useEffect(() => {
    if (singleProduct && unit) {
      unitDropdown(unit[0]?.unitid);
    }
  }, []);

  useEffect(() => {
    if (singleProduct && brand) {
      brandDropdown(brand[0]?.category1id);
    }
  }, []);

  useEffect(() => {
    if (singleProduct && productCategories) {
      productCategoryDropdown(productCategories[0]?.category2id);
    }
  }, []);

  useEffect(() => {
    if (singleProduct && productGroup) {
      productGroupDropdown(productGroup[0]?.category3id);
    }
  }, []);

  useEffect(() => {
    if (singleProduct && hsnCodes) {
      hsnCodeDropdown(hsnCodes[0]?.id);
    }
  }, []);

  // const disabled = true;
  // multipleFiles.length !== 0
  //     ? false
  //     : singleProduct &&
  //     productValue.unitid[0] &&
  //     productValue.unitid[0].value === singleProduct[0].unitid &&
  //     productValue.barcode_symbol[0] &&
  //     productValue.barcode_symbol[0].value ===
  //     singleProduct[0].barcode_symbol.toString() &&
  //     singleProduct[0].name === productValue.name &&
  //     singleProduct[0].notes === productValue.notes &&
  //     singleProduct[0].product_price === productValue.product_price &&
  //     singleProduct[0]?.stock_alert?.toString() ===
  //     productValue.stock_alert &&
  //     singleProduct[0].product_cost === productValue.product_cost &&
  //     singleProduct[0].code === productValue.code &&
  //     JSON.stringify(singleProduct[0].order_tax) === productValue.order_tax &&
  //     singleProduct[0].quantity_limit === productValue.sale_quantity_limit &&
  //     singleProduct[0].category1_id.value ===
  //     productValue.category1_id.value &&
  //     newTax.length === productValue.tax_type.length &&
  //     singleProduct[0].category2_id.value ===
  //     productValue.category2_id.value &&
  //     JSON.stringify(singleProduct[0].images.imageUrls) ===
  //     JSON.stringify(removedImage);

  useEffect(() => {
    if (singleProduct) {
      console.log("singleProduct", singleProduct);
      setProductValue({
        items_id: singleProduct ? singleProduct[0]?.items_id : 0,
        name: singleProduct ? singleProduct[0]?.name : "",
        name_print: singleProduct ? singleProduct[0]?.name_print : "",
        name_tamil: singleProduct ? singleProduct[0]?.name_tamil : "",
        code_barcode: singleProduct ? singleProduct[0]?.code_barcode : "",
        category1_id: singleProduct && singleProduct[0]?.brand_id,
        category2_id: singleProduct && singleProduct[0]?.product_category_id,
        category3_id: singleProduct && singleProduct[0]?.product_group_id,
        category4_id: singleProduct && singleProduct[0]?.hsnCode,
        purchase_unit_id: singleProduct && singleProduct[0]?.purchase_unit,
        sales_unit_id: singleProduct && singleProduct[0]?.sale_unit,
        decimalPortion: singleProduct && singleProduct[0]?.decimalPortion,
        pack_count: singleProduct && singleProduct[0]?.pack_count,
        tax: singleProduct && singleProduct[0]?.tax_name,
        stock_alert: singleProduct && singleProduct[0].stock_alert,
        isactive: singleProduct && singleProduct[0]?.isactive,
        product_image: singleProduct && singleProduct[0]?.product_image,
        remarks: singleProduct && singleProduct[0]?.remarks,
        created_by: singleProduct && singleProduct[0]?.created_by,
        updated_by: singleProduct && singleProduct[0]?.updated_by,
      });
      setDecimal(singleProduct ? singleProduct[0]?.decimalPortion : 0);
      // setOpeningStockValue(
      //   {
      //     mrp:singleProduct && singleProduct[0]?.mrp,
      //   purchaseCost:singleProduct && singleProduct[0]?.purchaseCost,
      //   stock:singleProduct && singleProduct[0]?.stock,
      //   salesPrice:singleProduct && singleProduct[0]?.salesPrice
      //   }
      // )
      // setPriceListValue({

      // })
      debugger
      singleProduct[0]?.isEditable &&
        setDisable(singleProduct[0]?.isEditable ? false : true);
      // setDisable(singleProduct[0]?.is_Edit ? false : true);
      dispatch({ type: "DISABLE_OPTION", payload: true });
    }
  }, []);

  console.log("decimal Portion : ", Decimal);

  const onChangeFiles = (file) => {
    setMultipleFiles(file);
  };

  const handleChanged = (event) => {
    let checked = event.target.checked === true ? 1 : 0;
    console.log("checked", checked);

    setDisable(false);
    setChecked(checked);
    setProductValue({ ...productValue, isactive: checked });
    setOpeningStockValue({ ...openingStockValue });
  };

  const transferImage = (item) => {
    setRemovedImage(item);
    setMultipleFiles([]);
  };

  const handlePurchaseUnitChange = (obj) => {
    console.log("handlePurchaseUnitChange", obj);
    unitDropdown(obj.value);
    setIsClearDropdown(false);
    setIsDropdown(false);
    setProductValue({
      ...productValue,
      purchase_unit_id: obj,
      sales_unit_id: obj,
      pack_count: 1,
    });
    setErrors("");
    setPurchaseOpen(true);
    setBrandOpen(false);
    setCatOpen(false);
    setGroupOpen(false);
    setTaxOpen(false);
    setSalesOpen(false);
    setHsnOpen(false);
  };

  const handleSalesUnitChange = (obj) => {
    console.log("handleSalesUnitChange", obj);
    unitDropdown(obj.value);
    setIsClearDropdown(false);
    setIsDropdown(false);
    setProductValue({ ...productValue, sales_unit_id: obj });
    setErrors("");
    setSalesOpen(true);
    setBrandOpen(false);
    setCatOpen(false);
    setGroupOpen(false);
    setTaxOpen(false);
    setPurchaseOpen(false);
    setHsnOpen(false);
  };

  const onBrandChange = (obj) => {
    brandDropdown(obj.value);
    setIsClearDropdown(false);
    setIsDropdown(false);
    setProductValue({ ...productValue, category1_id: obj });
    setErrors("");
    setBrandOpen(true);
    setCatOpen(false);
    setGroupOpen(false);
    setTaxOpen(false);
    setPurchaseOpen(false);
    setSalesOpen(false);
    setHsnOpen(false);
  };

  const onBlur = () => {
    setBrandOpen(false);
    setCatOpen(false);
    setGroupOpen(false);
    setTaxOpen(false);
    setPurchaseOpen(false);
    setSalesOpen(false);
    setHsnOpen(false);
  };

  const onProductGroupChange = (obj) => {
    productGroupDropdown(obj.value);
    setIsClearDropdown(false);
    setIsDropdown(false);
    setProductValue({ ...productValue, category3_id: obj });
    setErrors("");
    setGroupOpen(true);
    setBrandOpen(false);
    setCatOpen(false);
    setTaxOpen(false);
    setPurchaseOpen(false);
    setSalesOpen(false);
    setHsnOpen(false);
  };

  const onProductCategoryChange = (obj) => {
    productCategoryDropdown(obj.value);
    setIsClearDropdown(false);
    setIsDropdown(false);
    setProductValue({ ...productValue, category2_id: obj });
    setErrors("");
    setCatOpen(true);
    setBrandOpen(false);
    setGroupOpen(false);
    setTaxOpen(false);
    setPurchaseOpen(false);
    setSalesOpen(false);
    setHsnOpen(false);
  };

  const handleValidation = () => {
    let errors = {};
    let isValid = false;
    console.log("handleValidation productValue", productValue);
    console.log("name==>", !productValue["name"]);
    if (!productValue["name"]) {
      errors["name"] = getFormattedMessage("product.input.name.validate.label");
    }
    if (!productValue["code_barcode"]) {
      errors["code_barcode"] = getFormattedMessage(
        "product.input.code_barcode.validate.label"
      );
    }
    if (
      (!productValue["tax"] && productValue["tax"] == 0) ||
      productValue["tax"]["label"] == undefined
    ) {
      errors["tax"] = getFormattedMessage("product.input.tax.validate.label");
    }
    if (
      !productValue["purchase_unit_id"] ||
      productValue["purchase_unit_id"]["value"] == undefined
    ) {
      errors["purchase_unit_id"] = getFormattedMessage(
        "product.input.purchase_unit.validate.label"
      );
    }
    if (
      !productValue["sales_unit_id"] ||
      productValue["sales_unit_id"]["value"] == undefined
    ) {
      errors["sales_unit_id"] = getFormattedMessage(
        "product.input.sales_unit.validate.label"
      );
    }
    if (!productValue["pack_count"]) {
      errors["pack_count"] = getFormattedMessage(
        "product.input.pack_count.validate.label"
      );
    } else {
      // isValid = true;
    }
    setErrors(errors);
    console.log("errors", errors);
    console.log("errorsLength", Object.keys(errors).length);
    if (Object.keys(errors).length === 0) {
      isValid = true;
    }
    return isValid;
  };

  const handleWheel = (e) => {
    e.target.blur();
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    const { value, name } = e.target;

    if (name === "stock") {
      if (value.match(/\./g)) {
        const [, decimal] = value.split(".");
        if (decimal?.length > Decimal) {
          return;
        }
      }
    }

    // check if value includes a decimal point
    if (value.match(/\./g) && name != "stock") {
      const [, decimal] = value.split(".");
      // restrict value to only 2 decimal places
      if (decimal?.length > 2) {
        // do nothing
        return;
      }
    }

    if (name === "pack_count" || name === "stock_alert") {
      if (value.length > 4) {
        return value.slice(0, -1);
      }
    }
    if (name === "name") {
      setProductValue((inputs) => ({
        ...inputs,
        name: value,
        name_print: value,
      }));
      setOpeningStockValue((inputs) => ({ ...inputs, [e.target.name]: value }));
    } else {
      setProductValue((inputs) => ({ ...inputs, [e.target.name]: value }));
      setOpeningStockValue((inputs) => ({ ...inputs, [e.target.name]: value }));
    }

    setErrors("");
  };

  const handleKeyDown = (e) => {
    if (["-", "+", ".", "e"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const onTaxChange = (obj) => {
    console.log("onTaxChange obj", obj);
    const new_values = { value: obj?.value ?? 0, label: obj?.label };
    setIsClearDropdown(false);
    setIsDropdown(false);
    setProductValue({ ...productValue, tax: new_values });
    setErrors("");
    setTaxOpen(true);
    setBrandOpen(false);
    setCatOpen(false);
    setGroupOpen(false);
    setPurchaseOpen(false);
    setSalesOpen(false);
    setHsnOpen(false);
  };

  const onHsnCodeChange = (obj) => {
    productCategoryDropdown(obj.value);
    setIsClearDropdown(false);
    setIsDropdown(false);
    setProductValue({ ...productValue, category4_id: obj });
    setErrors("");
    setCatOpen(true);
    setBrandOpen(false);
    setGroupOpen(false);
    setTaxOpen(false);
    setPurchaseOpen(false);
    setSalesOpen(false);
    setHsnOpen(false);
  };

  const [unitModel, setUnitModel] = useState(false);
  const showUnitModel = (val) => {
    setUnitModel(val);
  };

  const [brandModel, setBrandModel] = useState(false);
  const showBrandModel = (val) => {
    setBrandModel(val);
  };

  const handleBrandClose = (item) => {
    setBrandModel(!brandModel);
  };

  const handleCategoryClose = (item) => {
    setProductCategoryModel(!productCategoryModel);
    setCategory(true);
  };

  const handleGroupClose = (item) => {
    setProductGroupModel(!productGroupModel);
  };

  const handleTaxClose = (item) => {
    setTaxModal(!taxModal);
    setTaxBind(true);
  };

  const handleHsnClose = (item) => {
    console.log("DISABLE", disable);
    setHsnModal(!hsnModal);
    setHsnBind(true);
  };

  const handleUnitClose = (item) => {
    setUnitModel(!unitModel);
    setUnitsBind(true);
  };

  const [productGroupModel, setProductGroupModel] = useState(false);
  const showProductGroupModel = (val) => {
    setProductGroupModel(val);
  };

  const [productCategoryModel, setProductCategoryModel] = useState(false);
  const showProductCategoryModel = (val) => {
    setProductCategoryModel(val);
  };

  const [taxModal, setTaxModal] = useState(false);
  const showtaxModel = (val) => {
    console.log("showtaxModel", val);
    setTaxModal(val);
  };

  const [hsnModal, setHsnModal] = useState(false);
  const showHsnModel = (val) => {
    console.log("DISABLE", disable);
    // console.log("showHsnModel", val);
    setHsnModal(val);
  };

  const [roleName, setRoleName] = useState({
    edit: false,
    delete: false,
  });

  useEffect(() => {
    console.log("DISABLE", disable);
  }, [hsnModal]);

  const addUnitsData = (productValue) => {
    addUnit(productValue);
  };

  const addBrandData = (productValue) => {
    addBrand(productValue);
  };

  const addProductGroupsData = (productValue) => {
    addProductGroup(productValue);
  };

  const addProductcData = (productValue) => {
    addProductCategory(productValue);
  };
  const addTaxSetupData = (productValue) => {
    addTaxSetup(productValue);
  };
  const addHsnCodeData = (productValue) => {
    addHsnCode(productValue);
  };
  const prepareFormData = (data) => {
    console.log("prepareFormData DATA :: ", data);
    const payload = {
      items_id: data?.items_id ?? 0,
      name: data?.name,
      name_print: data?.name_print == "" ? data.name : data.name_print,
      name_tamil: data?.name_tamil,
      code_barcode: data?.code_barcode,
      category1_id: data.category1_id
        ? data.category1_id?.value
          ? data?.category1_id?.value
          : 1
        : 1,
      category2_id: data.category2_id
        ? data.category2_id?.value
          ? data?.category2_id?.value
          : 1
        : 1,
      category3_id: data?.category3_id
        ? data.category3_id?.value
          ? data?.category3_id?.value
          : 1
        : 1,
      purchase_unit_id:
        data?.purchase_unit_id && data.purchase_unit_id[0]
          ? data?.purchase_unit_id[0].value
          : data?.purchase_unit_id.value,
      sales_unit_id:
        data?.sales_unit_id && data.sales_unit_id[0]
          ? data?.sales_unit_id[0].value
          : data?.sales_unit_id.value,
      pack_count: parseInt(data?.pack_count),
      tax_id: parseInt(data?.tax.value),
      hsnCode: data?.category4_id
        ? data.category4_id?.label
          ? data?.category4_id?.label
          : ""
        : "",
      stock_alert: data?.stock_alert == "" ? 0 : parseInt(data.stock_alert),
      isactive: data?.isactive ?? 1,
      product_image: data.product_image,
      remarks: data.remarks,
      created_by: 1,
      updated_by: 1,
    };
    // const formData = new FormData();
    // formData.append("name", data.name);
    // formData.append("code", data.code);
    // formData.append("category2_id", data.category2_id.value);
    // formData.append("category1_id", data.category1_id.value);
    // formData.append("category3_id", data.category3_id.value);
    // if (data.barcode_symbol[0]) {
    //     formData.append("barcode_symbol", data.barcode_symbol[0].value);
    // } else {
    //     formData.append("barcode_symbol", data.barcode_symbol.value);
    // }
    // formData.append("product_cost", data.product_cost);
    // formData.append("product_price", data.product_price);
    // formData.append(
    //     "unitid",
    //     data.unitid && data.unitid[0] ? data.unitid[0].value : data.unitid.value
    // );
    // formData.append(
    //     "sale_unit",
    //     data.sale_unit && data.sale_unit[0]
    //         ? data.sale_unit[0].value
    //         : data.sale_unit.value
    // );
    // formData.append(
    //     "purchase_unit",
    //     data.purchase_unit && data.purchase_unit[0]
    //         ? data.purchase_unit[0].value
    //         : data.purchase_unit.value
    // );
    // formData.append("stock_alert", data.stock_alert ? data.stock_alert : "");
    // formData.append("order_tax", data.order_tax ? data.order_tax : "");
    // formData.append(
    //     "quantity_limit",
    //     data.sale_quantity_limit ? data.sale_quantity_limit : ""
    // );
    // if (data.tax_type[0]) {
    //     formData.append(
    //         "tax_type",
    //         data.tax_type[0].value ? data.tax_type[0].value : 1
    //     );
    // } else {
    //     formData.append(
    //         "tax_type",
    //         data.tax_type.value ? data.tax_type.value : 1
    //     );
    // }
    // formData.append("notes", data.notes);
    // if (data.isEdit === false) {
    //     formData.append("purchase_supplier_id", data.supplier_id.value);
    //     formData.append("purchase_warehouse_id", data.warehouse_id.value);
    //     formData.append("purchase_date", moment(data.date).format("YYYY-MM-DD"));
    //     formData.append("purchase_quantity", data.add_stock);
    //     formData.append("purchase_status", data.status_id.value);
    // }
    // if (multipleFiles) {
    //     multipleFiles.forEach((image, index) => {
    //         formData.append(`images[${index}]`, image);
    //     });
    // }
    return payload;
  };

  const prepareImgFormData = () => {
    const formData = new FormData();
    formData.append("image", multipleFiles[0]);
    return formData;
  };

  const prepareStockFormData = (data) => {
    const payload = {
      warehouseId: 1,
      itemId: id,
      mrp: data.mrp,
      purchaseCost: isNaN(
        Number(
          parseFloat(data?.purchaseCost).toFixed(2) /
            (1 +
              taxes?.filter((item) => item.taxId == productValue?.tax?.value)[0]
                ?.attributes.taxPercentage /
                100)
        ).toFixed(2)
      )
        ? 0
        : Number(
            parseFloat(data?.purchaseCost).toFixed(2) /
              (1 +
                taxes?.filter(
                  (item) => item.taxId == productValue?.tax?.value
                )[0]?.attributes.taxPercentage /
                  100)
          ).toFixed(2),
      stock: data.stock,
      batchNo: "",
      salesPrice: isNaN(
        Number(
          parseFloat(data?.salesPrice).toFixed(2) /
            (1 +
              taxes?.filter((item) => item.taxId == productValue?.tax?.value)[0]
                ?.attributes.taxPercentage /
                100)
        ).toFixed(2)
      )
        ? 0
        : Number(
            parseFloat(data?.salesPrice).toFixed(2) /
              (1 +
                taxes?.filter(
                  (item) => item.taxId == productValue?.tax?.value
                )[0]?.attributes.taxPercentage /
                  100)
          ).toFixed(2),
    };

    return payload;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const valid = handleValidation();
    console.log("onSubmit :: valid", valid);
    productValue.images = multipleFiles;
    console.log("onSubmit :: multipleFiles", multipleFiles);
    if (singleProduct && valid) {
      // productValue.images = multipleFiles;
      //   setProductValue(productValue);
      //   setOpeningStockValue(openingStockValue);
      editProduct(
        id,
        prepareFormData(productValue),
        navigate,
        prepareImgFormData(),
        openingStockValue.mrp == ""
          ? undefined
          : prepareStockFormData(openingStockValue)
      );

      // if(flag)
      if (isDelete) {
        deleteItemImage(id);
      }
    } else {
      if (valid) {
        productValue.images = multipleFiles;
        setProductValue(productValue);
        setOpeningStockValue(openingStockValue);
        console.log(productValue);
        addProduct(
          prepareFormData(productValue),
          navigate,
          prepareImgFormData(),
          openingStockValue.mrp == ""
            ? undefined
            : prepareStockFormData(openingStockValue)
        );
      }
    }
  };

  useEffect(() => {
    console.log("brands", brands);
    if (
      brands &&
      brands.length > 0 &&
      brands?.filter((item) => item?.type === "product-product_categories")
        ?.length > 0
    ) {
      setProductValue({
        ...productValue,
        category1_id:
          {
            value: brands?.filter(
              (item) => item?.type === "product-product_categories"
            )[0].category1Id,
            label: brands?.filter(
              (item) => item?.type === "product-product_categories"
            )[0]?.attributes?.name,
          } || {},
      });
    }
  }, [brands]);

  useEffect(() => {
    console.log("hsnCodes", hsnCodes);
    if (hsnCodes && hsnCodes.length > 0 && hsnModal) {
      let sorted = hsnCodes?.sort((a, b) => a?.id - b?.id);
      let lastdata = sorted.pop();
      console.log("lastdataHSN", lastdata);
      setProductValue({
        ...productValue,
        category4_id:
          {
            value: lastdata?.attributes?.hsnCode,
            label: lastdata?.attributes?.hsnCode,
          } || {},
      });
    }
    setHsnBind(false);
  }, [hsnCodes]);

  useEffect(() => {
    console.log("productCategories", productCategories);
    if (productCategories && productCategories.length > 0 && category) {
      let sorted = productCategories?.sort(
        (a, b) => a.category2Id - b.category2Id
      );
      let lastdata = sorted.pop();
      setProductValue({
        ...productValue,
        category2_id:
          { value: lastdata?.category2Id, label: lastdata?.attributes?.name } ||
          {},
      });
    }
    setCategory(false);
  }, [productCategories]);

  useEffect(() => {
    console.log("productGroups", productGroups);
    if (
      productGroups &&
      productGroups.length > 0 &&
      productGroups?.filter(
        (item) => item?.type === "product-product_categories"
      )?.length > 0
    ) {
      setProductValue({
        ...productValue,
        category3_id:
          {
            value: productGroups?.filter(
              (item) => item?.type === "product-product_categories"
            )[0].category3Id,
            label: productGroups?.filter(
              (item) => item?.type === "product-product_categories"
            )[0]?.attributes?.name,
          } || {},
      });
    }
  }, [productGroups]);

  useEffect(() => {
    console.log("units", units);
    if (units && units.length > 0 && unitsBind) {
      let sorted = units?.sort((a, b) => a?.unitId - b?.unitId);
      let lastdata = sorted.pop();
      setProductValue({
        ...productValue,
        purchase_unit_id:
          { value: lastdata?.unitId, label: lastdata?.attributes?.unitName } ||
          {},
        sales_unit_id:
          { value: lastdata?.unitId, label: lastdata?.attributes?.unitName } ||
          {},
      });
      // setProductValue({ ...productValue, sales_unit_id: { value: lastdata?.unitId, label: lastdata?.attributes?.unitName} || {} });
    }
    setUnitsBind(false);
  }, [units]);

  useEffect(() => {
    console.log("taxs", taxes);
    const sorted_tax = taxes?.sort((a, b) => {
      const nameA = a?.attributes?.name?.trim().toLowerCase();
      const nameB = b?.attributes?.name?.trim().toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    setSortedTax(sorted_tax);
    console.log("sorted_tax", sorted_tax);
    if (sorted_tax && sorted_tax.length > 0 && taxBind) {
      let sorted = sorted_tax?.sort((a, b) => a?.taxId - b?.taxId);
      let lastdata = sorted.pop();
      setProductValue({
        ...productValue,
        tax:
          { value: lastdata?.taxId, label: lastdata?.attributes?.taxName } ||
          {},
      });
    }
  }, [taxes]);

  /**Sorting Brand Group Category */
  console.log("brands for sort", brands);
  const sorted_brands = brands?.sort((a, b) => {
    const nameA = a?.attributes?.name?.trim()?.toLowerCase();
    const nameB = b?.attributes?.name?.trim()?.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  console.log("brands for sort sorted_brands", sorted_brands);

  console.log("productCategories for sort", productCategories);
  const sorted_product_category = productCategories?.sort((a, b) => {
    const nameA = a?.attributes?.name?.trim()?.toLowerCase();
    const nameB = b?.attributes?.name?.trim()?.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  console.log(
    "productCategories for sort sorted_product_group",
    sorted_product_category
  );

  console.log("productGroups for sort", productGroups);
  const sorted_product_group = productGroups?.sort((a, b) => {
    const nameA = a?.attributes?.name?.trim().toLowerCase();
    const nameB = b?.attributes?.name?.trim().toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  console.log(
    "productGroups for sort sorted_product_group",
    sorted_product_group
  );

  const sortedHsn = hsnCodes?.sort((a, b) => {
    const nameA = a?.attributes?.name?.trim().toLowerCase();
    const nameB = b?.attributes?.name?.trim().toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  console.log("sortedHsn", sortedHsn);

  const handleKeyDownStock = (e) => {
    if (e.key === "ArrowDown") {
      if (e.target.value <= 0) {
        e.preventDefault();
      }
    } else if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const column = ["MRP", "Cost", "Sales Price", "Op.Stock", ""];

  useEffect(() => {
    if (
      singleProduct &&
      openingStock?.length > 0 &&
      openingStock.some((item) => item?.attributes?.stock !== 0)
    ) {
      debugger
      // setDisable(true);
    }
  }, [openingStock, singleProduct]);

  const itemsValue = singleProduct
    ? openingStock?.length > 0 &&
      openingStock
        .filter((stock) => stock?.attributes?.stock !== 0)
        .map((stock) => ({
          mrp: parseFloat(stock?.attributes?.mrp).toFixed(2),
          cost: Number(
            parseFloat(stock?.attributes?.purchaseCost).toFixed(2) *
              (1 +
                taxes?.filter(
                  (item) => item.taxId == productValue?.tax?.value
                )[0]?.attributes.taxPercentage /
                  100)
          ).toFixed(2),
          // salesPrice: parseFloat(stock?.attributes?.salesPrice).toFixed(2),
          salesPrice: Number(
            parseFloat(stock?.attributes?.salesPrice).toFixed(2) *
              (1 +
                taxes?.filter(
                  (item) => item.taxId == productValue?.tax?.value
                )[0]?.attributes.taxPercentage /
                  100)
          ).toFixed(2),
          stock: parseFloat(stock?.attributes?.stock).toFixed(Decimal),
          unitName: productValue?.sales_unit_id?.label ?? "",
        }))
    : [];

  console.log("Item Value: ", itemsValue);

  const [disableMrp, setDisableMrp] = useState(false);
  const [disablePurchaseCost, setDisablePurchaseCost] = useState(false);

  const editClick = (index, event) => {
    event.preventDefault();
    if (itemsValue && itemsValue[index]) {
      setOpeningStockValue({
        mrp: itemsValue[index]?.mrp || 0,
        purchaseCost: itemsValue[index]?.cost || 0,
        stock: itemsValue[index]?.stock || 0,
        salesPrice: itemsValue[index]?.salesPrice || 0,
      });
      setDisableMrp(true);
      setDisablePurchaseCost(true);
    }
  };

  const deleteClick = () => {
    if (itemsValue && itemsValue[deleteId]) {
      const item = itemsValue[deleteId];

      const taxPercentage =
        taxes?.filter((item) => item.taxId === productValue?.tax?.value)[0]
          ?.attributes?.taxPercentage || 0;

      const purchaseCost =
        (Number(item?.cost) / (1 + taxPercentage / 100)).toFixed(2) || 0;

      let values =
        "?itemId=" +
        id +
        "&mrp=" +
        (item?.mrp || 0) +
        "&batchNo=" +
        "&purchaseCost=" +
        purchaseCost;

      console.log("values => ", values);

      deleteOpeningStock(values, id, true);
      setStockDelete(false);
      setOpeningStockValue({
        mrp: "",
        purchaseCost: "",
        stock: "",
        salesPrice: "",
      });
      // Optionally, update itemsValue to reflect deleted item
      // const updatedItemsValue = itemsValue.filter((_, i) => i !== deleteId);
      // setItemsValue(updatedItemsValue);
    }
  };

  const onCancel = () => {
    setStockDelete(false);
  };

  const onDeleteIdClick = (index) => {
    setDeleteId(index);
    setStockDelete(true);
  };

  useEffect(() => {
    const userRole = localStorage.getItem("loginUserArray");
    const role = JSON.parse(userRole);
    const roleName = role?.role;
    if (roleName.toUpperCase() == "ADMINISTRATOR") {
      setRoleName({
        edit: true,
        delete: true,
        priceHistory: true,
        cancel: true,
      });
    } else if (roleName.toUpperCase() == "MANAGER") {
      setRoleName({
        edit: true,
        delete: false,
        priceHistory: false,
        cancel: false,
      });
    } else if (roleName.toUpperCase() == "USER") {
      setRoleName({
        edit: false,
        delete: false,
        priceHistory: false,
        cancel: false,
      });
    }
  }, []);

  const setDelete = (items) => {
    setIsDelete(items);
  };

  const openingStockhandleKeyDown = (e) => {
    const invalidKeys = ["e", "E", "+", "-", "ArrowUp", "ArrowDown"];
    const restrictedFields = ["mrp", "salesPrice", "purchaseCost", "stock"];

    const inputs = document.querySelectorAll("input, select, textarea");
    const currentIndex = Array.from(inputs).indexOf(event.target);

    if (currentIndex === -1) return;

    if (e.key === "ArrowUp") {
      if (e.target.name === "mrp") {
        e.preventDefault();
      } else if (currentIndex > 0) {
       singleProduct? inputs[currentIndex - 2]?.focus() : inputs[currentIndex - 1]?.focus();
      }
    }

    if (e.key === "ArrowDown") {
      if (e.target.name === "stock") {
        const submitButton = document.querySelector(".save-btn");
        if (submitButton) {
          submitButton.focus();
          e.preventDefault(); 
        }
      } else if (currentIndex < inputs.length - 1) {
        singleProduct? inputs[currentIndex + 2]?.focus() : inputs[currentIndex + 1]?.focus();
      }
    }

    if (
      restrictedFields.includes(e.target.name) &&
      invalidKeys.includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const nameKeys = (e) => {
    const inputs = document.querySelectorAll("input, select, textarea");
    const currentIndex = Array.from(inputs).indexOf(e.target);

    if (currentIndex === -1) return;

  

    if (e.key === "ArrowDown") {
      if (e.target.name === "remarks") {
        const submitButton = document.querySelector(".save-btn");
        if (submitButton) {
          submitButton.focus();
          e.preventDefault(); 
        }
      } else if (currentIndex < inputs.length - 1) {
        inputs[currentIndex + 1]?.focus();
      }
    }

    if (e.key === "Enter") {
      if (
        e.target.name === "brand"
        // || e.target.name === "brand" || e.target.name === "brand"
        // || e.target.name === "brand" || e.target.name === "brand" || e.target.name === "brand"
      ) {
        e.preventDefault();
      } else if (currentIndex > 0) {
        inputs[currentIndex + 1]?.focus();
      }
    }

    if (e.key === "ArrowUp") {
      if (e.target.name === "name") {
        e.preventDefault();
      } else if (currentIndex > 0) {
        inputs[currentIndex - 1]?.focus();
      }
    }
  };

  return (
    <>
      <Loader />
      <div>
        <div className="d-md-flex align-items-center justify-content-between mb-5">
          {title ? <h1 className="mb-0 create-title">{title}</h1> : ""}
          <div className="text-end mt-4 mt-md-0">
            <div className="row ">
              <div className="col d-flex">
                <Link
                  to={singleProduct ? singleProduct : ""}
                  className="btn btn-primary me-3 save-btn "
                  style={{ width: "120px" }}
                  onClick={onSubmit}
                >
                  {singleProduct
                    ? getFormattedMessage("globally.update-btn")
                    : getFormattedMessage("globally.save-btn")}
                </Link>
                <Link
                  to="/app/products"
                  className="btn btn-outline-primary back-btn"
                  style={{ width: "120px" }}
                >
                  {getFormattedMessage("globally.back-btn")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Form>
          <div className="row">
            <div className="col-xl-8 col-md-8">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div style={{ textAlign: "-webkit-right" }}>
                        <label className="form-check form-check-custom form-check-solid form-check-inline d-flex align-items-center my-4 cursor-pointer custom-label">
                          <input
                            type="checkbox"
                            name="isactive"
                            value={productValue?.isactive}
                            checked={productValue?.isactive == 1 ? true : false}
                            onChange={(event) => handleChanged(event)}
                            className="me-3 form-check-input cursor-pointer"
                          />
                          <div className="control__indicator" />{" "}
                          {getFormattedMessage("product.input.isactive.label")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          {getFormattedMessage("globally.input.name.label")}{" "}
                        </label>
                        <span className="required" />
                        <input
                          type="text"
                          name="name"
                          value={productValue.name}
                          placeholder={placeholderText(
                            "globally.input.name.placeholder.label"
                          )}
                          className="form-control"
                          autoFocus={true}
                          onChange={(e) => onChangeInput(e)}
                          autoComplete="off"
                          onKeyDown={nameKeys}
                        />
                        <span className="text-danger d-block fw-400 fs-small mt-2">
                          {errors["name"] ? errors["name"] : null}
                        </span>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          {getFormattedMessage(
                            "globally.input.name_print.label"
                          )}{" "}
                        </label>

                        <input
                          type="text"
                          name="name_print"
                          value={productValue.name_print}
                          placeholder={placeholderText(
                            "globally.input.name_print.placeholder.label"
                          )}
                          className="form-control"
                          // autoFocus={true}
                          onChange={(e) => onChangeInput(e)}
                          autoComplete="off"
                          onKeyDown={nameKeys}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          {getFormattedMessage(
                            "globally.input.code_barcode.label"
                          )}{" "}
                        </label>
                        <span className="required" />
                        <input
                          type="text"
                          name="code_barcode"
                          className=" form-control"
                          placeholder={placeholderText(
                            "globally.input.code_barcode.placeholder.label"
                          )}
                          onChange={(e) => onChangeInput(e)}
                          value={productValue.code_barcode}
                          autoComplete="off"
                          onKeyDown={nameKeys}
                        />
                        <span className="text-danger d-block fw-400 fs-small mt-2">
                          {errors["code_barcode"]
                            ? errors["code_barcode"]
                            : null}
                        </span>
                      </div>
                      {/* <div className="col-md-6 mb-3">
                        <label className="form-label">
                          {getFormattedMessage(
                            "globally.input.name_tamil.label"
                          )}{" "}
                        </label>
                        <input
                          type="text"
                          name="name_tamil"
                          value={productValue.name_tamil}
                          placeholder={placeholderText(
                            "globally.input.name_tamil.placeholder.label"
                          )}
                          className="form-control"
                          // autoFocus={true}
                          onChange={(e) => onChangeInput(e)}
                          autoComplete="off"
                        />
                      </div> */}

                      <div className="col-md-6 mb-3">
                        <InputGroup className="flex-nowrap dropdown-side-btn">
                          <ReactSelect
                            className="position-relative"
                            id="brand"
                            // isOpen={brandIsOpen}
                            title={getFormattedMessage(
                              "product.input.brand.label"
                            )}
                            placeholder={placeholderText(
                              "product.input.brand.placeholder.label"
                            )}
                            defaultValue={productValue.category1_id}
                            value={productValue.category1_id}
                            data={sorted_brands}
                            errors={errors["category1_id"]}
                            onChange={onBrandChange}
                            onBlur={onBlur}
                            onKeyDown={(e) => {
                              const dropdownMenu = document.querySelector(
                                ".react-select__menu"
                              );
                              if (e.key === "ArrowDown") {
                                if (!dropdownMenu) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else if (e.key === "ArrowUp") {
                                if (
                                  !dropdownMenu &&
                                  e.target.tagName === "INPUT" &&
                                  !e.target.classList.contains(
                                    "react-select__input"
                                  )
                                ) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else {
                                nameKeys(e);
                              }
                            }}
                          />
                          <Button
                            onClick={() => showBrandModel(true)}
                            className="position-absolute model-dtn"
                            style={{ height: "45px" }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <span
                            className="position-absolute model-dtn1"
                            // onClick={() => { setBrandOpen(!brandIsOpen); setCatOpen(false), setGroupOpen(false); setTaxOpen(false); setPurchaseOpen(false); setSalesOpen(false) }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </InputGroup>
                      </div>

                      <div className="col-md-6 mb-3">
                        <InputGroup className="flex-nowrap dropdown-side-btn">
                          <ReactSelect
                            className="position-relative"
                            // isOpen={catIsOpen}
                            title={getFormattedMessage(
                              "product.input.category.label"
                            )}
                            placeholder={placeholderText(
                              "product.input.category.placeholder.label"
                            )}
                            defaultValue={productValue.category2_id}
                            value={productValue.category2_id}
                            data={sorted_product_category}
                            errors={errors["category2_id"]}
                            onChange={onProductCategoryChange}
                            onBlur={onBlur}
                            onKeyDown={(e) => {
                              const dropdownMenu = document.querySelector(
                                ".react-select__menu"
                              );
                              if (e.key === "ArrowDown") {
                                if (!dropdownMenu) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else if (e.key === "ArrowUp") {
                                if (
                                  !dropdownMenu &&
                                  e.target.tagName === "INPUT" &&
                                  !e.target.classList.contains(
                                    "react-select__input"
                                  )
                                ) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else {
                                nameKeys(e);
                              }
                            }}
                          />
                          <Button
                            onClick={() => showProductCategoryModel(true)}
                            className="position-absolute model-dtn"
                            style={{ height: "45px" }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <span
                            className="position-absolute model-dtn1"
                            // onClick={() => { setCatOpen(!catIsOpen); setBrandOpen(false); setGroupOpen(false); setTaxOpen(false); setPurchaseOpen(false); setSalesOpen(false) }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </InputGroup>
                      </div>

                      <div className="col-md-6 mb-3">
                        <InputGroup className="flex-nowrap dropdown-side-btn">
                          <ReactSelect
                            className="position-relative"
                            // isOpen={groupIsOpen}
                            title={getFormattedMessage(
                              "product.input.group.label"
                            )}
                            placeholder={placeholderText(
                              "product.input.group.placeholder.label"
                            )}
                            defaultValue={productValue.category3_id}
                            value={productValue.category3_id}
                            data={sorted_product_group}
                            errors={errors["category3_id"]}
                            onChange={onProductGroupChange}
                            onBlur={onBlur}
                            onKeyDown={(e) => {
                              const dropdownMenu = document.querySelector(
                                ".react-select__menu"
                              );
                              if (e.key === "ArrowDown") {
                                if (!dropdownMenu) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else if (e.key === "ArrowUp") {
                                if (
                                  !dropdownMenu &&
                                  e.target.tagName === "INPUT" &&
                                  !e.target.classList.contains(
                                    "react-select__input"
                                  )
                                ) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else {
                                nameKeys(e);
                              }
                            }}
                          />
                          <Button
                            onClick={() => showProductGroupModel(true)}
                            className="position-absolute model-dtn"
                            style={{ height: "45px" }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <span
                            className="position-absolute model-dtn1"
                            // onClick={() => { setGroupOpen(!groupIsOpen); setBrandOpen(false); setCatOpen(false); setTaxOpen(false); setPurchaseOpen(false); setSalesOpen(false) }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </InputGroup>
                      </div>

                      <div className="col-md-6 mb-3">
                        <InputGroup className="flex-nowrap dropdown-side-btn">
                          <ReactSelect
                            className="position-relative"
                            // isOpen={taxIsOpen}
                            title={getFormattedMessage(
                              "globally.input.tax.label"
                            )}
                            placeholder={placeholderText(
                              "globally.input.tax.placeholder.label"
                            )}
                            id ="brand"
                            defaultValue={productValue?.tax}
                            value={productValue?.tax}
                            isRequired={true}
                            data={sortedtax}
                            errors={errors["tax"]}
                            onChange={onTaxChange}
                            onBlur={onBlur}
                            onKeyDown={(e) => {
                              const dropdownMenu = document.querySelector(
                                ".react-select__menu"
                              );
                              if (e.key === "ArrowDown") {
                                if (!dropdownMenu) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else if (e.key === "ArrowUp") {
                                if (
                                  !dropdownMenu &&
                                  e.target.tagName === "INPUT" &&
                                  !e.target.classList.contains(
                                    "react-select__input"
                                  )
                                ) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else {
                                nameKeys(e);
                              }
                            }}
                          />
                          <Button
                            onClick={() => showtaxModel(true)}
                            className="position-absolute model-dtn"
                            style={{ height: "45px" }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <span
                            className="position-absolute model-dtn1"
                            // onClick={() => { setTaxOpen(!taxIsOpen); setBrandOpen(false); setCatOpen(false); setGroupOpen(false); setPurchaseOpen(false); setSalesOpen(false) }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </InputGroup>
                      </div>

                      <div className="col-md-6 mb-3">
                        <InputGroup className="flex-nowrap dropdown-side-btn">
                          <ReactSelect
                            className="position-relative"
                            // isOpen={groupIsOpen}
                            title={getFormattedMessage(
                              "product.input.hsn.label"
                            )}
                            placeholder={placeholderText(
                              "product.input.hsn.placeholder.label"
                            )}
                              id ="brand"
                            defaultValue={productValue.category4_id}
                            value={productValue.category4_id}
                            data={sortedHsn?.filter(
                              (item) => item?.id != 1 && item != null
                            )}
                            errors={errors["category3_id"]}
                            onChange={onHsnCodeChange}
                            onBlur={onBlur}
                            onKeyDown={(e) => {
                              const dropdownMenu = document.querySelector(
                                ".react-select__menu"
                              );
                              if (e.key === "ArrowDown") {
                                if (!dropdownMenu) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else if (e.key === "ArrowUp") {
                                if (
                                  !dropdownMenu &&
                                  e.target.tagName === "INPUT" &&
                                  !e.target.classList.contains(
                                    "react-select__input"
                                  )
                                ) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else {
                                nameKeys(e);
                              }
                            }}
                          />
                          <Button
                            onClick={() => showHsnModel(true)}
                            className="position-absolute model-dtn"
                            style={{ height: "45px" }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <span
                            className="position-absolute model-dtn1"
                            // onClick={() => { setGroupOpen(!groupIsOpen); setBrandOpen(false); setCatOpen(false); setTaxOpen(false); setPurchaseOpen(false); setSalesOpen(false) }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </InputGroup>
                      </div>

                      <div className="col-md-6 mb-3">
                        <InputGroup className="flex-nowrap dropdown-side-btn">
                          <ReactSelect
                            className="position-relative"
                            // isOpen={purchaseIsOpen}
                            title={getFormattedMessage(
                              "product.input.purchase-unit.label"
                            )}
                            placeholder={placeholderText(
                              "product.input.purchase-unit.placeholder.label"
                            )}
                              id ="brand"
                            defaultValue={productValue.purchase_unit_id}
                            value={productValue.purchase_unit_id}
                            data={units}
                            errors={errors["purchase_unit_id"]}
                            onChange={handlePurchaseUnitChange}
                            isRequired={true}
                            onBlur={onBlur}
                            isWarehouseDisable={singleProduct && !singleProduct[0]?.isEditable}
                            isDisable={singleProduct && !singleProduct[0]?.isEditable}
                            onKeyDown={(e) => {
                              const dropdownMenu = document.querySelector(
                                ".react-select__menu"
                              );
                              if (e.key === "ArrowDown") {
                                if (!dropdownMenu) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else if (e.key === "ArrowUp") {
                                if (
                                  !dropdownMenu &&
                                  e.target.tagName === "INPUT" &&
                                  !e.target.classList.contains(
                                    "react-select__input"
                                  )
                                ) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else {
                                nameKeys(e);
                              }
                            }}
                          />
                          <Button
                            onClick={() =>
                              (singleProduct && singleProduct[0]?.isEditable) && showUnitModel(true)
                            }
                            className="position-absolute model-dtn"
                            style={{ height: "45px" }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <span
                            className="position-absolute model-dtn1"
                            // onClick={() => { setPurchaseOpen(!purchaseIsOpen); setBrandOpen(false); setCatOpen(false); setGroupOpen(false); setTaxOpen(false); setSalesOpen(false) }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </InputGroup>
                      </div>
                      <div className="col-md-6 mb-3">
                        <InputGroup className="flex-nowrap dropdown-side-btn">
                          <ReactSelect
                            className="position-relative"
                            name="sales_unit_id"
                              
                            // isOpen={salesIsOpen}
                            title={getFormattedMessage(
                              "product.input.sale-unit.label"
                            )}
                            placeholder={placeholderText(
                              "product.input.sale-unit.placeholder.label"
                            )}
                            defaultValue={productValue.sales_unit_id}
                            value={productValue.sales_unit_id}
                            data={units}
                            errors={errors["sales_unit_id"]}
                            onChange={handleSalesUnitChange}
                            isRequired={true}
                            onBlur={onBlur}
                            isWarehouseDisable={singleProduct && !singleProduct[0]?.isEditable}
                            isDisable={singleProduct && !singleProduct[0]?.isEditable}
                            onKeyDown={(e) => {
                              const dropdownMenu = document.querySelector(
                                ".react-select__menu"
                              );
                              if (e.key === "ArrowDown") {
                                if (!dropdownMenu) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else if (e.key === "ArrowUp") {
                                if (
                                  !dropdownMenu &&
                                  e.target.tagName === "INPUT" &&
                                  !e.target.classList.contains(
                                    "react-select__input"
                                  )
                                ) {
                                  e.stopPropagation();
                                } else {
                                  nameKeys(e);
                                }
                              } else {
                                nameKeys(e);
                              }
                            }}
                          />
                          <Button
                            onClick={() =>
                              (singleProduct && singleProduct[0]?.isEditable) && showUnitModel(true)
                            }
                            className="position-absolute model-dtn"
                            style={{ height: "45px" }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          <span
                            className="position-absolute model-dtn1"
                            // onClick={() => { setSalesOpen(!salesIsOpen); setBrandOpen(false); setCatOpen(false); setGroupOpen(false); setTaxOpen(false); setPurchaseOpen(false) }}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </span>
                        </InputGroup>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          {getFormattedMessage(
                            "globally.input.pack_count.label"
                          )}{" "}
                        </label>
                        <span className="required" />
                        <input
                          type="number"
                          name="pack_count"
                          value={productValue.pack_count}
                          placeholder={placeholderText(
                            "globally.input.pack_count.placeholder.label"
                          )}
                          className="form-control"
                          // autoFocus={true}
                          onChange={(e) => onChangeInput(e)}
                          autoComplete="off"
                          onKeyDown={(e) => handleKeyDown(e)}
                          min="0"
                          disabled={singleProduct && !singleProduct[0]?.isEditable}
                          // onKeyDown={nameKeys}
                        />
                        <span className="text-danger d-block fw-400 fs-small mt-2">
                          {errors["pack_count"] ? errors["pack_count"] : null}
                        </span>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          {getFormattedMessage(
                            "globally.input.stock_alert.label"
                          )}{" "}
                        </label>
                        {/* <span className="required" /> */}

                        <InputGroup className="flex-nowrap dropdown-side-btn">
                          <input
                            type="number"
                            name="stock_alert"
                            value={productValue.stock_alert}
                            placeholder={placeholderText(
                              "globally.input.stock_alert.placeholder.label"
                            )}
                            className="form-control"
                            // autoFocus={true}
                            // onKeyDown={(e) => handleKeyDown(e)}
                            onKeyDown={(e) => handleKeyDownStock(e)}
                            onChange={(e) => onChangeInput(e)}
                            autoComplete="off"
                            // onKeyDown={nameKeys}
                          />
                          {/* <span className="text-danger d-block fw-400 fs-small mt-2">
                                            {errors["stock_alert"] ? errors["stock_alert"] : null}
                                          </span> */}
                          <Button className="modal-label">
                            {productValue?.sales_unit_id?.label ?? ""}
                          </Button>
                        </InputGroup>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          {getFormattedMessage(
                            "product.input.product_image.label"
                          )}{" "}
                        </label>
                        <MultipleImage
                          product={singleProduct}
                          fetchFiles={onChangeFiles}
                          transferImage={transferImage}
                          singleImageSwitch="single-image"
                          setDelete={setDelete}
                          onKeyDown={nameKeys}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          {getFormattedMessage("globally.input.remarks.label")}{" "}
                        </label>
                        {/* <span className="required" /> */}
                        <textarea
                          className="form-control"
                          name="remarks"
                          rows={1}
                          placeholder={placeholderText(
                            "globally.input.remarks.placeholder.label"
                          )}
                          onChange={(e) => onChangeInput(e)}
                          value={
                            productValue.remarks
                              ? productValue.remarks === "null"
                                ? ""
                                : productValue.remarks
                              : ""
                          }
                          onKeyDown={nameKeys}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="col-md-12 mb-3">
                      <h1 className={"text-center"}>
                        {getFormattedMessage("opening-stock-add.title")}
                      </h1>
                    </div>
                    <div className="col-md-12 mb-3">
                      <h8 className={"text-center"}>
                        {getFormattedMessage("all-inclusive-tax.title")}
                      </h8>
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        {getFormattedMessage("product.input.mrp.label")}{" "}
                      </label>
                      {/* <span className="required" /> */}
                      <div className="input-group">
                        <input
                          type="number"
                          name="mrp"
                          value={openingStockValue.mrp || ""}
                          placeholder={placeholderText(
                            "product.input.mrp.placeholder.label"
                          )}
                          className="form-control"
                          // autoFocus={true}
                          onChange={(e) => onChangeInput(e)}
                          onKeyDown={openingStockhandleKeyDown}
                          onWheel={(e) => handleWheel(e)}
                          autoComplete="off"
                          disabled={disableMrp}
                        />

                        <Button
                          className="modal-label"
                          style={{ width: "100px" }}
                        >
                          {productValue?.sales_unit_id?.label ?? ""}
                        </Button>
                      </div>
                      {/* <span className="text-danger d-block fw-400 fs-small mt-2">
                                            {errors["stock_alert"] ? errors["stock_alert"] : null}
                                        </span> */}
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        {getFormattedMessage("product.input.sales-price.label")}{" "}
                      </label>
                      {/* <span className="required" /> */}
                      <div className="input-group">
                        <input
                          type="number"
                          name="salesPrice"
                          value={openingStockValue.salesPrice}
                          placeholder={placeholderText(
                            "product.input.sales-price.placeholder.label"
                          )}
                          className="form-control"
                          // autoFocus={true}
                          onChange={(e) => onChangeInput(e)}
                          onKeyDown={openingStockhandleKeyDown}
                          onWheel={(e) => handleWheel(e)}
                          autoComplete="off"
                          // disabled
                        />
                        <Button
                          className="modal-label"
                          style={{ width: "100px" }}
                        >
                          {productValue?.sales_unit_id?.label ?? ""}
                        </Button>
                        {/* <span className="text-danger d-block fw-400 fs-small mt-2">
                                            {errors["stock_alert"] ? errors["stock_alert"] : null}
                                          </span> */}
                      </div>
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        {getFormattedMessage(
                          "product.input.purchase-cost.label"
                        )}{" "}
                      </label>
                      {/* <span className="required" /> */}
                      <div className="input-group">
                        <input
                          type="number"
                          name="purchaseCost"
                          value={openingStockValue.purchaseCost}
                          placeholder={placeholderText(
                            "product.input.purchase-cost.placeholder.label"
                          )}
                          className="form-control"
                          // autoFocus={true}
                          onChange={(e) => onChangeInput(e)}
                          onKeyDown={openingStockhandleKeyDown}
                          onWheel={(e) => handleWheel(e)}
                          autoComplete="off"
                          // disabled
                          disabled={disablePurchaseCost}
                        />
                        <Button
                          className="modal-label"
                          style={{ width: "100px" }}
                        >
                          {productValue?.sales_unit_id?.label ?? ""}
                        </Button>
                        {/* <span className="text-danger d-block fw-400 fs-small mt-2">
                                            {errors["stock_alert"] ? errors["stock_alert"] : null}
                                          </span> */}
                      </div>
                    </div>

                    {/* <div className="col-md-12 mb-3">
                    <label className="form-label">
                      {getFormattedMessage("product.input.sales-price.label")}:{" "}
                    </label>
                    <input
                      type="text"
                      name="stock_alert"
                      value={productValue.stock_alert}
                      placeholder={placeholderText(
                        "product.input.sales-price.placeholder.label"
                      )}
                      className="form-control"
                      autoFocus={true}
                      onChange={(e) => onChangeInput(e)}
                    />
                  </div> */}

                    <div className="col-md-12 mb-3">
                      <label className="form-label">
                        {getFormattedMessage(
                          "product.input.opening-stock.label"
                        )}{" "}
                      </label>
                      {/* <span className="required" /> */}
                      <div className="input-group">
                        <input
                          type="number"
                          name="stock"
                          value={openingStockValue.stock}
                          placeholder={placeholderText(
                            "product.input.opening-stock.placeholder.label"
                          )}
                          className="form-control"
                          // autoFocus={true}
                          onChange={(e) => onChangeInput(e)}
                          onKeyDown={openingStockhandleKeyDown}
                          onWheel={(e) => handleWheel(e)}
                          autoComplete="off"
                          // disabled
                        />
                        <Button
                          className="modal-label"
                          style={{ width: "100px" }}
                        >
                          {productValue?.sales_unit_id?.label ?? ""}
                        </Button>
                        {/* <span className="text-danger d-block fw-400 fs-small mt-2">
                                            {errors["stock_alert"] ? errors["stock_alert"] : null}
                                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <ModelFooter
              onEditRecord={singleProduct}
              onSubmit={onSubmit}
              //editDisabled={disabled}
              link="/app/products"
            // addDisabled={!productValue.name}
            /> */}
              <br />
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div>
                      <div
                        style={{
                          maxHeight: "280px",
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          borderRadius: "5px 5px 5px 5px",
                        }}
                      >
                        <table
                          style={{
                            borderSpacing: "10px",
                            borderCollapse: "collapse",
                            width: "100%",
                            borderRadius: "5px 0px 5px 0px",
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "aqua",
                              width: "100%",
                              textAlign: "right",
                            }}
                          >
                            <tr>
                              {column.map((col, index) => (
                                <th
                                  key={index}
                                  style={{ textAlign: "right" }}
                                  className="py-3  "
                                >
                                  {col}
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {itemsValue && itemsValue.length > 0 ? (
                              itemsValue.map((col, index) => (
                                <tr key={index} style={{ textAlign: "right" }}>
                                  <td style={{ textAlign: "right" }}>
                                    {col.mrp}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {col.cost}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {col.salesPrice}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {col.stock + " " + col.unitName}
                                  </td>

                                  <td
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      marginLeft: "10%",
                                    }}
                                  >
                                    <button
                                      className={
                                        roleName?.edit
                                          ? "btn text-primary fs-3 border-0 px-xxl-2 px-1"
                                          : "d-none"
                                      }
                                      onClick={(event) =>
                                        editClick(index, event)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faPenToSquare} />
                                    </button>
                                    <button
                                      type="button"
                                      className={
                                        roleName?.delete
                                          ? "btn px-2 text-danger fs-3 border-0"
                                          : "d-none"
                                      }
                                      onClick={(event) =>
                                        onDeleteIdClick(index)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="5"
                                  style={{ textAlign: "center" }}
                                  className="p-5"
                                >
                                  No data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>

        {unitModel && (
          <UnitsForm
            addProductData={addUnitsData}
            unitid={productValue.unitid}
            title={getFormattedMessage("unit.create.title")}
            show={unitModel}
            handleUnitClose={handleUnitClose}
            hide={setUnitModel}
          />
        )}

        {brandModel && (
          <BrandsForm
            addBrandData={addBrandData}
            category1_id={productValue.category1_id}
            title={getFormattedMessage("brand.create.title")}
            show={brandModel}
            handleBrandClose={handleBrandClose}
            hide={setBrandModel}
          />
        )}

        {productCategoryModel && (
          <ProductCategoryForm
            addProductcData={addProductcData}
            category2_id={productValue.category2_id}
            title={getFormattedMessage("product-category.create.title")}
            show={productCategoryModel}
            handleCategoryClose={handleCategoryClose}
            hide={setProductCategoryModel}
          />
        )}

        {productGroupModel && (
          <ProductGroupsForm
            addProductGroupsData={addProductGroupsData}
            category3_id={productValue.category3_id}
            title={getFormattedMessage("productGroup.create.title")}
            show={productGroupModel}
            handleGroupClose={handleGroupClose}
            hide={setProductGroupModel}
          />
        )}

        {taxModal && (
          <TaxSetupForm
            addProductData={addTaxSetupData}
            show={taxModal}
            title={getFormattedMessage("taxSetup.create.title")}
            handleTaxClose={handleTaxClose}
            hide={setTaxModal}
          />
        )}

        {hsnModal && (
          <HsnCodeForm
            addHsnCodeData={addHsnCodeData}
            show={hsnModal}
            title={getFormattedMessage("Hsncode.create.title")}
            handleHsnClose={handleHsnClose}
            hide={setHsnModal}
          />
        )}
      </div>
      {stockDelete && (
        <SweetAlert
          confirmBtnBsStyle="success mb-3 fs-5 rounded"
          cancelBtnBsStyle="secondary mb-3 fs-5 rounded text-white"
          confirmBtnCssClass="alert_yes"
          confirmBtnText={"Yes,It's Ok"}
          cancelBtnText={"Cancel"}
          title={"Are you sure to delete the Opening stock record.?"}
          // onConfirm={onConfirm}
          // onCancel={onCancel}
          showCancel
          focusCancelBtn
          customButtons={
            <>
              <button
                id="cancel-button"
                onClick={() => onCancel()}
                className="btn btn-secondary"
              >
                No
              </button>

              <button
                id="confirm-button"
                className="btn btn-success"
                style={{ marginRight: "5%" }}
                autoFocus={true}
                onClick={() => deleteClick()}
              >
                Yes
                {/* ,It's Ok */}
              </button>
            </>
          }
          // customIcon={remove}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    brands,
    productCategories,
    productGroups,
    units,
    totalRecord,
    suppliers,
    warehouses,
    taxs,
    isLoading,
    openingStock,
    hsnCodes,
  } = state;
  return {
    brands,
    productCategories,
    productGroups,
    units,
    totalRecord,
    suppliers,
    warehouses,
    taxs,
    isLoading,
    openingStock,
    hsnCodes,
  };
};

export default connect(mapStateToProps, {
  fetchProduct,
  editProduct,
  addProduct,
  fetchAllBrands,
  fetchAllProductCategories,
  fetchAllProductGroups,
  fetchAllHsnCodes,
  fetchUnits,
  unitDropdown,
  fetchAllWarehouses,
  fetchAllSuppliers,
  addUnit,
  addBrand,
  brandDropdown,
  addProductCategory,
  productCategoryDropdown,
  addProductGroup,
  productGroupDropdown,
  fetchTax,
  fetchTaxSetup,
  fetchProducts,
  deleteOpeningStock,
  deleteItemImage,
})(ProductForm);
