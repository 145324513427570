import apiConfig from "../../config/apiConfig";
import { apiBaseURL, purchaseActionType, purchaseSingleActionType, salesOrderActionType, salesOrderListActionType, singleSalesOrderActionType, toastType } from "../../constants";
import { addToast } from "./toastAction";
import {
    setTotalRecord,
    addInToTotalRecord,
    removeFromTotalRecord,
} from "./totalRecordAction";
import requestParam from "../../shared/requestParam";
import { setLoading } from "./loadingAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setSavingButton } from "./saveButtonAction";
import { setLoader } from "./loaderAction";

export const fetchSalesOrders =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            if (isLoading) {
                dispatch(setLoading(true));
                dispatch(setLoader(true));
            }
            let url = apiBaseURL.SALES_ORDER;
            if (
                !_.isEmpty(filter) &&
                (filter.page ||
                    filter.pageSize ||
                    filter.search ||
                    filter.order_By ||
                    filter.created_at)
            ) {
                url += requestParam(filter, null, null, null, url);
            }
            apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: salesOrderListActionType.FETCH_SALES_ORDERS,
                        payload: response.data.data,
                    });
                    !allUser &&
                        dispatch(
                            setTotalRecord(
                                response.data.meta.total !== undefined &&
                                    response.data.meta.total >= 0
                                    ? response.data.meta.total
                                    : response.data.data.total
                            )
                        );
                    if (isLoading) {
                        dispatch(setLoading(false));
                        dispatch(setLoader(false));
                    }

                    if (response?.data?.success) {
                        dispatch(
                            setLoader(false),
                        )
                    }
                })
                .catch(({ response }) => {
                    if (isLoading) {
                        dispatch(setLoading(false));
                        dispatch(setLoader(false));
                    }
                    if (response) {
                        dispatch(
                            addToast({
                                type: toastType.ERROR,
                                message: getFormattedMessage(
                                    response.data.message
                                ),
                            })
                        );
                    }
                });
        }

export const fetchSingleSalesOrder =
    (Id, isLoading = true) =>
        async (dispatch) => {
            dispatch(setLoader(true));
            if (isLoading) {
                // dispatch(setLoading(true));
            }
            await apiConfig
                .get(apiBaseURL.SALES_ORDER + "?salesOrdertxNo=" + Id)
                .then((response) => {
                    dispatch({
                        type: singleSalesOrderActionType.FETCH_SINGLE_SALES_ORDER,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        if (isLoading) {
                            // dispatch(setLoading(false));
                        }
                        // dispatch(fetchPosSalesListing());
                        dispatch(setLoader(false));
                    }
                    else {
                        dispatch(setLoader(false));
                        // dispatch(fetchPosSalesListing());
                        dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

// export const addSalesOrder = (sales, navigate) => async (dispatch) => {
//     dispatch(setSavingButton(true));
//     dispatch(setLoader(true));
//     apiConfig
//         .post(apiBaseURL.SALES_ORDER, sales)
//         .then((response) => {
//             dispatch({
//                 type: salesOrderActionType.ADD_SALES_ORDER,
//                 payload: response.data.data,
//             });
//             if (response?.data?.success) {
//                 dispatch(
//                     addToast({
//                         text: getFormattedMessage(
//                             response?.data?.message
//                         ),
//                     })
//                 );
//                 dispatch(fetchSalesOrders());
//                 // if(purchase?.xMode != 'D'){window.location.href = "#/app/purchases";}
//                 dispatch(setLoader(false));
//                 dispatch(addInToTotalRecord(1));
//                 if(sales?.xMode != 'D'){window.location.href = "#/app/salesOrder";}
//             } else {
//                 dispatch(fetchSalesOrders());
//                 dispatch(setLoader(false));
//                 dispatch(
//                     addToast({ text: response?.data?.message, type: toastType.ERROR })
//                 );
//             }

//             dispatch(setSavingButton(false));
//         })
//         .catch(({ response }) => {
//             dispatch(setSavingButton(false));
//             dispatch(fetchSalesOrders());
//             dispatch(
//                 addToast({ text: response?.data?.message, type: toastType.ERROR })
//             );
//         });
// };

export const addSalesOrder = (sales, navigate, files) => async (dispatch) => {
    dispatch(setSavingButton(true));
    dispatch(setLoader(true));

    try {
        // First, post the sales order
        const response = await apiConfig.post(apiBaseURL.SALES_ORDER, sales);

        if (response?.data?.success) {
            dispatch({
                type: salesOrderActionType.ADD_SALES_ORDER,
                payload: response.data.data,
            });
            dispatch(addToast({ text: response?.data?.message }));
            dispatch(fetchSalesOrders());
            dispatch(addInToTotalRecord(1));
            dispatch(setLoader(false));

            // If sales order is successful and files are provided, upload the files
            if (files && files.length > 0) {
                const formData = new FormData();
                files.forEach((file) => formData.append("productImage", file));

                try {
                    await dispatch(addProductImage(formData, navigate, response.data.data));
                    dispatch(addToast({ text: "Files uploaded successfully!" }));
                } catch (error) {
                    dispatch(addToast({ text: "File upload failed.", type: toastType.ERROR }));
                }
            }

            if (sales?.xMode !== 'D') {
                window.location.href = "#/app/salesOrder";
            }
        } else {
            dispatch(fetchSalesOrders());
            dispatch(setLoader(false));
            dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));
        }
    } catch (error) {
        dispatch(setSavingButton(false));
        dispatch(fetchSalesOrders());
        dispatch(addToast({ text: error?.response?.data?.message || "An error occurred.", type: toastType.ERROR }));
    } finally {
        dispatch(setSavingButton(false));
    }
};

export const addProductImage = (formData, navigate, salesOrderData) => async (dispatch) => {
    dispatch(setSavingButton(true));
    dispatch(setLoader(true));
    console.log(salesOrderData);
    debugger;

    try {
        // Append `txNo` to FormData
        formData.append('txNo', salesOrderData.txNo);

        const response = await apiConfig.post(apiBaseURL.SALES_ORDER_IMAGE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response?.data?.success) {
            dispatch(addToast({ text: "Files uploaded successfully!" }));
            dispatch({
                type: 'UPLOAD_PRODUCT_IMAGES_SUCCESS',
                payload: response.data, // Assuming response contains file upload data
            });
        } else {
            dispatch(addToast({ text: response?.data?.message || "File upload failed.", type: toastType.ERROR }));
            dispatch({
                type: 'UPLOAD_PRODUCT_IMAGES_FAILURE',
            });
        }
    } catch (error) {
        dispatch(addToast({ text: "File upload failed.", type: toastType.ERROR }));
        dispatch({
            type: 'UPLOAD_PRODUCT_IMAGES_FAILURE',
        });
    } finally {
        dispatch(setSavingButton(false));
        dispatch(setLoader(false));
    }
};
