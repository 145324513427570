import { useNavigate, useParams } from "react-router"
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import { getFormattedMessage } from "../../shared/sharedMethod"
import { connect } from "react-redux"
import { Filters } from "../../constants"
import MasterLayout from "../MasterLayout"
import PosCustomerForm from "./PosCustomerForm"
import React, { useEffect, useState } from 'react';
import { addCustomer, getZones } from "../../store/action/PosCustomerAction"
import { companyDetailById } from "../../store/action/companyConfigAction"

const CreatePosCustomer = (props) => {
  const { addCustomer, getZones, companyDetailById } = props
  const navigate = useNavigate()
  const addCustomerData = (formValue) => {
    addCustomer(formValue, navigate, Filters.OBJ)
  }
  // const navigate = useNavigate();
  const [formcode, setFormCode] = useState("M02");
  // const  navigate = useNavigate();
  const { action } = useParams();
  useEffect(() => {
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      console.log("Parsed Form Data:", parsedFormData);
      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permissionAdd);
        console.log("Form Code Items:", formCodeItems);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
    getZones();
    companyDetailById(1);
  }, []);

  return (
    <div>
      <MasterLayout>
        <TopProgressBar />

        <PosCustomerForm addCustomerData={addCustomerData} title={getFormattedMessage("customer.create.title")} to='/app/posCustomer' action={action} />
      </MasterLayout>
    </div>
  )

}


export default connect(null, { addCustomer, getZones, companyDetailById })(CreatePosCustomer)