import {
    faEye,
    faFileExcel,
    faFilePdf,
    faFilter,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";
import { connect, useDispatch } from "react-redux";
import ClosingStockReportForm from "../closingStockReport/ClosingStockReportForm";
import ClosingStockField from "../closingStockReport/ClosingStockField";
import { fetchAcYear } from "../../../store/action/acYearAction";
import { Button, InputGroup } from "react-bootstrap-v5";
import ReactSelect from "../../../shared/select/reactSelect";
import { placeholderText } from "../../../shared/sharedMethod";
import { fetchGstrReport } from "../../../store/action/gstrReportAction";
import Loader from "../../loader/Loader";
import { generatePDF, printPDF } from "../pdfUtil";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import { addToast } from "../../../store/action/toastAction";
import moment from "moment";

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const HsnReport = (props) => {

    const { stockMovement, acYear, fetchAcYear, fetchGstrReport, gstrReport, fetchCompanyConfig, companyConfig, setFromDate, setDateto } = props;

    const today = new Date();
    const formattedDate = formatDate(today);
    const numOfDaysAdded = 0;
    const date = today.setDate(today.getDate() + numOfDaysAdded);
    const defaultValue = new Date(date).toISOString().split("T")[0]; // YYYY-MM-dd

    const formatDecimal = (cell, formatterParams, onRendered) => {
        const value = cell.getValue();

        return value?.toFixed(2);
    };
    const dispatch = useDispatch();
    const [filterGstr, setFilterGstr] = useState([]);
    const [fieldShow, setFieldShow] = useState(false);
    const handleFieldClose = () => setFieldShow(!fieldShow);
    const [showField, setShowField] = useState();
    const [minYear, setMinYear] = useState();
    const [maxYear, setMaxYear] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);
    const year = useMemo(() => acYear, [acYear]);
    const [label, setLabel] = useState(false);
    const [fetchRangeValue, setFetchRangeValue] = useState({
        category1Name: "",
        category2Name: "",
        category3Name: "",
        unitName: ""
    });
    const paySlipRef = useRef(null);
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearRange = `${currentYear}-${nextYear}`;
    const [selectedYearRange, setSelectedYearRange] = useState({
        value: yearRange,
        label: yearRange,
    });
    const [totalData, setTotalData] = useState({});
    const [range, setRange] = useState({
        category1Name: "",
        category2Name: "",
        category3Name: "",
        unitName: "",
    });

    const [dates, setDate] = useState({
        fromDate: defaultValue,
        toDate: defaultValue,
    });

    const columns = [
        {
            title: "HSN", field: "hsn", headerSort: false
        },
        {
            title: "Description", field: "description", headerSort: false, hozAlign: "right", headerHozAlign: "right",
            bottomCalc: () => 'Total', // Custom text for the bottom of this column
            bottomCalcFormatter: (cell) => {
                return '<strong style="padding-left: 10px;">Total</strong>'; // Display 'Total' in bold
            }
        },
        { title: "UQC", field: "uqc", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "Total Quantity", field: "totalQuantity", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        {
            title: "Total Value", field: "totalValue", headerSort: false, hozAlign: "right", headerHozAlign: "right", bottomCalc: (values, data) => {
                // Custom calculation logic
                let total = 0;
                values.forEach(value => {
                    total += parseFloat(value) || 0; // Ensure values are parsed as numbers
                });
                return total.toFixed(2); // Return total with two decimal places
            }
        },
        { title: "Rate", field: "tax", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        {
            title: "Taxable Value", field: "taxableValue", headerSort: false, hozAlign: "right", headerHozAlign: "right", bottomCalc: (values, data) => {
                // Custom calculation logic
                let total = 0;
                values.forEach(value => {
                    total += parseFloat(value) || 0; // Ensure values are parsed as numbers
                });
                return total.toFixed(2); // Return total with two decimal places
            }
        },
        { title: "Integrated Tax Amount", field: "integratedTaxAmount", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "Central Tax Amount", field: "centralTaxAmount", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "State/UT Tax Amount", field: "stateTaxAmount", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        {
            title: "Cess Amount", field: "cessAmount", headerSort: false, hozAlign: "right", headerHozAlign: "right", bottomCalc: (values, data) => {
                // Custom calculation logic
                let total = 0;
                values.forEach(value => {
                    total += parseFloat(value) || 0; // Ensure values are parsed as numbers
                });
                return total.toFixed(2); // Return total with two decimal places
            }
        },

    ];

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [inputValue, setInputValue] = useState(0);
    const companyDetails = {
        companyName: companyConfig?.companyName || 'Company Name',
        address: `${companyConfig?.attributes?.address1 || ''} , ${companyConfig?.attributes?.address2 || ''}`,
        phoneNumber: companyConfig?.attributes?.phoneNo || 'Phone Number'
    };

    const fromDate = useRef();
    const toDate = useRef();

    const loadValues = (filter) => {
        return "?fromDate=" + filter + "&toDate=" + filter;
    }

    useEffect(() => {
        fetchAcYear();
        fetchCompanyConfig();
        fetchGstrReport(loadValues(defaultValue));
        setFromDate(defaultValue);
        setDateto(defaultValue);
    }, []);

    useEffect(() => {
        console.log("GSTR Report =>", gstrReport);
        setFilterGstr(gstrReport);
        
        const hsn = new Set();

        // Iterate through the data
        gstrReport[0]?.attributes?.hsn?.filter(item => item?.hsn != "").forEach(item => {
            hsn.add(item.hsn);
        });

        // Get the counts
        const hsnCount = hsn.size;

        console.log("customerRegnoCount =>", hsnCount);

        setTotalData({
            "hsn": hsnCount,
            "totalValue": gstrReport[0]?.attributes?.hsn?.reduce((accumulator, currentValue) => accumulator + currentValue?.totalValue, 0),
            "totalTaxValue": gstrReport[0]?.attributes?.hsn?.reduce((accumulator, currentValue) => accumulator + currentValue?.taxableValue, 0),
            "totalCessValue": gstrReport[0]?.attributes?.hsn?.reduce((accumulator, currentValue) => accumulator + currentValue?.cessAmount, 0),
            "itgst": gstrReport[0]?.attributes?.hsn?.reduce((accumulator, currentValue) => accumulator + currentValue?.integratedTaxAmount, 0),
            "cgst": gstrReport[0]?.attributes?.hsn?.reduce((accumulator, currentValue) => accumulator + currentValue?.centralTaxAmount, 0),
            "sgst": gstrReport[0]?.attributes?.hsn?.reduce((accumulator, currentValue) => accumulator + currentValue?.stateTaxAmount, 0),
        }
        );

    }, [gstrReport])

    const handleChange = (selectedOption) => {
        setSelectedYearRange(selectedOption);
    };

    useEffect(() => {
        console.log("selectedYearRange =>", selectedYearRange);
        setMinYear(selectedYearRange?.label?.split("-")[0] + "-04-01");
        setMaxYear(selectedYearRange?.label?.split("-")[1] + "-03-31");
    }, [selectedYearRange]);

    const handleClick = (e) => {
        setFieldShow(true);
    };

    const onClick = () => {
        setLabel(true)
        setShow(true)
        setFetchRangeValue(range)
        console.log("fetchRangeValue =>", fetchRangeValue)
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setDate({ ...dates, [name]: value });
        setFromDate(name === "fromDate" ? value : dates.fromDate);
        setDateto(name === "toDate" ? value : dates.toDate);
    }

    const fetchData = () => {
        let values = "?fromDate=" + dates?.fromDate + "&toDate=" + dates?.toDate;
        if (moment(dates?.fromDate).isAfter(dates?.toDate)) {
            dispatch(addToast({ text: 'Invalid date range.!', type: 'error' }));
        } else {
        fetchGstrReport(values);
        }
    }

    const itemsValue = filterGstr?.length > 0 &&
        filterGstr[0]?.attributes?.hsn.map(data => ({
            hsn: data?.hsn,
            description: data?.description,
            uqc: data?.uqc,
            totalQuantity: parseFloat(data?.totalQuantity).toFixed(2),
            totalValue: parseFloat(data?.totalValue).toFixed(2),
            tax: parseFloat(data?.tax).toFixed(2),
            cessAmount: parseFloat(data?.cessAmount).toFixed(2),
            taxableValue: parseFloat(data?.taxableValue).toFixed(2),
            integratedTaxAmount: parseFloat(data?.integratedTaxAmount).toFixed(2),
            centralTaxAmount: parseFloat(data?.centralTaxAmount).toFixed(2),
            stateTaxAmount: parseFloat(data?.stateTaxAmount).toFixed(2),
        })) || [];

    const handlePrintPDF = () => {
        printPDF(paySlipRef, companyDetails, 'Landscape', 'hsnReport', itemsValue, columns);
    };

    const handleExportToPDF = () => {
        generatePDF(paySlipRef, companyDetails, 'Landscape', 'hsnReport', itemsValue, columns)
            .then(pdf => {
                pdf.save('hsnReport.pdf');
            })
            .catch(error => {
                console.error('Error generating PDF:', error);
            });
    };

    return (
        <>
            <Loader />
            <div style={{ backgroundColor: "white", height: "100%" }}>

                {fieldShow && <ClosingStockField setShowField={setShowField} fieldShow={fieldShow}
                    handleFieldClose={handleFieldClose} Title={"Stock Movement Report Form"} />}

                <div className="row">
                    <div className="col-md-5">
                        <div className="row" style={{ marginTop: "-10px" }}>
                            <div className="col-md-4">
                                <h4 className="mt-2">From Date</h4>
                                <input
                                    id1="dateInput"
                                    type="date"
                                    className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
                                    ref={fromDate}
                                    defaultValue={defaultValue}
                                    name="fromDate"
                                    onChange={onChange}
                                ></input>
                            </div>
                            <div className="col-md-4 mt-2">
                                <h4>To Date</h4>
                                <input
                                    id2="dateRequired2"
                                    type="date"
                                    className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                                    ref={toDate}
                                    defaultValue={defaultValue}
                                    name="toDate"
                                    onChange={onChange}
                                    min={dates?.fromDate}
                                ></input>
                            </div>
                            <div className="col-md-4 gstrGenerateBtn">
                                <button
                                    type="button"
                                    className="btn btn-success "
                                    onClick={() => fetchData()}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5"></div>
                    <div className="col-md-2 ">
                        <button
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                borderRadius: "10px",
                                width: "220px",
                                height: "60px",
                                gap: "13px",
                                background: "white",
                                marginTop: "-20px",
                                fontSize: "11px",
                                marginLeft: "-20px"
                            }}
                            ref={paySlipRef}
                        >
                            <FontAwesomeIcon
                                icon={faPrint}
                                className="fa-2x search-icon"
                                style={{ color: "black", paddingTop: "8px" }}
                                onClick={handlePrintPDF}
                            ></FontAwesomeIcon>

                            <FontAwesomeIcon
                                icon={faFileExcel}
                                className="fa-2x search-icon "
                                style={{
                                    color: "green",
                                    paddingLeft: "10px",
                                    paddingTop: "7px",
                                }}
                            //   onClick={exportToExcel}
                            ></FontAwesomeIcon>

                            <FontAwesomeIcon
                                icon={faFilePdf}
                                className="fa-2x search-icon"
                                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                                onClick={handleExportToPDF}
                            ></FontAwesomeIcon>
                        </button>
                    </div>
                </div>

                {/* <div className="row" style={{ marginTop: "-10px" }}>
                    <div className="col-md-4">
                        <h4 className="mt-2">From Date</h4>
                        <input
                            id1="dateInput"
                            type="date"
                            className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
                            ref={fromDate}
                            defaultValue={defaultValue}
                            name="fromDate"
                            onChange={onChange}
                        ></input>
                    </div>
                    <div className="col-md-4 mt-2">
                        <h4>To Date</h4>
                        <input
                            id2="dateRequired2"
                            type="date"
                            className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                            ref={toDate}
                            defaultValue={defaultValue}
                            name="toDate"
                            onChange={onChange}
                        ></input>
                    </div>
                    <div className="col-md-4 gstrGenerateBtn">
                        <button
                            type="button"
                            className="btn btn-success "
                            onClick={() => fetchData()}
                        >
                            Generate
                        </button>
                    </div>
                </div> */}
                <h2 style={{
                    // color: "green",
                    fontSize: "17px",
                    fontWeight: "700"
                }}>
                    {"GSTR1 Summary for HSN-wise Summary of Outward Supplies - 12"}
                </h2>
                <div className="row">
                    <div className="col-md-9"></div>
                    <div
                        className="col-md-3"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "-50px",
                        }}
                    >
                        {/* <button
                            style={{
                                marginTop: "20px",
                                border: "none",
                                borderRadius: "10px 0 0 10px",
                                backgroundColor: "white",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                padding: "0 10px",
                                color: "blue",
                                marginLeft: "20px",
                            }}
                            onClick={onClick}
                        >
                            <FontAwesomeIcon
                                icon={faFilter}
                                className="fa-2x search-icon"
                                style={{ fontSize: "15px" }}
                            />
                        </button> */}
                        {/* <button
                            style={{
                                marginTop: "21px",
                                border: " none",
                                borderRadius: "0 10px 10px 0",
                                backgroundColor: "white",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                padding: "0 10px",
                                marginLeft: "-13px",
                                marginRight: "5px",
                                color: "blue",
                            }}
                            onClick={onClick}
                        >
                            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                                Filter
                            </span>
                        </button> */}

                        {/* <button
                            style={{
                                marginTop: "20px",
                                border: "none",
                                borderRadius: "10px 0 0 10px",
                                backgroundColor: "white",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                padding: "0 10px",
                                color: "blue",
                            }}
                            onClick={handleClick}
                        >
                            <FontAwesomeIcon
                                icon={faEye}
                                className="fa-2x search-icon"
                                style={{ fontSize: "15px" }}
                            />
                        </button>
                        <button
                            style={{
                                marginTop: "21px",
                                border: "none",
                                borderRadius: "0 10px 10px 0",
                                backgroundColor: "white",
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                padding: "0 10px",
                                marginLeft: "-13px",
                                marginRight: "35px",
                                color: "blue",
                            }}
                            onClick={handleClick}
                        >
                            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                                Show Fields
                            </span>
                        </button> */}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 w-100 tabulator-container gstrTable">
                        <div className="summary-row">
                            <span>No.of HSN: <span className="fw-bold">{totalData?.hsn ? totalData?.hsn : 0}</span></span>
                            <span>Total Value: <span className="fw-bold">{totalData?.totalValue ? parseFloat(totalData?.totalValue).toFixed(2) : 0}</span></span>
                            <span>Total Taxable Value: <span className="fw-bold">{totalData?.totalTaxValue ? parseFloat(totalData?.totalTaxValue).toFixed(2) : 0}</span></span>
                            <span>Total Integrated Tax: <span className="fw-bold">{totalData?.itgst ? parseFloat(totalData?.itgst).toFixed(2) : 0}</span></span>
                            <span>Total Central Tax: <span className="fw-bold">{totalData?.cgst ? parseFloat(totalData?.cgst).toFixed(2) : 0}</span></span>
                            <span>Total State/UT Tax: <span className="fw-bold">{totalData?.sgst ? parseFloat(totalData?.sgst).toFixed(2) : 0}</span></span>
                            <span>Total Cess: <span className="fw-bold">{totalData?.totalCessValue ? parseFloat(totalData?.totalCessValue).toFixed(2) : 0}</span></span>
                        </div>
                        <ReactTabulator
                            columns={columns}
                            data={itemsValue}
                            // ref={pdfRef}
                            options={{
                                columnHeaderVertAlign: "bottom",
                                layout: 'fitDataTable',
                                // responsiveLayout: "collapse",
                                placeholder: "No records found",
                                height: "420px",
                                // footerElement: `<div style='width:100%;text-align: left; padding: 10px; border: 1px solid rgb(99, 166, 77); border-radius: 5px; display: flex; justify-content: space-between; align-items: center;'>
                                //         <div style='padding-left: 10px;'>Total</div>
                                //          <div style='padding-right: 10px;'>
                                //         ${totalData?.totalValue ? parseFloat(totalData?.totalValue).toFixed(2) : '0.00'}
                                //         </div>
                                //         <div style='padding-right: 10px;'>
                                //         ${totalData?.totalTaxValue ? parseFloat(totalData?.totalTaxValue).toFixed(2) : '0.00'}
                                //         </div>
                                //         <div style='padding-right: 10px;'>
                                //         ${totalData?.totalCessValue ? parseFloat(totalData?.totalCessValue).toFixed(2) : '0.00'}
                                //         </div>
                                // </div>`
                            }}
                        />
                    </div>
                </div>

                {show ? <ClosingStockReportForm
                    stockMovement={stockMovement}
                    show={show} handleClose={handleClose}
                    // setItemValues={setItemValues}
                    singleStock={itemsValue}
                    setRange={setRange}
                    Title={"Stock Movement Report Form"}
                // search={search}
                /> : null}


            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { stockMovement, acYear, gstrReport, companyConfig } = state;
    return { stockMovement, acYear, gstrReport, companyConfig }
}

export default connect(mapStateToProps, { fetchAcYear, fetchGstrReport, fetchCompanyConfig })(HsnReport);
