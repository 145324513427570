import { apiBaseURL, closingStockReportActionType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig';
import { setLoader } from "./loaderAction";

export const fetchClosingStockReport =
    (filter = {}, isLoading = true) =>
    async (dispatch) => {
        if (isLoading) {
            dispatch(setLoading(true));
            dispatch(setLoader(true));
        }
        let url = apiBaseURL.CLOSINGSTOCKREPORT;
        console.log(url)
       
        apiConfig
            .get(url)
            .then((response) => {
                console.log("REPONSE =>",response)
                dispatch({  
                    type: closingStockReportActionType.FETCH_CLOSING_STOCK_REPORT,
                    payload: response.data.data,
                });
                
                if (isLoading) {
                    dispatch(setLoading(false));
                    dispatch(setLoader(false));
                }
            })
            .catch(({ response }) => {
                dispatch(
                    addToast({
                        text: response.data.message,
                        type: toastType.ERROR,
                    })
                );
            });
    };

    //MARK FROM RAM [20-07-2024]

    export const fetchClosingStockReportFilter =
    (isLoading = true,
        filtered_brand,
        filtered_group,
        filtered_category,
        product_name,
        showFilterModal) =>
    async (dispatch) => {
        if (isLoading) {
            dispatch(setLoading(true));
            dispatch(setLoader(true));
        }
        const url =
        apiBaseURL.CLOSINGSTOCKREPORT +
        "?category1=" +
        filtered_brand +
        "&category2=" +
        filtered_category +
        "&category3=" +
        filtered_group +
        "&particular=" +
        "";
        // let url = apiBaseURL.CLOSINGSTOCKREPORT+values;
        console.log("Closing Stock filter URL : ",url)
       
        apiConfig
            .get(url)
            .then((response) => {
                console.log("REPONSE =>",response)
                dispatch({  
                    type: closingStockReportActionType.FETCH_CLOSING_STOCK_REPORT_FILTER,
                    payload: response?.data?.data,
                });
                
                if (isLoading) {
                    dispatch(setLoading(false));
                    dispatch(setLoader(false));
                }
            })
            .catch(({ response }) => {
                dispatch(
                    addToast({
                        text: response.data.message,
                        type: toastType.ERROR,
                    })
                );
            });
    };

    //MARK TO RAM [20-07-2024]