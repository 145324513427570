import React, { useCallback, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import remove from "../../assets/images/delete.gif";
import { getFormattedMessage } from "../../shared/sharedMethod";

const ValidationModal = (props) => {
  const { name, onClose, onConfirm, mrp } = props;

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      // User for Close the model on Escape
      //   onClickValidationModel(false);
    }
  }, []);

  useEffect(() => {
    console.log("name", name);
    document.getElementById("confirm-button")?.focus();
  }, [name]);

  const paymentKeyPress = useCallback((e) => {
    
    // Prevent default only if the key pressed is F6 or F10
    if (e.key === "Enter") {
      e.preventDefault();
      if(document.activeElement.id == "cancel-button"){
        onClose(); 
       }else if(document.activeElement.id != "confirm-button"){
        document.getElementById("confirm-button")?.click();
      }
      else{
        document.activeElement.click();
      }
      // document.activeElement.click();
    } else if (e.key === 'Esc') {
      e.preventDefault();
      onClose();
    } else if (e.key === "ArrowRight" && !mrp) {
      e.preventDefault();
      document.getElementById("cancel-button")?.focus();
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();
      document.getElementById("confirm-button")?.focus();
    }
  }, [name]);

  useEffect(() => {
    window.addEventListener("keydown", paymentKeyPress);

    return () => {
      window.removeEventListener("keydown", paymentKeyPress);
    };
  }, [paymentKeyPress]);

  //   useEffect(() => {
  //     document.addEventListener("keydown", escFunction, false);
  //     return () => {
  //       document.removeEventListener("keydown", escFunction, false);
  //     };
  //   }, []);

  return (
    // <SweetAlert
    //   custom
    //   confirmBtnBsStyle="danger mb-3 fs-5 rounded"
    //   cancelBtnBsStyle="secondary mb-3 fs-5 rounded text-white"
    //   confirmBtnText={getFormattedMessage("delete-modal.yes-btn")}
    //   cancelBtnText={getFormattedMessage("delete-modal.no-btn")}
    //   title={getFormattedMessage("delete-modal.title")}
    // //   onConfirm={}
    // //   onCancel={}
    //   showCancel
    //   focusCancelBtn
    //   customIcon={remove}
    // >
    //   <span className="sweet-text">
    //     {getFormattedMessage("delete-modal.msg")} {name} ?
    //   </span>
    // </SweetAlert>
    <SweetAlert
      confirmBtnBsStyle='success mb-3 fs-5 rounded'
      cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
      confirmBtnCssClass='alert_yes'
      confirmBtnText={"Yes,It's Ok"}
      cancelBtnText={"Cancel"}
      title={name}
      // onConfirm={onConfirm}
      // onCancel={onCancel}
      showCancel
      focusCancelBtn
      customButtons={!mrp ?
        <>
          <button id="cancel-button" onClick={() => { onClose() }} className="btn btn-secondary">
            No
          </button>

          <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} onClick={() => { onConfirm() }}>
            Yes
          </button>

        </> : <>
          <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} onClick={() => { onConfirm() }}>
            Ok
          </button>
        </>
      }
    />
  );
};
export default ValidationModal;
