import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchSale, fetchSingleSale } from '../../store/action/salesAction';
import HeaderTitle from '../header/HeaderTitle';
import MasterLayout from '../MasterLayout';
// import WholeSaleForm from './WholeSaleForm';
import PosMainPage from '../../frontend/components/PosMainPage';
import { getFormattedMessage } from '../../shared/sharedMethod';
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import { fetchAllWarehouses } from '../../store/action/warehouseAction';
import { fetchAllSuppliers } from '../../store/action/supplierAction';
import { fetchLedgers, fetchLedger } from '../../store/action/ledgerAction';

const EditWholeSales = (props) => {

    const { fetchSingleSale, saleSingle, fetchAllWarehouses, fetchAllSuppliers, fetchLedger, warehouses, suppliers, ledger } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    console.log('Sales id', id);
    const [formcode, setFormCode] = useState("T02");

    useEffect(() => {
        fetchAllWarehouses();
        fetchAllSuppliers();
        fetchLedger();
        fetchSingleSale(id);
    }, []);

    //console.log(editCustomer())

    useEffect(() => {
        console.log("POS SALES",saleSingle);
    }, [saleSingle]);

    // const itemsValue = customers && customers.length === 1 && customers.map(customer => ({
    //     name: customer.attributes.name,
    //     email: customer.attributes.email,
    //     phone: customer.attributes.phone,
    //     country: customer.attributes.country,
    //     city: customer.attributes.city,
    //     address: customer.attributes.address,
    //     dob: customer.attributes.dob,
    //     id: customer.id
    // }));

    // console.log(customers)

    const itemsValue = saleSingle;

    useEffect(() => {
        ;
        const storedFormData = localStorage.getItem("UserFormCode");
    
        if (storedFormData) {
          const parsedFormData = JSON.parse(storedFormData);
    
          console.log("Parsed Form Data:", parsedFormData);
          if (parsedFormData.length > 0) {
            const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permisssionUpdate );
            console.log("Form Code Items:", formCodeItems);
            if(!formCodeItems.length > 0){
                navigate("/app/dashboard");
            }
          } else {
            navigate("/app/dashboard");
          }
        } 
      }, []);

    return (
        <>
            <PosMainPage singleSale={itemsValue} id={id} warehouses={warehouses} 
            // ledger={ledger}
                suppliers={suppliers} />
        </>
    )
};


const mapStateToProps = (state) => {
    const { saleSingle, suppliers, totalRecord, ledger, warehouses } = state;
    return { saleSingle, suppliers, totalRecord, ledger, warehouses }
};

export default connect(mapStateToProps, { fetchSingleSale, fetchAllWarehouses, fetchAllSuppliers, fetchLedgers, fetchLedger })(EditWholeSales);

