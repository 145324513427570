import { companyConfigActionType } from "../../constants";



export default (state = [], action) => {
    switch (action.type) {
        case companyConfigActionType.FETCH_COMPANY_CONFIG_LIST:
            return {
                ...state,
                companyList: action.payload
            };
        case companyConfigActionType.FETCH_COMPANY_CONFIG_SPECIFIC:
            return {
                ...state,
                companyDetail: action.payload
            };
        case companyConfigActionType.FETCH_COMPANY_CONFIG:
            // return action.payload;
            return {
                ...state,
                ...action.payload
            };
        case companyConfigActionType.EDIT_COMPANY_CONFIG:
            // return action.payload;
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};