import React from 'react'
import moment from "moment"

const A4CompositeModal = (props) => {
    const { company, saleSingle, group, companyConfig } = props;
    return (
        <div
            id="beauteSlip"
            style={{
                // margin: '10mm', /* Top Right Bottom Left */
                border: '1px solid #000',
                width: '210mm',
                height: '297mm',
                fontFamily: 'Yokohama, sans-serif',
                boxSizing: 'border-box',
                backgroundColor: '#fff',
                position: 'relative',
                overflow: 'hidden',
                fontSize: '12px',
            }
            }
        >
            {/* <div
                        style={{
                            position: 'absolute',
                            left: '50%', // Center the line horizontally
                            top: '0',    // Start from the top
                            bottom: '800px', // Extend to the bottom
                            width: '0.5px', // Line thickness
                            backgroundColor: 'black', // Line color
                            transform: 'translateX(-50%)',
                            zIndex: '1',
                        }}
                    ></div> */}
            {/* <div
                    style={{
                        position: 'absolute',
                        left: '50%', // Center the line horizontally
                        top: '400px',    // Start from the top
                        bottom: '0', // Extend to the bottom
                        width: '0.5px', // Line thickness
                        backgroundColor: 'black', // Line color
                        transform: 'translateX(-50%)',
                        zIndex: '1',
                    }}
                    ></div> */}

            {/* <header style={{ marginBottom: '15px', textAlign: 'left', lineHeight: '1.5' }}> */}
            <div className='row' style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'left',
                minHeight: '13%'
            }}>
                <div className='col-md-6'
                    style={{
                        textAlign: 'left',
                        // marginBottom: '5px', 
                        lineHeight: '1.5',
                        // marginTop: '10px',
                        borderRight: '1px solid black',
                        paddingTop: '1%'
                    }}
                >
                    <h1 style={{ fontWeight: '600', margin: '0', color: 'darkred', fontSize: '22px', marginBottom: '10px', marginLeft: '5px' }}>{company?.companyName}</h1>
                    <p style={{ fontWeight: '400', margin: '0', marginLeft: '5px' }}>{company?.address}</p>
                    {company?.phoneNo && <p style={{ fontWeight: '400', margin: '0', marginLeft: '5px' }}>Phone: {company?.phoneNo}</p>}
                    {/* {companyConfig?.attributes?.gstin && <p style={{ fontWeight: '400', margin: '0', marginLeft: '5px' }}>GSTIN: {companyConfig?.attributes?.gstin}</p>} */}
                </div>

                <div className='col-md-6' style={{ textAlign: 'left', marginBottom: '15px', lineHeight: '1.5', marginTop: '10px' }}>
                    <h1 style={{ fontWeight: '600', fontSize: '20px', margin: '0', marginBottom: '10px' }}>&nbsp;</h1>
                    <div>
                        <span style={{ fontWeight: '600', margin: '0', display: "inline-block", width: "80px" }}>Invoice No </span>
                        <span>: </span>
                        <span>{saleSingle?.attributes?.invNo}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: '600', margin: '0', display: "inline-block", width: "80px" }}>Invoice Date</span>
                        <span>: </span>
                        <span>{moment(saleSingle?.attributes?.invDate.split(' ')[0] + " " + saleSingle?.attributes?.createdAt.split(' ')[1]).format('DD-MM-YYYY')}</span>
                    </div>
                </div >

            </div>
            <div className="row" style={{ backgroundColor: 'darkred', height: '40px', alignItems: 'left', fontWeight: '500', fontSize: '15px' }}>
                <div className="col-md-6" style={{ textAlign: 'left', color: 'white', padding: '10px' }}>
                    <p style={{ marginLeft: "2%" }}>BILL TO</p>
                </div>
                <div className="col-md-6" style={{ textAlign: 'left', color: 'white', padding: '10px' }} >SHIP TO</div>
            </div>
            {/* </header> */}
            <div className="row" style={{ textAlign: 'left', flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', alignItems: 'left', minHeight: '12%' }}>
                <div className="col-md-6" style={{ textAlign: 'left', lineHeight: '1.5', borderRight: '1px solid black' }}>
                    <p style={{ fontWeight: '600', margin: '0', fontSize: '16px', marginBottom: '5px', marginLeft: '5px' }}>{saleSingle?.attributes?.customerName}</p>
                    <p style={{ fontWeight: '400', margin: '0', marginLeft: '5px' }}>{saleSingle?.attributes?.customerAddress + (saleSingle?.attributes?.city ? ', ' + saleSingle?.attributes?.city : '') + (saleSingle?.attributes?.city ?  ', '+ saleSingle?.attributes?.state : '') + '.'}</p>
                    {saleSingle?.attributes?.customerMobile && <p style={{ fontWeight: '400', margin: '0', marginLeft: '5px' }}>Phone: {saleSingle?.attributes?.customerMobile}</p>}
                    {/* {saleSingle?.attributes?.customerRegNo && <p style={{ fontWeight: '400', margin: '0', marginLeft: '5px' }}>GSTIN: {saleSingle?.attributes?.customerRegNo}</p>} */}
                    {/* <p style={{ fontWeight: '400', margin: '0' }}>Place of Supply:Rajasthan</p> */}
                    <p>&nbsp;</p>
                </div>


                {/* Empty Column for alignment */}
                <div className="col-md-6" style={{ textAlign: 'left', marginBottom: '15px', lineHeight: '1.5' }}>
                    <div style={{}}>
                        <p style={{ fontWeight: '600', margin: '0', fontSize: '16px', marginBottom: '5px' }}>{saleSingle?.attributes?.customerName}</p>
                        <p style={{ fontWeight: '400', margin: '0' }}>{saleSingle?.attributes?.customerAddress + (saleSingle?.attributes?.city ? ', ' + saleSingle?.attributes?.city : '') + (saleSingle?.attributes?.city ?  ', '+ saleSingle?.attributes?.state : '') + '.'}</p>
                        {saleSingle?.attributes?.customerMobile && <p style={{ fontWeight: '400', margin: '0' }}>Phone: {saleSingle?.attributes?.customerMobile}</p>}
                        {/* {saleSingle?.attributes?.customerRegNo && <p style={{ fontWeight: '400', margin: '0' }}>GSTIN: {saleSingle?.attributes?.customerRegNo}</p>} */}
                        {/* <p style={{ fontWeight: '400', margin: '0' }}>Pin:308582</p> */}
                    </div>
                </div>
                {/* Colons Column */}
            </div>
            <section style={{
                // height: '100%',
                textAlign: 'left', lineHeight: '1.5', minHeight: "58%"
            }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '12px', lineHeight: '25px', }}>
                    <thead style={{ fontWeight: '500', backgroundColor: 'darkred', height: '40px', fontSize: '15px' }}>
                        <tr>
                            {/* <td style={{ textAlign: 'right', padding: '3px', color: 'white' }}>No.</td> */}
                            <td style={{ textAlign: 'left', color: 'white', padding: '5px' }}>Description</td>
                            {/* <td style={{ textAlign: 'right', padding: '5px', color: 'white' }}>HSN</td> */}
                            <td style={{ textAlign: 'right', padding: '5px', color: 'white' }}>Qty</td>
                            <td style={{ textAlign: 'right', padding: '3px', color: 'white' }}>Rate</td>
                            {/* <td style={{ textAlign: 'right', padding: '3px', color: 'white' }}>Tax %</td> */}
                            <td style={{ textAlign: 'right', padding: '3px', color: 'white', paddingRight: '10px' }}>Amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {[...Array(5).keys()].map((_, i) => (
                                <tr key={i}>
                                    <td style={{ textAlign: 'left', padding: '2px' }}>Apple Norm(td-1)</td>
                                    <td style={{ textAlign: 'right', padding: '2px' }}>5KG</td>
                                    <td style={{ textAlign: 'right', padding: '2px' }}>100.00</td>
                                    <td style={{ textAlign: 'right', padding: '2px' }}>RS.5.00(5%)</td>
                                    <td style={{ textAlign: 'right', padding: '2px', paddingRight: '10px' }}>525.00</td>
                                </tr>
                            ))} */}
                        {Array.from({ length: 10 }).map((_, i) => {
                            const item = saleSingle?.attributes?.sales2?.[i]; // Access the item or leave it undefined for empty rows
                            return (
                                <tr key={i}>
                                    {/* <td style={{ textAlign: 'center', padding: '2px' }}>{i + 1}</td> */}
                                    <td style={{ textAlign: 'left', paddingLeft: '5px', width: '32%' }}>
                                        <p className="m-0" style={{
                                            whiteSpace: "nowrap",
                                            width: "235px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}>{item ? item.printName : '\u00A0'}</p>
                                    </td>
                                    {/* <td style={{ textAlign: 'right', padding: '2px' }}> */}
                                    {/* {item ? item.hsnCode : ""} */}
                                    {/* </td> */}
                                    <td style={{ textAlign: 'right', padding: '2px' }}>
                                        {item ? `${item.qty} ${item.salesUnitName}` : ""}
                                    </td>
                                    <td style={{ textAlign: 'right', padding: '2px' }}>
                                        {item ? parseFloat(item.netSalesRate).toFixed(2) : ""}
                                    </td>
                                    {/* <td style={{ textAlign: 'right', padding: '2px' }}> 
                                               {item ? parseFloat(item.tax).toFixed(2) : ""} 
                                            </td> */}
                                    <td style={{ textAlign: 'right', paddingRight: '10px' }}>
                                        {item ? parseFloat(item.netAmount).toFixed(2) : ""}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot style={{ fontWeight: '500', backgroundColor: 'darkred', height: '40px', fontSize: '14px' }}>
                        <tr>
                            <td style={{ textAlign: 'left', padding: '5px', color: 'white' }}>Sub Total</td>
                            {/* <td style={{ textAlign: 'right', padding: '5px', color: 'white' }}></td> */}
                            <td style={{ textAlign: 'right', padding: '5px', color: 'white' }}>{saleSingle?.attributes?.sales2?.every(sale => sale.salesUnitName == saleSingle?.attributes?.sales2?.[0]?.salesUnitName) ? saleSingle?.attributes?.sales2?.reduce((a, b) => parseFloat(a) + parseFloat(b.qty), 0) + " " + saleSingle?.attributes?.sales2?.[0]?.salesUnitName : ""}</td>
                            <td style={{ textAlign: 'right', padding: '5px', color: 'white' }}></td>
                            {/* <td style={{ textAlign: 'right', padding: '5px', color: 'white' }}></td> */}
                            <td style={{ textAlign: 'right', padding: '5px', color: 'white', paddingRight: '10px' }}>{saleSingle?.attributes?.sales2?.length > 0 && parseFloat(saleSingle?.attributes?.sales2?.reduce((a, b) => a + b.netAmount, 0)).toFixed(2)}</td>

                        </tr>
                    </tfoot>
                </table>
                <div className="row" style={{ justifyContent: 'space-between', marginTop: "5px", lineHeight: '0.4' }}>
                    <div className='col-md-5' style={{ lineHeight: '1' }}>
                        <div className='' style={{ marginTop: "10px", fontSize: '18px', fontWeight: '600', marginLeft: '5px', marginBottom: '14px' }}>Bank Details</div>
                        <div className="row">
                            <div className="col-md-5" style={{ lineHeight: '0.5', marginLeft: '5px' }} >
                                <p>Account Holder</p>
                                <p>Account Number</p>
                                <p>Bank</p>
                                <p>Branch</p>
                                <p>IFSC Code</p>
                                <p>Account Type</p>
                                <p>&nbsp;</p>
                                {/* <h4 style={{ fontSize: '12px', fontWeight: '600', textAlign: 'left', margin: '0', lineHeight: '1' }}>Notes</h4>
                                    <p>&nbsp;</p>
                                    <p style={{ lineHeight: '1' }}>1.No return Deal</p>
                                    <p>&nbsp;</p>
                                    <h5 style={{ fontSize: '12px', fontWeight: '600', lineHeight: '1' }}>Terms of Condition</h5>
                                    <p>&nbsp;</p>
                                    <p style={{ fontSize: '10px', lineHeight: '1', width: '200px' }}>1.Customer will pay the GST</p>
                                    <p style={{ fontSize: '10px', lineHeight: '1', width: '200px' }}>2.Customer will pay the delivery Charges</p>
                                    <p style={{ fontSize: '10px', lineHeight: '1', width: '200px' }}>3.Pay due amount within 15 datys</p> */}
                            </div>
                            <div className="col-md-1" style={{ lineHeight: '0.5' }}>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                            </div>
                            <div className="col-md-4" style={{ lineHeight: '0.5' }}>
                                <p style={{ width: '200px' }}>Spaco Technologies</p>
                                <p>70340200001363</p>
                                <p>Bank of Baroda</p>
                                <p>Perumalpuram</p>
                                <p>BARB0VJPACO</p>
                                <p>Current Account</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5" style={{ fontWeight: '400', fontSize: '12px', margin: '0', padding: '0', lineHeight: '1.5', textAlign: 'right' }}>
                        <div className="row" style={{ width: "100%", marginTop: '15px' }}>
                            <div className="col-md-8" style={{ lineHeight: '0.5', padding: "0" }}>
                                <p>Sub Total</p>
                                {/* <p>CGST</p> */}
                                {/* <p>SGST</p> */}
                                <p>Less/Adj</p>
                                <p>Rounded Off</p>
                                <p style={{ fontSize: '15px', fontWeight: "bolder", lineHeight: '1.5' }}>Grand Total</p>
                            </div>
                            <div className="col-md-1" style={{ lineHeight: '0.5', padding: "0" }}>
                                <p >:</p>
                                {/* <p >:</p> */}
                                {/* <p >:</p> */}
                                <p >:</p>
                                <p >:</p>
                                <p style={{ lineHeight: '1.5' }}>:</p>
                            </div>
                            <div className="col-md-3" style={{ lineHeight: '0.5' }}>
                                <p>{saleSingle?.attributes?.sales2?.length > 0 && parseFloat(saleSingle?.attributes?.sales2?.reduce((a, b) => a + b.netAmount, 0)).toFixed(2)}</p>
                                {/* <p>{saleSingle?.attributes?.sales2?.length > 0 && parseFloat(saleSingle?.attributes?.sales2?.reduce((a, b) => a + b.taxAmount, 0) / 2).toFixed(2)}</p> */}
                                {/* <p>{saleSingle?.attributes?.sales2?.length > 0 && parseFloat(saleSingle?.attributes?.sales2?.reduce((a, b) => a + b.taxAmount, 0) / 2).toFixed(2)}</p> */}
                                <p>{parseFloat(saleSingle?.attributes?.less).toFixed(2)}</p>
                                <p>{parseFloat(saleSingle?.attributes?.roundOff).toFixed(2)}</p>
                                <p style={{ fontSize: '15px', fontWeight: "bolder", lineHeight: '1.5' }}>{parseFloat(saleSingle?.attributes?.netTotal).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='row'>
                <div className='row' style={{ marginLeft: '5px' }} >
                    <div className='col-md-6' style={{
                        textAlign: 'left', fontSize: '13px', fontWeight: '600', alignItems: 'end',
                        display: 'flex',
                        marginBottom: '4%'
                    }}>Customer Signature</div>
                    <div className='col-md-6' style={{ textAlign: 'right', fontSize: '13px', fontWeight: '600', lineHeight: '1.5' }}>
                        <img id="signatureImage" src={companyConfig?.attributes?.digitalSignature} alt="signature" style={{
                            width: '140px',
                            height: '75px'
                        }} />
                        {/* <img id="signatureImage" src={base64} alt="signature" style={{
                                    width: '155px',
                                    height: '54px'
                                }} /> */}
                        <p style={{ margin: '0' }}>Authorised Signatory for</p>
                        <p>{company?.companyName}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default A4CompositeModal