import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { deletePurchase } from '../../store/action/purchaseAction';
import DeleteModel from '../../shared/action-buttons/DeleteModel';
import { getFormattedMessage } from '../../shared/sharedMethod';
import { addSale, fetchSingleSale } from '../../store/action/salesAction';
import { addSalesOrder, fetchSingleSalesOrder, fetchSalesOrders } from '../../store/action/salesOrderAction';

const DeleteOrder = (props) => {
    const { addSalesOrder, fetchSingleSalesOrder, onDelete, deleteModel, onClickDeleteModel, singleSalesOrder, fetchSalesOrders } = props;
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (onDelete?.id) {
            fetchSingleSalesOrder(onDelete?.id);
        }
    }, [onDelete]);

    useEffect(() => {
        let data = localStorage.getItem("loginUserArray");
        console.log(JSON.parse(data)['id']);
        setUserId(JSON.parse(data)['id']);
    }, []);

    const deleteSaleClick = () => {
        // deletePurchase(onDelete.id);

        let purchaseVal =
        {
            "txNo": onDelete?.id,
            "configId": 1,
            "companyId": 1,
            "channelId": 1,
            "orderDate": "2024-11-04",
            "orderStatusId": 1,
            "customerId": 8,
            "customerName": "ANU BHARATHI",
            "address": "27/5, GROUND FLOOR, GREEN COMMERCIAL COMPLEX, OPPOSITE NAINAAR MAHAL, PALAYAMKOTTAI, TIRUNELVELI - 627002.",
            "city": "TIRUNELVELI - 627002",
            "state": "TAMIL NADU",
            "mobileNo": "9876543214",
            "gstNo": "33HBYUS6542DG",
            "orderSubTotal": 637.5,
            "less": "5.00",
            "roundOff": "0.01",
            "orderValue": "21.00",
            "advanceAmount": "5",
            "remarks": "",
            "orderBy": 1,
            "updatedBy": 1,
            "salesOrder2": [
                {
                    "txNo": 0,
                    "slno": 1,
                    "itemId": 1,
                    "mrp": "8.00",
                    "batchNo": "",
                    "qty": "10",
                    "rate": "5.00",
                    "basicAmount": "25.00",
                    "discPercent": "1.00",
                    "discAmount": "0.25",
                    "lessAmount": "0.00",
                    "totalDiscAmount": "0.25",
                    "grossAmount": "24.75",
                    "tax": "5.00",
                    "taxAmount": "1.24",
                    "rateWithTax": "5.25",
                    "netSalesRate": "5.20",
                    "netAmount": "25.99",
                    "itemDesc": "desc",
                    "soldQty": 1.9
                }
            ],
            "salesOrder4": [
                {
                    "txNo": 0,
                    "slno": 1,
                    "paymentType": "",
                    "refNo": "2445",
                    "refDate": "2024-11-06",
                    "amount": "16.00"
                }
            ],
            "salesOrder5": [
                {
                    "txNo": 0,
                    "slno": 1,
                    "imageUrl": "",
                    "updatedAt": "2024-10-30"
                }
            ],
            "xMode": "D"
        };   
        addSalesOrder(purchaseVal);
        // fetchSalesOrders();
        onClickDeleteModel(false);
    };

    return (
        <div>
            {deleteModel && <DeleteModel onClickDeleteModel={onClickDeleteModel} deleteModel={deleteModel}
                deleteUserClick={deleteSaleClick}
                name={getFormattedMessage('Sales Order')} />}
        </div>
    )
};

const mapStateToProps = (state) => {
    const { singleSalesOrder } = state;
    return { singleSalesOrder }
}
export default connect(mapStateToProps, { addSalesOrder, fetchSingleSalesOrder, fetchSalesOrders })(DeleteOrder);
