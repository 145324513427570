import React from 'react';
import {connect} from 'react-redux';
import DeleteModel from '../../shared/action-buttons/DeleteModel';
import {getFormattedMessage} from '../../shared/sharedMethod';
import { deleteHsnCode } from '../../store/action/hsnCodeAction';

const DeleteHsnCode = (props) => {
    const { deleteHsnCode,onDelete, deleteModel, onClickDeleteModel} = props;

    const deleteHsnCodeClick = () => {
       deleteHsnCode(onDelete.id);
        onClickDeleteModel(false);
    };

    return (
        <div>
            {deleteModel && <DeleteModel onClickDeleteModel={onClickDeleteModel} deleteModel={deleteModel}
                                         deleteUserClick={deleteHsnCodeClick} name={getFormattedMessage('HsnCode.title')}/>}
        </div>
    )
};

export default connect(null, {deleteHsnCode})(DeleteHsnCode);
