import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form, InputGroup, Modal } from "react-bootstrap"
import ReactSelect from "../../../shared/select/reactSelect"
import { getFormattedMessage } from "../../../shared/sharedMethod"
import { useEffect, useState } from "react"
import { fetchProducts } from "../../../store/action/productAction"
import { connect } from "react-redux"
import { useNavigate } from "react-router"
import { fetchOpeningStock } from "../../../store/action/openingStockAction"
import StockRegisterReport from "./StockRegisterReport"
import { fetchAllStockRegister } from "../../../store/action/StockRegisterReportAction"
import Loader from "../../loader/Loader"

const StockRegisterItemFilter = (props) => {

    const {show,handleClose,title,fetchProducts,products,fetchOpeningStock,openingStock,fetchAllStockRegister,stockRegister,setFilterRangeValue,setFilterRangeMrp} = props;
   
    const closeButtonClick = () => {
        handleClose(show);
    };

    const [itemId, setItemId] = useState(0);
    const [opMrp, setOpMrp] = useState(0);
    const [filterValue, setFilterValue] = useState({
        itemName: '',
        itemLabel: ''
    });
    const [filterMrpValue,setFilterMrp] = useState({
        mrp:"",
        mrpLabel:""
    })
    
    const onChangeDropDown = (selectedOption) => {
        const selectedItemId = selectedOption?.value;
        setItemId(selectedItemId);
        setFilterValue({
            itemName: selectedOption?.value,
            itemLabel: selectedOption?.label
        });
    
        console.log("Selected Item Id:", selectedItemId);
        if (selectedItemId > 0) {
            fetchOpeningStock(selectedItemId, selectedOption, true);
        }
    };
    
    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]); 
    
    const handleMrpChange = (selectedOption) => {
        const selectedMrpValue = selectedOption?.value; 
        const selectedMrpLabel = selectedOption?.label; 
    
        setOpMrp(selectedMrpLabel); 
    
        setFilterMrp({
            mrp: selectedOption?.label,    
            mrpLabel: selectedMrpValue
        });
    
    };
    console.log("Selected MRP Value:", filterMrpValue?.mrp);
    
    const loadValues = () => {
        const values = `?fromDate=&toDate=&itemId=${filterValue?.itemName}&mrp=${filterMrpValue.mrp}`; 
        console.log("Generated query:", values); 
        fetchAllStockRegister(values, true, true);
    };
    
    const onSubmitClick = () => {
        if (filterValue?.itemName) {
            setFilterRangeValue({
                itemName: filterValue.itemName,
                itemLabel: filterValue.itemLabel || "" 
            });
            setFilterRangeMrp({
                mrp:filterMrpValue?.mrp || 0,
                mrpLabel:filterMrpValue.mrpLabel || 0 
            })
        }

    
        loadValues(); 
        
        handleClose(!show);
    };
    
    console.log("item Label Name : ",filterValue.itemLabel)



    return (
        <div>
            <Loader/>
            <Modal show={show} onHide={closeButtonClick} centered >
            <Form  >
                <Modal.Header className="d-flex justify-content-between align-items-center">
                    <Modal.Title className="text-start">{title}</Modal.Title>
                    <button
                        style={{
                            backgroundColor: "white",
                            border: "none",
                            padding: 0,
                            margin: 0,
                        }}
                         onClick={closeButtonClick}
                    >
                        <FontAwesomeIcon
                            icon={faXmark}
                            className="fa-2x"
                            style={{ height: "20px", width: "27px", color: "gray" }}
                        />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-8 mb-3">
                            <h4 >
                                {getFormattedMessage("itemName.title")}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <ReactSelect
                              data={products?.map(item => ({ label: item?.attributes?.name, value: item?.items_id }))}
                              onChange={onChangeDropDown}
                              value={products?.find(option => option?.attributes?.name === filterValue?.itemName) }
                                
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <h4 className="mt-3">
                                {getFormattedMessage("mrp.title")}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <ReactSelect
                                 data={openingStock?.map(item => ({ label: parseFloat(item?.attributes?.mrp).toFixed(2), value: item?.attributes?.itemId }))}
                                 onChange ={handleMrpChange}
                                 value= {openingStock?.find(option => option?.attributes?.mrp === filterMrpValue?.mrpLabel)}
                                 
                            />
                        </div>
                    </div>
                    <br />
                  
                    <br />
                    <br />
                    <div style={{
                        textAlign: "center", marginBottom: "20px", display: "flex", gap: "20px", justifyContent: "center"
                    }}>
                        <button style={{
                            width: "100px",
                            height: "30px",
                            border: "none",
                            borderRadius: "10px",
                            backgroundColor: "green",
                            color: "white"
                        }} 
                        onClick={onSubmitClick}
                            type="button">
                            Submit
                        </button>
                        <button style={{
                            width: "100px",
                            height: "30px",
                            border: "none",
                            borderRadius: "10px",
                            backgroundColor: "red",
                            color: "white"
                        }}
                          onClick={closeButtonClick}
                            type="button">
                            Close
                        </button>
                    </div>
                </Modal.Body>
            </Form>

        </Modal>

        {show==false ? <StockRegisterReport  
                    itemIdValue ={filterValue?.itemName}
                    mrpValue= {filterMrpValue?.mrp}
                    itemLabel = {filterValue?.itemLabel}
                    stockRegister={stockRegister}
                    // setFilterRangeValue={filterValue.itemName}
                    /> : ""}

        </div>

    )
}

const mapStateToProps = (state) => {
    const {products,openingStock,stockRegister} = state;
    return {products,openingStock,stockRegister}
}


export default connect(mapStateToProps, {fetchProducts,fetchOpeningStock,fetchAllStockRegister}) (StockRegisterItemFilter)