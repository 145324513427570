import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
  faCirclePlus,
  faCircleCheck,
  faTimesCircle,
  faFileInvoice,
  faRefresh,
  faPrint,
  faCircleXmark,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { placeholderText } from "../sharedMethod";
import priceHistory from "../../assets/images/price-history.png";
import invoice from "../../assets/images/Invoice.png";
import Dropdown from 'react-bootstrap/Dropdown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ChangeStatus from "../../components/salesOrder/ChangeStatus";

const ActionButton = (props) => {
  const {
    goToEditProduct,
    enableEdit,
    item,
    onClickDeleteModel = true,
    onClickIsActiveModel = true,
    onClickDeActiveModel = true,
    onPrintModal = true,
    onConvert = true,
    isDeleteMode = true,
    isActiveMode,
    isDeActiveMode,
    isEditMode = true,
    isEditMode2 = false,
    goToDetailScreen,
    isViewIcon = false,
    isEditIcon = true,
    isCancel = false,
    isPrint = false,
    isPrint1 = false,
    isSales,
    addNewRow,
    editId,
    isViewPriceHistory,
    isViewAddIcon,
    onSubmitEdit,
    addNewPriceItem,
    onClickPriceHistory,
    addPriceListModalShowing,
    module
  } = props;
  const [roleName, setRoleName] = useState({
    edit: false,
    delete: false,
    priceHistory: false,
    cancel: false
  });

  const [parsedFormData, setParsedFormData] = useState();
  const [selectedItem, setSelectedItem] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);
      setParsedFormData(parsedFormData?.filter((item) => item?.attributes?.formCode == 'M05' && item?.attributes?.visibility).length > 0 ? true : false);
      console.log("Parsed Form Data:", parsedFormData);

    }
  }, []);

  console.log("parsed Form Data :", parsedFormData)

  useEffect(() => {
    const userRole = localStorage.getItem("loginUserArray")
    const role = JSON.parse(userRole);
    const roleName = role?.role;
    if (roleName.toUpperCase() == "ADMINISTRATOR") {
      setRoleName({ edit: true, delete: true, priceHistory: true, cancel: true })
    }
    else if (roleName.toUpperCase() == "MANAGER") {
      setRoleName({ edit: true, delete: false, priceHistory: parsedFormData, cancel: false })
    }
    else if (roleName.toUpperCase() == "USER") {
      setRoleName({ edit: false, delete: false, priceHistory: parsedFormData, cancel: false })
    }
  }, [])

  const changeStatus = (item) => {
    setShow(true);
    setSelectedItem(item);
    // return <ChangeStatus show={true} handleClose={() => setShow(false)} item={item}/>
  }
  return (
    <>
      <div className="border-gradient">
        {isViewIcon ? (
          <button
            title={placeholderText("globally.view.tooltip.label")}
            className="btn text-success px-2 fs-3 ps-0 border-0"
            onClick={(e) => {
              e.stopPropagation();
              goToDetailScreen(item.id);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        ) : null}
        {
          // item.name === "admin" ||
          //   item.email === "admin@infy-pos.com" ||
          isEditMode === "save" ? (
            isEditIcon && <button
              title={placeholderText("globally.edit.tooltip.label")}
              className="btn text-primary fs-3 border-0 px-xxl-2 px-1"
              onClick={(e) => {
                e.stopPropagation();
                onSubmitEdit();
              }}
            >
              <FontAwesomeIcon icon={faCircleCheck} />
            </button>
          ) : isEditMode === "add_new_row" ? (
            isEditIcon && <button
              title={placeholderText("globally.edit.tooltip.label")}
              className={roleName?.edit ? "btn text-primary fs-3 border-0 px-xxl-2 px-1" : "d-none"}
              onClick={(e) => {
                e.stopPropagation();
                addNewPriceItem(item);
              }}
            >
              <FontAwesomeIcon icon={faCircleCheck} />
            </button>
          ) : (
            isEditIcon && <button
              title={placeholderText("globally.edit.tooltip.label")}
              className={roleName?.edit ? "btn text-primary fs-3 border-0 px-xxl-2 px-1" : "d-none"}
              id={editId}
              onClick={(e) => {
                e.stopPropagation();
                goToEditProduct(item);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
          )}
        {
          // item.name === "admin" ||
          //   item.email === "admin@infy-pos.com" ||
          isDeleteMode === false ? null : (
            <button
              title={placeholderText("globally.delete.tooltip.label")}
              className={roleName?.delete ? "btn px-2  text-danger fs-3 border-0" : "d-none"}
              // className="btn px-2 pe-0 text-danger fs-3 border-0"
              // className="btn px-2 text-danger fs-3 border-0"
              style={module == "salesOrder" ? { display: "none" } : {}}
              onClick={(e) => {
                e.stopPropagation();
                onClickDeleteModel(item);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          )}

        {
          // item.name === "admin" ||
          //   item.email === "admin@infy-pos.com" ||
          isDeActiveMode === true && (
            <button
              title={placeholderText("globally.delete.tooltip.label")}
              className={roleName?.delete ? "btn px-2 text-danger fs-3 border-0" : "d-none"}
              // className="btn px-2 pe-0 text-danger fs-3 border-0"
              // className="btn px-2 text-danger fs-3 border-0"
              onClick={(e) => {
                e.stopPropagation();
                onClickDeActiveModel(item);
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>
          )}

        {isActiveMode === true && (
          <button
            title={placeholderText("globally.delete.tooltip.label")}
            className={roleName?.delete ? "btn px-2 text-success fs-3 border-0" : "d-none"}
            // className="btn px-2 pe-0 text-danger fs-3 border-0"
            // className="btn px-2 text-danger fs-3 border-0"
            onClick={(e) => {
              e.stopPropagation();
              onClickIsActiveModel(item);
            }}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
          </button>
        )}

        {item.name === "admin" ||
          item.email === "admin@infy-pos.com" ||
          isCancel === false ? null : (
          <button
            title={placeholderText("De-Activate")}
            className={roleName?.cancel ? "btn px-2 pe-0 text-danger fs-3 border-0" : "d-none"}
            onClick={(e) => {
              e.stopPropagation();
              onClickDeleteModel(item);
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        )}
        {item.name === "admin" ||
          item.email === "admin@infy-pos.com" ||
          isPrint === false ? null : (
          <button
            title={isSales ? placeholderText("Print") : placeholderText("Payslip")}
            className={isSales ? "btn px-2 pe-0 fs-3 border-0 px-2" : "btn px-2 pe-0 fs-3 border-0 px-2 text-success"}
            onClick={(e) => {
              e.stopPropagation();
              onPrintModal(item);
            }}
            style={{ color: '#313131' }}
          >
            {isSales ? <FontAwesomeIcon icon={faPrint} /> : <FontAwesomeIcon icon={faFileInvoice} />}
          </button>
        )}
        {
          // item.name === "admin" ||
          //   item.email === "admin@infy-pos.com" ||
          isViewAddIcon === true ? (
            <button
              title={placeholderText("globally.add.tooltip.label")}
              className="btn px-2 pe-0 Pricelistaddbutton fs-3 border-0"
              onClick={(e) => {
                e.stopPropagation();
                // to add new row
                // addNewRow(item);
                addPriceListModalShowing(item);
              }}
            >
              <FontAwesomeIcon icon={faCirclePlus} />
            </button>
          ) : (
            ""
          )}
        {
          // item.name === "admin" ||
          //   item.email === "admin@infy-pos.com" ||
          isViewPriceHistory === true ? (
            <button
              title={placeholderText("globally.add.tooltip.label")}
              className={parsedFormData ? "btn px-2 pe-0 text-danger fs-3 border-0" : "d-none"}
              // className={"btn px-2 pe-0 text-danger fs-3 border-0" }
              onClick={(e) => {
                e.stopPropagation();
                onClickPriceHistory(item);
              }}
            >
              <img style={{ width: "23px", height: "23px" }} src={priceHistory} />
            </button>
          ) : (
            ""
          )}
        {
          module === "salesOrder" &&
          // <button
          //   title={placeholderText("globally.edit.tooltip.label")}
          //   className={ roleName?.edit ? "btn text-primary fs-3 border-0 px-xxl-2 px-1" : "d-none" }
          //   id={editId}
          //   onClick={(e) => {
          //     e.stopPropagation();
          //   }}
          // >
          //   <FontAwesomeIcon icon={faPenToSquare} />
          // </button>

          // <Dropdown>
          //   <Dropdown.Toggle id="dropdown-basic" split>
          //     <FontAwesomeIcon icon={faPenToSquare} />
          //   </Dropdown.Toggle>

          //   <Dropdown.Menu>
          //     <Dropdown.Item onClick={(e) => goToEditProduct(item)}><FontAwesomeIcon icon={faPenToSquare} className="text-primary" /> Edit</Dropdown.Item>
          //     <Dropdown.Item ><FontAwesomeIcon icon={faRefresh} /> Change Status</Dropdown.Item>
          //     <Dropdown.Item onClick={(e) => {
          //       e.stopPropagation();
          //       onClickDeleteModel(item);
          //     }}><FontAwesomeIcon icon={faTrash} className="text-danger" /> Delete</Dropdown.Item>
          //     <Dropdown.Item href="#/action-4"><span><img style={{ width: "18px", height: "18px" }} src={invoice} /></span> Convert to Sale</Dropdown.Item>
          //   </Dropdown.Menu>
          // </Dropdown>
          <>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {isEditMode && <MenuItem onClick={(e) => goToEditProduct(item)} className={roleName?.edit ? "" : "d-none"}><FontAwesomeIcon icon={faPenToSquare} className="text-primary pe-3" /> Edit</MenuItem>}
              {!isEditMode && <MenuItem onClick={(e) => goToDetailScreen(item)}><FontAwesomeIcon icon={faEye} className="text-primary pe-3" /> View</MenuItem>}
              {!isEditMode2 ? <MenuItem onClick={() => {
                onPrintModal(item);
                handleClose();
              }}><FontAwesomeIcon icon={faRefresh} className="pe-3" /> {isEditMode ? "Change Status" : "Activity Log"}</MenuItem> 
              : 
              <MenuItem onClick={() => {
                onPrintModal(item);
                handleClose();
              }}><FontAwesomeIcon icon={faRefresh} className="pe-3" /> {"Change Status"}</MenuItem>}
              <MenuItem onClick={(e) => {
                e.stopPropagation();
                onClickDeleteModel(item);
                handleClose();
              }} className={roleName?.delete ? "" : "d-none"}><FontAwesomeIcon icon={faTrash} className="text-danger pe-3" /> Delete</MenuItem>
              {/* {isPrint1 && <MenuItem onClick={(e) => {
                e.stopPropagation();
                // onClickDeleteModel(item);
                handleClose();
              }}><FontAwesomeIcon icon={faPrint} className="pe-3" /> Print</MenuItem>} */}
              {isEditMode && <MenuItem onClick={() => { onConvert(item); handleClose(); }}><span className="pe-3"><img style={{ width: "18px", height: "18px" }} src={invoice} /></span> Convert to Sale</MenuItem>}
            </Menu>
          </>
        }
      </div >
    </>
  );
};
export default ActionButton;
