import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Board from 'react-trello';
import { fetchCompanyConfig } from '../../store/action/companyConfigAction';
import { fetchOrderStatus } from '../../store/action/orderStatusAction';
import { addLog, fetchSalesOrderLog } from '../../store/action/salesOrderLog';

const SalesKanban = (props) => {
  const { showKanban, itemsValue, companyConfig, fetchCompanyConfig, fetchOrderStatus, addLog } = props;
  const orderStatusData = useSelector(state => state.orderStatus);
  const [userId, setUserId] = useState(null);

  const statusColors = {
    OPEN: 'rgb(201 201 201)',
    'INPROGRESS': 'rgb(108 186 255)',
    CLOSED: 'rgb(107 255 141)',
    REJECTED: 'rgb(2255 76 93)',
  };

  useEffect(() => {
    fetchCompanyConfig();
  }, [fetchCompanyConfig]);

  useEffect(() => {
    if (companyConfig?.attributes?.companyId) {
      fetchOrderStatus(companyConfig.attributes.companyId, 1, 0);
    }
  }, [companyConfig, fetchOrderStatus]);

  useEffect(() => {
    let data = localStorage.getItem("loginUserArray");
    console.log(JSON.parse(data)['id']);
    setUserId(JSON.parse(data)['id']);
  }, []);

  const transformedData = useMemo(() => {
    const lanes = orderStatusData?.map((status) => {
      const filteredCards = itemsValue
        ?.filter((item) => item?.status?.toUpperCase() === status?.attributes?.statusName?.toUpperCase())
        ?.map((item) => ({
          id: item.id,
          title: item.orderId,
          label: item.orderValue,
          description: `Customer: ${item.customer_name}\nMobile: ${item.mobile}\nOrder Value: ₹${item.orderValue}`
        })) || [];

      return {
        id: `lane_${status.statusId}`,
        title: status?.attributes?.statusName,
        label: `${filteredCards.length}/${filteredCards.length}`,
        cards: filteredCards,
        style: {backgroundColor: statusColors[status?.attributes?.statusName?.toUpperCase()] || 'grey'}
      };
    }) || [];

    return { lanes };
  }, [orderStatusData, itemsValue]);


  console.log("transformedData", transformedData);

  const handleCardMove = async (sourceLaneId, targetLaneId, cardId) => {
    console.log(`Card ID: ${cardId} moved from Lane ID: ${sourceLaneId} to Lane ID: ${targetLaneId}`);
    const targetLane = transformedData?.lanes?.find(lane => lane.id === targetLaneId);
    const targetLaneName = targetLane ? targetLane.title : 'Unknown Lane';

    console.log(`Moved to Lane Name: ${targetLaneName}`);
    let data = {
      "txNo": cardId,
      "slNo": 1,
      "orderStatusId": targetLaneId?.split("_")[1],
      "updatedBy": userId,
      "statusRemarks": "",
    }
    const response = await addLog(data);
    if (response?.data?.success === true) {
    }
  }

  const CustomLaneHeader = ({ title }) => (
    <div
      style={{
        padding: '10px',
        // color: 'white',
        fontWeight: 'bold',
        // backgroundColor: statusColors[title.toUpperCase()] || 'grey', // Default to grey if status not found
      }}
    >
      {title}
    </div>
  );

  const components = {
    LaneHeader: CustomLaneHeader,
  };

  return (
    <Board
      //  cardDragClass="draggingCard"
      data={transformedData}
      draggable={true}
      collapsibleLanes={true}
      laneDraggable={false}
      style={{ backgroundColor: 'transparent' }}
      onCardMoveAcrossLanes={handleCardMove}
      components={components}
    />
  );
};

const mapStateToProps = (state) => ({
  companyConfig: state.companyConfig,
});

export default connect(mapStateToProps, { fetchCompanyConfig, fetchOrderStatus, addLog })(SalesKanban);