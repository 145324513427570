import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal } from "react-bootstrap-v5";
import TopProgressBar from "../../../shared/components/loaders/TopProgressBar";
import MasterLayout from "../../MasterLayout";
import { ReactTabulator } from "react-tabulator";
import "react-tabulator/lib/styles.css";
import "tabulator-tables/dist/css/tabulator_simple.min.css";
import {
  faFilter,
  faFileExcel,
  faFilePdf,
  faPrint,
  faXmark,
  faEye,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import ClosingStockReportForm from "./ClosingStockReportForm";
import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import UnitsForm from "../../units/UnitsForm";
import Units from "../../units/Units";
import { connect } from "react-redux";
import { fetchClosingStockReport } from "../../../store/action/ClsoingStockReportAction";
import html2canvas from "html2canvas";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import { getFormattedMessage } from "../../../shared/sharedMethod";
import ClosingStockField from "./ClosingStockField";
import { jsPDF } from "jspdf";
import ReactSelect from "../../../shared/select/reactSelect";
import * as XLSX from "xlsx";
import { printPDF, generatePDF } from "../pdfUtil";
import Loader from "../../loader/Loader";
import PriceListHeaderComponents from "../../../shared/action-buttons/PriceListHeaderComponents";
import FilterModal from "../../priceList/FilterModal";

const today = new Date();
const numOfDaysAdded = 0;
const date = new Date(today.setDate(today.getDate() + numOfDaysAdded));
const defaultValue = date.toISOString().split("T")[0];
const formatDecimal = (cell, formatterParams, onRendered) => {
  const value = cell.getValue();
  // Format number to 2 decimal places
  return value.toFixed(2);
};
const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  // const hours = String(date.getHours()).padStart(2, '0');
  // const minutes = String(date.getMinutes()).padStart(2, '0');
  // const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}-${month}-${year} `;
};
const formattedDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}-${month}-${year}  ${hours}:${minutes}:${seconds}`;
};

const defaultValuePdf = formattedDate(new Date());

const ClosingStockReport = ({
  closingStocks,
  fetchClosingStockReport,
  ItemValues,
  fetchCompanyConfig,
  companyConfig,
}) => {
  const today = new Date();
  const formattedDate = formatDate(today);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [fetchRangeValue, setFetchRangeValue] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });
  const [label, setLabel] = useState(false);
  const [fieldShow, setFieldShow] = useState(false);
  const handleFieldClose = () => setFieldShow(!fieldShow);
  const pdfRef = useRef(null);
  const searchRef = useRef();
  const [fieldValue, setFieldValue] = useState({
    showPageSize: "",
    showPageOrientation: "",
  });
  const [loadingPdf, setLoadingPdf] = useState(false);
  const navigate = useNavigate();

  const userRole = localStorage.getItem("loginUserArray");
  const role = JSON.parse(userRole);
  const roleName = role?.role;
  const userName = role?.user_name

  // console.log("Role Name  :" ,roleName);

  const [showField, setShowField] = useState();

  console.log("Show Field  : ", showField);

  const columns = [
    ...(showField?.showCode || showField == undefined
      ? [{ title: "Code", field: "code", headerSort: false, width: "10%",}]
      : []),
    {
      title: "Product Name",
      field: "productName",
      headerSort: false,
      width: "40%",
      bottomCalc: () => 'Total', // Custom text for the bottom of this column
      bottomCalcFormatter: (cell) => {
          return '<strong>Total</strong>'} 
    },
    {
      title: "MRP",
      field: "mrp",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      formatter: formatDecimal,
      width: "10%",
    },
    // { title: "Stock", field: "stock", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: "10%" },
    // { title: "Unit Name", field: "unitName", headerSort: false, hozAlign: "right", headerHozAlign: "right", width: "10%" },
    {
      title: "Stock",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      field: "stock",
      // width: "25%",
      height: "10px",
      formatter: function (cell) {
        // Access the row data
        const rowData = cell.getRow().getData();
        const stock = parseFloat(rowData.stock).toFixed(2);
        const unitName = rowData.unitName;

        return `<span style="border-right: 2px solid #dddddd;
    padding-right: 3%;
    display: inline-flex;
    height: 43px;
    margin-top: -8px;
    /* padding: 0% 0%; */
    width: 50%;
    justify-content: center;
    align-items: center;">${stock}</span><span style="display: inline-flex;width: 50%; justify-content: center;
    align-items: center;">${unitName}</span>`;
      },
    },
    // ...(roleName.toUpperCase() !== "USER"
    //   ? [{ title: "Cost", field: "cost", headerSort: false, hozAlign: "right", headerHozAlign: "right", formatter: formatDecimal, width: "10%" }]
    //   : []),
    ...(showField?.showItemCost || showField == undefined
      ? roleName.toUpperCase() !== "USER"
        ? [
            {
              title: "Cost",
              field: "cost",
              headerSort: false,
              hozAlign: "right",
              headerHozAlign: "right",
              formatter: formatDecimal,
              width: "10%",
            },
          ]
        : []
      : []),
    ...(showField?.showStockValue || showField == undefined
      ? [
          {
            title: "Value",
            field: "value",
            headerSort: false,
            hozAlign: "right",
            headerHozAlign: "right",
            formatter: formatDecimal,
            width: roleName.toUpperCase() !== "USER" ? "10%" : "20%"
            , bottomCalc: (values, data) => {
              // Custom calculation logic
              let total = 0;
              values.forEach(value => {
                  total += parseFloat(value) || 0; // Ensure values are parsed as numbers
              });
              return total.toFixed(2); // Return total with two decimal places
          }
          },
        ]
      : []),
     
  ];

  const onChange = () => {
    setSearch(searchRef.current.value);
  };

  const [decimalPortion, setDecimalPortion] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);
  const [filterClosingStock, setFilterClosingStock] = useState([]);
  const [formcode, setFormCode] = useState("R02");
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      console.log("Parsed Form Data:", parsedFormData);
      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter(
          (item) =>
            item?.attributes?.formCode == formcode &&
            item?.attributes?.visibility
        );
        console.log("Form Code Items:", formCodeItems);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  const [isExclusive, setIsExclusive] = useState(false);
  const handleIncExc = () => {
    setIsExclusive(!isExclusive);
  };

  useEffect(() => {
    fetchCompanyConfig();
    fetchClosingStockReport();
  }, []);

  useEffect(() => {
    
    setFilterClosingStock(closingStocks);
  }, [closingStocks]);

  const paySlipRef = useRef(null);
  let tableRef = useRef(null);
  const [showFilterModals, setShowFilterModals] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    brand: "ALL",
    category: "ALL",
    group: "ALL",
  });
  const [filterTextShow, setFilterTextShow] = useState(false);
  const showFilterModal = () => {
    console.log("showFilterModal");
    setShowFilterModals(!showFilterModals);
  };
  const selectedFilterSetting = (
    filtered_brand,
    filtered_group,
    filtered_category
  ) => {
    setSelectedFilter({
      brand: filtered_brand,
      category: filtered_category,
      group: filtered_group,
    });

    setFilterTextShow(
      filtered_brand || filtered_group || filtered_category ? true : false
    );
  };
  console.log("selectedFilter", selectedFilter);

  console.log("closing Stock Values :", closingStocks);

  const totalSalesValue = (data) => {
    return new Intl.NumberFormat("en-IN", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(data.reduce((acc, curr) => acc + parseFloat(curr.value), 0));
  };
  
  // const appliedFilter = [
  //   {
  //     brand:filtered_brand,
  //     category:filtered_category,
  //     group:filtered_group
  //   }
  // ]

  const companyDetails = {
    companyName: companyConfig?.companyName || "Company Name",
    address: `${companyConfig?.attributes?.address1 || ""} ,\n ${
      companyConfig?.attributes?.address2 || ""
    }`,
    phoneNumber: companyConfig?.attributes?.phoneNo || "Phone Number",
  };

  const handleExportToPDF = () => {
    generatePDF(
      paySlipRef,
      companyDetails,
      "Portrait",
      "Closing Stock Report",
     " Brand :" +`${ selectedFilter.brand != undefined ?selectedFilter.brand : "N/A"}`+ 
      " Category :" + `${selectedFilter.category  != undefined  ? selectedFilter.category : "N/A"}` + 
      " Group :" +`${selectedFilter.group  != undefined ?selectedFilter.group : "N/A"}`,
      // itemsValue=[],
      // columns=[],
      "Printed By  : " +userName,
      tableRef
    )
      .then((pdf) => {
        pdf.save("Closing Stock Report.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  const handlePrintPDF = () => {
    if (tableRef.current) {
      printPDF(
        paySlipRef,
        companyDetails,
        "Portrait",
        "Closing Stock Report",
        `Brand: ${selectedFilter.brand || "N/A"} Category: ${selectedFilter.category || "N/A"} Group: ${selectedFilter.group || "N/A"}`,
        userName,
        tableRef // Pass the table reference here
      );
    }
  };
  

  const handleSearchData = (e) => {
    const { name, value } = e.target;
    console.log("hi name", name);
    console.log("hi value", value);
    const filtered_ClosingStock =
      value.length > 0
        ? closingStocks?.filter((item) => {
            const itemCode = item?.attributes?.itemCode?.toLowerCase() || "";
            const itemName =
              item?.attributes?.itemName?.toString().toLowerCase() || "";

            return (
              itemCode.includes(value.toLowerCase()) ||
              itemName.includes(value.toLowerCase())
            );
          })
        : closingStocks;

    setFilterClosingStock(filtered_ClosingStock);
  };

  const itemsValue =
  filterClosingStock?.length > 0 
    ? (selectedFilter.brand!== "ALL" || selectedFilter?.category !=="ALL" ||  selectedFilter?.group !=="ALL" 
        ? filterClosingStock.filter(item => {
          let shouldInclude = true;

          // Check brand filter
          if (selectedFilter?.brand !== "ALL" && selectedFilter?.brand !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category1Name === selectedFilter.brand;
          }
  
          // Check category filter
          if (selectedFilter?.category !== "ALL" && selectedFilter?.category !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category2Name === selectedFilter.category;
          }
  
          // Check group filter
          if (selectedFilter?.group !== "ALL" && selectedFilter?.group !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category3Name === selectedFilter.group;
          }
  
          return shouldInclude;  
        }) 
        : filterClosingStock 
      ).map(closingStock => {
        return {
          code: closingStock?.attributes?.itemCode,
          productName: closingStock?.attributes?.itemName,
          mrp: closingStock?.attributes?.mrp,
          stock: closingStock?.attributes?.stock,
          unitName: closingStock?.attributes?.unitName,
          cost: roleName.toUpperCase() !== "USER" 
            ? closingStock?.attributes?.purchaseCost 
            : "",
          value: closingStock?.attributes?.salesPrice,
        };
      })
    : [];


  const calculateFooterData = (table) => {
    const data = table.getData();

    const totalValue = data.reduce((acc, row) => acc + (row.value || 0), 0);

    return `
      <div style='width:100%; text-align: right; padding: 10px; border-top: 1px solid #ddd; background-color: #f9f9f9;'>
        <div>Total</div>
        <div>Total Value:${totalValue.toFixed(2)}</div>
      </div>
    `;
  };

  const [range, setRange] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });

  const handleClick = (e) => {
    setFieldShow(true);
  };

  // const companyDetails = {
  //   companyName: companyConfig?.companyName,
  //   address: `${companyConfig?.attributes?.address1} , ${companyConfig?.attributes?.address2}`,
  //   phoneNumber: companyConfig?.attributes?.phoneNo
  // };

  const reportDetails = {
    title: "Closing Stock Report as on",
    dateRange: formattedDate,
  };
  useEffect(() => {
    console.log("Closing Stocks: ", closingStocks);
  
  }, [closingStocks]);

  const getCurrentDateTimeInIST = () => {
    const now = new Date();
    const offset = 5.5; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + offset * 3600 * 1000);
    return istDate.toISOString().replace(/T/, " ").replace(/\..+/, "");
  };

  // const generatePDF = useCallback((companyDetails, reportDetails, orientation) => {
  //   if (!pdfRef.current) {
  //     console.error("pdfRef.current is null");
  //     return;
  //   }

  //   html2canvas(pdfRef.current, { scale: 1.5, useCORS: true }).then(canvas => {
  //     const resizedCanvas = document.createElement('canvas');
  //     const resizedCtx = resizedCanvas.getContext('2d');
  //     resizedCanvas.width = canvas.width / 2;
  //     resizedCanvas.height = canvas.height / 2;
  //     resizedCtx.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

  //     const imgData = resizedCanvas.toDataURL('image/png');
  //     const isLandscape = orientation === 'Landscape';
  //     const pdf = new jsPDF({
  //       orientation: isLandscape ? 'landscape' : 'portrait',
  //       unit: 'mm',
  //       format: isLandscape ? [297, 210] : [210, 297]
  //     });

  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const centerX = pdfWidth / 2;
  //     const topMargin = 20;
  //     const textSpacing = 3;

  //     pdf.setFontSize(12);
  //     pdf.text(companyDetails.companyName, centerX, topMargin, { align: 'center' });
  //     pdf.setFontSize(10);
  //     pdf.text(companyDetails.address, centerX, topMargin + textSpacing + 5, { align: 'center' });
  //     pdf.text(`Phone: ${companyDetails.phoneNumber}`, centerX, topMargin + 2 * textSpacing + 10, { align: 'center' });

  //     pdf.setLineWidth(0.2);
  //     pdf.line(10, topMargin + 3 * textSpacing + 15, pdfWidth - 10, topMargin + 3 * textSpacing + 15);

  //     pdf.setFontSize(12);
  //     pdf.text(reportDetails.title, 10, topMargin + 4 * textSpacing + 25);
  //     pdf.text(reportDetails.dateRange, 10, topMargin + 5 * textSpacing + 30);

  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfImgWidth = pdfWidth - 20;
  //     const pdfImgHeight = (imgProps.height * pdfImgWidth) / imgProps.width;

  //     pdf.addImage(imgData, 'PNG', 10, topMargin + 6 * textSpacing + 35, pdfImgWidth, pdfImgHeight);
  //     setLoadingPdf(false);
  //     pdf.save(`ClosingStockReport_${formattedDate}.pdf`);
  //   }).catch(error => {
  //     console.error('Error generating PDF:', error);
  //   });
  // }, [formattedDate]);

  const generateAndPrintPDF = useCallback(
    (companyDetails, reportDetails, orientation) => {
      const { companyName, address, phoneNumber } = companyDetails;
      const { title, dateRange } = reportDetails;

      if (!pdfRef.current) {
        console.error("pdfRef.current is null");
        return;
      }

      const input = pdfRef.current;
      html2canvas(input, { scale: 1.5, useCORS: true })
        .then((canvas) => {
          const resizedCanvas = document.createElement("canvas");
          const resizedCtx = resizedCanvas.getContext("2d");
          resizedCanvas.width = canvas.width / 2; // Reduce the size
          resizedCanvas.height = canvas.height / 2;
          resizedCtx.drawImage(
            canvas,
            0,
            0,
            resizedCanvas.width,
            resizedCanvas.height
          );

          const imgData = resizedCanvas.toDataURL("image/png");
          const isLandscape = orientation === "Landscape";
          const pdf = new jsPDF({
            orientation: isLandscape ? "landscape" : "portrait",
            unit: "mm",
            format: isLandscape ? [297, 210] : [210, 297],
          });

          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const centerX = pdfWidth / 2;
          const topMargin = 20;
          const textSpacing = 3;
          const lineSpacing = 15;

          pdf.setFontSize(12);
          pdf.text(companyName, centerX, topMargin, { align: "center" });
          pdf.setFontSize(10);
          pdf.text(address, centerX, topMargin + textSpacing + 5, {
            align: "center",
          });
          pdf.text(
            `Phone: ${phoneNumber}`,
            centerX,
            topMargin + 2 * textSpacing + 10,
            { align: "center" }
          );

          pdf.setLineWidth(0.2);
          pdf.line(
            10,
            topMargin + 3 * textSpacing + 15,
            pdfWidth - 10,
            topMargin + 3 * textSpacing + 15
          );

          pdf.setFontSize(12);
          pdf.text(title, 10, topMargin + 4 * textSpacing + 25);
          pdf.text(dateRange, 10, topMargin + 5 * textSpacing + 30);

          const imgWidth = pdfWidth - 20;
          const imgHeight =
            (resizedCanvas.height * imgWidth) / resizedCanvas.width;
          const xOffset = 10;
          const yOffset = topMargin + 6 * textSpacing + 35;

          pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

          const addFooter = () => {
            const pageCount = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.text(
                `Page ${i} of ${pageCount}`,
                pdfWidth / 2,
                pdfHeight - 10,
                { align: "center" }
              );
            }
          };
          addFooter();
          const pdfBlob = pdf.output("blob");
          const pdfUrl = URL.createObjectURL(pdfBlob);
          const iframe = document.createElement("iframe");
          iframe.style.position = "absolute";
          iframe.style.width = "0";
          iframe.style.height = "0";
          iframe.style.border = "none";
          document.body.appendChild(iframe);

          iframe.onload = () => {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          };

          iframe.contentWindow.onafterprint = () => {
            document.body.removeChild(iframe);
            URL.revokeObjectURL(pdfUrl);
          };

          iframe.src = pdfUrl;
        })
        .catch((error) => {
          console.error("Error generating canvas:", error);
        });
    },
    []
  );
  const exportToPrintPDF = () => {
    generateAndPrintPDF(
      companyDetails,
      reportDetails,
      fieldValue.showPageOrientation
    );
  };
  const XLSX = require("xlsx");
  const fs = require("fs");

  const generateClosingStockReportExcel = (
    companyDetails,
    reportDetails,
    itemsValue
  ) => {
    const { companyName, address, phoneNumber } = companyDetails;
    const { title, dateRange } = reportDetails;

    const workbook = XLSX.utils.book_new();

    // Create worksheet content
    const worksheet = XLSX.utils.aoa_to_sheet([
      [companyName],
      [address],
      [phoneNumber],
      [],
      [`${title} ${dateRange}`],
      [],
      ["Code", "Product Name", "Stock", "Unit Name", "Cost", "Value"], // Header Row
    ]);

    // Add rows to worksheet
    itemsValue.forEach((item) => {
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            item.Code,
            item.productName,
            item.stock,
            item.unitName,
            item.cost,
            item.value,
          ],
        ],
        { origin: -1 }
      );
    });

    // Calculate and add total value
    const totalValue = itemsValue
      .reduce((acc, curr) => acc + parseFloat(curr.value), 0)
      .toFixed(2);
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["", "", "", "", "Total", totalValue]],
      { origin: -1 }
    );

    // Define column widths
    worksheet["!cols"] = [
      { wch: 15 }, // Code
      { wch: 30 }, // Product Name
      { wch: 15 }, // Stock
      { wch: 20 }, // Unit Name
      { wch: 15 }, // Cost
      { wch: 20 }, // Value
    ];

    // Center align company details and title
    ["A1", "A2", "A3", "A5"].forEach((cell) => {
      if (worksheet[cell]) {
        worksheet[cell].s = { alignment: { horizontal: "center" } };
      }
    });
    const headerCells = ["C7", "E7", "F7"];
    headerCells.forEach((cell) => {
      if (worksheet[cell]) {
        worksheet[cell].s = { alignment: { horizontal: "right" } };
      }
    });

    const totalValueCell =
      "F" + worksheet["!ref"].split(":")[1].replace(/\D/g, "");
    if (worksheet[totalValueCell]) {
      worksheet[totalValueCell].s = { alignment: { horizontal: "right" } };
    }
    // Append worksheet to workbook and save
    XLSX.utils.book_append_sheet(workbook, worksheet, "Closing Stock Report");
    XLSX.writeFile(workbook, "Closing_Stock_Report.xlsx");
  };

  const exportToExcel = () => {
    generateClosingStockReportExcel(companyDetails, reportDetails, itemsValue);
  };

  const exportToPDF = () => {
    //generatePDF(companyDetails, reportDetails,fieldValue.showPageOrientation )
  };
  const showPageOrientation = [
    { value: "Portrait", label: "Portrait" },
    { value: "Landscape", label: "Landscape" },
  ];
  const showPageSize = [{ value: "A4", label: "A4" }];
  const defaultPageOrientation = showPageOrientation.find(
    (option) => option.value === "Portrait"
  );
  const defaultPageSize = showPageSize.find((option) => option.value === "A4");

  const closeButtonClick = () => {
    setLoadingPdf(false);
  };
  const handleFieldChange = (field) => (selectedOption) => {
    setFieldValue((prevValues) => ({
      ...prevValues,
      [field]: selectedOption ? selectedOption.value : "",
    }));
  };
  const handlePdfClick = () => {
    setLoadingPdf(true);
  };
  const handleFieldCancel = () => {
    setLoadingPdf(false);
  };
  const onClick = () => {
    setLabel(true);
    //  navigate(`/app/report/closingStock`)
    //    setShowStatements(true)
    setShow(true);
    // setShowFilterModals(!showFilterModals);
    //  console.log("Range =>" ,range)
    setFetchRangeValue(range);
    console.log("fetchRangeValue =>", fetchRangeValue);
  };


  return (
    <>
      <Loader />
      <div style={{ backgroundColor: "white", height: "100%" }}>
        {fieldShow && (
          <ClosingStockField
            setShowField={setShowField}
            fieldShow={fieldShow}
            handleFieldClose={handleFieldClose}
            Title={"Show Field"}
            // closingStocks={closingStocks}
            closingStocks={columns}
          />
        )}
        <div className="row">
          <div className="col-md-5">
            <h2 style={{ color: "green" }}>
              Closing Stock Report as on {formattedDate}
            </h2>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-2 ">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                borderRadius: "10px",
                width: "220px",
                height: "60px",
                gap: "13px",
                background: "white",
                marginTop: "-20px",
                fontSize: "11px",
                marginLeft: "-20px",
              }}
              ref={paySlipRef}
            >
              <FontAwesomeIcon
                icon={faPrint}
                className="fa-2x search-icon"
                style={{ color: "black", paddingTop: "8px" }}
                onClick={handlePrintPDF}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFileExcel}
                className="fa-2x search-icon "
                style={{
                  color: "green",
                  paddingLeft: "10px",
                  paddingTop: "7px",
                }}
                onClick={exportToExcel}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFilePdf}
                className="fa-2x search-icon"
                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                onClick={handleExportToPDF}
              ></FontAwesomeIcon>
            </button>
          </div>
        </div>
        <div className="row">
          {/* <div className='col-md-1 mt-3'>
            <h4 >Search</h4>
          </div> */}
          <div className="col-md-4 mb-3 searchBox mt-1">
            <div
              className="position-relative d-flex width-420"
              style={{ height: "40px" }}
            >
              <input
                className="form-control ps-8"
                type="search"
                name="searchData"
                id="search"
                placeholder="Search Product Name (or) Product Code"
                aria-label="Search"
                onChange={(e) => handleSearchData(e)}
                autoComplete="off"
              />
              <span className="position-absolute d-flex align-items-center top-0 bottom-0 left-0 text-gray-600 ms-3">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
          </div>

          <div className="col-md-4"></div>
          <div
            className="col-md-3"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-20px",
            }}
          >
            <button
              style={{
                marginTop: "20px",
                border: "none",
                borderRadius: "10px 0 0 10px",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                color: "blue",
                marginLeft: "20px",
              }}
              onClick={showFilterModal}
            >
              <FontAwesomeIcon
                icon={faFilter}
                className="fa-2x search-icon"
                style={{ fontSize: "15px" }}
              />
            </button>
            <button
              style={{
                marginTop: "21px",
                border: " none",
                borderRadius: "0 10px 10px 0",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                marginLeft: "-13px",
                marginRight: "5px",
                color: "blue",
              }}
              onClick={showFilterModal}
            >
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Filter
              </span>
            </button>

            <button
              style={{
                marginTop: "20px",
                border: "none",
                borderRadius: "10px 0 0 10px",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                color: "blue",
              }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={faEye}
                className="fa-2x search-icon"
                style={{ fontSize: "15px" }}
              />
            </button>
            <button
              style={{
                marginTop: "21px",
                border: "none",
                borderRadius: "0 10px 10px 0",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                marginLeft: "-13px",
                marginRight: "35px",
                color: "blue",
              }}
              onClick={handleClick}
            >
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Show Fields
              </span>
            </button>
          </div>
        </div>
        {/* {
  label&&(
    <div className="row">
      <div className="col-md-3">
        <h4> search : {search}</h4>
    </div>

    <div className="col-md-3">
        <h4> Category1 : {range.category1Name}</h4>
    </div>

    <div className="col-md-3">
        <h4> Category2 : {range.category2Name}</h4>
    </div>

    <div className="col-md-3">
        <h4> Category3 : {range.category3Name}</h4>
    </div>

    
    </div>
  )
} */}
        {/* <div className="row" >
<div className= "col-md-12 py-4" style={{ display: filterTextShow ? "block" : "none" }}>
        <span className="m-0" style={{ fontWeight: 700, fontSize: "17px" }}>
          <span style={{fontStyle:"italic", fontWeight: "normal"}}>Applied Filters: </span> 
          {selectedFilter.brand && `Brand : ${selectedFilter.brand ?? "ALL"},`} 
          {selectedFilter.category && `Category : ${selectedFilter.category ?? "ALL"},`} 
          {selectedFilter.group && `Group : ${selectedFilter?.group ?? "ALL"}`}
        </span>
</div>
</div>

        <div className="row mt-10" style={{overflowX:"hidden"}}>

          <div className="col-md-12 tabulator-container nilStock gstrTable" style={{overflowX:"hidden"}}   >


            <ReactTabulator 
              columns={columns}
              data={itemsValue}
              // ref={pdfRef}
              options={{
                columnHeaderVertAlign: "bottom",
                layout: 'fitColumns',
                responsiveLayout: "collapse",
                placeholder: "No records found",
                height: "420px",
                footerElement: `<div style='width:100%;text-align: left; padding: 10px; border: 1px solid rgb(99, 166, 77); border-radius: 5px; display: flex; justify-content: space-between; align-items: center;'>
                    <div style='padding-left: 10px;'>Total</div>
                    <div style='padding-right: 10px;'>
                    ${totalSalesValue(itemsValue)}
                    </div>
            </div>`
              }}
            />


          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12 w-100 tabulator-container nilStock gstrTable">
            <div className="mt-1">
              <span
                className="m-0 fw-bold"
                style={{ fontWeight: 500, fontSize: "15px" }}
              >
                <span style={{ fontWeight: "normal" }}>Applied Filters: </span>
                {selectedFilter.brand &&
                  `Brand : ${selectedFilter.brand ?? "ALL"},`}
                {selectedFilter.category &&
                  `Category : ${selectedFilter.category ?? "ALL"},`}
                {selectedFilter.group &&
                  `Group : ${selectedFilter?.group ?? "ALL"}`}
              </span>
            </div>
            <div className="mt-2">
              <ReactTabulator
              onRef={(r) => (tableRef = r)} 
                columns={columns}
                data={itemsValue}
                
                options={{
                  columnHeaderVertAlign: "bottom",
                  layout: "fitColumns",
                  responsiveLayout: "collapse",
                  placeholder: "No records found",
                  height: "420px",
            //       footerElement: `<div style='width:100%;text-align: left; padding: 10px; border: 1px solid rgb(99, 166, 77); border-radius: 5px; display: flex; justify-content: space-between; align-items: center;'>
            //         <div style='padding-left: 10px;'>Total</div>
            //         <div style='padding-right: 10px;'>
            //         ${totalSalesValue(itemsValue)}
            //         </div>
            // </div>`,
                }}
              />
            </div>{" "}
          </div>
        </div>

        {/* {show ? <ClosingStockReportForm closingStocks={closingStocks} show={show} handleClose={handleClose}
          // setItemValues={setItemValues}
          singleStock={itemsValue}
          setRange={setRange}
          Title={"Apply Filter"}
          search={search} /> : null} */}

        <FilterModal
          showFilterModals={showFilterModals}
          showFilterModal={showFilterModal}
          selectedFilterSetting={selectedFilterSetting}
          selectedFilter={selectedFilter}
          title="Apply Filter"
        />

        {/* </MasterLayout> */}
      </div>

      <Modal
        className="pdfTable"
        show={loadingPdf}
        onHide={() => setLoadingPdf(false)}
        centered
      >
        <Form>
          <Modal.Header>
            <Modal.Title>Print</Modal.Title>
            <button
              style={{
                backgroundColor: "white",
                display: "flex",
                gap: "10px",
                border: "none",
              }}
              onClick={closeButtonClick}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="fa-2x search-icon"
                style={{
                  height: "20px",
                  width: "27px",
                  marginTop: "2px",
                  color: "gray",
                }}
              />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-3">
                <p style={{ fontSize: "13px" }}>
                  Create a printer-friendly PDF of your report.
                </p>
              </div>
              <div className="col-md-12 mb-3">
                <ReactSelect
                  className="position-relative"
                  title={getFormattedMessage("globally.input.pageSize.name")}
                  data={showPageSize}
                  defaultValue={defaultPageSize}
                  value={showPageSize.find(
                    (option) => option.value === fieldValue.showPageSize
                  )}
                  onChange={handleFieldChange("showPageSize")}
                />
              </div>
              <div className="col-md-12 mb-3">
                <ReactSelect
                  className="position-relative"
                  title={getFormattedMessage(
                    "globally.input.pageOrientation.name"
                  )}
                  data={showPageOrientation}
                  defaultValue={defaultPageOrientation}
                  value={showPageOrientation.find(
                    (option) => option.value === fieldValue.showPageOrientation
                  )}
                  onChange={handleFieldChange("showPageOrientation")}
                />
              </div>
            </div>
          </Modal.Body>
          <div
            style={{
              textAlign: "center",
              marginBottom: "20px",
              display: "flex",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                width: "100px",
                height: "30px",
                border: "none",
                borderRadius: "10px",
                backgroundColor: "red",
                color: "white",
              }}
              onClick={exportToPDF}
            >
              Print
            </button>
            <button
              style={{
                width: "100px",
                height: "30px",
                border: "none",
                borderRadius: "10px",
                backgroundColor: "green",
                color: "white",
              }}
              onClick={handleFieldCancel}
            >
              Cancel
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { closingStocks, companyConfig } = state;
  return { closingStocks, companyConfig };
};

export default connect(mapStateToProps, {
  fetchClosingStockReport,
  fetchCompanyConfig,
})(ClosingStockReport);
