import {setLoading} from './loadingAction';
import apiConfig from '../../config/apiConfig';
import {toastType, salesInvoice, apiBaseURL, salesOrderNo, salesOrderLog} from '../../constants';
import {addToast} from './toastAction';
import { getFormattedMessage } from '../../shared/sharedMethod';
import { fetchSalesOrders } from './salesOrderAction';

export const fetchSalesOrderLog = (txno,companyId) => async (dispatch) => {
    
    apiConfig.get(apiBaseURL.SALES_ORDER_LOG+"?salesOrderLogTxno="+txno+"&companyId="+companyId+"").then((response) => {
        dispatch({
            type: salesOrderLog.FETCH_SALES_ORDER_LOG,
            payload: response.data.data,
        })
    })
    .catch(({ response }) => {
        dispatch(
            addToast({
                text: response?.message,
                type: toastType.ERROR,
            })
        );
    });
}

export const addLog = (logData) => async (dispatch) => {
    // dispatch(setSavingButton(true));
    try {
        const response = await apiConfig.post(apiBaseURL.SALES_ORDER_LOG, logData);
        
        dispatch({
            type: salesOrderLog.ADD_LOG,
            payload: response?.data?.data,
        });

        if (response?.data?.success === true) {
            dispatch(
                addToast({
                    text: getFormattedMessage(response?.data?.message),
                })
            );
            dispatch(fetchSalesOrders());
        } else {
            dispatch(
                addToast({ text: response?.data?.message, type: toastType.ERROR })
            );
        }
        return response;  // Return the response
    } catch (error) {
        // dispatch(setSavingButton(false));
        dispatch(
            addToast({ text: error.response?.data?.message, type: toastType.ERROR })
        );
        throw error;  // Re-throw the error so it can be caught in the calling function
    }
}