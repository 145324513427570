import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { Button, InputGroup, Table } from 'react-bootstrap-v5';
import { searchPurchaseProduct } from '../../store/action/purchaseProductAction';
import { addPurchase, editPurchase, fetchPosPurchaseListing } from '../../store/action/purchaseAction';
import { fetchAllProducts } from '../../store/action/productAction';
import { posAllProduct } from '../../store/action/pos/posAllProductAction';
import PurchaseTable from '../../shared/components/purchase/PurchaseTable';
import { preparePurchaseProductArray } from '../../shared/prepareArray/preparePurchaseArray';
import { decimalValidate, getFormattedMessage, placeholderText, onFocusInput, getFormattedOptions } from '../../shared/sharedMethod';
import { calculateCartTotalAmount, calculateCartTotalTaxAmount } from '../../shared/calculation/calculation';
import ModelFooter from '../../shared/components/modelFooter';
import ProductSearch from '../../shared/components/product-cart/search/ProductSearch';
import { addToast } from '../../store/action/toastAction';
import { cartItem, saleStatusOptions, toastType } from '../../constants';
import ReactDatePicker from '../../shared/datepicker/ReactDatePicker';
import ProductMainCalculation from '../sales/ProductMainCalculation';
import ReactSelect from '../../shared/select/reactSelect';
import ProductCartList from '../../frontend/components/cart-product/ProductCartList';
import CartItemMainCalculation from '../../frontend/components/cart-product/CartItemMainCalculation';
import Select from "react-select";
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import ProductSearchbar from '../../frontend/components/product/ProductSearchbar';
import Loader from '../loader/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { fetchpurchaseSlNo } from '../../store/action/purchaseslnoAction';
import CreateCustomerPurchase from './CreateCustomerPurchase';
import { fetchCompanyConfig } from '../../store/action/companyConfigAction';

const PurchaseForm = (props) => {
    const {
        addPurchaseData,
        id,
        editPurchase,
        customProducts,
        singlePurchase,
        warehouses,
        suppliers,
        ledger,
        fetchAllProducts,
        posAllProducts,
        addPurchase,
        posAllProduct,
        companyConfig,
        singlePurchaseProduct,
        fetchpurchaseSlNo,
        purchaseSlno,
        fetchCompanyConfig,
        products, frontSetting, allConfigData, purchases,
        fetchPosPurchaseListing
    } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newCost, setNewCost] = useState('');
    const [newDiscount, setNewDiscount] = useState('');
    const [newTax, setNewTax] = useState('');
    const [newPurchaseUnit, setNewPurchaseUnit] = useState('');
    const [subTotal, setSubTotal] = useState('');
    const [updateProducts, setUpdateProducts] = useState([]);
    const [customerShow, setCustomerShow] = useState(false);
    const [newCustomer, setNewCustomer] = useState();
    const [quantity, setQuantity] = useState(0);
    const [purchase, setPurchase] = useState();
    const [backAlert, setBackAlert] = useState(false);
    const [warehouseDef, setWarehouseDef] = useState({});
    const [financeYear, setFinanceYear] = useState();
    const [paymentMethod, setPaymentMethod] = useState({ label: "CREDIT", value: 'Credit' });
    const cartitems = useSelector((state) => state.cartItems);
    const cartcalc = useSelector((state) => state.cartValues);
    const [invNo, setInvNo] = useState(0);
    const [purchaseValue, setPurchaseValue] = useState({
        date: singlePurchase ? moment(singlePurchase.date).toDate() : new Date(),
        warehouse_id: singlePurchase ? singlePurchase.warehouse_id : '',
        supplier_id: singlePurchase ? singlePurchase.supplier_id : '',
        tax_rate: singlePurchase ? singlePurchase.tax_rate.toFixed(2) : '0.00',
        tax_amount: singlePurchase ? singlePurchase.tax_amount.toFixed(2) : '0.00',
        discount: singlePurchase ? singlePurchase.discount.toFixed(2) : '0.00',
        shipping: singlePurchase ? singlePurchase.shipping.toFixed(2) : '0.00',
        grand_total: singlePurchase ? singlePurchase.grand_total : '0.00',
        notes: singlePurchase ? singlePurchase.notes : '',
        status_id: singlePurchase ? singlePurchase.status_id : { label: getFormattedMessage("status.filter.received.label"), value: 1 },
    });
    const [userId, setUserId] = useState(null);
    const [errors, setErrors] = useState({
        date: '',
        warehouse_id: '',
        supplier_id: '',
        details: '',
        tax_rate: '',
        discount: '',
        shipping: '',
        status_id: ''
    });

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    // useEffect(() => {
    //     setUpdateProducts(updateProducts);
    // }, [updateProducts, quantity, newCost, newDiscount, newTax, subTotal, newPurchaseUnit]);

    // useEffect(() => {
    //     updateProducts.length >= 1 ? dispatch({ type: 'DISABLE_OPTION', payload: true }) : dispatch({ type: 'DISABLE_OPTION', payload: false })
    // }, [updateProducts])

    // useEffect(() => {
    //     if (singlePurchase) {
    //         setUpdateProducts(singlePurchase.purchase_items);
    //     }
    // }, []);

    // useEffect(() => {
    //     purchaseValue.warehouse_id.value ? fetchAllProducts() : null
    // }, [purchaseValue.warehouse_id]);

    const [formcode, setFormCode] = useState("T01");
    useEffect(() => {
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            console.log("Parsed Form Data:", parsedFormData);
            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
                console.log("Form Code Items:", formCodeItems);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);

    useEffect(() => {
        fetchpurchaseSlNo();
        fetchCompanyConfig();
    }, [])

    useEffect(() => {
        let purchaseData = { ...purchase };
        purchaseData["entryno"] = purchaseSlno?.attributes?.purchaseSlno;
        setPurchase(purchaseData);
    }, [purchaseSlno])

    useEffect(() => {
        console.log(warehouses.map((warehouse) => ({ label: warehouse.attributes.warehouseName, value: warehouse.id }))[0]);
        console.log(warehouses[0]?.attributes.warehouseName);
        setWarehouseDef(warehouses.map((warehouse) => ({ label: warehouse.attributes.warehouseName, value: warehouse.id }))[0]);
        if (warehouses) {
            // setWarehouseDef()
            // console.log
        }
    }, [warehouses]);

    useEffect(() => {
        console.log("PURCHASES", purchases);
        let purchaseData = { ...purchase };
        if (purchases != null) {
            if (purchases[purchases?.length - 1]?.attributes && !singlePurchaseProduct?.txNo) {
                // purchaseData["entryno"] = (purchases[purchases?.length - 1]?.txNo) + 1;
            }
        }
        // else if(searchPurchaseProduct?.txNo) {
        //     purchaseData["entryno"] = singlePurchaseProduct?.txNo;
        // }else{
        //     purchaseData["entryno"] = 1;
        // }
        setPurchase(purchaseData);
    }, [purchases]);

    useEffect(() => {
        let data = localStorage.getItem("loginUserArray");
        console.log(JSON.parse(data)['id']);
        setUserId(JSON.parse(data)['id']);
    }, []);

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        let startDate, endDate;

        // If the current date is before April 1, we are in the previous financial year
        if (today.getMonth() < 3) {
            startDate = new Date(year - 1, 3, 1); // April 1 of the previous year
            endDate = new Date(year, 2, 31);      // March 31 of the current year
        } else {
            startDate = new Date(year, 3, 1);     // April 1 of the current year
            endDate = new Date(year + 1, 2, 31);  // March 31 of the next year
        }
        setFinanceYear({
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
        })
        console.log("Current financial year", moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'));
    }, []);

    const incrementNumber = (str) => {
        // Extract the numeric part of the string between '#' and '/'
        let prefix = str.substring(0, str.indexOf('/'));
        let numberPart = prefix.replace('#', '');

        // Convert the extracted part to a number and increment it
        let incrementedNumber = (parseInt(numberPart, 10) + 1).toString().padStart(3, '0');

        // Return the new string with the incremented number
        return `#${incrementedNumber}${str.substring(str.indexOf('/'))}`;
    }

    const capitalizeFirstLetter = (string) => {
        let val = string.toLowerCase();
        console.log(val.charAt(0).toUpperCase() + val.slice(1));
        return val.charAt(0).toUpperCase() + val.slice(1);
    }

    useEffect(() => {
        if (newCustomer != undefined) {
            let purchaseData = { ...purchase };
            let supplier = ledger?.filter((ledger) => ledger.id == newCustomer?.id);
            
            purchaseData["supplier"] = { label: (supplier[0]?.attributes?.salutation != "" ? (supplier[0]?.attributes?.salutation + ".") : "" ) + supplier[0]?.attributes?.ledgerName, value: supplier[0]?.id };
            // purchaseData["supplierId"] = supplier[0]?.id;
            purchaseData["suppliername"] = supplier[0]?.attributes?.ledgerName;
            purchaseData["supplieraddress"] = supplier[0]?.attributes?.address + " " + supplier[0]?.attributes?.area + " " + (supplier[0]?.attributes?.city ? supplier[0]?.attributes?.city : "");
            purchaseData["city"] = supplier[0]?.attributes?.city;
            purchaseData["state"] = supplier[0]?.attributes?.state;
            purchaseData["suppliermobile"] = supplier[0]?.attributes?.mobileNo1;
            purchaseData["suppliergstin"] = supplier[0]?.attributes?.regNo;
            setPurchase(purchaseData);
        }
    }, [ledger]);

    useEffect(() => {
        console.log("purchaseSingle", singlePurchaseProduct);
        let purchaseData = { ...purchase };
        if (singlePurchaseProduct?.attributes) {
            purchaseData["invoice_Number"] = singlePurchaseProduct?.attributes?.invNo;
            purchaseData["suppliername"] = singlePurchaseProduct?.attributes?.supplierName;
            purchaseData["suppliermobile"] = singlePurchaseProduct?.attributes?.supplierMobile;
            purchaseData["supplieraddress"] = singlePurchaseProduct?.attributes?.supplierAddress;
            purchaseData["city"] = singlePurchaseProduct?.attributes?.city;
            purchaseData["state"] = singlePurchaseProduct?.attributes?.state;
            purchaseData["suppliergstin"] = singlePurchaseProduct?.attributes?.supplierGsTin;
            purchaseData["payment_Type"] = singlePurchaseProduct?.attributes?.paymentType;
            purchaseData["bill_Amount"] = singlePurchaseProduct?.attributes?.billAmount;
            purchaseData["invoice_Date"] = moment(singlePurchaseProduct?.attributes?.invDate).format('YYYY-MM-DD');
            purchaseData["Date"] = moment(singlePurchaseProduct?.attributes?.entryDate).format('YYYY-MM-DD');
            purchaseData["decimal"] = singlePurchaseProduct?.attributes?.decimalPoints;
            purchaseData['entryno'] = singlePurchaseProduct?.txNo;
            setPaymentMethod({ label: singlePurchaseProduct?.attributes?.paymentType?.toUpperCase(), value: capitalizeFirstLetter(singlePurchaseProduct?.attributes?.paymentType) });
            setInvNo(singlePurchaseProduct?.attributes?.invNo);
            let customer = ledger.filter((item) => item?.id === singlePurchaseProduct?.attributes?.supplierId);
            purchaseData["supplier"] = { label: (customer[0]?.attributes?.salutation != "" ? (customer[0]?.attributes?.salutation + ".") : "" ) + customer[0]?.attributes?.ledgerName, value: customer[0]?.id };
            purchaseData["supplierId"] = customer[0]?.id;
            let newProduct = {};
            let cartItems = singlePurchaseProduct?.attributes?.purchase2?.map((items) => {
                return {
                    name: items?.itemName,
                    id: items?.itemId,
                    quantity: items?.pack_count > 1 ? 0 : items?.qty,
                    salesQty: items?.pack_count > 1 ? items?.qty : 0,
                    netSalesRate: items?.netSalesRate,
                    netAmount: items?.netamount,
                    pack_count: items?.pack_count,
                    tax_amount: items?.taxAmount,
                    taxAmount: items?.taxAmount,
                    taxPercentage: items?.tax,
                    grossAmount: items?.grossAmount,
                    calculation: {
                        totalQty: items?.qty,
                        basicAmount: items?.basicAmount,
                        discAmount: items?.discAmount,
                        totalDiscAmount: items.totalDiscAmount,
                        grossAmount: items?.grossAmount,
                        taxAmount: items?.taxAmount,
                        rateWithTax: items?.taxAmount,
                        netAmount: items?.netamount,
                        cost: items?.cost,
                    },
                    unit: items?.salesUnitName,
                    sales_unit_name: items?.salesUnitName,
                    purchase_unit_name: items?.purchaseUnitName,
                    // stock: product?.stock,
                    // code: product?.code,
                    purchaseRate: items?.rate,
                    discount: items?.discPercent,
                    lessRs: items?.lessAmount,
                    mrp: items?.mrp,
                    product_price: items?.mrp,
                    decimal: items?.decimalPoints,
                    lineId: items?.lineId,
                }
            });
            console.log(cartItems);

            dispatch({ type: cartItem.CART_ITEMS, payload: [...cartItems] });
            setPurchase(purchaseData);
        }
        // qtySelect()
        // cartitems.push(newProduct);
    }, [singlePurchaseProduct]);

    useEffect(() => {
        console.log("CART ITEMS", cartitems);
    }, [cartitems]);

    useEffect(() => {
        console.log("ledger", ledger);
        console.log(ledger?.filter((item) => item?.underGroup?.toLowerCase() === "supplier")?.map((item) => item?.attributes?.ledgerName));
        console.log(ledger?.filter((item) => item?.attributes?.underGroup === "SUPPLIER")?.map((data) => ({ label: data?.attributes?.ledgerName, value: data?.attributes?.ledgerName })))
    }, [ledger]);

    useEffect(() => {
        let date = moment(new Date()).format('YYYY-MM-DD');
        let purchaseData = { ...purchase };
        purchaseData["Date"] = date;
        purchaseData["invoice_Date"] = date;
        setPurchase(purchaseData);
    }, []);

    useEffect(() => {
        posAllProduct();
        fetchPosPurchaseListing();
        dispatch({ type: cartItem.CART_ITEMS, payload: [] });
    }, []);

    const handleDate = (e) => {
        let date = moment(e).format('YYYY-MM-DD');
        // setPurchaseValue((prev) => ({ ...prev, date }));
        let purchaseData = { ...purchase };
        purchaseData["Date"] = date;
        setPurchase(purchaseData);
    }

    const handleInvoiceDate = (e) => {
        let date = moment(e).format('YYYY-MM-DD');
        // setPurchaseValue((prev) => ({ ...prev, date }));
        let purchaseData = { ...purchase };
        purchaseData["invoice_Date"] = date;
        setPurchase(purchaseData);
    }

    const handleInvoiceNumber = (e) => {
        const allowedCharacters = /^[a-zA-Z0-9\/\-]*$/;
        let purchaseData = { ...purchase };
        let newValue = '';
        for (let char of e) {
            if (allowedCharacters.test(char)) {
                newValue += char;
            }
        }
        console.log(newValue)
        e = newValue;
        purchaseData["invoice_Number"] = newValue;
        setPurchase(purchaseData);
        setInvNo(newValue);
    }

    const handlePayment = (e) => {
        let purchaseData = { ...purchase };
        setPaymentMethod(e);
        // purchaseData["suppliername"] = "";
        // purchaseData["supplieraddress"] = "";
        // purchaseData["suppliermobile"] = "";
        // purchaseData["suppliergstin"] = "";
        if (e.value === "Credit") {
            // purchaseData["supplierId"] = "";
            // purchaseData["suppliername"] = "";
            // purchaseData["supplieraddress"] = "";
            // purchaseData["suppliermobile"] = "";
            // purchaseData["suppliergstin"] = "";
            // if (purchase.supplier) {
            //     handleCombo(purchase.supplier);
            // }
        } else if (e.value === "Cash") {
            let supplier = ledger?.filter((item) => item.attributes.underGroup == "CASH-IN-HAND");
            purchaseData["supplierId"] = supplier[0]?.id;
        } else if (e.value === "Bank") {
            let supplier = ledger?.filter((item) => item.attributes.underGroup == "BANK" && item.attributes.forSales == true);
            purchaseData["supplierId"] = supplier[0]?.id;
        }
        setPurchase(purchaseData);
    }

    const handleCombo = (e) => {
        console.log(e);
        let purchaseData = { ...purchase };
        if (typeof (e) === "object") {
            let supplier = ledger?.filter((item) => item.id == e.value);
            console.log("SUPPLIER", supplier);
            purchaseData["supplier"] = e;
            // purchaseData["supplierId"] = supplier[0]?.id;
            purchaseData["suppliername"] = supplier[0]?.attributes?.ledgerName;
            purchaseData["supplieraddress"] = (supplier[0]?.attributes?.address ? supplier[0]?.attributes?.address+"" : "") + (supplier[0]?.attributes?.area ? supplier[0]?.attributes?.area  : "");
            purchaseData["city"] = supplier[0]?.attributes?.city;
            purchaseData["state"] = supplier[0]?.attributes?.state;
            purchaseData["suppliermobile"] = supplier[0]?.attributes?.mobileNo1;
            purchaseData["suppliergstin"] = supplier[0]?.attributes?.regNo;
            if (paymentMethod?.value === "Credit") {
                let supplier = ledger?.filter((item) => item.id == e.value);
                purchaseData["supplierId"] = supplier[0]?.id;
            }
            setPurchase(purchaseData);
            console.log(purchase)
        } else {
            purchaseData["suppliername"] = e;
            setPurchase(purchaseData);
            console.log(purchase)
        }
    };

    const handleCustomer = (e, key) => {
        const { value } = e.target;
        let purchaseData = { ...purchase };
        if (paymentMethod.value != "Credit") {
            purchaseData[key] = value;
            setPurchase(purchaseData);
        }
    }

    const handleComboOther = (e) => {
        let purchaseData = { ...purchase };
        // console.log(e.target.value);
        // console.log(e.target.name);
    }

    const handleValidation = () => {
        let errorss = {};
        let isValid = false;
        const qtyCart = updateProducts.filter((a) => a.quantity === 0);
        if (!purchaseValue.date) {
            errorss['date'] = getFormattedMessage('globally.date.validate.label');
        } else if (!purchaseValue.warehouse_id) {
            errorss['warehouse_id'] = getFormattedMessage('purchase.select.warehouse.validate.label')
        } else if (!purchaseValue.supplier_id) {
            errorss['supplier_id'] = getFormattedMessage('purchase.select.supplier.validate.label')
        } else if (qtyCart.length > 0) {
            dispatch(addToast({
                text: getFormattedMessage('globally.product-quantity.validate.message'),
                type: toastType.ERROR
            }))
        } else if (updateProducts.length < 1) {
            dispatch(addToast({
                text: getFormattedMessage('purchase.product-list.validate.message'),
                type: toastType.ERROR
            }))
        } else if (!purchaseValue.status_id) {
            errorss['status_id'] = getFormattedMessage('globally.status.validate.label')
        } else {
            isValid = true;
        }
        setErrors(errorss);
        return isValid;
    };

    const setCustomer = (item) => {
        console.log("new customer", item);
        setNewCustomer(item);
    }

    const onWarehouseChange = (obj) => {
        setPurchaseValue(inputs => ({ ...inputs, warehouse_id: obj }))
        setErrors('')
    };

    const onSupplierChange = (obj) => {
        setPurchaseValue(inputs => ({ ...inputs, supplier_id: obj }))
        setErrors('');
    };

    const onStatusChange = (obj) => {
        setPurchaseValue(inputs => ({ ...inputs, status_id: obj }))
    };

    const updateCost = (item) => {
        setNewCost(item);
    };

    const updateDiscount = (item) => {
        setNewDiscount(item);
    };

    const updateTax = (item) => {
        setNewTax(item);
    };

    const onChangeInput = (e) => {
        e.preventDefault();
        const { value } = e.target;
        // check if value includes a decimal point
        if (value.match(/\./g)) {
            const [, decimal] = value.split('.');
            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
                // do nothing
                return;
            }
        }
        setPurchaseValue(inputs => ({ ...inputs, [e.target.name]: value && value }))
    };

    const onNotesChangeInput = (e) => {
        e.preventDefault();
        setPurchaseValue(inputs => ({ ...inputs, notes: e.target.value }))
    }

    const handleCallback = (date) => {
        setPurchaseValue(previousState => {
            return { ...previousState, date: date }
        });
        setErrors('')
    };

    const updatedQty = (qty) => {
        setQuantity(qty);
    };

    const updateSubTotal = (item) => {
        setSubTotal(item);
    };

    const updatePurchaseUnit = (item) => {
        setNewPurchaseUnit(item);
    };

    const statusFilterOptions = getFormattedOptions(saleStatusOptions)
    const statusDefaultValue = statusFilterOptions.map((option) => {
        return {
            value: option.id,
            label: option.name
        }
    })

    const prepareData = (prepareData) => {
        const formValue = {
            date: moment(prepareData.date).toDate(),
            warehouse_id: prepareData.warehouse_id.value ? prepareData.warehouse_id.value : prepareData.warehouse_id,
            supplier_id: prepareData.supplier_id.value ? prepareData.supplier_id.value : prepareData.supplier_id,
            discount: prepareData.discount,
            tax_rate: prepareData.tax_rate,
            tax_amount: calculateCartTotalTaxAmount(updateProducts, purchaseValue),
            purchase_items: updateProducts,
            shipping: prepareData.shipping,
            grand_total: calculateCartTotalAmount(updateProducts, purchaseValue),
            received_amount: '',
            paid_amount: '',
            payment_type: 0,
            notes: prepareData.notes,
            reference_code: '',
            status: prepareData.status_id.value ? prepareData.status_id.value : prepareData.status_id,
        }
        return formValue
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const valid = handleValidation();
        if (valid) {
            if (singlePurchase) {
                editPurchase(id, prepareData(purchaseValue), navigate);
            } else {
                addPurchaseData(prepareData(purchaseValue));
                setPurchaseValue(purchaseValue);
            }
        }
    };

    const onBlurInput = (el) => {
        if (el.target.value === '') {
            if (el.target.name === 'shipping') {
                setPurchaseValue({ ...purchaseValue, shipping: '0.00' })
            }
            if (el.target.name === 'discount') {
                setPurchaseValue({ ...purchaseValue, discount: '0.00' })
            }
            if (el.target.name === 'tax_rate') {
                setPurchaseValue({ ...purchaseValue, tax_rate: '0.00' })
            }
        }
    }

    const validation = () => {
        let isValid = true;
        if (products['suppliername'] == '') {
            isValid = false;
            dispatch(addToast({
                text: "Enter Supplier Name",
                type: toastType.ERROR
            }))
        }
        return isValid;
    };

    const onSubmitPurchase = async () => {
        let valid = validation();
        if (cartitems.length > 0 && valid) {
            let purchaseVal = {
                txNo: singlePurchaseProduct?.attributes ? singlePurchaseProduct?.txNo : 0,
                warehosueId: warehouseDef?.value ? warehouseDef?.value : 1,
                entryDate: purchase?.Date,
                invNo: purchase?.invoice_Number ? purchase?.invoice_Number : "",
                invDate: purchase?.invoice_Date ? purchase?.invoice_Date : "",
                purchaseOrderNo: "",
                purchaseState: "Local State",
                supplierId: purchase?.supplierId ? purchase?.supplierId : purchase?.supplier?.value,
                supplierName: purchase?.suppliername ? purchase?.suppliername : "",
                supplierAddress: purchase?.supplieraddress ? purchase?.supplieraddress : "",
                city: purchase?.city ? purchase?.city : "",
                state: purchase?.state ? purchase?.state : "",
                supplierMobile: purchase?.suppliermobile ? purchase?.suppliermobile : "",
                supplierGsTin: purchase?.suppliergstin ? purchase?.suppliergstin : "",
                purchaseValue: cartcalc[0]?.purchase ? cartcalc[0]?.purchase : 0.00,
                lessAdj: cartcalc[0]?.less ? cartcalc[0]?.less : 0.00,
                billdiscAmount: 0.0,
                roundOff: cartcalc[0]?.round ? cartcalc[0]?.round : 0.00,
                netTotal: cartcalc[0]?.bill ? cartcalc[0]?.bill : 0.00,
                paymentType: paymentMethod?.label ? paymentMethod?.label : "",
                paidAmount: 0.00,
                remarks: "",
                updatedBy: userId,
                purchase2: cartitems.map((items, ind) => {
                    return {
                        txno: 0,
                        slno: ind + 1,
                        lineId: 1,
                        itemId: items?.id,
                        mrp: items?.mrp,
                        batchNo: "",
                        qty: items?.calculation?.totalQty,
                        rate: items?.purchaseRate && items?.purchaseRate != "" ? items?.purchaseRate : 0,
                        basicAmount: items?.calculation?.basicAmount && items?.calculation?.basicAmount != "" ? items?.calculation?.basicAmount : 0,
                        discPercent: items?.discount && items?.discount != "" ? items?.discount : 0,
                        discAmount: items?.calculation?.discAmount && items?.calculation?.discAmount != "" ? items?.calculation?.discAmount : 0,
                        lessAmount: items?.lessRs && items?.lessRs != "" ? items?.lessRs : 0,
                        totalDiscAmount: items?.calculation?.totalDiscAmount && items?.calculation?.totalDiscAmount != "" ? items?.calculation?.totalDiscAmount : 0,
                        cost: items?.calculation?.cost && items?.calculation?.cost != "" ? items?.calculation?.cost : 0,
                        grossAmount: items?.calculation?.grossAmount && items?.calculation?.grossAmount != "" ? items?.calculation?.grossAmount : 0,
                        tax: items?.taxPercentage,
                        taxAmount: items?.calculation?.taxAmount && items?.calculation?.taxAmount != "" ? items?.calculation?.taxAmount : 0,
                        netAmount: items?.calculation?.netAmount && items?.calculation?.netAmount != "" ? items?.calculation?.netAmount : 0
                    }
                }),
                xMode: singlePurchaseProduct?.attributes ? "U" : "S"
            }
            let res = addPurchase(purchaseVal);
            // console.log(res);
            // window.location.reload();
            console.log(purchaseVal);
        } else {
            dispatch(
                addToast({
                    text: 'Cart is empty!.',
                    type: toastType.ERROR,
                })
            );
        }
    }

    const handleSupliers = (e) => {
        const { name, value } = e.target;
        setPurchase({ ...purchase, [name]: value });
    }

    const handleWheel = (e) => {
        e.target.blur();
    };

    const keyDown = (e) => {
        console.log(e.key);
        if (['e', 'E', '+', '-'].includes(e.key)) {
            e.preventDefault();
        }
        if (e.key === "ArrowDown") {
            e.preventDefault();
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
        }
    };

    const invoicekeyDown = (e) => {
        const allowedSymbols = ['/', '-'];

        // Get the key pressed
        const key = e.key;

        // Check if the key is a symbol (non-alphanumeric) and is not in the allowedSymbols array
        const isSymbol = /[^\w\s]/.test(key); // non-alphanumeric and non-whitespace characters
        const isAllowed = allowedSymbols.includes(key);

        // If it's a symbol and not allowed, prevent the input
        if (isSymbol && !isAllowed) {
            e.preventDefault();
        }
    }

    const mobilKeydown = (e) => {
        if (['e', 'E', '+', '-', '.'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const keyDown1 = (e, name) => {
        console.log(e.key);
        if (e.key === "ArrowDown" || e.key === "Enter" || e.key === "Tab") {
            e.preventDefault();
            if (name === "entryDate") {
                document.getElementById('invoiceNo').focus();
                document.getElementById('invoiceNo').select();
            }
            if (name === "invoiceNo") {
                document.getElementById('invoiceDate').focus();
            }
            if (name === "invoiceDate") {
                document.getElementById('payment-method').getElementsByTagName("input")[0].focus();
                document.getElementById('payment-method').getElementsByTagName("input")[0].select();
            }
            if (name === "payment-method") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "warehouse") {
                // document.getElementById('supplier').focus();
                if (paymentMethod.value != "Credit") {
                    document.getElementById('supplier_input').focus();
                    document.getElementById('supplier_input').select();
                } else {
                    document.getElementById('supplier').getElementsByTagName("input")[0].focus();
                }
            }
            if (name === "supplier") {
                document.getElementById('address').focus();
                document.getElementById('address').select();
            }
            if (name === "supplier_input") {
                document.getElementById('address').focus();
                document.getElementById('address').select();
            }
            if (name === "city") {
                document.getElementById('state').focus();
                document.getElementById('state').select();
            }
            if (name === "address") {
                document.getElementById('city').focus();
                document.getElementById('city').select();
            }
            if (name === "state") {
                document.getElementById('mobile').focus();
                document.getElementById('mobile').select();
            }
            if (name === "gst") {
                // document.getElementById('mobile').focus();
                // document.getElementById('mobile').select();
            }
            if (name === "mobile") {
                // document.getElementById('btnSubmit').focus();
                document.getElementById('gst').focus();
                document.getElementById('gst').select();
            }
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            if (name === "invoiceNo") {
                document.getElementById('entryDate').focus();
                document.getElementById('entryDate').select();
            }
            if (name === "invoiceDate") {
                document.getElementById('invoiceNo').focus();
                document.getElementById('invoiceNo').select();
            }
            if (name === "payment-method") {
                document.getElementById('invoiceDate').focus();
                document.getElementById('invoiceDate').select();
            }
            if (name === "warehouse") {
                document.getElementById('payment-method').getElementsByTagName("input")[0].focus();
                document.getElementById('payment-method').getElementsByTagName("input")[0].select();
            }
            if (name === "supplier") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "supplier_input") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "address") {
                if (paymentMethod.value != "Credit") {
                    document.getElementById('supplier_input').focus();
                    document.getElementById('supplier_input').select();
                } else {
                    document.getElementById('supplier').getElementsByTagName("input")[0].focus();
                    document.getElementById('supplier').getElementsByTagName("input")[0].select();
                }
            }
            if (name === "city") {
                document.getElementById('address').focus();
                document.getElementById('address').select();
            }
            if (name === "state") {
                document.getElementById('city').focus();
                document.getElementById('city').select();
            }
            if (name === "gst") {
                document.getElementById('mobile').focus();
                document.getElementById('mobile').select();
            }
            if (name === "mobile") {
                document.getElementById('state').focus();
                document.getElementById('state').select();
            }
        }
    };

    const qtySelect = (e) => {
        let elem = document.getElementById('qty');
        if (elem) {
            elem.type = "text";
            elem.setSelectionRange(0, elem.value.length);

            // Adding a short delay before changing the type back to 'number'
            setTimeout(() => {
                elem.type = "text";
                elem.setSelectionRange(0, elem.value.length);
                elem.type = "number";
            }, 100);
        }
    }

    const onCancel = () => {
        setBackAlert(false);
    }

    const cancelKey = (e) => {
        if (e.key == "ArrowLeft") {
            e.preventDefault();
            document.getElementById('confirm-button').focus();
        } else if (e.key == "Enter") {
            console.log(document.activeElement.id);
            onCancel();
        }
    }

    const paymentKeyPress = (e) => {
        if (e.key == "Enter") {
            document.getElementById('confirm-button').focus();
        } else if (e.key == "ArrowRight") {
            document.getElementById('cancel-button').focus();
        }
    }

    return (
        <>
            <Loader />
            <div className="d-md-flex align-items-center justify-content-between mb-5">
                <h1 className="mb-0 create-title">{getFormattedMessage("Purchase")}</h1>
                <div className="text-end mt-4 mt-md-0">
                    {singlePurchaseProduct?.attributes ? <Button className="btn btn-outline-primary me-2 save-btn" style={{ width: '50%' }} onClick={() => onSubmitPurchase()}>{getFormattedMessage("Update")}</Button> :
                        <Button className="btn btn-outline-primary me-2 save-btn" style={{ width: '46%' }} onClick={() => onSubmitPurchase()}>{getFormattedMessage("globally.save-btn")}</Button>}
                    <button className="btn btn-outline-primary back-btn" onClick={() => {
                        setBackAlert(true);
                    }}>
                        {getFormattedMessage("Back")}
                    </button>
                </div>
            </div>
            <div className='row wholeSaleForm' style={{ background: 'white', padding: '2%', borderRadius: '8px' }}>
                <div className='col-4' style={{ borderRight: '2px solid deepskyblue', borderRadius: '8px' }}>
                    <div>
                        <Form className='row'>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="exampleForm.ControlInput1">
                            <label style={{ fontStyle: 'italic' }}>Entry No.</label>
                                <Form.Control type="number" id='entry' placeholder="Entry Number" disabled value={purchase?.entryno ? purchase?.entryno : 1} onWheel={(e) => handleWheel(e)} onKeyDown={(e) => keyDown(e)} autoComplete='off' />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="entryDate">
                            <label style={{ fontStyle: 'italic' }}>Entry Date</label>
                                <Form.Control type="date" onChange={(e) => handleDate(e.target.value)} placeholder="Entry Date" value={purchase?.Date} min={purchase?.invoice_Date} onKeyDown={(e) => keyDown1(e, "entryDate")} />
                            </Form.Group>
                        </Form>
                    </div>
                    <div>
                        <Form.Label style={{ color: 'rgb(40, 167, 69)', fontWeight: '600' }}>Invoice Details:</Form.Label>
                        <Form className='row'>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="invoiceNo">
                                <label style={{ fontStyle: 'italic' }}>Invoice No.</label>
                                <Form.Control type="text" placeholder="Invoice Number" onChange={(e) => handleInvoiceNumber(e.target.value)} value={invNo ? invNo : ""} autoFocus onKeyDown={(e) => { invoicekeyDown(e); keyDown1(e, "invoiceNo") }} autoComplete='off' />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="invoiceDate">
                                <label style={{ fontStyle: 'italic' }}>Invoice Date</label>
                                <Form.Control type="date" placeholder="Entry Date" value={purchase?.invoice_Date} onChange={(e) => handleInvoiceDate(e.target.value)} onKeyDown={(e) => keyDown1(e, "invoiceDate")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="exampleForm.ControlInput1">
                                <label style={{ fontStyle: 'italic' }}>Payment Mode</label>
                                <Select
                                    errors={errors["sales_unit_id"]}
                                    value={paymentMethod}
                                    options={[{ label: 'CASH', value: "Cash" }, { label: 'CREDIT', value: "Credit" }, { label: 'BANK', value: "Bank" }]}
                                    id="payment-method"
                                    onChange={(e) => handlePayment(e)}
                                    onKeyDown={(e) => keyDown1(e, "payment-method")}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="exampleForm.ControlInput1">
                                <label style={{ fontStyle: 'italic' }}>Warehouse</label>
                                <Select
                                    className="position-relative"
                                    value={warehouseDef}
                                    options={warehouses.map((warehouse) => ({ label: warehouse.attributes.warehouseName, value: warehouse.id }))}
                                    errors={errors["sales_unit_id"]}
                                    id="warehouse"
                                    onChange={(e) => setWarehouseDef(e)}
                                    onKeyDown={(e) => keyDown1(e, "warehouse")}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <hr style={{ color: '#eaeaea', height: '1px', opacity: '0.75' }}></hr>
                    <div>
                        <Form.Label style={{ color: 'rgb(40, 167, 69)', fontWeight: '600' }}>Supplier Details:</Form.Label>
                        {/* <Form>
                            <label style={{ fontStyle: 'italic' }}>Supplier Name</label>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                {paymentMethod?.value === "Credit" ?
                                    <Select
                                        className="position-relative"
                                        errors={errors["sales_unit_id"]}
                                        options={ledger.filter((item) => item?.attributes?.underGroup.toLowerCase() === "suppliers").map((data) => ({ label: data?.attributes?.ledgerName, value: data?.id }))}
                                        id="supplier"
                                        onChange={(e) => handleCombo(e)}
                                        onKeyDown={(e) => keyDown1(e, "supplier")}
                                        value={purchase?.supplier ? purchase?.supplier : null}
                                        placeholder="Select Suppliers"
                                    />
                                    :
                                    <Combobox
                                        id='supplier'
                                        hideEmptyPopup
                                        // data={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "supplier").map((data) => ({id: data?.id, name: data?.attributes?.ledgerName}))}
                                        data={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "suppliers").map((data) => ({ value: data?.id, label: data?.attributes?.ledgerName }))}
                                        placeholder='Select Suppliers'
                                        dataKey={"value"}
                                        textField={"label"}
                                        value={purchase?.suppliername ? purchase?.suppliername : null}
                                        onChange={(e) => handleCombo(e)}
                                        onKeyDown={(e) => keyDown1(e, "supplier_input")}
                                    // value={purchase?.suppliername ? purchase?.suppliername : null}
                                    />
                                    // <Form.Control type="text" placeholder="Supplier" onChange={(e) => handleCombo(e.target.value)} />
                                }
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="city">
                                <label style={{ fontStyle: 'italic' }}>City</label>
                                <Form.Control type="text" placeholder="Supplier City" name='supplierAddress' value={purchase?.supplieraddress} onChange={(e) => handleCustomer(e, "supplieraddress")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "city")} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="gst" >
                                <label style={{ fontStyle: 'italic' }}>GST No.</label>
                                <Form.Control type="text" placeholder="GST No." name='supplierGsTin' value={purchase?.suppliergstin} onChange={(e) => handleCustomer(e, "suppliergstin")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "gst")} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="mobile" >
                                <label style={{ fontStyle: 'italic' }}>Mobile No.</label>
                                <Form.Control type="number" placeholder="Mobile No." name='supplierMobile' value={purchase?.suppliermobile} onChange={(e) => handleCustomer(e, "suppliermobile")} onWheel={(e) => handleWheel(e)} onKeyDown={(e) => { keyDown1(e, "mobile"); mobilKeydown(e) }} />
                            </Form.Group>
                        </Form> */}
                        <Form className='row'>
                            <Form.Group className="mb-3 customer_wholesale" controlId="exampleForm.ControlInput1">
                                <label style={{ fontStyle: 'italic' }}>Supplier Name</label>
                                <InputGroup className="mb-3">
                                    {paymentMethod?.value === "Credit" ?
                                        <Select
                                            className="position-relative"
                                            errors={errors["sales_unit_id"]}
                                            options={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "suppliers" && item?.attributes?.isActive).map((data) => ({ label: (data?.attributes?.salutation != "" ? (data?.attributes?.salutation + ".") : "" ) + data?.attributes?.ledgerName, value: data?.id }))?.sort((a, b) => {
                                                if (a.label < b.label) return -1;
                                                if (a.label > b.label) return 1;
                                                return 0;
                                            })}
                                            id="supplier"
                                            onChange={(e) => handleCombo(e)}
                                            onKeyDown={(e) => keyDown1(e, "supplier")}
                                            value={purchase?.supplier ? purchase?.supplier : null}
                                            placeholder="Select Suppliers"
                                        />
                                        :
                                        <Combobox
                                            id='supplier'
                                            hideEmptyPopup
                                            // data={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "supplier").map((data) => ({id: data?.id, name: data?.attributes?.ledgerName}))}
                                            data={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "suppliers").map((data) => ({ value: data?.id, label: (data?.attributes?.salutation != "" ? (data?.attributes?.salutation + ".") : "" ) + data?.attributes?.ledgerName }))?.sort((a, b) => {
                                                if (a.label < b.label) return -1;
                                                if (a.label > b.label) return 1;
                                                return 0;
                                            })}
                                            placeholder='Select Suppliers'
                                            dataKey={"value"}
                                            value={purchase?.suppliername ? purchase?.suppliername : null}
                                            textField={"label"}
                                            onChange={(e) => handleCombo(e)}
                                            onKeyDown={(e) => keyDown1(e, "supplier_input")}
                                        // value={purchase?.suppliername ? purchase?.suppliername : null}
                                        />
                                        // <Form.Control type="text" placeholder="Supplier" onChange={(e) => handleCombo(e.target.value)} />
                                    }
                                    <Button variant="outline-secondary" id="button-addon1" style={{ color: 'white', backgroundColor: 'rgb(40 167 69)' }} 
                                    onClick={() => setCustomerShow(true)}
                                    >
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="address">
                                <label style={{ fontStyle: 'italic' }}>Address</label>
                                <Form.Control type="text" placeholder="Supplier Address" value={purchase?.supplieraddress} onChange={(e) => handleCustomer(e, "supplieraddress")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "address")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="city">
                                <label style={{ fontStyle: 'italic' }}>City</label>
                                <Form.Control type="text" placeholder="Supplier City" value={purchase?.city} onChange={(e) => handleCustomer(e, "city")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "city")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="state">
                                <label style={{ fontStyle: 'italic' }}>State</label>
                                <Form.Control type="text" placeholder="Supplier State" value={purchase?.state} onChange={(e) => handleCustomer(e, "state")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "state")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="mobile" >
                                <label style={{ fontStyle: 'italic' }}>Mobile No.</label>
                                <Form.Control type="number" placeholder="Mobile No." value={purchase?.suppliermobile} onChange={(e) => handleCustomer(e, "suppliermobile")} onWheel={(e) => handleWheel(e)} onKeyDown={(e) => { keyDown1(e, "mobile"); mobilKeydown(e) }} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="gst" >
                                <label style={{ fontStyle: 'italic' }}>GST No.</label>
                                <Form.Control type="text" placeholder="GST No." value={purchase?.suppliergstin} onChange={(e) => handleCustomer(e, "suppliergstin")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "gst")} />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <div className='col-8 right-content purchase'>
                    <div className=" items-container purchase-search">
                        <ProductSearchbar
                            // customCart={customCart}
                            // setUpdateProducts={setUpdateProducts}
                            // updateProducts={updateProducts}
                            // updateCart={addToCarts}
                            // setFilterName= {setFilterName}
                            // handleOnSelect={handleOnSelect} handleOnSearch={handleOnSearch}
                            // searchString={searchString}
                            purchase={true}
                            mode={"purchase"}
                        />
                    </div>
                    <div className='left-content custom-card p-3' style={{ background: 'white' }}>
                        <div className="main-table overflow-auto" style={{ height: "40%", minHeight: 'calc(100vh - 470px)', maxHeight: '523px' }}>
                            <Table className="mb-0">
                                <thead className="position-sticky top-0">
                                    <tr>
                                        <th style={{ padding: '0px 10px'}}>
                                            {getFormattedMessage(
                                                "pos-product.title"
                                            )}
                                        </th>
                                        {companyConfig?.attributes?.mrpWiseStock && <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-mrp.title"
                                            )}
                                        </th>}
                                        <th
                                            className={
                                                updateProducts &&
                                                    updateProducts.length
                                                    ? "text-end"
                                                    : "text-end"
                                            }
                                        >
                                            {getFormattedMessage(
                                                "pos-qty.title"
                                            )}
                                        </th>
                                        {/* <th
                                            className={
                                                updateProducts &&
                                                    updateProducts.length
                                                    ? "text-center"
                                                    : "text-center"
                                            }
                                            style={{width: "200%"}}
                                        >
                                            {getFormattedMessage(
                                                "pos-qty.title"
                                            )}
                                        </th> */}
                                        <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-rate.title"
                                            )}
                                        </th>
                                        {/* <th>
                                            {getFormattedMessage(
                                                "pos-disc.title"
                                            )}
                                        </th>
                                        <th>
                                            {getFormattedMessage(
                                                "pos-net-price.title"
                                            )}
                                        </th> */}
                                        <th className="text-end">
                                            {/* {getFormattedMessage(
                                            "pos-amount.title"
                                        )} */}
                                            Disc
                                        </th>
                                        <th className="text-end">
                                            {/* {getFormattedMessage(
                                            "pos-amount.title"
                                        )} */}
                                            Tax%
                                        </th>
                                        <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-amount.title"
                                            )}
                                        </th>
                                        <th className="text-end">
                                            
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="border-0">
                                    {cartitems && cartitems.length ? (
                                        cartitems.map(
                                            (updateProduct, index) => {
                                                return (
                                                    <ProductCartList
                                                        singleProduct={
                                                            updateProduct
                                                        }
                                                        key={index + 1}
                                                        index={index}
                                                        posAllProducts={
                                                            posAllProducts
                                                        }
                                                        // onClickUpdateItemInCart={
                                                        //     onClickUpdateItemInCart
                                                        // }
                                                        // updatedQty={updatedQty}
                                                        // updateCost={updateCost}
                                                        // onDeleteCartItem={
                                                        //     onDeleteCartItem
                                                        // }
                                                        // quantity={quantity}
                                                        // frontSetting={
                                                        //     frontSetting
                                                        // }
                                                        newCost={newCost}
                                                        allConfigData={
                                                            allConfigData
                                                        }
                                                        setUpdateProducts={
                                                            setUpdateProducts
                                                        }
                                                        purchase={true}
                                                        mode={"purchase"}
                                                    />
                                                );
                                            }
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={10}
                                                className="custom-text-center text-gray-900 fw-bold py-5"
                                            >
                                                {getFormattedMessage(
                                                    "sale.product.table.no-data.label"
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <hr />
                        <CartItemMainCalculation
                            // totalQty={!totalQty}
                            // subTotal={subTotal}
                            // grandTotal={grandTotal}
                            // cartItemValue={cartItemValue}
                            // onChangeCart={onChangeCart}
                            // allConfigData={allConfigData}
                            // reset = {reset}
                            // frontSetting={frontSetting}
                            // onChangeTaxCart={onChangeTaxCart}
                            cartItems={cartitems}
                            singlePurchase={singlePurchaseProduct}
                            purchase={true}
                        />
                        {/* <div className="d-flex purchase justify-content-end">
                            <Button
                                type="button"
                                variant="success"
                                className="text-white py-3 paybtn rounded-10 px-3 pos-pay-btn mx-4"
                                // onClick={openPaymentModel}
                                onClick={() => onSubmitPurchase()}
                            // onKeyDown={(e) => paymentKeyPress(e)}
                            >
                                Save
                            </Button>
                            <Link to={'/app/purchases'} style={{ width: '20%' }} onClick={() => {
                                dispatch({ type: cartItem.CART_ITEMS, payload: [] });
                            }}>
                                <Button
                                    type="button"
                                    variant="danger"
                                    className="text-white py-3 paybtn rounded-10 px-3 pos-pay-btn w-100"
                                // onClick={openPaymentModel}
                                // onKeyDown={(e) => paymentKeyPress(e)}
                                >
                                    Cancel
                                </Button>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
            <CreateCustomerPurchase show={customerShow} handleClose={() => setCustomerShow(false)} setCustomer={setCustomer} companyConfig={companyConfig}/>
            {backAlert && (
                <SweetAlert
                    confirmBtnBsStyle='success mb-3 fs-5 rounded'
                    cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
                    confirmBtnCssClass='alert_yes'
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={"Back to Purchase Listing Page.?"}
                    // onConfirm={onConfirm}
                    // onCancel={onCancel}
                    showCancel
                    focusCancelBtn
                    customButtons={
                        <>
                            <button id="cancel-button" onClick={() => onCancel()} className="btn btn-secondary" onKeyDown={(e) => cancelKey(e)}>
                                No
                            </button>

                            <Link to={'/app/purchases'} onClick={() => {
                                dispatch({ type: cartItem.CART_ITEMS, payload: [] });
                            }} style={{ marginRight: "5%" }}
                                onKeyDown={(e) => paymentKeyPress(e)}>
                                <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} >
                                    Yes
                                </button>
                            </Link>
                        </>
                    }
                // customIcon={remove} 
                />
            )}
        </>
    )
};

const mapStateToProps = (state) => {
    const { purchaseProducts, products, frontSetting, allConfigData, posAllProducts, cartValues, purchases, purchaseSlno, companyConfig } = state;
    return { customProducts: preparePurchaseProductArray(products), purchaseProducts, products, frontSetting, allConfigData, posAllProducts, cartValues, purchases, purchaseSlno, companyConfig }
};

export default connect(mapStateToProps, { editPurchase, fetchAllProducts, searchPurchaseProduct, posAllProduct, addPurchase, fetchPosPurchaseListing, fetchpurchaseSlNo, fetchCompanyConfig })(PurchaseForm);
