import { hsnCodeActionType } from "../../constants";

export default (state = [], action) => {
    switch (action.type) {
        case hsnCodeActionType.FETCH_ALL_HSN_CODE:
            return action.payload;
        case hsnCodeActionType.ADD_HSN_CODE:
            return [...state, action.payload];
        case hsnCodeActionType.FETCH_HSN_CODE:
            return [action.payload];
        case hsnCodeActionType.EDIT_HSN_CODE:
            return state.map(item => item.id === +action.payload.id ? action.payload : item);
        case hsnCodeActionType.DELETE_HSN_CODE:
            return state.filter(item => item.id !== action.payload);
        default :
            return state;

    }};