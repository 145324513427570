import { NilStockActionType } from "../../constants";

export default (state = [],action) => {
    switch(action.type) {
        case NilStockActionType.FETCH_NIL_STOCK:
            return action.payload
        case NilStockActionType.FETCH_NIL_STOCK_FILTER  :
            return action.payload;  
        default :
            return state
    }
}