import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyConfig } from "../../store/action/companyConfigAction";
import CompanyConfigForm from "./CompanyConfigForm";
import { useNavigate } from "react-router";

const EditCompanyConfig = () => {
  console.log("EditCompanyConfig component Rendering");
  const { companyConfig } = useSelector((state) => state);
  const [companyConfigData, setCompanyConfigData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const storedFormData = localStorage.getItem("loginUserArray");
    console.log("storedFormData", JSON.parse(storedFormData));
    if(JSON.parse(storedFormData).role?.toUpperCase() != "ADMINISTRATOR") {
      navigate("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCompanyConfig(true));
  }, []);

  useEffect(() => {
    setCompanyConfigData(companyConfig);
  }, []);

  return <CompanyConfigForm companyConfig={companyConfig} />;
};

export default EditCompanyConfig;
