import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal } from "react-bootstrap-v5";
import { getFormattedMessage, placeholderText } from "../../shared/sharedMethod";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ModelFooter from "../../shared/components/modelFooter";
import { addHsnCode, editHsnCode } from "../../store/action/hsnCodeAction";
import { connect, useSelector } from "react-redux";
import ReactSelect from "../../shared/select/reactSelect";

const HsnCodeForm = (props) => {
  const { show, handleClose, title, addHsnCodeData, singleHsnCode, id, editHsnCode, handleHsnClose, hide, addHsnCode } = props;
  console.log("single Hsn Code :", singleHsnCode)
  const [hsnCodeValue, setHsnCodeValue] = useState({
    hsnCode: '',
    description: '',
    hsnUnit: '',
  })

  useEffect(() => {
    setHsnCodeValue({
      hsnCode: singleHsnCode?.hsnCode || '',
      description: singleHsnCode?.description || '',
      hsnUnit: singleHsnCode?.hsnUnit || '',
    });
  }, [singleHsnCode]);

  const [errors, setErrors] = useState({
    hsnCode: '',
    description: ''
  });
  const [selectImg, setSelectImg] = useState(null);
  // const newImg = hsnCodeValue.image === false ? user : editImg;
  // const [imagePreviewUrl, setImagePreviewUrl] = useState(newImg);

  const disabled = false

  const { hsnCodeForm } = useSelector((state) => state);

  const [formcode, setFormCode] = useState("M01");
  const navigate = useNavigate()
  useEffect(() => {
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      console.log("Parsed Form Data:", parsedFormData);
      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
        console.log("Form Code Items:", formCodeItems);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  useEffect(() => {
    console.log(hsnCodeForm);
    if (hsnCodeForm) {
      // clearField(true);
      // handleClose();
    }
    console.log(document.getElementById("name"));
    document.getElementById("name")?.focus();

    // return () => {
    //   clearField(false);
    // }
  }, [hsnCodeForm]);


  const onChangeInput = (e) => {
    e.preventDefault();
    setHsnCodeValue((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
    setErrors("");
  };


  const locationType = [
    { value: "BAG-BAGS", label: "BAG-BAGS" },
    { value: "BAL-BALE", label: "BAL-BALE" },
    { value: "BDL-BUNDLES", label: "BDL-BUNDLES" },
    { value: "BKL-BUCKLES", label: "BKL-BUCKLES" },
    { value: "BOU-BILLIONS OF UNITS", label: "BOU-BILLIONS OF UNITS" },
    { value: "BOX-BOX", label: "BOX-BOX" },
    { value: "BTL-BOTTLES", label: "BTL-BOTTLES" },
    { value: "BUN-BUNCHES", label: "BUN-BUNCHES" },
    { value: "CAN-CANS", label: "CAN-CANS" },
    { value: "CBM-CUBIC METER", label: "CBM-CUBIC METER" },
    { value: "CCM-CUBIC CENTIMETER", label: "CCM-CUBIC CENTIMETER" },
    { value: "CMS-CENTIMETER", label: "CMS-CENTIMETER" },
    { value: "CTN-CARTONS", label: "CTN-CARTONS" },
    { value: "DOZ-DOZEN", label: "DOZ-DOZEN" },
    { value: "DRM-DRUM", label: "DRM-DRUM" },
    { value: "GGR-GREAT GROSS", label: "GGR-GREAT GROSS" },
    { value: "GMS-GRAMS", label: "GMS-GRAMS" },
    { value: "GRS-GROSS", label: "GRS-GROSS" },
    { value: "GYD-GROSS YARDS", label: "GYD-GROSS YARDS" },
    { value: "KGS-KILOGRAMS", label: "KGS-KILOGRAMS" },
    { value: "KLR-KILOLITER", label: "KLR-KILOLITER" },
    { value: "KME-KILOMETRE", label: "KME-KILOMETRE" },
    { value: "MLT-MILLILITRE", label: "MLT-MILLILITRE" },
    { value: "MTR-METERS", label: "MTR-METERS" },
    { value: "MTS-METRIC TONS", label: "MTS-METRIC TONS" },
    { value: "NOS-NUMBERS", label: "NOS-NUMBERS" },
    { value: "PAC-PACKS", label: "PAC-PACKS" },
    { value: "PCS-PIECES", label: "PCS-PIECES" },
    { value: "PRS-PAIRS", label: "PRS-PAIRS" },
    { value: "QTL-QUINTAL", label: "QTL-QUINTAL" },
    { value: "ROL-ROLLS", label: "ROL-ROLLS" },
    { value: "SET-SETS", label: "SET-SETS" },
    { value: "SQF-SQUARE FEET", label: "SQF-SQUARE FEET" },
    { value: "SQM-SQUARE METERS", label: "SQM-SQUARE METERS" },
    { value: "SQY-SQUARE YARDS", label: "SQY-SQUARE YARDS" },
    { value: "TBS-TABLETS", label: "TBS-TABLETS" },
    { value: "TGM-TEN GROSS", label: "TGM-TEN GROSS" },
    { value: "THD-THOUSANDS", label: "THD-THOUSANDS" },
    { value: "TON-TONNES", label: "TON-TONNES" },
    { value: "TUB-TUBES", label: "TUB-TUBES" },
    { value: "UGS-US GALLONS", label: "UGS-US GALLONS" },
    { value: "UNT-UNITS", label: "UNT-UNITS" },
    { value: "YDS-YARDS", label: "YDS-YARDS" },
    { value: "OTH-OTHERS", label: "OTH-OTHERS" }
  ];

  const handleDropdownChange = (option) => {
    setHsnCodeValue((prev) => ({
      ...prev,
      hsnUnit: option.value
    }));
  }

  const handleValidation = () => {
    let errors = {};
    let isValid = false;

    if (!hsnCodeValue["hsnCode"]) {
      errors["hsnCode"] = getFormattedMessage("hsnCode.input.name.validate.label");
    } else if (!hsnCodeValue["description"]) {
      errors["description"] = getFormattedMessage("description.input.validate.label");
    } else if (!hsnCodeValue["hsnUnit"]) {
      errors["hsnUnit"] = getFormattedMessage("hsnUnit.input.validate.label");
    }
    else {
      isValid = true;
    }
    setErrors(errors);
    return isValid;
  };

  const prepareFormData = (data) => {
    console.log("Data =>", data)
    let formData = {
      "id": singleHsnCode ? singleHsnCode?.id : 0,
      "hsnCode": data.hsnCode,
      "description": data.description,
      "hsnUnit": data?.hsnUnit,
    }
    return formData;
  }

  const handleformClose = () => {
    
    handleHsnClose ? handleHsnClose(false) : hide(false);
    handleClose ? handleClose(false) : hide(false);
  }


  const onSubmit = (event) => {
    event.preventDefault();
    const valid = handleValidation();
    if (singleHsnCode) {
      
      if (!disabled && valid) {

        editHsnCode(singleHsnCode?.id, prepareFormData(hsnCodeValue), handleClose);

      }
    }
    else {
      if (valid) {
        
        setHsnCodeValue(hsnCodeValue)
        addHsnCode(
          prepareFormData(hsnCodeValue), () => {
            handleClose,
              handleformClose,
              clearField();
          });

      }
    }
  }

  const clearField = () => {
    setHsnCodeValue({
      hsnCode: '',
      description: "",
      hsnUnit: ''
      // image: ''
    });

    // handleformClose();
    setErrors("");
    handleClose ? handleClose(false) : hide(false);
  };

  // const closeButtonClick =() => {
  //     handleClose(show)
  // }

  const hsnCodehandleKeyDown = (e) => {
    if (e.target.name === 'hsnCode' && (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.'
      || e.key === 'ArrowDown' || e.key === 'ArrowUp'
    )) {
      e.preventDefault();
    }
  };



  return (
    <div>
      <Modal
        show={show}
        onHide={clearField}
        keyboard={true}
        onShow={() =>
          setTimeout(() => {
            document.getElementById("name")?.focus();
          }, 1)
        }
      >
        <Form
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit(e);
            }
          }}
        >
          <Modal.Header className="modal_header_sub" closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="form-label">
                  {getFormattedMessage("HsnCode.title")}:{" "}
                </label>
                <span className="required" />
                <input
                  type="number"
                  name="hsnCode"
                  value={hsnCodeValue.hsnCode}
                  placeholder={placeholderText(
                    "globally.input.hsnCode.placeholder.label"
                  )}
                  className="form-control"
                  autoComplete="off"
                  onChange={(e) => onChangeInput(e)}
                  onKeyDown={hsnCodehandleKeyDown}
                  style={{ width: "353px" }}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["hsnCode"] ? errors["hsnCode"] : null}
                </span>
              </div>

              <div className="row" >
                <div className="col-md-12 mb-3">
                  <label className="form-label">
                    {getFormattedMessage("description.title")}:{" "}
                  </label>
                  <span className="required" />
                  <input
                    type="text"
                    name="description"
                    value={hsnCodeValue.description}
                    placeholder={placeholderText(
                      "globally.input.description.placeholder.label"
                    )}
                    className="form-control"
                    autoComplete="off"
                    onChange={(e) => onChangeInput(e)}
                  />
                  <span className="text-danger d-block fw-400 fs-small mt-2">
                    {errors["description"] ? errors["description"] : null}
                  </span>
                </div>
              </div>

              <div className="row" >
                <div className="col-md-12">
                  <label className="form-label">
                    {getFormattedMessage("hsnUnit.title")}:{" "}
                  </label>
                  <span className="required" />
                  <ReactSelect
                    name='hsnUnit'
                    value={locationType.find(option => option.value === hsnCodeValue.hsnUnit)}
                    maxLength={50}
                    data={locationType}
                    onChange={handleDropdownChange}
                  />
                  <span className="text-danger d-block fw-400 fs-small mt-2">
                    {errors["hsnUnit"] ? errors["hsnUnit"] : null}
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Form>
        <ModelFooter
          onEditRecord={singleHsnCode}
          onSubmit={onSubmit}
          editDisabled={disabled}
          clearField={clearField}
          addDisabled={!hsnCodeValue?.hsnCode}
        />
      </Modal>
    </div>
  )
}

export default connect(null, { editHsnCode, addHsnCode })(HsnCodeForm)