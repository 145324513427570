import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { addToast } from '../../store/action/toastAction';
import { connect, useDispatch } from 'react-redux';
import { name } from 'faker/lib/locales/id_ID';
import { addPriceLevelName } from '../../store/action/priceLevelListAction';

const AddPriceLevel = (props) => {
    const { show, handleClose, addPriceLevelName } = props;
    const [name, setName] = useState('');

    const close = () => {
        setName('');
        handleClose();
    };

    const handleSumbit = async () => {
        let data = {
            "pricelevelno": 0,
            "pricelevelname": name,
            "remarks": "",
            "isActive": true
        }
        let response = await addPriceLevelName(data);
        if (response?.data?.success === true) {
            setName('');
            handleClose();
        }
    };

    return (
        <>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Create New Price Level</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Price Level Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Price Level Name"
                                autoFocus
                                value={name}
                                autoComplete='off'
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSumbit} style={{
                        fontWeight: "700",
                        borderRadius: "11px",
                        width: "28%"
                    }}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={close} style={{
                        fontWeight: "700",
                        borderRadius: "11px",
                        width: "28%"
                    }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    const { } = state;
    return {}
};

export default connect(mapStateToProps, { addPriceLevelName })(AddPriceLevel);