import apiConfig from "../../config/apiConfig";
import { apiBaseURL, multipleSalesOrderActionType, multipleSalesOrderDetailsActionType, toastType } from "../../constants";
import { addToast } from "./toastAction";
import { setLoading } from "./loadingAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setLoader } from "./loaderAction";

export const fetchMultipleSalesOrderDetails =
    (orderNos, isLoading = true) =>
        async (dispatch) => {
            
            dispatch(setLoader(true));
            if (isLoading) {
                dispatch(setLoading(true));
            }
            let url = apiBaseURL.GET_MULTIPLE_SALESORDER_DETAILS + "?salesOrderNo=" + orderNos;
            apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: multipleSalesOrderDetailsActionType.FETCH_MULTIPLE_SALESOREDER_DETAIL,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        // dispatch(setLoader(false));
                    } else {
                        dispatch(setLoader(false));
                    }
                    if (isLoading) {
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };