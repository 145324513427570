import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {editUser, fetchUser} from '../../store/action/userAction';
import {useNavigate, useParams} from 'react-router-dom';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import {getFormattedMessage} from '../../shared/sharedMethod';
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import UsersForm from '../posUsers/UsersForm';
import EmployeeForm from './EmployeeForm';
import { fetchEmployee } from '../../store/action/employeeAction';

const EditEmployees = (props) => {

    const {fetchEmployee, singleEmployee, singleUser} = props;
    const {id} = useParams();

    console.log('user id',id);
    console.log("Hii Edit User ......")

    const [formcode, setFormCode] = useState("HR01");
    const navigate =useNavigate();
    useEffect(() => {
      ;
      const storedFormData = localStorage.getItem("UserFormCode");
  
      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
  
        console.log("Parsed Form Data:", parsedFormData);
        if (parsedFormData.length > 0) {
          const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permisssionUpdate);
          console.log("Form Code Items:", formCodeItems);
          if(!formCodeItems.length > 0){
              navigate("/app/dashboard");
          }
        } else {
          navigate("/app/dashboard");
        }
      } 
    }, []);

    useEffect(() => {
        
        fetchEmployee(id);
    }, [] );


    console.log(singleEmployee)

    return (
        <MasterLayout>
            <TopProgressBar />
            {/* <HeaderTitle title={getFormattedMessage('user.edit.title')} to='/app/employees'/> */}
            {singleEmployee?.attributes && <EmployeeForm singleUser={singleEmployee} id={id} />}
        </MasterLayout>
    );
}

const mapStateToProps = (state) => {
    const {singleEmployee} = state;
    return {singleEmployee}
};

export default connect(mapStateToProps, {fetchEmployee})(EditEmployees);
