import React from 'react';

const Footer = (props) => {
    const {allConfigData, frontSetting} = props
    return (
        <footer className='border-top w-100 pt-4 mt-7 d-flex justify-content-between'>
            <p className='fs-6 text-white'>All Rights Reserved (C) {new Date().getFullYear()}
                <a href='https://vcefe.com' target='_blank' className='text-decoration-none'> {frontSetting?.value?.company_name}</a>
            </p>
            <div className="fs-6 text-white">
                {/* {allConfigData && allConfigData.is_version === "1" ? "v" + allConfigData.version : ""} */}
                v2.0.0
            </div>
        </footer>
    )
};

export default Footer;
