import {
  faEye,
  faFileExcel,
  faFilePdf,
  faFilter,
  faPrint,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";
import { connect } from "react-redux";
import ClosingStockReportForm from "../closingStockReport/ClosingStockReportForm";
import ClosingStockField from "../closingStockReport/ClosingStockField";
import {
  fetchAllStockRegister,
  fetchStockRegisterReportFilter,
} from "../../../store/action/StockRegisterReportAction";
import { InputGroup } from "react-bootstrap-v5";
import ReactSelect from "../../../shared/select/reactSelect";
import { placeholderText } from "../../../shared/sharedMethod";
import { fetchProducts } from "../../../store/action/productAction";
import { generateClosingStockReportExcel, generatePDF, printPDF } from "../pdfUtil";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import moment from "moment";
import StockRegisterItemFilter from "./StockRegisterItemFilter";
import Loader from "../../loader/Loader";
import FilterModal from "../../priceList/FilterModal";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const StockRegisterReport = (props) => {
  const {
    stockRegister,
    fetchAllStockRegister,
    fetchProducts,
    products,
    fetchStockRegisterReportFilter,
    fetchCompanyConfig,companyConfig,mrpValue,itemIdValue,itemLabel
  } = props;

  console.log("STOCK REGISTEER : ", stockRegister);
  console.log("Mrp Value :", mrpValue)


  const today = new Date();
  const formattedDate = formatDate(today);
  const numOfDaysAdded = 0;
  const date = today.setDate(today.getDate() + numOfDaysAdded);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // YYYY-MM-dd

  const formatDecimal = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();

    return value?.toFixed(2);
  };

  const [filterStockRegister, setFilterStockRegister] = useState([]);
  const [fieldShow, setFieldShow] = useState(false);
  const handleFieldClose = () => setFieldShow(!fieldShow);
  const [showField, setShowField] = useState();
  const [itemId, setItemId] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);

  const [itemShow, setItemShow] = useState(true);
    const handleCloseCreate = () => setItemShow(!itemShow);

  const [label, setLabel] = useState(false);
  const [fetchRangeValue, setFetchRangeValue] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });

  const [range, setRange] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });

  const [defaultDate,setDefaultDate] = useState (defaultValue) ;


  useEffect(() => {
    setFilterStockRegister(stockRegister);
  }, [stockRegister]);

  useEffect(() => {
    // fetchAllStockRegister();
    fetchProducts();
  }, []); 
  const [filterRangeValue, setFilterRangeValue] = useState({
    itemName: '',
    itemLabel: ''
});
const [filterMrpRangeValue,setFilterRangeMrp] = useState({
    mrp:"",
    mrpLabel:""
})



  
const paySlipRef = useRef(null);
const TableRef = useRef(null);

useEffect(() => {
    fetchCompanyConfig();
}, [fetchCompanyConfig]);



const [showFilterModals, setShowFilterModals] = useState(false);
const [selectedFilter, setSelectedFilter] = useState({
  brand: "ALL",
  category: "ALL",
  group: "ALL",
});
const [filterTextShow, setFilterTextShow] = useState(false);
const showFilterModal = () => {
  console.log("showFilterModal");
  setShowFilterModals(!showFilterModals);
};
const selectedFilterSetting = (
  filtered_brand,
  filtered_group,
  filtered_category
) => {
  setSelectedFilter({
    brand: filtered_brand,
    category: filtered_category,
    group: filtered_group,
  });

  setFilterTextShow(
    filtered_brand || filtered_group || filtered_category ? true : false
  );
};
console.log("selectedFilter", selectedFilter);


const companyDetails = {
  companyName: companyConfig?.companyName || 'Company Name',
  address: `${companyConfig?.attributes?.address1 || ''} , ${companyConfig?.attributes?.address2 || ''}`,
  phoneNumber: companyConfig?.attributes?.phoneNo || 'Phone Number'
};

const handleExportToPDF = () => {
  generatePDF(paySlipRef, companyDetails, 'Portrait','Stock Register Report', selectedFilter, itemsValue, columns)
      .then(pdf => {
          pdf.save('Stock Register Report.pdf');
      })
      .catch(error => {
          console.error('Error generating PDF:', error);
      });
};

const handlePrintPDF = () => {
  printPDF( paySlipRef,companyDetails,  'Portrait','Stock Register Report', selectedFilter, itemsValue, columns);
};


const reportDetails = {
  title: "Stock Register Report ",
  dateRange: formattedDate
};

const handleExportExcel = () => {
  generateClosingStockReportExcel(companyDetails,reportDetails, itemsValue);
};

console.log("Show Field :" ,showField)

const columns = [
  { title: "Date", field: "date", headerSort: false, width: "10%" ,
    bottomCalc: () => 'Total', // Custom text for the bottom of this column
    bottomCalcFormatter: (cell) => {
        return '<strong>Total</strong>'} 
  },
  {
    title: "Description",
    field: "description",
    headerSort: false,
    width: "27%",
  },
  {
    title: "Ref Type",
    field: "refType",
    headerSort: false,
    hozAlign: "center",
    headerHozAlign: "center",
    width: "8%",
  },
  {
    title: "Ref No",
    field: "refNo",
    headerSort: false,
    hozAlign: "right",
    headerHozAlign: "right",width:"10%"
  },

  // Inward Column
  ...(showField?.inWard || showField == undefined ? [{
    title: "Inward",
    field:"inWard",
    headerSort: false,
    hozAlign: "center",
    headerHozAlign: "center",
    // field: "stocks",
    // width: "25%",
    height: "10px"
    , bottomCalc: (values, data) => {
      // Custom calculation logic
      let total = 0;
      values.forEach(value => {
          total += parseFloat(value) || 0; // Ensure values are parsed as numbers
      });
      return total.toFixed(2); // Return total with two decimal places
  },
    // formatter: formatDecimal,
    formatter: function (cell) {
      // Access the row data
      const rowData = cell.getRow().getData();
      const inWard = rowData.inWard;
      const unitName = rowData.unitName;

      return `<span style="border-right: 2px solid #dddddd;
  padding-right: 3%;
  display: inline-flex;
  height: 43px;
  margin-top: -8px;
  /* padding: 0% 0%; */
  width: 50%;
  justify-content: center;
  align-items: center;">${inWard}</span><span style="display: inline-flex;width: 50%; justify-content: center;
  align-items: center;">${unitName}</span>`;
    }
   
  }] : []),

  // Outward Column
  ...(showField?.outWard || showField == undefined ? [{
    title: "Outward",
    field:"outWard",
    headerSort: false,
    hozAlign: "center",
    headerHozAlign: "center",
    // field: "stocks",
    // width: "25%",
    height: "10px"
    , bottomCalc: (values, data) => {
      // Custom calculation logic
      let total = 0;
      values.forEach(value => {
          total += parseFloat(value) || 0; // Ensure values are parsed as numbers
      });
      return total.toFixed(2); // Return total with two decimal places
  },
    formatter: function (cell) {
      // Access the row data
      const rowData = cell.getRow().getData();
      const outWard = rowData.outWard;
      const unitName = rowData.unitName;

      return `<span style="border-right: 2px solid #dddddd;
  padding-right: 3%;
  display: inline-flex;
  height: 43px;
  margin-top: -8px;
  /* padding: 0% 0%; */
  width: 50%;
  justify-content: center;
  align-items: center;">${outWard}</span><span style="display: inline-flex;width: 50%; justify-content: center;
  align-items: center;">${unitName}</span>`;
    }
  
  } ] : [] ),
  ...(showField?.closing || showField == undefined ? [{
    title: "Closings",
    field: "closing",
    headerSort: false,
    hozAlign: "right",
    headerHozAlign: "right",
    formatter: formatDecimal,
    width: "12%"
    , bottomCalc: (values, data) => {
      // Custom calculation logic
      let total = 0;
      values.forEach(value => {
          total += parseFloat(value) || 0; // Ensure values are parsed as numbers
      });
      return total.toFixed(2); // Return total with two decimal places
  }
  } ] : []),
];

  
    
    

  //   const handleSearchData = (e) => {
  //     const { name, value } = e.target;
  //     console.log("hi name", name);
  //     console.log("hi value", value);
  //     const filtered_Stock_Register = value.length > 0
  //         ? stockRegister?.filter((item) => {
  //             const date = item?.attributes?.date?.toLowerCase() || "";
  //             const description = item?.attributes?.description?.toString().toLowerCase() || "";

  //             return date.includes(value.toLowerCase()) ||
  //                 description.includes(value.toLowerCase())

  //         })
  //         : stockRegister;

  //         setFilterStockRegister(filtered_Stock_Register);
  // };

  const [typingTimeout, setTypingTimeout] = useState(null);
  const [inputValue, setInputValue] = useState(0);

  // const [itemIdValue, setItemIdValue] = useState({
  //   itemId: 0,
  // });

  const fromDate = useRef();
  const toDate = useRef();

  useEffect(() => {
    console.log("Products Value :", products);
  }, [fetchProducts]);


  const onChangeDropDown = (selectedOption) => {
    ;
   setItemId(selectedOption?.value); 
  };

  console.log("Item Name Label :",filterRangeValue?.itemLabel)
 
const loadValues = (filter) => {
  ;
  let values =
    "?fromDate=" +
    fromDate?.current?.value +
    "&toDate=" +
    toDate?.current?.value +
    "&itemId=" + filterRangeValue?.itemName +
    "&mrp=" + filterMrpRangeValue?.mrp
    ; 

  fetchAllStockRegister(values, filter, true);
};

  const onChange = (e) => {
    ;
    const value = e.target?.value || "";
    // const selectedItemValue = selectedOption ? selectedOption?.value : '';
    setInputValue(value);
    // setItemIdValue((prev) => ({ ...prev, itemId: e?.value }));
    // const timeOutId = setTimeout(() => {
    //   loadValues(selectedOption?.value);
    // }, 3000);

    // if (typingTimeout) {
    //   clearTimeout(typingTimeout);
    // }

    // const timeOutId = setTimeout(() => {
    //   loadValues();
    // }, 3000);

    // setTypingTimeout(timeOutId);
  };

  const handleSearchData = (selectedOption) => {
    const value = selectedOption ? selectedOption?.label : "";

    console.log("Selected value:", value);
    // loadValues();

    const filteredItems =
      value?.length > 0
        ? itemsValue?.filter((item) => {
            const itemName =
              item?.attributes?.itemName?.toString().toLowerCase() || "";

            return itemName.includes(value.toLowerCase());
          })
        : itemsValue;

    setFilterStockRegister(filteredItems);
  };

  const itemsValue =
  filterStockRegister?.length > 0  
      ? (selectedFilter.brand!== "ALL" || selectedFilter?.category !=="ALL" ||  selectedFilter?.group !=="ALL" 
        ? filterStockRegister.filter(item => {
          let shouldInclude = true;

          // Check brand filter
          if (selectedFilter?.brand !== "ALL" && selectedFilter?.brand !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category1Name === selectedFilter.brand;
          }
  
          // Check category filter
          if (selectedFilter?.category !== "ALL" && selectedFilter?.category !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category2Name === selectedFilter.category;
          }
  
          // Check group filter
          if (selectedFilter?.group !== "ALL" && selectedFilter?.group !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category3Name === selectedFilter.group;
          }
  
          return shouldInclude;  
        }) 
        : filterStockRegister 
      ).map(StockRegister => {
        return{
        date: StockRegister?.attributes?.date ? moment(StockRegister.attributes.date).format('DD-MM-YYYY')  : null,
        description: StockRegister?.attributes?.description,
        refType: StockRegister?.attributes?.refType,
        refNo: StockRegister?.attributes?.refNo,
        inWard: StockRegister?.attributes?.inWard,
        outWard: StockRegister?.attributes?.outWard,
        unitName: StockRegister?.attributes?.unitName,
        closing: StockRegister?.attributes?.closing,
      };
    })
  : [];

    const totalSalesValue = (data) => {
      return new Intl.NumberFormat('en-IN', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(data.reduce((acc, curr) => acc + parseFloat(curr.closing), 0));
    };

  const handleClick = (e) => {
    setFieldShow(true);
  };

  const onClick = () => {
    setLabel(true);
    setShow(true);
    setFetchRangeValue(range);
    console.log("fetchRangeValue =>", fetchRangeValue);
  };

  console.log("defalut Value :" , defaultValue)

  const itemsInputClick = () => {
    setItemShow(true);
    // const formattedDate = formatDate(defaultValue);
    setDefaultDate(defaultValue);

    console.log("defalut Date :" , defaultDate)
  }


  return (
    <>
    <Loader/>
      <div style={{ backgroundColor: "white", height: "100%" }}>
        {fieldShow && (
          <ClosingStockField
            setShowField={setShowField}
            fieldShow={fieldShow}
            handleFieldClose={handleFieldClose}
            Title={"Show Field"}
            // stockRegister={stockRegister}
            stockRegister={columns}
          />
        )}

        <div className="row">
          <div className="col-md-5">
            <h2 style={{ color: "green" }}>
              Stock Register Report 
            </h2>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-2 ">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                borderRadius: "10px",
                width: "220px",
                height: "60px",
                gap: "13px",
                background: "white",
                marginTop: "-20px",
                fontSize: "11px",
                marginLeft: "-20px",
              }}
              ref={paySlipRef}
            >
              <FontAwesomeIcon
                icon={faPrint}
                className="fa-2x search-icon"
                style={{ color: "black", paddingTop: "8px" }}
                onClick={handlePrintPDF}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFileExcel}
                className="fa-2x search-icon "
                style={{
                  color: "green",
                  paddingLeft: "10px",
                  paddingTop: "7px",
                }}
                   onClick={handleExportExcel}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFilePdf}
                className="fa-2x search-icon"
                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                onClick={handleExportToPDF}
              ></FontAwesomeIcon>
            </button>
          </div>
        </div>

        <div className="row">
        <div className="col-md-4 searchBox">
        <div className="position-relative d-flex width-420"  style={{ height: "45px" }}>

          <input
            className="form-control ps-8"
            type="search"
            name="searchData"
            id="search"
            placeholder="Search Product Name (or) Product Code"
            aria-label="Search"
            onChange={(e) => handleSearchData(e)}
          />
          <span className="position-absolute d-flex align-items-center top-0 bottom-0 left-0 text-gray-600 ms-3">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
      </div>

{/* <div className="col-md-1"></div> */}

          <div className="col-md-2" style={{ marginTop: "-35px" }}>
            <h4 className="mt-2">From Date</h4>

            <input
              id1="dateInput"
              type="date"
              className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
              ref={fromDate}
              defaultValue={defaultValue}
              onChange={onChange}
            ></input>
          </div>

          {/* <div className="col-md-1"></div> */}

          <div className="col-md-2" style={{ marginTop: "-26px" }}>
            <h4>To Date</h4>

            <input
              id2="dateRequired2"
              type="date"
              className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
              ref={toDate}
              defaultValue={defaultValue}
              onChange={onChange}
            ></input>
          </div>

          <div
            className="col-md-2 gstrGenerateBtn ml-3" 
            style={{ marginTop: "-35px" }}
          >
            <button
              type="button"
              className="btn btn-success "
              onClick={() => loadValues()}
            >
              Generate
            </button>
          </div>
        </div>

        <br/> 

        <div className="row" >
        <div className=" col-md-8" style={{marginTop: "-20px"}}>
              <span
                className="m-0 fw-bold"
                style={{ fontWeight: 500, fontSize: "15px" }}
              >
                <span style={{ fontWeight: "normal" }}>Applied Filters: </span>
                {selectedFilter.brand &&
                  `Brand : ${selectedFilter.brand ?? "ALL"},`}
                {selectedFilter.category &&
                  `Category : ${selectedFilter.category ?? "ALL"},`}
                {selectedFilter.group &&
                  `Group : ${selectedFilter?.group ?? "ALL"}`}
              </span>
            </div>
<div className="col-md-1"></div>
          <div
            className="col-md-3"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-50px",
            }}
          >
            <button
              style={{
                marginTop: "20px",
                border: "none",
                borderRadius: "10px 0 0 10px",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                color: "blue",
                marginLeft: "20px",
              }}
              onClick={showFilterModal}
            >
              <FontAwesomeIcon
                icon={faFilter}
                className="fa-2x search-icon"
                style={{ fontSize: "15px" }}
              />
            </button>
            <button
              style={{
                marginTop: "21px",
                border: " none",
                borderRadius: "0 10px 10px 0",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                marginLeft: "-13px",
                marginRight: "5px",
                color: "blue",
              }}
              onClick={showFilterModal}
            >
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Filter
              </span>
            </button>

            <button
              style={{
                marginTop: "20px",
                border: "none",
                borderRadius: "10px 0 0 10px",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                color: "blue",
              }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={faEye}
                className="fa-2x search-icon"
                style={{ fontSize: "15px" }}
              />
            </button>
            <button
              style={{
                marginTop: "21px",
                border: "none",
                borderRadius: "0 10px 10px 0",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                marginLeft: "-13px",
                marginRight: "35px",
                color: "blue",
              }}
              onClick={handleClick}
            >
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Show Fields
              </span>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 w-100 tabulator-container nilStock gstrTable">
            <ReactTabulator
              columns={columns}
              data={itemsValue}
              // ref={pdfRef}
              options={{
                columnHeaderVertAlign: "bottom",
                layout: "fitColumns",
                responsiveLayout: "collapse",
                placeholder: "No records found",
                height: "420px",
                //        footerElement:`<div style='width:100%;text-align: left; padding: 10px; border: 1px solid rgb(99, 166, 77); border-radius: 5px; display: flex; justify-content: space-between; align-items: center;'>
                //         <div style='padding-left: 10px;'>Total</div>
                //         <div style='padding-right: 10px;'>
                //         ${totalSalesValue(itemsValue)}
                //         </div>
                // </div>`
              }}
            />
          </div>
        </div>

        <FilterModal
          showFilterModals={showFilterModals}
          showFilterModal={showFilterModal}
          selectedFilterSetting={selectedFilterSetting}
          selectedFilter={selectedFilter}
          title="Apply Filter"
        />

        {/* {show ? (
          <ClosingStockReportForm
            stockRegister={stockRegister}
            show={show}
            handleClose={handleClose}
            // setItemValues={setItemValues}
            singleStock={itemsValue}
            setRange={setRange}
            Title={"Stock Register Report Form"}
            // search={search}
          />
        ) : null} */}

{itemShow ? (
          <StockRegisterItemFilter
            show={itemShow}
            handleClose={handleCloseCreate}
            title={"Product Filter"}
            setFilterRangeValue={setFilterRangeValue}
            setFilterRangeMrp = {setFilterRangeMrp}
          />
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { stockRegister, products,companyConfig,itemLabel } = state;
  return { stockRegister, products,companyConfig,itemLabel};
};

export default connect(mapStateToProps, {
  fetchAllStockRegister,
  fetchProducts,
  fetchStockRegisterReportFilter,
  fetchCompanyConfig
})(StockRegisterReport);
