import apiConfig from "../../config/apiConfig";
import { apiBaseURL, orderActionType, toastType } from "../../constants";
import { addToast } from "./toastAction";
import requestParam from "../../shared/requestParam";
import { setLoading } from "./loadingAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setSavingButton } from "./saveButtonAction";
import { setLoader } from "./loaderAction";


export const fetchOrderStatus =
    (Id, channelId, OrderStatusId, filter = {}, isLoading = true) =>
        async (dispatch) => {
            
            dispatch(setLoader(true));
            if (isLoading) {
                dispatch(setLoading(true));
            }
            let url = apiBaseURL.ORDER_STATUS;
            apiConfig
                .get(url + "?companyId=" + Id + "&channelId=" + channelId + "&orderStatusId=" + OrderStatusId)
                .then((response) => {
                    dispatch({
                        type: orderActionType.FETCH_ORDER_STATUS,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        dispatch(setLoader(false));
                    } else {
                        dispatch(setLoader(false));
                    }
                    if (isLoading) {
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };


export const addOrderStatus = (status) => async (dispatch) => {
    // dispatch(setSavingButton(true));
    try {
        const response = await apiConfig.post(apiBaseURL.ORDER_STATUS, status);
        
        dispatch({
            type: orderActionType.ADD_ORDER_STATUS,
            payload: response?.data?.data,
        });

        if (response?.data?.success === true) {
            dispatch(
                addToast({
                    text: getFormattedMessage("status.success.create.message"),
                })
            );
            dispatch(fetchOrderStatus(1, status?.channelId, 0));
        } else {
            dispatch(
                addToast({ text: response?.data?.message, type: toastType.ERROR })
            );
            dispatch(fetchOrderStatus(1, status?.channelId, 0));
        }
        return response;  // Return the response
    } catch (error) {
        // dispatch(setSavingButton(false));
        dispatch(
            addToast({ text: error.response?.data?.message, type: toastType.ERROR })
        );
        dispatch(fetchOrderStatus(1, status?.channelId, 0));
        throw error;  // Re-throw the error so it can be caught in the calling function
    }
}