import React from 'react'
import moment from "moment"

const A501Modal = (props) => {
    const { company, saleSingle, group } = props;
    return (
        <div
            id="beauteSlip"
            style={{
                width: '210mm',
                height: '297mm',
                padding: '10mm',
                fontFamily: 'Yokohama, sans-serif',
                boxSizing: 'border-box',
                backgroundColor: '#fff',
                position: 'relative',
                overflow: 'hidden',
                fontSize: '14px'
            }}
        >
            <header style={{ marginBottom: '15px', textAlign: 'center' }}>
                <div className='row' style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 10mm'
                }}>
                    <div className='col-md-2' style={{ textAlign: 'center' }}>
                        <img src={company.companyLogo} alt="Logo" style={{ height: '100px' }} />
                    </div>

                    <div className='col-md-7' style={{ textAlign: 'center' }}>
                        <h5 style={{ fontWeight: '600', margin: '0' }}>{company.companyName}</h5>
                        <p style={{ fontWeight: '400', margin: '0' }}>{company.address}</p>
                        <p style={{ fontWeight: '400', margin: '0' }}>{company.phoneNo}</p>
                    </div>

                    <div className='col-md-3' style={{ textAlign: 'right', marginBottom: '50px' }}>
                        <h6 style={{ fontWeight: '600' }}>TAX INVOICE</h6>
                    </div>
                </div>
            </header>
            <hr style={{ border: 'none', borderTop: '1px dashed #000' }} />
            <section style={{ marginTop: '10px', height: '80%' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
                    {/* Labels Column */}
                    <div style={{ flex: '1', minWidth: '380px' }}>
                        {/* <p style={{ fontWeight: '600', margin: '0' }}>Customer Name</p>
                        <p style={{ fontWeight: '600', margin: '0' }}>Address</p>
                        <p style={{ fontWeight: '600', margin: '0' }}>Mobile No.</p>
                        <p style={{ fontWeight: '600', margin: '0' }}>GSTIN</p> */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1', minWidth: '100px' }}>
                                <p style={{ fontWeight: '600', margin: '0' }}>Customer Name</p>
                            </div>
                            <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                <p style={{ margin: '0' }}>:</p>
                            </div>
                            <div style={{ flex: '2', minWidth: '250px' }}>
                                <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.customerName}</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1', minWidth: '100px' }}>
                                <p style={{ fontWeight: '600', margin: '0' }}>Address</p>
                            </div>
                            <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                <p style={{ margin: '0' }}>:</p>
                            </div>
                            <div style={{ flex: '2', minWidth: '250px' }}>
                                <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.customerAddress + (saleSingle?.attributes?.city ? ', ' + saleSingle?.attributes?.city : '') + (saleSingle?.attributes?.city ?  ', '+ saleSingle?.attributes?.state : '') + '.'}</p>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1', minWidth: '100px' }}>
                                <p style={{ fontWeight: '600', margin: '0' }}>City</p>
                            </div>
                            <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                <p style={{ margin: '0' }}>:</p>
                            </div>
                            <div style={{ flex: '2', minWidth: '250px' }}>
                                <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.city}</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1', minWidth: '100px' }}>
                                <p style={{ fontWeight: '600', margin: '0' }}>State</p>
                            </div>
                            <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                <p style={{ margin: '0' }}>:</p>
                            </div>
                            <div style={{ flex: '2', minWidth: '250px' }}>
                                <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.state}</p>
                            </div>
                        </div> */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1', minWidth: '100px' }}>
                                <p style={{ fontWeight: '600', margin: '0' }}>Mobile No.</p>
                            </div>
                            <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                <p style={{ margin: '0' }}>:</p>
                            </div>
                            <div style={{ flex: '2', minWidth: '250px' }}>
                                <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.customerMobile}</p>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1', minWidth: '100px' }}>
                                <p style={{ fontWeight: '600', margin: '0' }}>GSTIN</p>
                            </div>
                            <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                <p style={{ margin: '0' }}>:</p>
                            </div>
                            <div style={{ flex: '2', minWidth: '250px' }}>
                                <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.customerRegNo}</p>
                            </div>
                        </div>
                    </div>

                    {/* Colons Column */}
                    {/* <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                        <p style={{ margin: '0' }}>:</p>
                        <p style={{ margin: '0' }}>:</p>
                        <p style={{ margin: '0' }}>:</p>
                        <p style={{ margin: '0' }}>:</p>
                    </div> */}

                    {/* Values Column */}
                    {/* <div style={{ flex: '2', minWidth: '250px' }}>
                        <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.customerName}</p>
                        <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.customerAddress}</p>
                        <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.customerMobile}</p>
                        <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.customerRegNo}</p>
                    </div> */}

                    {/* Empty Column for alignment */}
                    <div style={{ flex: '0 0 auto', minWidth: '10px' }}></div>

                    {/* Additional Details Column */}
                    <div style={{ flex: '1', minWidth: '100px' }}>
                        {/* <p style={{ fontWeight: '600', margin: '0' }}>Inv.No</p>
                        <p style={{ fontWeight: '600', margin: '0' }}>Inv. Date</p>
                        <p style={{ fontWeight: '600', margin: '0' }}>Mode</p> */}
                        <div style={{ flex: '1', minWidth: '380px' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: '1', minWidth: '100px' }}>
                                    <p style={{ fontWeight: '600', margin: '0' }}>Inv.No</p>
                                </div>
                                <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                    <p style={{ margin: '0' }}>:</p>
                                </div>
                                <div style={{ flex: '2', minWidth: '250px' }}>
                                    <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.invNo}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: '1', minWidth: '100px' }}>
                                    <p style={{ fontWeight: '600', margin: '0' }}>Inv. Date</p>
                                </div>
                                <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                    <p style={{ margin: '0' }}>:</p>
                                </div>
                                <div style={{ flex: '2', minWidth: '250px' }}>
                                    <p style={{ margin: '0', marginLeft: '10px' }}>{moment(saleSingle?.attributes?.invDate.split(' ')[0]).format('DD-MM-YYYY')}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: '1', minWidth: '100px' }}>
                                    <p style={{ fontWeight: '600', margin: '0' }}>&nbsp;</p>
                                </div>
                                <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                    <p style={{ margin: '0' }}>&nbsp;</p>
                                </div>
                                <div style={{ flex: '2', minWidth: '250px' }}>
                                    <p style={{ margin: '0', marginLeft: '10px' }}>&nbsp;</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: '1', minWidth: '100px' }}>
                                    <p style={{ fontWeight: '600', margin: '0' }}>Mode</p>
                                </div>
                                <div style={{ flex: '0 0 auto', minWidth: '2px', textAlign: 'center' }}>
                                    <p style={{ margin: '0' }}>:</p>
                                </div>
                                <div style={{ flex: '2', minWidth: '250px' }}>
                                    <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.paymentType?.toUpperCase()}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Colons Column */}
                    {/* <div style={{ flex: '0 0 auto', textAlign: 'center' }}>
                        <p style={{ margin: '0' }}>:</p>
                        <p style={{ margin: '0' }}>:</p>
                        <p style={{ margin: '0' }}>:</p>
                    </div> */}

                    {/* Additional Details Values Column */}
                    {/* <div style={{ flex: '2', minWidth: '220px' }}>
                        <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.invNo}</p>
                        <p style={{ margin: '0', marginLeft: '10px' }}>{moment(saleSingle?.attributes?.invDate.split(' ')[0] + " " + saleSingle?.attributes?.createdAt.split(' ')[1]).format('DD-MM-YYYY')}</p>
                        <p style={{ margin: '0', marginLeft: '10px' }}>{saleSingle?.attributes?.paymentType?.toUpperCase()}</p>
                    </div> */}
                </div>
                <hr style={{ borderTop: '1px dashed #000' }} />
                <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '12px' }}>
                    <thead style={{ fontWeight: '600', borderBottom: '1px solid black', borderStyle: 'dashed' }}>
                        <tr>
                            <td style={{ textAlign: 'right', padding: '3px' }}>No.</td>
                            <td style={{ textAlign: 'left', padding: '3px' }}>Description</td>
                            <td style={{ textAlign: 'right', padding: '3px' }}>&nbsp;</td>
                            <td style={{ textAlign: 'right', padding: '3px' }}>Qty</td>
                            <td style={{ textAlign: 'right', padding: '3px' }}>Price</td>
                            <td style={{ textAlign: 'right', padding: '3px' }}>Disc.</td>
                            <td style={{ textAlign: 'right', padding: '3px' }}>Tax%</td>
                            <td style={{ textAlign: 'right', padding: '3px' }}>Net Price</td>
                            <td style={{ textAlign: 'right', padding: '3px' }}>Amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        {saleSingle?.attributes?.sales2?.map((item, i) => (
                            <tr key={i}>
                                <td style={{ textAlign: 'center', padding: '2px' }}>{i + 1}</td>
                                <td style={{ textAlign: 'left', padding: '2px', width: '32%' }}><p className="m-0" style={{
                                    whiteSpace: "nowrap",
                                    width: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}>{item.itemName}</p></td>
                                <td style={{ textAlign: 'right', padding: '2px' }}>&nbsp;</td>
                                <td style={{ textAlign: 'right', padding: '2px' }}>{item.qty + " " + item.salesUnitName}</td>
                                <td style={{ textAlign: 'right', padding: '2px' }}>{parseFloat(item.rate).toFixed(2)}</td>
                                <td style={{ textAlign: 'right', padding: '2px' }}>{parseFloat(item.totalDiscAmount).toFixed(2)}</td>
                                <td style={{ textAlign: 'right', padding: '2px' }}>{parseFloat(item.tax).toFixed(2)}</td>
                                <td style={{ textAlign: 'right', padding: '2px' }}>{parseFloat(item.netSalesRate).toFixed(2)}</td>
                                <td style={{ textAlign: 'right', padding: '2px' }}>{parseFloat(item.grossAmount).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot style={{ borderTop: '1px dashed #000', borderBottom: '1px dashed #000', fontWeight: '600' }}>
                        <tr>
                            <td></td>
                            <td></td>
                            <td style={{ textAlign: 'right', padding: '2px' }}></td>
                            <td style={{ textAlign: 'right', padding: '2px' }}>{saleSingle?.attributes?.sales2?.every(sale => sale.salesUnitName == saleSingle?.attributes?.sales2?.[0]?.salesUnitName) ? saleSingle?.attributes?.sales2?.reduce((a, b) => parseFloat(a) + parseFloat(b.qty), 0) + " " + saleSingle?.attributes?.sales2?.[0]?.salesUnitName : ""}</td>
                            <td style={{ textAlign: 'right', padding: '2px' }}></td>
                            <td style={{ textAlign: 'right', padding: '2px' }}></td>
                            <td style={{ textAlign: 'right', padding: '2px' }}></td>
                            <td></td>
                            <td style={{ textAlign: 'right', padding: '' }}>{saleSingle?.attributes?.sales2?.length > 0 && parseFloat(saleSingle?.attributes?.sales2?.reduce((a, b) => a + b.grossAmount, 0)).toFixed(2)}</td>
                        </tr>
                    </tfoot>
                </table>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
                    <div className='col-md-6' style={{ width: '40%' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse', fontWeight: '600' }}>
                            <thead>
                                <tr>
                                    <td style={{ borderBottom: '1px solid #000', textAlign: 'end' }}>Tax%</td>
                                    <td style={{ borderBottom: '1px solid #000', textAlign: 'end' }}>CGST</td>
                                    <td style={{ borderBottom: '1px solid #000', textAlign: 'end' }}>SGST</td>
                                    <td style={{ borderBottom: '1px solid #000', textAlign: 'end' }}>Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                {group != null && Object?.entries(group).map(([taxPercentage, data]) => (
                                    // <p key={taxPercentage}>
                                    //     <span className='percent'>{taxPercentage + "%"}</span> <span className='bar'>|</span> <span className='itemsCount'>{data.items + " item(s)"}</span>  <span className='bar'>|</span> <span className='taxamountCalc'>{data.tax_amount.toFixed(2)}</span>
                                    // </p>
                                    <tr>
                                        <td style={{ textAlign: 'end' }}>{taxPercentage + "%"}</td>
                                        <td style={{ textAlign: 'end' }}>{(data.tax_amount / 2).toFixed(2)}</td>
                                        <td style={{ textAlign: 'end' }}>{(data.tax_amount / 2).toFixed(2)}</td>
                                        <td style={{ textAlign: 'end' }}>{data.tax_amount.toFixed(2)}</td>
                                    </tr>
                                ))}
                                {/* <tr>
                                                    <td>5%</td>
                                                    <td>2.10</td>
                                                    <td>2.10</td>
                                                    <td>4.20</td>
                                                </tr>
                                                <tr>
                                                    <td>18%</td>
                                                    <td>3.75</td>
                                                    <td>3.75</td>
                                                    <td>7.50</td>
                                                </tr> */}
                            </tbody>
                            <tfoot style={{ borderTop: '1px solid #000', fontWeight: 'bold' }}>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td style={{ textAlign: 'end' }}>Total</td>
                                    <td style={{ textAlign: 'end' }}>{group != null && Object?.entries(group).reduce((a, b) => a + b[1].tax_amount, 0).toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    {/* <div className="col-md-2"></div> */}
                    <div className="col-md-6" style={{ fontWeight: '700', fontSize: '12px', margin: '0', padding: '0', lineHeight: '0.2' }}>
                        <div className="row" style={{ width: '100%', justifyContent: 'end' }}>
                            <div className="col-md-7" style={{ width: '30%' }}>
                                <p>Sub Total</p>
                                <p>GST</p>
                                <p>Less/Adj</p>
                                <p style={{ marginBottom: '24px' }}>Rounded Off</p>
                                <p style={{ fontSize: '13px', fontWeight: "bolder" }}>Grand Total</p>
                                {/* <p>Outstanding</p> */}
                            </div>
                            <div className="col-md-1">
                                <p>:</p>
                                <p>:</p>
                                <p>:</p>
                                <p style={{ marginBottom: '24px' }}>:</p>
                                <p>:</p>
                                {/* <p>:</p> */}
                                {/* <p>:</p> */}
                            </div>
                            <div className="col-md-3" style={{ padding: '0', width: '17%' }}>
                                <p style={{ textAlign: 'right' }}>{saleSingle?.attributes?.sales2?.length > 0 && parseFloat(saleSingle?.attributes?.sales2?.reduce((a, b) => a + b.grossAmount, 0)).toFixed(2)}</p>
                                <p style={{ textAlign: 'right' }}>{saleSingle?.attributes?.sales2?.length > 0 && parseFloat(saleSingle?.attributes?.sales2?.reduce((a, b) => a + b.taxAmount, 0)).toFixed(2)}</p>
                                <p style={{ textAlign: 'right' }}>{parseFloat(saleSingle?.attributes?.less).toFixed(2)}</p>
                                <p style={{ textAlign: 'right', marginBottom: '24px' }}>{parseFloat(saleSingle?.attributes?.roundOff).toFixed(2)}</p>
                                <p style={{ textAlign: 'right', fontSize: '13px', fontWeight: "bolder" }}>{parseFloat(saleSingle?.attributes?.netTotal).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default A501Modal