import React from 'react';
import {connect} from 'react-redux';
import DeleteModel from '../../shared/action-buttons/DeleteModel';
import {getFormattedMessage} from '../../shared/sharedMethod';
import { deleteZone } from '../../store/action/ZoneAction';

const DeleteZone = (props) => {
    const {deleteZone, onDelete, deleteModel, onClickDeleteModel} = props;

    const deleteZoneClick = () => {
       deleteZone(onDelete.zoneId);
        onClickDeleteModel(false);
    };

    return (
        <div>
            {deleteModel && <DeleteModel onClickDeleteModel={onClickDeleteModel} deleteModel={deleteModel}
                                         deleteUserClick={deleteZoneClick} name={getFormattedMessage('zone.title')}/>}
        </div>
    )
};

export default connect(null, {deleteZone})(DeleteZone);
