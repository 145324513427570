import { Form } from "react-bootstrap-v5";
import MasterLayout from "../MasterLayout";
import TabTitle from "../../shared/tab-title/TabTitle";
import { useDispatch } from "react-redux";
import { editCompanyConfig } from "../../store/action/companyConfigAction";
import { getFormattedMessage, placeholderText } from "../../shared/sharedMethod";
import CompanyConfigImage from "../companyConfig/CompanyConfigImage";
import { useEffect, useState, useRef } from "react";
import { companyConfigActionType } from "../../constants";
import { connect } from "react-redux";
import ReactSelect from "../../shared/select/reactSelect";
import CompanyConfigSignature from "./CompanyConfigSignature";
import * as EmailValidator from "email-validator";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import LocationPopup from "../../shared/LocationPopup";
import { useNavigate } from "react-router";
import { toastType } from '../../constants';
import { addToast } from "../../store/action/toastAction";


const CompanyConfigForm = (props) => {
  const { companyConfig, editCompanyConfig, action, to } = props;
  const dispatch = useDispatch();

  const dropBusiness = [
    { value: "Regular", label: "Regular" },
    { value: "Composite", label: "Composite" },
  ];

  const showPricein = [
    { value: "Inclusive", label: "Inclusive" },
    { value: "Exclusive", label: "Exclusive" },
  ];

  const mrpStacks = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ];

  const isActive = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ];

  const geoTrackings = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ];

  const printingType = [
    { value: "A4", label: "A4" },
    { value: "A4 - Composite", label: "A4 - Composite" },
    { value: "A5", label: "A5" },
    { value: "Thermal", label: "Thermal" },
    { value: "A5-01", label: "A5-01" },
    { value: "A4-01", label: "A4-01" }
  ];

  const initialCenter = { lat: 8.6870727, lng: 77.7050785 };

  const today = new Date();
  const numOfDaysAdded = 0;
  const date = today.setDate(today.getDate() + numOfDaysAdded);
  const defaultAccFromDate = new Date(today.getFullYear(), 3, 1 + 1).toISOString().split("T")[0];
  const defaultAccToDate = new Date(today.getFullYear() + 1, 2, 31 + 1).toISOString().split("T")[0];
  const defaultValue = new Date(date).toISOString().split("T")[0];
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [CompanyConfigValue, setCompanyConfigValue] = useState({
    companyName: "",
    shortName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    phoneNo: "",
    emailId: "",
    gstin: "",
    businessType: "Regular",
    showPriceIn: "Inclusive",
    allownegativestock: "No",
    companyLogo: "",
    digitalSignature: "",
    printModel: "A5",
    currentAcFrom: today.getFullYear(),
    currentAcTo: today.getFullYear() + 1,
    accFromDate: defaultAccFromDate,
    accToDate: defaultAccToDate,
    companyId: 0,
    mrpWiseStock: "Yes",
    geoTracking: "No",
    latitude: 0,
    longitude: 0,
    geourl: "",
    radius: 0,
    isActive: true,
    isSearchOn: false,
    address: "",
    fiscalStartDate: "",
    fiscalEndDate: ""
  });
  const inputRefs = {
    companyName: useRef(null),
    shortName: useRef(null),
    accFromDate: useRef(null),
    accToDate: useRef(null),
    address1: useRef(null),
    address2: useRef(null),
    city: useRef(null),
    state: useRef(null),
    phoneNo: useRef(null),
    emailId: useRef(null),
    gstin: useRef(null),
    businessType: useRef(null),
    companyLogo: useRef(null),
    digitalSignature: useRef(null),
    allownegativestock: useRef(null),
    mrpWiseStock: useRef(null),
    showPriceIn: useRef(null),
    printModel: useRef(null),
    geoTracking: useRef(null),
    isSearchOn: useRef(null)
  }
  const [formData, setFormData] = useState({
    keywords: '',
    latitude: '',
    longitude: '',
    geoUrl: '',
    radius: 0,
    markerPosition: initialCenter,
    address: ''
  });
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [multipleFiles1, setMultipleFiles1] = useState([]);
  const [removedImage, setRemovedImage] = useState([]);
  const [delImageIds, setDelImageIds] = useState({
    companyLogo: "",
    digitalSignature: ""
  });
  const [errors, setErrors] = useState({
    companyname: '',
    businesstype: '',
    showpricein: '',
    isActive: "",
    printingtype: ""
  });

  const companyRef = useRef();
  const fromDate = useRef();
  const toDate = useRef();

  useEffect(() => {
    // companyRef.current.focus();
    inputRefs.companyName.focus();
  }, []);

  useEffect(() => {
    if (companyConfig.companyDetail && action == "edit") {
      setCompanyDetails(companyConfig.companyDetail);

      setCompanyConfigValue({
        companyName: companyConfig.companyDetail?.companyName || "",
        shortName: companyConfig.companyDetail?.attributes?.shortName || "",
        address1: companyConfig.companyDetail?.attributes?.address1 || "",
        address2: companyConfig.companyDetail?.attributes?.address2 || "",
        city: companyConfig.companyDetail?.attributes?.city || "",
        state: companyConfig.companyDetail?.attributes?.state || "",
        phoneNo: companyConfig.companyDetail?.attributes?.phoneNo || "",
        emailId: companyConfig.companyDetail?.attributes?.emailId || "",
        gstin: companyConfig.companyDetail?.attributes?.gstin || "",
        businessType: companyConfig.companyDetail?.attributes?.businessType || "Regular",
        showPriceIn: companyConfig.companyDetail?.attributes?.showpricein || "Inclusive",
        allownegativestock: companyConfig.companyDetail?.attributes?.allownegativestock == true ? "Yes" : "No",
        companyLogo: companyConfig.companyDetail?.attributes?.companyLogo || "",
        digitalSignature: companyConfig.companyDetail?.attributes?.digitalSignature || "",
        printModel: companyConfig.companyDetail?.attributes?.printmodel || "A5",
        currentAcFrom: companyConfig.companyDetail?.attributes?.accFromDate ? new Date(companyConfig.companyDetail?.attributes?.accFromDate).getFullYear() : new Date().getFullYear(),
        currentAcTo: companyConfig.companyDetail?.attributes?.accToDate ? new Date(companyConfig.companyDetail?.attributes?.accToDate).getFullYear() : new Date().getFullYear() + 1,
        accFromDate: companyConfig.companyDetail?.attributes?.accFromDate ? getFormattedDate(companyConfig.companyDetail?.attributes?.accFromDate) : defaultAccFromDate,
        accToDate: companyConfig.companyDetail?.attributes?.accToDate ? getFormattedDate(companyConfig.companyDetail?.attributes?.accToDate) : defaultAccToDate,
        companyId: companyConfig.companyDetail?.attributes?.companyId || 0,
        mrpWiseStock: companyConfig.companyDetail?.attributes?.mrpWiseStock == true ? "Yes" : "No",
        geoTracking: companyConfig.companyDetail?.attributes?.geoTracking == true ? "Yes" : "No",
        latitude: companyConfig.companyDetail?.attributes?.latitude || "",
        longitude: companyConfig.companyDetail?.attributes?.longitude || "",
        geourl: companyConfig.companyDetail?.attributes?.geourl || "",
        radius: companyConfig.companyDetail?.attributes?.radius || "",
        isActive: companyConfig.companyDetail?.attributes?.isActive,
        isSearchOn: companyConfig.companyDetail?.attributes?.isSearchOn,
        address: companyConfig.companyDetail?.attributes?.address || ""
      });

      setFormData({
        keywords: "",
        latitude: companyConfig.companyDetail?.attributes?.latitude || '',
        longitude: companyConfig.companyDetail?.attributes?.longitude || '',
        geoUrl: companyConfig.companyDetail?.attributes?.geourl || "",
        radius: companyConfig.companyDetail?.attributes?.radius || 0,
        markerPosition: { lat: companyConfig.companyDetail?.attributes?.latitude || initialCenter.lat, lng: companyConfig.companyDetail?.attributes?.longitude || initialCenter.lng },
        address: companyConfig.companyDetail?.attributes?.address || ""
      });

      dispatch({
        type: companyConfigActionType.FETCH_COMPANY_CONFIG_SPECIFIC,
        payload: null,
      });
    }
  }, [companyConfig.companyDetail]);

  const getFormattedDate = (date) => {
    const initialDate = new Date(date);
    initialDate.setDate(initialDate.getDate() + 1);
    return initialDate.toISOString().split("T")[0];
  }

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    var input = value;
    var fromYr = "";
    var toYr = "";
    if (name === "isActive") {
      input = checked;
    }
    if (name === "accFromDate") {
      fromYr = new Date(value).getFullYear();
      toYr = new Date(CompanyConfigValue.accToDate).getFullYear();
    }
    if (name === "accToDate") {
      fromYr = new Date(CompanyConfigValue.accFromDate).getFullYear();
      toYr = new Date(value).getFullYear();
    }
    setCompanyConfigValue((prev) => ({
      ...prev,
      [name]: input,
      currentAcFrom: fromYr ? fromYr : prev.currentAcFrom,
      currentAcTo: toYr ? toYr : prev.currentAcTo,
    }));
  }

  const handleDropdownChange = (option) => {
    setCompanyConfigValue((prev) => ({
      ...prev,
      businessType: option.label
    }));
  };

  const onChangeFiles = (file) => {
    if (file.length > 0) {
      setMultipleFiles(file);
      setCompanyConfigValue((prev) => ({
        ...prev,
        companyLogo: file[0].name
      }));
    }
  };

  const onChangeFiles1 = (file) => {
    if (file.length > 0) {
      setMultipleFiles1(file);
      setCompanyConfigValue((prev) => ({
        ...prev,
        digitalSignature: file[0].name
      }));
    }
  };

  const deletedImage = (item) => {
    if (action == "edit") {
      setDelImageIds(prev => ({
        ...prev,
        companyLogo: CompanyConfigValue.companyId
      }))
    }
  }

  const deletedImage1 = (item) => {
    if (action == "edit") {
      setDelImageIds(prev => ({
        ...prev,
        digitalSignature: CompanyConfigValue.companyId
      }))
    }
  }

  const transferImage = (item) => {
    setRemovedImage(item);
    setMultipleFiles([]);
  };

  const transferImage1 = (item) => {
    setRemovedImage(item);
    setMultipleFiles1([]);
  };

  const handleActiveChange = (option) => {
    setCompanyConfigValue((prev) => ({
      ...prev,
      allownegativestock: option.value
    }));
  }

  const handleMRPStackChange = (option) => {
    setCompanyConfigValue((prev) => ({
      ...prev,
      mrpWiseStock: option.value
    }));
  };

  const handleShowPriceChange = (option) => {
    setCompanyConfigValue((prev) => ({
      ...prev,
      showPriceIn: option.value
    }));
  };

  const handlePrintingTypeChange = (option) => {
    
    setCompanyConfigValue((prev) => ({
      ...prev,
      printModel: option.value
    }));
  }

  const handleGeoTrackingChange = (option) => {
    setCompanyConfigValue((prev) => ({
      ...prev,
      geoTracking: option.value,
      isSearchOn: option.value === "No" ? false : CompanyConfigValue.isSearchOn === true ? true : false,
    }));
  }

  const handleSearchOnChange = (event) => {
    const { checked } = event.target;
    setCompanyConfigValue((prev) => ({
      ...prev,
      isSearchOn: checked,
      latitude: checked ? prev.latitude : 0,
      longitude: checked ? prev.longitude : 0,
      geourl: checked ? prev.geourl : "",
      radius: checked ? prev.radius : 0,
      address: checked ? prev.address : 0,
    }));
    setFormData((prev) => ({
      keywords: checked ? prev.keywords : '',
      latitude: checked ? prev.latitude : '',
      longitude: checked ? prev.longitude : '',
      geoUrl: checked ? prev.geoUrl : '',
      radius: checked ? prev.radius : 0,
      markerPosition: checked && prev.latitude && prev.longitude ? { lat: prev.latitude, lng: prev.longitude } : initialCenter,
      address: checked ? prev.address : ''
    }));
    setShow(checked);
  }

  const fetchFormData = (formValue) => {
    setFormData(formValue);
    setCompanyConfigValue((prevState) => ({
      ...prevState,
      latitude: formValue.latitude,
      longitude: formValue.longitude,
      geourl: formValue.geoUrl,
      radius: formValue.radius,
      address: formValue.address
    }));
  };

  const handleValidation = () => {
    let errors = {};
    let isValid = true;

    if (!CompanyConfigValue["companyName"]) {
      errors["companyName"] = "Company Name is Required";
      isValid = false;
    } else if (CompanyConfigValue["companyName"] && CompanyConfigValue["companyName"].length > 100) {
      errors["companyName"] = "Company Name must not exceed 100 characters";
      isValid = false;
    }

    if (!CompanyConfigValue["shortName"]) {
      errors["shortName"] = "Company Code is Required";
      isValid = false;
    } else if (CompanyConfigValue["shortName"] && CompanyConfigValue["shortName"].length > 100) {
      errors["shortName"] = "Company Code must not exceed 100 characters";
      isValid = false;
    }

    if (CompanyConfigValue["address1"] && CompanyConfigValue["address1"].length > 100) {
      errors["address1"] = "Building No/Street must not exceed 100 characters";
      isValid = false;
    }

    if (CompanyConfigValue["address2"] && CompanyConfigValue["address2"].length > 100) {
      errors["address2"] = "Area must not exceed 100 characters";
      isValid = false;
    }

    if (CompanyConfigValue["city"] && CompanyConfigValue["city"].length > 50) {
      errors["city"] = "City must not exceed 50 characters";
      isValid = false;
    }
    if (!CompanyConfigValue["state"]) {
      errors["state"] = "State is Required";
      isValid = false;
    } else if (CompanyConfigValue["state"] && CompanyConfigValue["state"].length > 50) {
      errors["state"] = "State must not exceed 50 characters";
      isValid = false;
    }

    if (CompanyConfigValue["phoneNo"] && CompanyConfigValue["phoneNo"].length > 15) {
      errors["phoneNo"] = "Invalid Phone.!";
      isValid = false;
    }

    if (CompanyConfigValue["emailId"] && !EmailValidator.validate(CompanyConfigValue["emailId"])) {
      errors["emailId"] = "Invalid Email Id.!";
      isValid = false;
    } else if (CompanyConfigValue["emailId"] && EmailValidator.validate(CompanyConfigValue["emailId"]) && CompanyConfigValue["emailId"].length > 100) {
      errors["emailId"] = "Email ID must not exceed 100 characters";
      isValid = false;
    }

    if (CompanyConfigValue["gstin"] && CompanyConfigValue["gstin"].length > 20) {
      errors["gstin"] = "GSTIN/Tax No must not exceed 20 characters"
      isValid = false;
    }

    if (!CompanyConfigValue["businessType"]) {
      errors["businessType"] = "Business Type is required";
      isValid = false;
    } else if (CompanyConfigValue["businessType"] && CompanyConfigValue["businessType"].length > 20) {
      errors["businessType"] = "Business Type must not exceed 20 characters";
      isValid = false;
    }

    if (!CompanyConfigValue["allownegativestock"]) {
      errors["allownegativestock"] = "Allow Negative Stock selection is required";
      isValid = false;
    }

    if (!CompanyConfigValue["mrpWiseStock"]) {
      errors["mrpWiseStock"] = "MRP Wise Stock selection is required";
      isValid = false;
    }

    if (!CompanyConfigValue["showPriceIn"]) {
      errors["showPriceIn"] = "Show Price selection is required";
      isValid = false;
    } else if (CompanyConfigValue["showPriceIn"] && CompanyConfigValue["showPriceIn"].length > 20) {
      errors["showPriceIn"] = "Show Price must not exceed 15 characters";
      isValid = false;
    }

    if (!CompanyConfigValue["printModel"]) {
      errors["printModel"] = "Sales Printing Model selection is required";
      isValid = false;
    } else if (CompanyConfigValue["printModel"] && CompanyConfigValue["printModel"].length > 20) {
      errors["printModel"] = "Show Price must not exceed 20 characters";
      isValid = false;
    }

    if (!CompanyConfigValue["geoTracking"]) {
      errors["geoTracking"] = "Enable Geo-Tracking selection is required";
      isValid = false;
    }

    if (CompanyConfigValue["isSearchOn"] && (!CompanyConfigValue["latitude"] || !CompanyConfigValue["longitude"] || !CompanyConfigValue["radius"])) {
      // errors["isSearchOn"] = "Latitude and Longitude must be filled";
      dispatch(
        addToast({
          text: "Latitude and Longitude must be filled",
          type: toastType.ERROR,
        })

      );
      isValid = false;
    }

    if (!CompanyConfigValue["accFromDate"]) {
      errors["accFromDate"] = "A/C From Date is required";
      isValid = false;
    }

    if (!CompanyConfigValue["accToDate"]) {
      errors["accToDate"] = "A/C To Date is required";
      isValid = false;
    } else {
      // const from = new Date(CompanyConfigValue["accFromDate"]);
      // const to = new Date(CompanyConfigValue["accToDate"]);

      // if (isNaN(from)) {
      //   errors["accFromDate"] = 'Invalid date format';
      //   isValid = false;
      // }

      // if (isNaN(to)) {
      //   errors["accToDate"] = 'Invalid date format';
      //   isValid = false;
      // }

      // const startYear = from.getMonth() >= 3 ? from.getFullYear() : from.getFullYear() - 1;
      // const fiscalStartDate = new Date(startYear, 3, 1);
      // const fiscalEndDate = new Date(startYear + 1, 2, 31);

      // const convertedFromDate = moment(from).format("DD-MMM-YYYY");
      // const convertedToDate = moment(to).format("DD-MMM-YYYY");
      // const convertedfiscalStartDate = moment(fiscalStartDate).format("DD-MMM-YYYY");
      // const convertedfiscalEndDate = moment(fiscalEndDate).format("DD-MMM-YYYY");

      // if (!(Date.parse(convertedFromDate) >= Date.parse(convertedfiscalStartDate) && Date.parse(convertedFromDate) <= Date.parse(convertedfiscalEndDate))) {
      //   errors["accFromDate"] = `A/C From Date must be between ${convertedfiscalStartDate} and ${convertedfiscalEndDate}`;
      //   isValid = false;
      // } else {
      //   setCompanyConfigValue((prevState) => ({
      //     ...prevState,
      //     fiscalStartDate: convertedfiscalStartDate
      //   }));
      // }

      // if (!(Date.parse(convertedToDate) >= Date.parse(convertedfiscalStartDate) && Date.parse(convertedToDate) <= Date.parse(convertedfiscalEndDate))) {
      //   errors["accToDate"] = `A/C To Date must be between ${convertedfiscalStartDate} and ${convertedfiscalEndDate}`;
      //   isValid = false;
      // } else {
      //   setCompanyConfigValue((prevState) => ({
      //     ...prevState,
      //     fiscalEndDate: convertedfiscalEndDate
      //   }));
      // }
    }

    setErrors(errors);
    return isValid;
  };

  const prepareFormData = (data) => {
    const payload = {
      companyName: data.companyName,
      shortName: data.shortName,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      phoneNo: data.phoneNo,
      emailId: data.emailId,
      gstin: data.gstin,
      businessType: data.businessType,
      showPriceIn: data.showPriceIn,
      allownegativestock: data.allownegativestock == "Yes" ? true : false,
      companyLogo: "",
      digitalSignature: "",
      printModel: data.printModel,
      currentAcFrom: data.currentAcFrom,
      currentAcTo: data.currentAcTo,
      accFromDate: data.fiscalStartDate ? data.fiscalStartDate : data.accFromDate,
      accToDate: data.fiscalEndDate ? data.fiscalEndDate : data.accToDate,
      companyId: data.companyId,
      mrpWiseStock: data.mrpWiseStock == "Yes" ? true : false,
      geoTracking: data.geoTracking == "Yes" ? true : false,
      latitude: data.latitude ? data.latitude : 0,
      longitude: data.longitude ? data.longitude : 0,
      geourl: data.geourl,
      radius: data.radius ? parseInt(data.radius) : 0,
      isActive: data.isActive,
      isSearchOn: data.isSearchOn,
      address: data.address
    };
    console.log("payload", payload)
    return payload;
  };

  const prepareImgFormData = () => {
    const formData = new FormData();
    formData.append("image", multipleFiles[0]);
    console.log("formData1", formData)
    return formData;
  };

  const prepareImgFormData1 = () => {
    const formData = new FormData();
    formData.append("image", multipleFiles1[0]);
    console.log("formData2", formData)
    return formData;
  };

  const handleClose = () => {
    setShow(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();

    const valid = handleValidation();
    if (companyConfig && valid) {

      editCompanyConfig(prepareFormData(CompanyConfigValue), prepareImgFormData(), prepareImgFormData1(), navigate, delImageIds);
    }
  }

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const { name } = target;
    if (name === 'phoneNo' && (key === 'e' || key === 'E' || key === '+' || key === '-' || key === "ArrowUp" || key === "ArrowDown")) {
      e.preventDefault();
    }
    if (key === 'Enter' || key === 'ArrowDown' || key === 'Tab' || key === 'ArrowRight') {
      if (name == "companyName") {
        inputRefs.shortName.focus();
      } else if (name == "shortName") {
        inputRefs.address1.focus();
      } else if (name == "address1") {
        inputRefs.address2.focus();
      } else if (name == "address2") {
        inputRefs.city.focus();
      } else if (name == "city") {
        inputRefs.state.focus();
      } else if (name == "state") {
        inputRefs.phoneNo.focus();
      } else if (name == "phoneNo") {
        inputRefs.emailId.focus();
      } else if (name == "emailId") {
        inputRefs.gstin.focus();
      } else if (name == "gstin") {
        inputRefs.businessType.focus();
      } else if (name == "businessType") {
        inputRefs.companyLogo.focus();
      } else if (name == "companyLogo") {
        inputRefs.digitalSignature.focus();
      } else if (name == "digitalSignature") {
        inputRefs.allownegativestock.focus();
      } else if (name == "allownegativestock") {
        inputRefs.mrpWiseStock.focus();
      } else if (name == "mrpWiseStock") {
        inputRefs.showPriceIn.focus();
      } else if (name == "showPriceIn") {
        inputRefs.printModel.focus();
      } else if (name == "printModel") {
        inputRefs.geoTracking.focus();
      } else if (name == "geoTracking") {
        inputRefs.isSearchOn.focus();
      }
    } else if (key === 'ArrowUp' || key === 'ArrowLeft') {
      if (name == "shortName") {
        inputRefs.companyName.focus();
      } else if (name == "address1") {
        inputRefs.shortName.focus();
      } else if (name == "address2") {
        inputRefs.address1.focus();
      } else if (name == "city") {
        inputRefs.address2.focus();
      } else if (name == "state") {
        inputRefs.city.focus();
      } else if (name == "phoneNo") {
        inputRefs.state.focus();
      } else if (name == "emailId") {
        inputRefs.phoneNo.focus();
      } else if (name == "gstin") {
        inputRefs.emailId.focus();
      } else if (name == "businessType") {
        inputRefs.gstin.focus();
      } else if (name == "companyLogo") {
        inputRefs.businessType.focus();
      } else if (name == "digitalSignature") {
        inputRefs.companyLogo.focus();
      } else if (name == "allownegativestock") {
        inputRefs.digitalSignature.focus();
      } else if (name == "mrpWiseStock") {
        inputRefs.allownegativestock.focus();
      } else if (name == "showPriceIn") {
        inputRefs.mrpWiseStock.focus();
      } else if (name == "printModel") {
        inputRefs.showPriceIn.focus();
      } else if (name == "geoTracking") {
        inputRefs.printModel.focus();
      }
    }
  }

  return (
    <MasterLayout>
      <div className="d-md-flex align-items-center justify-content-between mb-5">
        <h1 className="mb-0 create-title">{action == "edit" ? getFormattedMessage("companyconfig.edit.company") : getFormattedMessage("companyconfig.create.company")}</h1>
        <div className="text-end mt-4 mt-md-0">
          <Link to={""} className="btn btn-primary me-3 save-btn"
            style={{ minWidth: "120px" }} onClick={onSubmit} ref={(el) => (inputRefs.save = el)}
            onKeyDown={(e) => handleKeyDown(e)}>
            {action === "edit" ? getFormattedMessage("globally.update-btn") : getFormattedMessage("globally.save-btn")}
          </Link>
          {to &&
            <Link to={to} style={{ minWidth: "120px" }} className="btn btn-outline-primary back-btn">
              {getFormattedMessage("globally.back-btn")}
            </Link>
          }
        </div>
      </div>
      <Form>
        <TabTitle title={placeholderText("companyconfig.title")} />
        <div className="row">
          <div className="col-xl-12 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="col-md-12 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h1 className="mb-0">{getFormattedMessage("AboutCompany.title")}</h1>
                    <label className="form-check form-check-custom form-check-solid form-check-inline d-flex align-items-center cursor-pointer custom-label">
                      <input
                        type="checkbox"
                        name="isActive"
                        className="me-3 form-check-input cursor-pointer"
                        style={{
                          marginLeft: "10px",
                          width: "22px",
                          height: "22px",
                          marginTop: "1px"
                        }}
                        checked={CompanyConfigValue.isActive}
                        placeholder={placeholderText(
                          "globally.input.remarks.placeholder.label"
                        )}
                        autoComplete="off"
                        onChange={handleInputChange}
                      />
                      <div className="control__indicator" />{" "}
                      {getFormattedMessage("globally.input.isActive.?.label")}
                    </label>
                  </div>
                  <hr></hr>
                </div>
                <div className="card">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage(
                          "globally.input.CompanyConfig.label"
                        )}

                      </label>
                      <span className="required" />
                      <input
                        type="text"
                        name="companyName"
                        value={CompanyConfigValue.companyName}
                        className="form-control"
                        onChange={handleInputChange}
                        // ref={companyRef}
                        autoComplete="off"
                        ref={(el) => (inputRefs.companyName = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                      />
                      <span
                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['companyName'] ? errors['companyName'] : null}</span>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        {getFormattedMessage(
                          "globally.input.CompanyCode.label"
                        )}

                      </label>
                      <span className="required" />
                      <input
                        type="text"
                        name="shortName"
                        value={CompanyConfigValue.shortName}
                        className="form-control"
                        ref={(el) => (inputRefs.shortName = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        disabled={action === "edit"}
                        autoComplete="off"
                        onChange={handleInputChange}
                      />
                      <span
                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['shortName'] ? errors['shortName'] : null}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">A/C From Date</label>
                      <span className="required" />
                      <input
                        type="date"
                        className="form-control  rounded text-align-center  align-items-center mr-15"
                        // ref={fromDate}
                        ref={(el) => (inputRefs.accFromDate = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        value={CompanyConfigValue.accFromDate}
                        defaultValue={defaultValue}
                        name="accFromDate"
                        onChange={handleInputChange}
                      ></input>
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['accFromDate'] ? errors['accFromDate'] : null}
                      </span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label className="form-label">A/C To Date</label>
                          <span className="required" />
                        </div>
                        <div>
                          <i>{CompanyConfigValue.currentAcFrom + "-" + CompanyConfigValue.currentAcTo}</i>
                        </div>
                      </div>
                      <input
                        type="date"
                        className="form-control rounded text-align-center align-items-center mr-15"
                        // ref={toDate}
                        ref={(el) => (inputRefs.accToDate = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        value={CompanyConfigValue.accToDate}
                        defaultValue={defaultValue}
                        name="accToDate"
                        onChange={handleInputChange}
                        min={CompanyConfigValue.accFromDate}
                      ></input>
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['accToDate'] ? errors['accToDate'] : null}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage(
                          "globally.input.Building-no-street.label"
                        )}

                      </label>

                      <input
                        type="text"
                        name="address1"
                        value={CompanyConfigValue.address1}
                        ref={(el) => (inputRefs.address1 = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="form-control"
                        autoFocus={true}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['address1'] ? errors['address1'] : null}
                      </span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        {getFormattedMessage("globally.input.area.label")}
                      </label>

                      <input
                        type="text"
                        name="address2"
                        ref={(el) => (inputRefs.address2 = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        value={CompanyConfigValue.address2}
                        className="form-control"
                        autoFocus={true}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['address2'] ? errors['address2'] : null}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage("globally.input.city.label")}
                      </label>

                      <input
                        type="text"
                        name="city"
                        value={CompanyConfigValue.city}
                        ref={(el) => (inputRefs.city = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="form-control"
                        autoFocus={true}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['city'] ? errors['city'] : null}
                      </span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        {getFormattedMessage("globally.input.state.label")}
                      </label>

                      <input
                        type="text"
                        name="state"
                        ref={(el) => (inputRefs.state = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        value={CompanyConfigValue.state}
                        className="form-control"
                        autoFocus={true}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['state'] ? errors['state'] : null}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage("globally.input.Mobileno.label")}
                      </label>

                      <input
                        type="number"
                        name="phoneNo"
                        value={CompanyConfigValue.phoneNo}
                        ref={(el) => (inputRefs.phoneNo = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="form-control"
                        autoFocus={true}
                        onChange={handleInputChange}
                        autoComplete="off"
                      // onKeyDown={(e) => {
                      //   if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E' || e.key === "ArrowUp" || e.key === "ArrowDown") {
                      //     e.preventDefault();
                      //   }
                      // }}
                      />
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['phoneNo'] ? errors['phoneNo'] : null}
                      </span>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        {getFormattedMessage("globally.input.Email.label")}
                      </label>

                      <input
                        type="email"
                        name="emailId"
                        value={CompanyConfigValue.emailId}
                        ref={(el) => (inputRefs.emailId = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="form-control"
                        autoFocus={true}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['emailId'] ? errors['emailId'] : null}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage(
                          "globally.input.Registrationno.label"
                        )}

                      </label>

                      <input
                        type="text"
                        name="gstin"
                        value={CompanyConfigValue.gstin}
                        ref={(el) => (inputRefs.gstin = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        className="form-control"
                        autoFocus={true}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      <span className='text-danger d-block fw-400 fs-small mt-2'>
                        {errors['gstin'] ? errors['gstin'] : null}
                      </span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage(
                          "globally.input.businesstype.label"
                        )}

                      </label>
                      <span className="required" />

                      <ReactSelect
                        value={dropBusiness.find(option => option.value === CompanyConfigValue.businessType)}
                        data={dropBusiness}
                        onChange={handleDropdownChange}
                        ref={(el) => (inputRefs.businessType = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        autoComplete="off"
                        placeholder="Select Business Type"
                        isDisable={true}
                        isSearchable={false}
                      />
                      <span className='text-danger d-block fw-400 fs-small mt-2'>{errors['businessType'] ? errors['businessType'] : null}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        {getFormattedMessage(
                          "globally.input.Company Logo.label"
                        )}

                      </label>
                      <div className="">
                        <CompanyConfigImage
                          companyConfig={companyDetails}
                          ref={(el) => (inputRefs.companyLogo = el)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          fetchFiles={onChangeFiles}
                          transferImage={transferImage}
                          deletedImage={deletedImage}
                          singleImageSwitch="single-image"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        {getFormattedMessage(
                          "digital.signature.label"
                        )}
                      </label>
                      <div className="">
                        <CompanyConfigSignature
                          companyConfig={companyDetails}
                          ref={(el) => (inputRefs.digitalSignature = el)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          fetchFiles={onChangeFiles1}
                          transferImage={transferImage1}
                          deletedImage={deletedImage1}
                          singleImageSwitch="single-image"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <h1>{getFormattedMessage("companyconfig.configurations")}</h1>
                    <hr></hr>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage("is Active")}

                      </label>
                      <span className="required" />
                      <ReactSelect name='allownegativestock'
                        value={isActive.find(option => option.value === CompanyConfigValue.allownegativestock)}
                        data={isActive}
                        ref={(el) => (inputRefs.allownegativestock = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={handleActiveChange}
                        autoComplete="off"
                        placeholder="Select Option"
                        isSearchable={false}
                      />
                      <span
                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['allownegativestock'] ? errors['allownegativestock'] : null}</span>

                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage(
                          "companyconfig.input.mrpstack.label"
                        )}

                      </label>
                      <span className="required" />
                      <ReactSelect name='mrpWiseStock'
                        value={mrpStacks.find(option => option.value === CompanyConfigValue.mrpWiseStock)}
                        data={mrpStacks}
                        ref={(el) => (inputRefs.mrpWiseStock = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={handleMRPStackChange}
                        autoComplete="off"
                        placeholder="Select Option"
                        isSearchable={false}
                      />
                      <span
                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['mrpWiseStock'] ? errors['mrpWiseStock'] : null}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage(
                          "globally.input.showpricein.label"
                        )}

                      </label>
                      <span className="required" />
                      <ReactSelect name='showPriceIn'
                        value={showPricein.find(option => option.value === CompanyConfigValue.showPriceIn)}
                        data={showPricein}
                        ref={(el) => (inputRefs.showPriceIn = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={handleShowPriceChange}
                        autoComplete="off"
                        placeholder="Select Show Price"
                        isDisable={true}
                        isSearchable={false}
                      />
                      <span
                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['showPriceIn'] ? errors['showPriceIn'] : null}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage(
                          "globally.input.printing.label"
                        )}

                      </label>
                      <span className="required" />
                      <ReactSelect name='printModel'
                        value={printingType?.find(option => option.label === CompanyConfigValue?.printModel)}
                        data={printingType}
                        ref={(el) => (inputRefs.printModel = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={handlePrintingTypeChange}
                        autoComplete="off"
                        placeholder="Select Printing Model"
                        isSearchable={false}
                      />
                      <span
                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['printModel'] ? errors['printModel'] : null}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label">
                        {getFormattedMessage(
                          "companyconfig.input.geotracking.label"
                        )}

                      </label>
                      <span className="required" />
                      <ReactSelect name='geoTracking'
                        value={geoTrackings?.find(option => option.value === CompanyConfigValue?.geoTracking)}
                        data={geoTrackings}
                        ref={(el) => (inputRefs.geoTracking = el)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={handleGeoTrackingChange}
                        autoComplete="off"
                        placeholder="Select Option"
                        isSearchable={false}
                      />
                      <span
                        className='text-danger d-block fw-400 fs-small mt-2'>{errors['geoTracking'] ? errors['geoTracking'] : null}</span>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="d-flex align-items-center h-100">
                        <label style={{ marginTop: 20 }} className={`p-0 form-check form-check-custom form-check-solid form-check-inline d-flex align-items-center custom-label ${CompanyConfigValue?.geoTracking === "No" ? "cursor-not-allowed" : "cursor-pointer"}`}>
                          <input
                            type="checkbox"
                            name="isSearchOnMap"
                            className="me-3 form-check-input"
                            style={{
                              marginLeft: "5px",
                              width: "22px",
                              height: "22px",
                              marginTop: "1px"
                            }}
                            ref={(el) => (inputRefs.isSearchOn = el)}
                            disabled={CompanyConfigValue?.geoTracking === "No"}
                            checked={CompanyConfigValue.isSearchOn}
                            placeholder={placeholderText(
                              "globally.input.remarks.placeholder.label"
                            )}
                            autoComplete="off"
                            onChange={handleSearchOnChange}
                          />
                          <div className="control__indicator" />{" "}
                          {getFormattedMessage("companyconfig.input.issearchonmap.label")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <LocationPopup fetchFormData={fetchFormData} mapData={formData} handleClose={handleClose} show={show}
                  title={getFormattedMessage('companyconfig.location.popup.title')} />
                <div className="row" hidden={!CompanyConfigValue.latitude || !CompanyConfigValue.longitude || !CompanyConfigValue.isSearchOn}>
                  <div className="col-md-12">
                    <div className="location-addr-container">
                      <p className="m-0">{`${CompanyConfigValue.address}, Lat: ${CompanyConfigValue.latitude}, Long: ${CompanyConfigValue.longitude}`}</p><span onClick={() => setShow(true)}> <FontAwesomeIcon color={"#6571ff"} size={"lg"} icon={faPenToSquare} /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </MasterLayout>
  );
};

export default connect(null, { editCompanyConfig })(CompanyConfigForm);
