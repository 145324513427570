import { priceLevelActionType } from '../../constants';

export default (state = [], action) => {
    switch (action.type) {
        case priceLevelActionType.FETCH_PRICE_LEVEL:
            return action.payload;
        case priceLevelActionType.ADD_PRICE_LEVEL:
            return action.payload;
        // case employeeActionType.FETCH_EMPLOYEE:
        //     return [action.payload]
        // case priceLevelActionType.ADD_ORDER_CONFIG:
        //     return action.payload;
        // case priceLevelActionType.EDIT_ORDER_CONFIG:
        //     return action.payload;
        // case employeeActionType.DELETE_EMPLOYEE:
        //     return state.filter(item => item.id !== action.payload);
        default:
            return state;
    }
};