import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyConfig, companyDetailById } from "../../store/action/companyConfigAction";
import CompanyConfigForm from "./CompanyConfigForm";
import { useNavigate, useParams } from "react-router";

const CompanyCreationForm = () => {
  console.log("EditCompanyConfig component Rendering");
  const { companyConfig } = useSelector((state) => state);
  const [companyConfigData, setCompanyConfigData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, action } = useParams();

  useEffect(() => {
    const storedFormData = localStorage.getItem("loginUserArray");
    console.log("storedFormData", JSON.parse(storedFormData));
    if (JSON.parse(storedFormData).role?.toUpperCase() != "ADMINISTRATOR") {
      navigate("/app/dashboard");
    }
  }, []);

  useEffect(() => {
    // dispatch(fetchCompanyConfig(true));
    if (id) {
      dispatch(companyDetailById(id));
    }
  }, []);

  useEffect(() => {
    setCompanyConfigData(companyConfig);
  }, []);

  return <CompanyConfigForm companyConfig={companyConfig} action={action} to="/app/companies" />;
};

export default CompanyCreationForm;
