import { salesOrderActionType } from '../../constants';

export default (state = [], action) => {
    switch (action.type) {
        case salesOrderActionType.FETCH_SALES_ORDER:
            return action.payload;
        case salesOrderActionType.SALES_ORDER_DETAILS:
            return action.payload;
        case salesOrderActionType.ADD_SALES_ORDER:
            return action.payload;
        case salesOrderActionType.EDIT_SALES_ORDER:
            return state.map(item => item.id === +action.payload.id ? action.payload : item);
        case salesOrderActionType.DELETE_SALES_ORDER:
            return state.filter(item => item.id !== action.payload);
        default:
            return state;
    }
};
