import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MasterLayout from '../../MasterLayout';
import TopProgressBar from '../../../shared/components/loaders/TopProgressBar';
import { getFormattedMessage, placeholderText } from '../../../shared/sharedMethod';
import TabTitle from '../../../shared/tab-title/TabTitle';
import { fetchClosingStockReport } from '../../../store/action/ClsoingStockReportAction';
import ClosingStockReport from '../closingStockReport/ClosingStockReport';
import LowStockReport from '../lowStockReport/LowStockReport';
import NilStockReport from '../nilStock/NilStockReport';
import StockMovementReport from '../stockMovement/StockMovementReport';
import StockRegisterReport from '../stockRegister.js/StockRegisterReport';
import jsonImg from '../../../assets/images/JSON.png';
import { useNavigate } from 'react-router';
import B2bReport from './b2bReport';
import B2csReport from './B2csReport';
import NilReport from './NilReport';
import HsnReport from './HsnReport';
import { prepareGstArray } from "../../../shared/prepareArray/prepareGstArray";
import { fetchCompanyConfig } from '../../../store/action/companyConfigAction';
import moment from 'moment';

const GstrReport = (props) => {
    const { fetchClosingStockReport, posClsoingstock, closingStocks, allConfigData, gstrReport, fetchCompanyConfig, companyConfig } = props;
    const [closingStockValue, setclosingStockValue] = useState({ label: getFormattedMessage("unit.filter.all.label"), value: null });

    const [key, setKey] = useState('sales');

    console.log(closingStockValue)


    const array = posClsoingstock
    const newFirstElement = { attributes: { name: getFormattedMessage("report-all.warehouse.label") }, id: null }
    const newArray = [newFirstElement].concat(array)
    const [fromDate, setFromDate] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [formcode, setFormCode] = useState("R03");
    const navigate = useNavigate();

    const setDate =(item)=>{
        setFromDate(item?.split('-')[1]+item?.split('-')[0]);
        console.log("item",item?.split('-')[1]+item?.split('-')[0]);
        // setDates({...dates,fromDate:item});
        setFrom(item);
    };

    const setDateto =(item)=>{
        setTo(item);
    };

    useEffect(() => {
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            console.log("Parsed Form Data:", parsedFormData);
            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
                console.log("Form Code Items:", formCodeItems);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);

    useEffect(() => {
        fetchCompanyConfig();
    }, []);

    const generateJSONFile = (data, filename) => {
        const jsonString = JSON.stringify(data);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleGenerateJSON = () => {
        
        let data = gstrReport[0]?.attributes?.b2b || {}
        generateJSONFile(prepareGstArray(gstrReport[0]?.attributes, companyConfig?.attributes?.gstin, fromDate), 'Gstr1_report_' + moment(from).format('DD-MM-YYYY') + '_' + moment(to).format('DD-MM-YYYY') + '.json');
    }
    return (


        <div className='gstreportDiv' style={{ backgroundColor: "white", height: "100%" }}>
            <MasterLayout>
                <TopProgressBar />
                <TabTitle title={placeholderText('GSTR-1 Report')} />

                <h1 style={{ textAlign: 'center', color: 'green' }}> GSTR-1 Report</h1>
                <div style={{ textAlign: 'right' }}>
                    <button className='px-3' style={{
                        position: "absolute",
                        top: "10%",
                        left: "93%",
                        border: "none",
                        borderRadius: "5px",
                        fontWeight: "600"
                    }}
                        onClick={handleGenerateJSON}
                    >
                        <img src={jsonImg} alt="json" />JSON
                    </button>
                </div>
                <Tabs defaultActiveKey='sales' id='uncontrolled-tab-example' onSelect={(k) => setKey(k)}
                    className='p-3 mb-2 custom-bg-color text-white  mb-5 payrolltabs' >

                    <Tab eventKey='sales' title={getFormattedMessage('b2bLabel.title')}
                        tabClassName=' me-15'>

                        <div className='w-100 mx-auto border-radius-50'>
                            <B2bReport key={key} setFromDate={setDate} setDateto={setDateto}/>
                        </div>

                    </Tab>

                    <Tab eventKey='stockMovement' title={getFormattedMessage('b2cLabel.title')}
                        tabClassName=' me-15'
                    >
                        <div className='w-100 mx-auto'>
                            <B2csReport key={key} setFromDate={setDate} setDateto={setDateto}/>
                        </div>
                    </Tab>

                    <Tab eventKey='stockRegister' title={getFormattedMessage('nil.title')}
                        tabClassName=' me-15'
                    >
                        <div className='w-100 mx-auto'>
                            <NilReport key={key} setFromDate={setDate} setDateto={setDateto}/>
                        </div>
                    </Tab>

                    <Tab eventKey='lowStock' title={getFormattedMessage('hsn.title')}
                        tabClassName=' me-15'
                    >
                        <div className='w-100 mx-auto'>
                            <HsnReport key={key} setFromDate={setDate} setDateto={setDateto}/>
                        </div>
                    </Tab>
                </Tabs>
            </MasterLayout >
        </div >

    )
};

const mapStateToProps = (state) => {
    const { closingStocks, allConfigData, posClsoingstock, gstrReport, companyConfig } = state;
    return { closingStocks, allConfigData, posClsoingstock, gstrReport, companyConfig }
};
export default connect(mapStateToProps, { fetchCompanyConfig })(GstrReport);

