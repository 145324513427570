import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchSingleOrder } from '../../store/action/orderConfigAction';
import TopProgressBar from '../../shared/components/loaders/TopProgressBar';
import MasterLayout from '../MasterLayout';
import SalesOrderForm from './SalesOrderForm';
import SalesItemForm from './SalesItemForm';
import { fetchSingleSalesOrder } from '../../store/action/salesOrderAction';
import { fetchLedgers, fetchLedger } from '../../store/action/ledgerAction';

const EditSalesItem = ( props) => {
    const { fetchSingleSalesOrder, fetchLedgers, fetchLedger, singleSalesOrder, ledger } = props;
    const { id } = useParams();

    const [formcode, setFormCode] = useState("T04");
    const navigate = useNavigate();
    useEffect(() => {
        
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            console.log("Parsed Form Data:", parsedFormData);
            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permisssionUpdate);
                console.log("Form Code Items:", formCodeItems);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);

    useEffect(() => {
        fetchSingleSalesOrder(id);
        fetchLedger()
    }, []);

    const itemsValue = singleSalesOrder;

    return (
        <MasterLayout>
            <TopProgressBar />
            <SalesItemForm singleSalesOrder={itemsValue} ledger={ledger} mode={"Edit"} isView={true}/>
        </MasterLayout>
    )
}

const mapStateToProps = (state) => {
    const { singleSalesOrder, ledger } = state;
    return { singleSalesOrder, ledger }
};

export default connect(mapStateToProps, { fetchSingleSalesOrder, fetchLedgers, fetchLedger })(EditSalesItem);