import apiConfig from "../../config/apiConfig";
import { apiBaseURL, channelActionType, orderActionType, toastType } from "../../constants";
import { addToast } from "./toastAction";
import requestParam from "../../shared/requestParam";
import { setLoading } from "./loadingAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setSavingButton } from "./saveButtonAction";
import { setLoader } from "./loaderAction";


export const fetchChannels =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            
            dispatch(setLoader(true));
            if (isLoading) {
                dispatch(setLoading(true));
            }
            let url = apiBaseURL.CHANNEL;
            apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: channelActionType.FETCH_CHANNELS,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        dispatch(setLoader(false));
                    } else {
                        dispatch(setLoader(false));
                    }
                    if (isLoading) {
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };