import { validate } from "email-validator";
import apiConfig from "../../config/apiConfig";
import { apiBaseURL, gstSummaryActionType, toastType } from "../../constants";
import { setLoading } from "./loadingAction";
import { addToast } from "./toastAction";
import { setLoader } from "./loaderAction";

export const fetchGstSummaryReport =
  (values,filter = {}, isLoading = true) =>
    async (dispatch) => {
      if (isLoading) {
        dispatch(setLoading(true));
        dispatch(setLoader(true));
      }

      let url = apiBaseURL.GST_SUMMARY_REPORT +values;
      console.log(url)

      apiConfig
        .get(url)
        .then((response) => {
          console.log("Resonse", response)
          dispatch({
            type: gstSummaryActionType.FETCH_GST_SUMMARY_REPORT,
            payload: response?.data?.data,
          });
          if(response?.data?.success){
            // dispatch(addToast({ text: response?.data?.message, type: toastType.SUCCESS }));
          }else{
            // dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));   
          }
          if (isLoading) {
            dispatch(setLoader(false));
            dispatch(setLoading(false));
          }
        })
        .catch(({ response }) => {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
        });
    };