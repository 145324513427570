import { faSearch } from "@fortawesome/free-solid-svg-icons"
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import { getFormattedMessage, placeholderText } from "../../shared/sharedMethod"
import TabTitle from "../../shared/tab-title/TabTitle"
import MasterLayout from "../MasterLayout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap-v5"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import ActionButton from "../../shared/action-buttons/ActionButton"
import { fetchAllHsnCodes } from "../../store/action/hsnCodeAction"
import { connect, useDispatch } from "react-redux"
import ReactDataTable from "../../shared/table/ReactDataTable"
import CreateHsnCode from "./CreateHsnCode"
import EditHsnCode from "./EditHsnCode"
import DeleteHsnCode from "./DeleteHsnCode"
import { hsnCodeFormActionType } from "../../constants"
import HeaderTitle from "../header/HeaderTitle"

const HsnCodeList =(props) => {

    const {fetchAllHsnCodes,hsnCodes,isLoading} =props;

    const [formcode, setFormCode] = useState("M01");
    const [filterHsnCode,setFilterHsnCode] = useState([])

    const [editModel, setEditModel] = useState(false);
    const [hsnCode, setHsnCode] = useState();

    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(null);

    const [show, setShow] = useState(false);
    const handleCloseCreate = () => setShow(!show);

    const Dispatch =useDispatch();
    const handleClose = (item) => {
      Dispatch({
        type: hsnCodeFormActionType.FORM_CLOSE,
        payload: false,
      });
      setEditModel(!editModel);
      setHsnCode(item);
    };
      
      const onClickDeleteModel = (isDelete = null) => {
        setDeleteModel(!deleteModel);
        setIsDelete(isDelete);
      };


  const navigate =useNavigate()

  useEffect(() => {
    
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      console.log("Parsed Form Data:", parsedFormData);
      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility );
        console.log("Form Code Items:", formCodeItems);
        if(!formCodeItems.length > 0){
            navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    } 
  }, []);

  useEffect(() => {
    fetchAllHsnCodes() ;
  },[])

  useEffect(() => {
    setFilterHsnCode(hsnCodes);
  }, [hsnCodes]);

  const column = [
    
        {
            name: getFormattedMessage("globally.input.hsnCode.label"),
            selector: (row) => row.hsnCode,
            sortField: "hsnCode",
            sortable: true,
        },
        {
            name: getFormattedMessage("globally.input.description.label"),
            selector: (row) => row.description,
            sortField: "description",
            sortable: true,
        },
        {
          name: getFormattedMessage("globally.input.hsnUnit.label"),
          selector: (row) => row.hsnUnit,
          sortField: "hsnUnit",
          sortable: true,
      },
        {
            name: getFormattedMessage("react-data-table.action.column.label"),
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => (
              <ActionButton
                 item={row}
                goToEditProduct={handleClose}
                 isEditMode={true}
                 onClickDeleteModel={onClickDeleteModel}
              />
            ),
          },
    
  ]

  const handleSearchData = (e) => {
    const { name, value } = e.target;
    console.log("hi name", name);
    console.log("hi value", value);
    const filtered_hsnCodes =
      value.length > 0
        ? hsnCodes.filter((item) =>
            item?.attributes?.hsnCode
              ?.toLowerCase()
              ?.includes(value?.toLowerCase())
          )
        : hsnCodes;
        setFilterHsnCode(filtered_hsnCodes);
  };

  const itemsValue =filterHsnCode?.filter((item)=> item?.id != 1 && item != null).map((hsncode) => ({
    hsnCode:hsncode?.attributes?.hsnCode,
    description:hsncode?.attributes?.description,
    hsnUnit:hsncode?.attributes?.hsnUnit,
    id:hsncode?.id
    
}))  ;

const  onClick = () => {
    setShow(true)
}



    return(
        <div>
            <MasterLayout>
                <TopProgressBar/>
                <TabTitle title ={placeholderText("HsnCode.title")} />
                {/* <div>
                    <h3 className="text-light fw-bolder">List Of HSN Codes</h3>
                </div> */}
<HeaderTitle title={placeholderText('ListOfHsnCodes.title')}/>
<div className="row">
    <div className="col-md-5 mb-3 searchBox">
        <div className="position-relative d-flex width-320">

          <input
            className="form-control ps-8"
            type="search"
            name="searchData"
            id="search"
            placeholder={placeholderText(
              "react-data-table.searchbar.placeholder"
            )}
            aria-label="Search"
         onChange={(e) => handleSearchData(e)}
            autoComplete="off"
          />
          <span className="position-absolute d-flex align-items-center top-0 bottom-0 left-0 text-gray-600 ms-3">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
      </div>    

<div className="col-md-4"></div>
<div className="col-md-3  d-flex justify-content-end" >
        <Button type="button" variant="primary" className="crt_product" onClick={onClick} >
              Create HSN Code
        </Button>
</div>
</div>


            <div  >
                <ReactDataTable
                    columns={column}
                    items={itemsValue ? itemsValue : []}
                    isLoading={isLoading}
                    totalRows={itemsValue?.length}
                    isUnitFilter
                    subHeader={false}
                />
            </div>

{show ? <CreateHsnCode show={show} handleClose ={handleCloseCreate} /> : ""}

{editModel?
<EditHsnCode
        handleClose={handleClose}
        show={editModel}
        hsnCode={hsnCode}
      /> : "" 
}     

<DeleteHsnCode
        onClickDeleteModel={onClickDeleteModel}
        deleteModel={deleteModel}
        onDelete={isDelete}
      /> 


            </MasterLayout>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {hsnCodes,isLoading} = state;
    return {hsnCodes,isLoading}
}

export default connect(mapStateToProps,{fetchAllHsnCodes}) (HsnCodeList)