import React from "react";
import { connect } from "react-redux";
import { deletePrice, updatePriceList } from "../../store/action/priceListAction";
import DeleteModel from "../../shared/action-buttons/DeleteModel";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { deletePriceLevelItem } from "../../store/action/priceLvlListAction";

const DeletePriceLevel = (props) => {
  const { deletePrice, updatePriceList, onDelete, deleteModel, onClickDeleteModel, name, deletePriceLevelItem } =
    props;

  console.log("onDelete", onDelete);
  const deleteUserClick = () => {
    console.log("onDelete", onDelete);
    // if(Number(onDelete?.mrp) != 0){
    //   onDelete.salesPrice = 0;
    //   console.log("onDeleteChange", onDelete);
    // // updatePriceList(onDelete);
    // }else{
    deletePrice(onDelete.itemId, onDelete.mrp, onDelete.batchNo);
    deletePriceLevelItem(onDelete.itemId, onDelete.mrp, onDelete.batchNo);
    // }
    onClickDeleteModel(false);
  };

  return (
    <div>
      {deleteModel && (
        <DeleteModel
          onClickDeleteModel={onClickDeleteModel}
          deleteModel={deleteModel}
          deleteUserClick={deleteUserClick}
          name={name ?? getFormattedMessage("product.title")}
          componentName="PriceList"
        />
      )}
    </div>
  );
};

export default connect(null, { deletePrice, updatePriceList, deletePriceLevelItem })(DeletePriceLevel);
