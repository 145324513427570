import { useNavigate, useParams } from "react-router";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import MasterLayout from "../MasterLayout"
import { useEffect, useState } from "react";
import PosCustomerForm from "./PosCustomerForm";
import { connect } from "react-redux";
import { editCustomer, fetchCustomer, getZones } from "../../store/action/PosCustomerAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { companyDetailById } from "../../store/action/companyConfigAction"

const EditPosCustomer = (props) => {
  const { fetchCustomer, posCustomer, companyDetailById, getZones } = props;
  const { id, action } = useParams();
  console.log('testEditPos', posCustomer)
  const [formcode, setFormCode] = useState("M02");
  const navigate = useNavigate();
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      console.log("Parsed Form Data:", parsedFormData);
      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permisssionUpdate);
        console.log("Form Code Items:", formCodeItems);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
    if (id) {
      fetchCustomer(id);
      getZones();
      companyDetailById(1);
    }
  }, []);


  const itemsValue = posCustomer && posCustomer.length === 1 && posCustomer.map(posCustomer => ({
    customerName: posCustomer?.attributes?.ledgerName,
    salutation: posCustomer?.attributes?.salutation,
    customerCode: posCustomer?.attributes?.ledgerCode,
    address: posCustomer?.attributes?.address,

    area: posCustomer?.attributes?.area,
    city: posCustomer?.attributes?.city,
    state: posCustomer?.attributes?.state,
    location: posCustomer?.attributes?.location,
    gstNo: posCustomer?.attributes?.regNo,
    email: posCustomer?.attributes?.email,
    mobileNo: posCustomer?.attributes?.mobileNo1,
    entry: posCustomer?.attributes?.entryFrom,
    isActive: posCustomer?.attributes?.isActive,
    id: posCustomer?.id,
  }))
  console.log("itemsValue", itemsValue)

  return (
    <MasterLayout>
      <TopProgressBar />
      {/* <HeaderTitle title={getFormattedMessage( "customer.edit.title" )}  to='/app/posCustomer' /> */}
      <PosCustomerForm singleCustomer={itemsValue} id={id}
        title={getFormattedMessage("customer.edit.title")} to='/app/posCustomer' action={action}
      />
    </MasterLayout>
  )

}
const mapStateToProps = (state) => {
  const { posCustomer } = state;
  return { posCustomer }
}

export default connect(mapStateToProps, { fetchCustomer, editCustomer, companyDetailById, getZones })(EditPosCustomer)