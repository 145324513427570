import React from "react";
import { Modal } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import { getFormattedMessage, placeholderText } from "../sharedMethod";
import { useSelector } from "react-redux";

const FilterModelFooter = (props) => {
  const { resetFilter, onSubmit } = props;
  return (
    <div
      style={{
        textAlign: "center",
        padding: "0 1.875rem 1.875rem 1.875rem",
      }}
    >
      <button className="btn btn-success" onClick={onSubmit} style={{
        fontWeight: "700",
        borderRadius: "11px",
        width: "28%"
      }}>
        Apply
      </button>
      <button className="btn btn-danger mx-3" onClick={resetFilter} style={{
        fontWeight: "700",
        borderRadius: "11px",
        width: "28%"
      }}>
        Reset
      </button>
    </div>
  );
};
export default FilterModelFooter;
