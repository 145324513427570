import apiConfig from '../../config/apiConfig';
import {apiBaseURL,   EmployeeAadharActionType,  toastType} from '../../constants';
import {removeFromTotalRecord} from './totalRecordAction';
import {addToast} from './toastAction';
import {getFormattedMessage} from '../../shared/sharedMethod';

export const deleteEmployeeAadhar = (imageId) => async (dispatch) => {
    
    apiConfig.delete(apiBaseURL.DELETE_EMPLOYEE_AADHAR + '?empNo=' + imageId)
        .then((response) => {
            dispatch(removeFromTotalRecord(1));
            dispatch({type: EmployeeAadharActionType.DELETE_EMPLOYEE_AADHAR, payload: imageId});
            dispatch(addToast({text: response?.data?.message,type:toastType?.success}));
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response?.data?.message, type: toastType?.ERROR}));
        });
};
