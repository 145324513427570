import moment from "moment";

const groupedData = (data) => {
  
  const groupData = data?.reduce((acc, curr) => {
    const { customerRegno, invNo } = curr;
    if (!acc[customerRegno]) {
      acc[customerRegno] = {};
    }
    if (!acc[customerRegno][invNo]) {
      acc[customerRegno][invNo] = [];
    }
    acc[customerRegno][invNo]?.push(curr);
    return acc;
  }, {});

  // Transform the grouped object into an array format
  const result = Object.entries(groupData)?.map(([customerRegno, invoices]) => ({
    customerRegno,
    invoices: Object.entries(invoices)?.map(([invNo, records]) => ({
      invNo,
      records
    }))
  }));
  console.log(result);
  return result;
};

export const prepareGstArray = (data, gstin, filing) => {
  
  let gstArray = {
    "gstin": gstin,
    "fp": filing,
    "version": "GST3.2",
    "hash": "hash",
    "b2b": groupedData(data?.b2b).map((item) => {
      return {
        "ctin": item?.customerRegno,
        "inv": item?.invoices?.map((inv) => {
          return{
            "inum": inv?.invNo,
            "idt": moment(inv?.records[0]?.invDate).format("DD-MM-YYYY"),
            "val": parseFloat(inv?.records[0]?.netTotal).toFixed(2),
            "pos": "33",
            "rchrg": "N",
            "inv_typ": "R",
            "itms": inv?.records?.map((itm) => {
              return {
                "num": parseInt(itm?.rate + "01"),
                "itm_det": {
                  "txval": parseFloat(itm?.taxableValue).toFixed(2),
                  "rt": parseFloat(itm?.rate).toFixed(2),
                  "camt": parseFloat(itm?.taxAmount/2).toFixed(2),
                  "samt": parseFloat(itm?.taxAmount/2).toFixed(2),
                  "csamt": 0
                }
              }
            })
          }
        })
      };
    }),
    // "b2b": [
    //   {
    //     "ctin": "33AAFCR4024B1Z1",
    //     "inv": [
    //       {
    //         "inum": "1",
    //         "idt": "04-10-2022",
    //         "val": 1000,
    //         "pos": "33",
    //         "rchrg": "N",
    //         "inv_typ": "R",
    //         "itms": [
    //           {
    //             "num": 501,
    //             "itm_det": {
    //               "txval": 100,
    //               "rt": 5,
    //               "camt": 2.5,
    //               "samt": 2.5,
    //               "csamt": 0
    //             }
    //           },
    //           {
    //             "num": 1201,
    //             "itm_det": {
    //               "txval": 200,
    //               "rt": 12,
    //               "camt": 12,
    //               "samt": 12,
    //               "csamt": 0
    //             }
    //           },
    //           {
    //             "num": 1801,
    //             "itm_det": {
    //               "txval": 150,
    //               "rt": 18,
    //               "camt": 13.5,
    //               "samt": 13.5,
    //               "csamt": 0
    //             }
    //           }
    //         ]
    //       },
    //       {
    //         "inum": "2",
    //         "idt": "05-10-2024",
    //         "val": 2500,
    //         "pos": "33",
    //         "rchrg": "N",
    //         "inv_typ": "R",
    //         "itms": [
    //           {
    //             "num": 501,
    //             "itm_det": {
    //               "txval": 100,
    //               "rt": 5,
    //               "camt": 2.5,
    //               "samt": 2.5,
    //               "csamt": 0
    //             }
    //           },
    //           {
    //             "num": 1201,
    //             "itm_det": {
    //               "txval": 200,
    //               "rt": 12,
    //               "camt": 12,
    //               "samt": 12,
    //               "csamt": 0
    //             }
    //           },
    //           {
    //             "num": 1801,
    //             "itm_det": {
    //               "txval": 200,
    //               "rt": 18,
    //               "camt": 18,
    //               "samt": 18,
    //               "csamt": 0
    //             }
    //           }
    //         ]
    //       },
    //       {
    //         "inum": "3",
    //         "idt": "06-10-2024",
    //         "val": 5000,
    //         "pos": "33",
    //         "rchrg": "N",
    //         "inv_typ": "R",
    //         "itms": [
    //           {
    //             "num": 301,
    //             "itm_det": {
    //               "txval": 50,
    //               "rt": 3,
    //               "camt": 0.75,
    //               "samt": 0.75,
    //               "csamt": 0
    //             }
    //           },
    //           {
    //             "num": 501,
    //             "itm_det": {
    //               "txval": 100,
    //               "rt": 5,
    //               "camt": 2.5,
    //               "samt": 2.5,
    //               "csamt": 0
    //             }
    //           },
    //           {
    //             "num": 1201,
    //             "itm_det": {
    //               "txval": 200,
    //               "rt": 12,
    //               "camt": 12,
    //               "samt": 12,
    //               "csamt": 0
    //             }
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // ],
    "b2cs": data?.b2cs?.map((item) => {
      return {
        "sply_ty": "INTRA",
        "pos": "33",
        "typ": "OE",
        "txval": parseFloat(item?.taxableValue).toFixed(2),
        "rt": parseFloat(item?.rate).toFixed(2),
        "iamt": 0,
        "camt": parseFloat(item?.taxAmount/2).toFixed(2),
        "samt": parseFloat(item?.taxAmount/2).toFixed(2),
        "csamt": 0
      }
    }),
    // "b2cs": [
    //   {
    //     "sply_ty": "INTRA",
    //     "pos": "33",
    //     "typ": "OE",
    //     "txval": 1000,
    //     "rt": 5,
    //     "iamt": 0,
    //     "camt": 25,
    //     "samt": 25,
    //     "csamt": 0
    //   },
    //   {
    //     "sply_ty": "INTRA",
    //     "pos": "33",
    //     "typ": "OE",
    //     "txval": 5000,
    //     "rt": 12,
    //     "iamt": 0,
    //     "camt": 300,
    //     "samt": 300,
    //     "csamt": 0
    //   },
    //   {
    //     "sply_ty": "INTRA",
    //     "pos": "33",
    //     "typ": "OE",
    //     "txval": 3000,
    //     "rt": 18,
    //     "iamt": 0,
    //     "camt": 270,
    //     "samt": 270,
    //     "csamt": 0
    //   }
    // ],
    // "nil": {
    //   "inv": data?.nilData?.map((item) => {
    //     return {
    //       "sply_ty": item?.description == "intra state unregister person " ? "INTRAB2C" : item?.description == "intra state register person " ? "INTRAB2B" : item?.description == "inter state unregister person " ? "INTRB2C" : "INTRB2B",
    //       "expt_amt": 0,
    //       "nil_amt": parseFloat(item?.value).toFixed(2),
    //       "ngsup_amt": 0
    //     }
    //   })
    // },
    "nil": {
      "inv": [
        { "sply_ty": "INTRB2B", "expt_amt": 0, "nil_amt": data?.nilData?.filter((item) => item?.description == "inter state register person ")[0]?.value?.toFixed(2), "ngsup_amt": 0 },
        { "sply_ty": "INTRAB2B", "expt_amt": 0, "nil_amt": data?.nilData?.filter((item) => item?.description == "intra state register person ")[0]?.value?.toFixed(2), "ngsup_amt": 0 },
        { "sply_ty": "INTRB2C", "expt_amt": 0, "nil_amt": data?.nilData?.filter((item) => item?.description == "inter state unregister person ")[0]?.value?.toFixed(2), "ngsup_amt": 0 },
        { "sply_ty": "INTRAB2C", "expt_amt": 0, "nil_amt": data?.nilData?.filter((item) => item?.description == "intra state unregister person ")[0]?.value?.toFixed(2), "ngsup_amt": 0 },
      ]
    },
    "hsn": {
      "data": data?.hsn?.map((item, ind) => {
        return {
          "num": ind + 1,
          "hsn_sc": item?.hsn,
          "desc": item?.description,
          "uqc": item?.uqc?.split("-")[0],
          "qty": parseFloat(item?.totalQuantity).toFixed(2),
          "rt": parseFloat(item?.tax).toFixed(2),
          "txval": parseFloat(item?.taxableValue).toFixed(2),
          "iamt": parseFloat(item?.integratedTaxAmount).toFixed(2),
          "camt": parseFloat(item?.centralTaxAmount).toFixed(2),
          "samt": parseFloat(item?.stateTaxAmount).toFixed(2),
          "csamt": parseFloat(item?.cessAmount).toFixed(2)
        }
      }),
    }
    // "hsn": {
    //   "data": [
    //     {
    //       "num": 1,
    //       "hsn_sc": "8310",
    //       "desc": "SIGN-PLATES, NAME-PLATES, ADDRESS-PLATES AND SIMILAR PLATES, NUMBERS, LETTERS AND OTHER SYMBOLS, OF BASE METAL, EXCLUDING THOSE OF HEADING 9405",
    //       "uqc": "PAC",
    //       "qty": 80,
    //       "rt": 5,
    //       "txval": 500,
    //       "iamt": 0,
    //       "camt": 0,
    //       "samt": 0,
    //       "csamt": 0
    //     }
    //   ]
    // }
  }
  return gstArray;
}