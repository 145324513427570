import React, { useEffect } from "react";
import { Form } from "react-bootstrap-v5";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedMessage } from "../sharedMethod";

const ReactSelect = React.forwardRef((props, ref) => {
  const {
    title,
    placeholder,
    data,
    defaultValue,
    onChange,
    errors,
    value,
    isRequired,
    multiLanguageOption,
    isWarehouseDisable,
    addSearchItems,
    id,
    isOpen,
    isDisable,
    onBlur,
    onKeyDown,
    isSearchable = true,
  } = props;
  const dispatch = useDispatch();
  const isOptionDisabled = useSelector((state) => state.isOptionDisabled);

  const option = data
    ? data?.map((da) => {
      // console.log("da===>", da);
      return {
        value: da?.value
          ? da?.value
          : da?.category1Id
            ? da?.category1Id
            : da?.category2Id
              ? da?.category2Id
              : da?.category3Id
                ? da?.category3Id
                : da?.unitId
                  ? da?.unitId
                  : da?.taxId
                    ? da?.taxId
                    : da?.items_id
                      ? da?.items_id
                      : da?.id
                        ? da?.id
                        : da?.attributes?.acFrom
                          ? da?.attributes?.acFrom
                          : da?.businessTypeValue
                            ? da?.businessTypeValue
                            : da?.id
                              ? da?.id
                              : da?.zoneId
                              ? da?.attributes?.empName
                              : da?.attributes?.empName,
        label: da?.label
          ? da?.label
          : da?.attributes?.symbol
            ? da?.attributes?.symbol
            : da?.attributes?.name
              ? da?.attributes?.name
              : da?.attributes?.unitName
                ? da?.attributes?.unitName
                : da?.attributes?.taxName
                  ? da?.attributes?.taxName
                  : da?.attributes?.hsnCode
                    ? da?.attributes?.hsnCode
                    : da?.attributes?.acYear
                      ? da?.attributes?.acYear
                      : da?.attributes?.empId
                    ? da?.attributes?.empId
                      : da?.businessTypeLabel
                        ? da?.businessTypeLabel
                        : da?.attributes?.name,
          name:da?.name
          ? da?.empNo
          :da?.empNo,
                        
      };
    })
    : multiLanguageOption?.map((option) => {
      return {
        value: option.id,
        label: option.name,
      };
    });


  useEffect(() => {
    addSearchItems
      ? dispatch({ type: "DISABLE_OPTION", payload: true })
      : dispatch({ type: "DISABLE_OPTION", payload: false });
  }, []);

  return (
    <Form.Group className="form-group w-100" controlId="formBasic">
      {title ? <Form.Label>{title} </Form.Label> : ""}
      {isRequired ? <span className="required" /> : ""}
      <Select
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        options={option}
        id={id}
        ref={ref}
        onKeyDown={onKeyDown}
        menuIsOpen={isOpen}
        onBlur={onBlur}
        isSearchable={isSearchable}
        // onFocus={onChange}
        noOptionsMessage={() => getFormattedMessage("no-option.label")}
        isDisabled={isDisable ? true : isWarehouseDisable ? isOptionDisabled : false}
      />
      {errors ? (
        <span className="text-danger d-block fw-400 fs-small mt-2">
          {errors ? errors : null}
        </span>
      ) : null}
    </Form.Group>
  );
});
export default ReactSelect;
