import apiConfig from '../../config/apiConfig';
import {apiBaseURL,   EmployeeAadharActionType,  EmployeePanActionType,  toastType} from '../../constants';
import {removeFromTotalRecord} from './totalRecordAction';
import {addToast} from './toastAction';
import {getFormattedMessage} from '../../shared/sharedMethod';

export const deleteEmployeePan= (imageId) => async (dispatch) => {
    
    apiConfig.delete(apiBaseURL.DELETE_EMPLOYEE_PAN + '?empNo=' + imageId)
        .then((response) => {
            dispatch(removeFromTotalRecord(1));
            dispatch({type: EmployeePanActionType.DELETE_EMPLOYEE_PAN, payload: imageId});
            dispatch(addToast({text: response?.data?.message,type:toastType?.success}));
        })
        .catch(({response}) => {
            dispatch(addToast(
                {text: response?.data?.message, type: toastType?.ERROR}));
        });
};
