import { zoneActionType } from "../../constants";

export default (state = [], action) => {
    switch (action.type) {
        case zoneActionType.FETCH_ZONE:
            return action.payload;
        case zoneActionType.ADD_ZONE:
            return [...state, action.payload];
        case zoneActionType.DELETE_ZONE:
            return state.filter(item => item.id !== action.payload);
        default :
            return state;
}};