import {openingStockActionType} from '../../constants';

export default (state = [], action) => {
    switch (action?.type) {
        
        case openingStockActionType.FETCH_OPENING_STOCK:
            return action?.payload;
        case openingStockActionType.DELETE_OPENING_STOCK:
            return [action?.payload];
        default:
            return state;
    }
};
