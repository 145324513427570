import { orderSingleActionType } from '../../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case orderSingleActionType.FETCH_SINGLE_ORDER:
            return action.payload;
        default:
            return state;
    }
};
