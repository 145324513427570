import TopProgressBar from "../../../shared/components/loaders/TopProgressBar";
import MasterLayout from "../../MasterLayout";

function ProductSalesTab() {
    return(
        <MasterLayout>
            <TopProgressBar/>
            <div>
           <h3> Hiii  .. Maja  .....</h3>
        </div>
        </MasterLayout>
        
    )
}

export default ProductSalesTab;