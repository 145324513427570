import React from 'react';
import {connect} from 'react-redux';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import {useNavigate} from 'react-router-dom';
import {Filters} from '../../constants';
// import {addCustomer} from '../../store/action/customerAction';
import {getFormattedMessage} from '../../shared/sharedMethod';
import SalesOrderForm from './SalesOrderForm';

const CreateSalesOrder = (props) => {
    const {addCustomer} = props;
    const navigate = useNavigate();

    // const addCustomerData = (formValue) => {
    //     addCustomer(formValue, navigate, Filters.OBJ);
    // };

    return (
        <MasterLayout>
            {/* <HeaderTitle title={getFormattedMessage('Order Configuration')} to='/app/salesOrder'/> */}
            <SalesOrderForm />
        </MasterLayout> 
    )
};

export default connect(null, {})(CreateSalesOrder);
