import apiConfig from "../../config/apiConfig";
import { apiBaseURL, multipleSalesOrderActionType, toastType } from "../../constants";
import { addToast } from "./toastAction";
import { setLoading } from "./loadingAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setLoader } from "./loaderAction";

export const fetchMultipleSalesOrderNo =
    (customerId, statusName, isLoading = true) =>
        async (dispatch) => {
            
            dispatch(setLoader(true));
            if (isLoading) {
                dispatch(setLoading(true));
            }
            let url = apiBaseURL.GET_CUTOMER_ORDER + "?customerId=" + customerId + "&statusName=" + statusName;
            apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: multipleSalesOrderActionType.FETCH_MULTIPLE_SALESOREDER,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        dispatch(setLoader(false));
                    } else {
                        dispatch(setLoader(false));
                    }
                    if (isLoading) {
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };