import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addCustomer } from '../../store/action/customerAction';
import { connect, useDispatch } from 'react-redux';
import { addLedger } from '../../store/action/ledgerAction';
import { addToast } from '../../store/action/toastAction';
import { toastType } from '../../constants';

const CreateCustomersWholeSale = (props) => {
    const { show, handleClose, addLedger, setCustomer, companyConfig } = props;
    const dispatch = useDispatch();
    const [saltuation, setSaltuation] = useState("Mr");
    const [customer, setCustomerDetails] = useState({
        name: "",
        address: "",
        city: "",
        state: "",
        mobileNo: "",
        gstNo: "",
        email: ""
    });

    const handleCustomer = (e, key) => {
        const { value } = e.target;

        let customerData = { ...customer };
        if (key == "mobileNo") {
            if (value.length > 15) {
                return;
            }
        }
        customerData[key] = value;
        setCustomerDetails(customerData);
    }

    const close = () => {
        setCustomerDetails({
            name: "",
            address: "",
            city: "",
            state: "",
            mobileNo: "",
            gstNo: "",
            email: ""
        });
        handleClose();
    }

    const validation = () => {
        let isValid = true;
        if (customer.name == '') {
            isValid = false;
            dispatch(addToast({
                text: "Enter Customer Name",
                type: toastType.ERROR
            }))
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customer.email) && customer.email !== '') {
            isValid = false;
            dispatch(addToast({
                text: "Enter a valid Email Address",
                type: toastType.ERROR
            }));
        }
        return isValid;
    }

    const createCustomer = async () => {
        let customerdata = { ...customer };
        let data = {
            "id": 0,
            "salutation": saltuation,
            "ledgerName": customerdata.name,
            "altlanguage": "",
            "undergroup": "CUSTOMERS",
            "dob": null,
            "mobileno1": customerdata.mobileNo,
            "mobileno2": "",
            "regno": customerdata.gstNo,
            "email": customerdata.email,
            "address": customerdata.address,
            "area": "",
            "city": customerdata.city,
            "state": customerdata.state || customerdata.state != "" ? customerdata.state : "TamilNadu",
            "country": "",
            "location": ((companyConfig?.attributes?.state && companyConfig?.attributes?.state?.toLowerCase() == customerdata?.state?.toLowerCase()) || customerdata?.state == "") ? "Local State" : "Other State",
            "isactive": true,
            "remarks": "",
            "entryfrom": "Sales",
            "forSales": false,
            "searchonMap": false,
            "fullAddress": "",
            "zoneId": 0,
            "latitude": 0,
            "longitude": 0,
            "geoUrl": "",
            "radius": 0,
            "distance": 0,
            "code": ""
        }
        console.log(data);
        if (validation()) {
            const response = await addLedger(data, "", "salesOrder");
            console.log(response);
            if (response?.data?.success == true) {
                setCustomer(response?.data?.data);
                close();
            }
        }
    }
    const keyDown1 = (e, name) => {
        console.log(e.key);
        if (e.key === "ArrowDown" || e.key === "Enter" || e.key === "Tab") {
            e.preventDefault();
            if (name === "name") {
                document.getElementById('addressfield').focus();
                document.getElementById('addressfield').select();
            }
            if (name === "address") {
                document.getElementById('cityField').focus();
                document.getElementById('cityField').select();
            }
            if (name === "city") {
                document.getElementById('statefield').focus();
                document.getElementById('statefield').select();
            }
            if (name === "state") {
                document.getElementById('mobileNo').focus();
                document.getElementById('mobileNo').select();
            }
            if (name === "mobileNo") {
                document.getElementById('gstNo').focus();
                document.getElementById('gstNo').select();
            }
            if (name === "gstNo") {
                document.getElementById('emailfield').focus();
                document.getElementById('emailfield').select();
            }
            if (name === "email") {
                document.getElementById('confirm-button').focus();
                // document.getElementById('address').select();
            }
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            if(name === "email") {
                document.getElementById('gstNo').focus();
                document.getElementById('gstNo').select();
            }
            if (name === "gstNo") {
                document.getElementById('mobileNo').focus();
                document.getElementById('mobileNo').select();
            } if (name === "mobileNo") {
                document.getElementById('statefield').focus();
                document.getElementById('statefield').select();
            } if (name === "state") {
                document.getElementById('cityField').focus();
                document.getElementById('cityField').select();
            } if (name === "city") {
                document.getElementById('addressfield').focus();
                document.getElementById('addressfield').select();
            } if (name === "address") {
                document.getElementById('name').focus();
                document.getElementById('name').select();
            }
        }
    }

    const keyDown2 = (e, name) => {
        console.log(e.key);
        if (e.key === "ArrowRight") {
            e.preventDefault();
            if (name == "confirm-button") {
                document.getElementById('cancel-button').focus();
            }
        }
        if (e.key === "ArrowLeft") {
            e.preventDefault();
            if (name == "cancel-button") {
                document.getElementById('confirm-button').focus();
            }
        }
        if (e.key === "Enter") {
            e.preventDefault();
            if (name == "cancel-button") {
                close();
            } else if (name == "confirm-button") {

            }
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            if (name == "confirm-button") {
                document.getElementById('emailfield').focus();
                document.getElementById('emailfield').select();
            }
        }
    }

    const mobilKeydown = (e) => {
        if (['e', 'E', '+', '-', '.'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const handleWheel = (e) => {
        e.target.blur();
    };

    return (
        <Modal
            size="lg"
            show={show}
            onHide={close}
            aria-labelledby="example-modal-sizes-title-lg"
            className='customer-creation'
        >
            <Modal.Header className='px-2 py-4 customer-modal' closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className='' style={{ fontWeight: 'bold' }}>Customer Creation</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-20'>
                <Form className='row'>
                    {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <label style={{ fontStyle: 'italic' }}>Customer Name<span className='text-danger'> *</span></label>
                        <Form.Control type="text" placeholder="Customer Name" id="name" value={customer?.name} onChange={(e) => handleCustomer(e, "name")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "name")} autoFocus maxlength={100} />
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <label style={{ fontStyle: 'italic' }}>Customer Name<span className='text-danger'> *</span></label>
                        <InputGroup>
                            {/* <InputGroup.> */}
                            <Form.Control
                                as="select"
                                name="salutation"
                                // value={allFormData ? allFormData[0]?.salutation : ""}
                                onChange={(e) => { setSaltuation(e.target.value) }}
                                // style={{ color: "white", background: "#6571FF" }}
                                className="mb-2"
                            >
                                <option style={{ color: "black", background: "white" }}>
                                    Mr
                                </option>
                                <option style={{ color: "black", background: "white" }}>
                                    Mrs
                                </option>
                                <option style={{ color: "black", background: "white" }}>
                                    Ms
                                </option>
                            </Form.Control>
                            {/* </InputGroup.Prepend> */}
                            <Form.Control
                                type="text"
                                name="employeeName"
                                placeholder="Customer Name"
                                id="name"
                                // value={form.employeeName}
                                value={customer?.name}
                                onChange={(e) => handleCustomer(e, "name")}
                                autoComplete='off'
                                onKeyDown={(e) => keyDown1(e, "name")}
                                maxlength={100}
                                style={{ width: "87%" }}
                                autoFocus
                                // size='50'
                                className="mb-2"
                            />
                        </InputGroup>
                        {/* <Form.Control type="text" placeholder="Customer Name" id="name" value={customer?.name} onChange={(e) => handleCustomer(e, "name")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "name")} autoFocus maxlength={100} /> */}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="city">
                        <label style={{ fontStyle: 'italic' }}>Address</label>
                        <Form.Control type="text" placeholder="Customer Address" id="addressfield" value={customer?.address} onChange={(e) => handleCustomer(e, "address")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "address")} maxlength={100} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="city">
                        <label style={{ fontStyle: 'italic' }}>City</label>
                        <Form.Control type="text" placeholder="Customer City" id="cityField" value={customer?.city} onChange={(e) => handleCustomer(e, "city")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "city")} maxlength={50} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="city">
                        <label style={{ fontStyle: 'italic' }}>State</label>
                        <Form.Control type="text" placeholder="Customer State" id="statefield" value={customer?.state} onChange={(e) => handleCustomer(e, "state")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "state")} maxlength={50} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="mobile" >
                        <label style={{ fontStyle: 'italic' }}>Mobile No.</label>
                        <Form.Control type="number" placeholder="Mobile No." id="mobileNo" value={customer?.mobileNo} onChange={(e) => handleCustomer(e, "mobileNo")} onWheel={(e) => handleWheel(e)} onKeyDown={(e) => { keyDown1(e, "mobileNo"); mobilKeydown(e) }} max="15" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="gst" >
                        <label style={{ fontStyle: 'italic' }}>GST No.</label>
                        <Form.Control type="text" placeholder="GST No." id="gstNo" value={customer?.gstNo} onChange={(e) => handleCustomer(e, "gstNo")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "gstNo")} maxlength="15" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="mobile" >
                        <label style={{ fontStyle: 'italic' }}>Email</label>
                        <Form.Control type="email" placeholder="Email" id="emailfield" value={customer?.email} onChange={(e) => handleCustomer(e, "email")} onKeyDown={(e) => { keyDown1(e, "email")}} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" id='confirm-button' onKeyDown={(e) => keyDown2(e, "confirm-button")} onClick={createCustomer} style={{ borderRadius: '9px', fontWeight: '700', width: '14%' }}>
                    Save
                </Button>
                <Button variant="danger" onClick={close} id='cancel-button' onKeyDown={(e) => keyDown2(e, "cancel-button")} style={{ borderRadius: '9px', fontWeight: '700', width: '14%', background:'rgba(255, 0, 0, 1)' }}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { } = state;
    return {}
};

export default connect(mapStateToProps, { addLedger })(CreateCustomersWholeSale);