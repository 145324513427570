import { stockRegisterActionType } from "../../constants";

export default (state = [],action) => {
    switch(action.type) {
        case stockRegisterActionType.FETCH_STOCK_REGISTER:
            return action.payload
        case stockRegisterActionType.FETCH_STOCK_REGISTER_FILTER  :
            return action.payload;  
        default :
            return state
    }
}