import { Form, Modal } from "react-bootstrap-v5";
import { getFormattedMessage, placeholderText } from "../../../shared/sharedMethod";
import ReactSelect from "../../../shared/select/reactSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const ClosingStockField = ({ fieldShow, setShowField, handleFieldClose, Title, closingStocks, lowStock,nilStock,stockRegister,stockMovement }) => {
  const [fieldValue, setFieldValue] = useState({
    showCode: false,
    showItemCost: false,
    showStockValue: false,
    soldOnDays: false,
    inWard:false,
    outWard:false,
    closing:false
  });

  const showFieldCode = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  
  const showItemCost = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  
  const showStockValue = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  
  const soldOnDays = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const inWard = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  
  const outWard = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  
  const closing = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];


  const handleFieldChange = (field) => (selectedOption) => {
    if (selectedOption) {
      setFieldValue((prev) => ({
        ...prev,
        [field]: selectedOption.label, 
      }));
    } else {
      setFieldValue((prev) => ({
        ...prev,
        [field]: "No", 
      }));
    }
  };

  const closeButtonClick = () => {
    handleFieldClose();
  };

  const showClick = () => {
    setShowField({
      showCode: fieldValue.showCode === "Yes",
      showItemCost: fieldValue.showItemCost === "Yes",
      showStockValue: fieldValue.showStockValue === "Yes",
      soldOnDays: fieldValue.soldOnDays === "Yes",
      inWard: fieldValue.inWard === "Yes",
      outWard: fieldValue.outWard === "Yes",
      closing: fieldValue.closing === "Yes",
    });
    handleFieldClose();
  };

  const handleFieldCancel = () => {
    handleFieldClose();
  };

  return (
    <Modal show={fieldShow}>
      <Form>
        <Modal.Header>
          <Modal.Title>{Title}</Modal.Title>
          <button
            style={{ backgroundColor: "white", border: "none" }}
            onClick={closeButtonClick}
          >
            <FontAwesomeIcon
              icon={faXmark}
              className="fa-2x search-icon"
              style={{ height: "20px", width: "27px", color: "gray" }}
            />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {(closingStocks || lowStock || nilStock ) ? (
              <div className="col-md-12 mb-3">
                <ReactSelect
                  className="position-relative"
                  title={getFormattedMessage("globally.input.showcode.name")}
                  placeholder={placeholderText("globally.input.placeholder.showcode.name")}
                  data={showFieldCode}
                  value={showFieldCode?.find((option) => option.value === fieldValue.showCode)}
                  onChange={handleFieldChange('showCode')}
                />
              </div>
            ) : null}

            {closingStocks && (
              <>
                <div className="col-md-12 mb-3">
                  <ReactSelect
                    className="position-relative"
                    title={getFormattedMessage("globally.input.showItemCost.name")}
                    placeholder={placeholderText("globally.input.placeholder.showItemCost.name")}
                    data={showItemCost}
                    value={showItemCost?.find((option) => option.value === fieldValue.showItemCost)}
                    onChange={handleFieldChange('showItemCost')}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <ReactSelect
                    className="position-relative"
                    title={getFormattedMessage("globally.input.stockValue.name")}
                    placeholder={placeholderText("globally.input.placeholder.stockValue.name")}
                    data={showStockValue}
                    value={showStockValue?.find((option) => option.value === fieldValue.showStockValue)}
                    onChange={handleFieldChange('showStockValue')}
                  />
                </div>
              </>
            )}

            {lowStock && (
              <div className="col-md-12 mb-3">
                <ReactSelect
                  className="position-relative"
                  title={getFormattedMessage("globally.input.SoldOnDays")}
                  placeholder={placeholderText("globally.input.placeholder.soldOnDays.name")}
                  data={ lowStock?soldOnDays : ""}
                  value={lowStock?soldOnDays?.find((option) => option.value === fieldValue.soldOnDays) :""}
                  onChange={lowStock  ? handleFieldChange('soldOnDays') : ""}
                />
              </div>
            )}

{ (stockRegister || stockMovement) && (
<>
              <div className="col-md-12 mb-3">
                <ReactSelect
                  className="position-relative"
                  title={getFormattedMessage("globally.input.InWard")}
                  placeholder={placeholderText("globally.input.placeholder.InWard.name")}
                  data={ stockRegister || stockMovement ?inWard : ""}
                  value={stockRegister  || stockMovement ?inWard?.find((option) => option.value === fieldValue.inWard) :""}
                  onChange={stockRegister  || stockMovement  ? handleFieldChange('inWard') : ""}
                />
              </div>
              <div className="col-md-12 mb-3">
                <ReactSelect
                  className="position-relative"
                  title={getFormattedMessage("globally.input.OutWard")}
                  placeholder={placeholderText("globally.input.placeholder.OutWard.name")}
                  data={ stockRegister  || stockMovement  ?outWard : ""}
                  value={stockRegister   || stockMovement  ?outWard?.find((option) => option.value === fieldValue.outWard) :""}
                  onChange={stockRegister  || stockMovement  ? handleFieldChange('outWard') : ""}
                />
              </div>
              <div className="col-md-12 mb-3">
                <ReactSelect
                  className="position-relative"
                  title={getFormattedMessage("globally.input.Closing")}
                  placeholder={placeholderText("globally.input.placeholder.Closing.name")}
                  data={ stockRegister   || stockMovement  ?closing : ""}
                  value={stockRegister  || stockMovement  ?closing?.find((option) => option.value === fieldValue.closing) :""}
                  onChange={stockRegister  || stockMovement  ? handleFieldChange('closing') : ""}
                />
              </div>

</>
              
              
            )}

          </div>
        </Modal.Body>
      </Form>
      <div className="modal-footer " style={{textAlign:"center",marginBottom:"20px",display:"flex",gap:"20px",justifyContent:"center"}}>
      <button
          style={{
            width: "100px",
            height: "30px",
            border: "none",
            borderRadius: "10px",
            backgroundColor: "green",
            color: "white",
          }}
          onClick={showClick}
        >
          Submit
        </button>
        <button
          style={{
            width: "100px",
            height: "30px",
            border: "none",
            borderRadius: "10px",
            backgroundColor: "red",
            color: "white",
          }}
          onClick={handleFieldCancel}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ClosingStockField;
