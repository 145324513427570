import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { Button, InputGroup, Table } from 'react-bootstrap-v5';
import { searchPurchaseProduct } from '../../store/action/purchaseProductAction';
import { addPurchase, editPurchase } from '../../store/action/purchaseAction';
import { fetchAllProducts } from '../../store/action/productAction';
import { posAllProduct } from '../../store/action/pos/posAllProductAction';
import { preparePurchaseProductArray } from '../../shared/prepareArray/preparePurchaseArray';
import { getFormattedMessage, getFormattedOptions } from '../../shared/sharedMethod';
import { calculateCartTotalAmount, calculateCartTotalTaxAmount } from '../../shared/calculation/calculation';
import { addToast } from '../../store/action/toastAction';
import { saleStatusOptions, toastType, cartItem } from '../../constants';
import ProductCartList from '../../frontend/components/cart-product/ProductCartList';
import CartItemMainCalculation from '../../frontend/components/cart-product/CartItemMainCalculation';
import Select from "react-select";
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import ProductSearchbar from '../../frontend/components/product/ProductSearchbar';
import { addSale } from '../../store/action/salesAction';
import Loader from '../loader/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { fetchSalesInvNo } from '../../store/action/invoiceAction';
import MasterLayout from '../MasterLayout';
import CreateSalesCustomer from './CreateSalesCustomer';
import CreatePayment from './CreatePayment';
import { FaUpload, FaTrash } from "react-icons/fa";
import { addSalesOrder } from '../../store/action/salesOrderAction';
import { fetchCompanyConfig } from '../../store/action/companyConfigAction';
import { fetchSingleOrder } from '../../store/action/orderConfigAction';
import { fetchLedgers } from '../../store/action/ledgerAction';
import { fetchOrderStatus } from '../../store/action/orderStatusAction';
import { fetchSalesOrderNo } from '../../store/action/orderNoAction';
import { fetchPriceLevel } from "../../store/action/priceLevelListAction";
import { setLoader } from '../../store/action/loaderAction';
import WholeSaleForm from '../wholeSale/WholeSaleForm';
import { fetchChannels } from '../../store/action/channelAction';
import { fetchSalesOrderLog } from "../../store/action/salesOrderLog";
import ChangeStatus from './ChangeStatus';

function SalesItemForm(props) {
    const {
        addPurchaseData,
        id,
        editPurchase,
        customProducts,
        singlePurchase,
        warehouses,
        suppliers,
        ledger,
        fetchAllProducts,
        posAllProducts,
        addPurchase,
        addSalesOrder,
        fetchSalesInvNo,
        posAllProduct,
        singleSale,
        salesInvoice,
        companyConfig,
        fetchCompanyConfig,
        fetchOrderStatus,
        fetchSingleOrder,
        fetchSalesOrderNo,
        singleOrder,
        singleSalesOrder,
        products, frontSetting, allConfigData, sales, mode, salesOrderNo,
        fetchPriceLevel,
        fetchChannels,
        fetchSalesOrderLog,
        isView,
        salesOrderLog
    } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(1);
    const [customerShow, setCustomerShow] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [newCost, setNewCost] = useState('');
    const [newDiscount, setNewDiscount] = useState('');
    const [newTax, setNewTax] = useState('');
    const [newPurchaseUnit, setNewPurchaseUnit] = useState('');
    const [subTotal, setSubTotal] = useState('');
    const [updateProducts, setUpdateProducts] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState();
    const [quantity, setQuantity] = useState(0);
    const [purchase, setPurchase] = useState();
    const [backAlert, setBackAlert] = useState(false);
    const [priceLvlAlert, setPriceLvlAlert] = useState(false);
    const [files, setFiles] = useState([]);
    const [invNo, setInvNo] = useState(0);
    const [warehouseDef, setWarehouseDef] = useState({});
    const [priceLvlDef, setPriceLvlDef] = useState();
    const [paymentMethod, setPaymentMethod] = useState({ label: "CREDIT", value: "Credit" });
    const cartitems = useSelector((state) => state.cartItems);
    const cartcalc = useSelector((state) => state.cartValues);
    const channelData = useSelector(state => state.channel);
    const orderStatusData = useSelector(state => state.orderStatus);
    const priceLevel = useSelector((state) => state.priceLevel);
    const [orderStatusOption, setOrderStatusOption] = useState();
    const [orderStatus, setOrderStatus] = useState();
    const [userId, setUserId] = useState(null);
    const [newCustomer, setNewCustomer] = useState();
    const [channel, setChannel] = useState();
    const [isProcess, setIsProcess] = useState(false);
    const [show, setShow] = useState(false);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [purchaseValue, setPurchaseValue] = useState({
        date: singlePurchase ? moment(singlePurchase.date).toDate() : new Date(),
        warehouse_id: singlePurchase ? singlePurchase.warehouse_id : '',
        supplier_id: singlePurchase ? singlePurchase.supplier_id : '',
        tax_rate: singlePurchase ? singlePurchase.tax_rate.toFixed(2) : '0.00',
        tax_amount: singlePurchase ? singlePurchase.tax_amount.toFixed(2) : '0.00',
        discount: singlePurchase ? singlePurchase.discount.toFixed(2) : '0.00',
        shipping: singlePurchase ? singlePurchase.shipping.toFixed(2) : '0.00',
        grand_total: singlePurchase ? singlePurchase.grand_total : '0.00',
        notes: singlePurchase ? singlePurchase.notes : '',
        status_id: singlePurchase ? singlePurchase.status_id : { label: getFormattedMessage("status.filter.received.label"), value: 1 },
    });
    const [errors, setErrors] = useState({
        date: '',
        warehouse_id: '',
        supplier_id: '',
        details: '',
        tax_rate: '',
        discount: '',
        shipping: '',
        status_id: ''
    });

    const [formcode, setFormCode] = useState("T04");
    // const navigate = useNavigate();
    useEffect(() => {
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            console.log("Parsed Form Data:", parsedFormData);
            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
                console.log("Form Code Items:", formCodeItems);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);

    useEffect(() => {
        fetchSalesInvNo();
        fetchCompanyConfig();
        fetchSingleOrder(1, 1, 1);
        fetchOrderStatus(1, 1, 0);
        fetchPriceLevel();
        fetchChannels();
    }, []);

    const colorStyles = {
        control: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: orderStatusOption?.find((item) => item?.value == orderStatus)?.color ? orderStatusOption?.find((item) => item?.value == orderStatus)?.color : orderStatusOption?.find((obj) => obj?.value === orderStatusData?.filter(item => item?.attributes?.statusName?.toLowerCase() === 'open')[0]?.statusId)?.color,
                // borderColor: "transparent",
                color: "black",
            };
        },
        option: (styles, { data }) => {
            return {
                ...styles,
                // backgroundColor: data.color,
                // color: "black",
            };
        },
    };

    useEffect(() => {
        setOrderStatusOption(orderStatusData?.sort((a, b) => a.attributes.sequenceNo - b.attributes.sequenceNo)?.map((item) => {
            if (item.statusId == 1) {
                return { value: item.statusId, label: item.attributes.statusName, color: "rgb(115 115 115 / 50%)" }
            } else if (item.statusId == 2) {
                return { value: item.statusId, label: item.attributes.statusName, color: "rgb(7 255 213 / 50%)" }
            } else if (item.statusId == 3) {
                return { value: item.statusId, label: item.attributes.statusName, color: "rgb(40 167 69 / 50%)" }
            } else if (item.statusId == 4) {
                return { value: item.statusId, label: item.attributes.statusName, color: "rgb(255 0 24 / 65%)" }
            } else {
                return { value: item.statusId, label: item.attributes.statusName, color: "rgb(7 255 213 / 50%)" }
            }
        }))
    }, [orderStatusData]);

    useEffect(() => {
        console.log("SalesInvoice", salesInvoice);
        let purchaseData = { ...purchase };
        purchaseData["invoice_Number"] = salesInvoice?.attributes?.salesInvNo;
        setInvNo(salesInvoice?.attributes?.salesInvNo);
        setPurchase(purchaseData);
    }, [salesInvoice]);

    useEffect(() => {
        // console.log(warehouses?.map((warehouse) => ({ label: warehouse?.attributes?.warehouseName, value: warehouse?.id }))[0]);
        // console.log(warehouses[0]?.attributes?.warehouseName);
        setWarehouseDef(warehouses?.map((warehouse) => ({ label: warehouse?.attributes?.warehouseName, value: warehouse?.id }))[0]);
        if (warehouses) {
            // setWarehouseDef()
            // console.log
        }
    }, [warehouses]);

    useEffect(() => {
        console.log("SALES", sales);
        let purchaseData = { ...purchase };
        if (sales?.length > 0) {
            if (sales[sales?.length - 1]?.attributes && !singleSale?.attributes) {
                console.log(sales[sales?.length - 1]?.attributes?.invNo);
                let nextNumber = incrementNumber(sales[sales?.length - 1]?.attributes?.invNo);
                console.log(nextNumber);
                // purchaseData["invoice_Number"] = nextNumber;
                // setInvNo(nextNumber);
                // setPurchase(purchaseData);
            } else {

            }
        }
        setPurchase(purchaseData);
    }, [sales]);

    useEffect(() => {
        console.log("PURCHASE", purchase);
    }, [purchase]);

    useEffect(() => {
        console.log("WholeSale All Product", posAllProducts);
        console.log(cartitems);
        let CartData = [];
        // cartitems?.map((item) => {
        //     if (posAllProducts?.length > 0) {
        //         console.log("Filtered Product", item);
        //         console.log("Filtered Product", posAllProducts?.filter((product) => product?.items_id == item?.id && product?.attributes?.mrp == item?.mrp));
        //         let data = priceLvlCalculation(item, posAllProducts?.filter((product) => product?.items_id == item?.id && product?.attributes?.mrp == item?.mrp)[0]);
        //         CartData.push(data);
        //     }
        // });
        debugger;
        processCartItems();
    }, [posAllProducts]);

    const processCartItems = async () => {
        if (flag != 1) {
            setIsProcess(true); // Set the flag to true at the start
            dispatch(setLoader(true));
            let CartData = [];
            for (const item of cartitems || []) {
                if (posAllProducts?.length > 0) {
                    console.log("Filtered Product", item);
                    const filteredProduct = posAllProducts?.filter(
                        (product) =>
                            product?.items_id == item?.id &&
                            product?.attributes?.mrp == item?.mrp
                    )[0];
                    console.log("Filtered Product", filteredProduct);

                    // Await the calculation if it's asynchronous
                    let data = priceLvlCalculation(item, filteredProduct);
                    debugger;
                    CartData.push(data);
                }
            }
            setIsProcess(false); // Set the flag to false after all items are processed
            dispatch(setLoader(false));
            dispatch
            console.log("CartData", CartData);
            dispatch({ type: cartItem.CART_ITEMS, payload: CartData });
        }
    };

    const priceLvlCalculation = (item, product) => {
        let calc = {};
        debugger;
        if (item && product) {
            let totalQty = parseFloat(((item?.quantity ? parseFloat(item?.quantity).toFixed(item?.decimal ? item?.decimal : 0) : 0) * (product?.attributes?.pack_count ? product?.attributes?.pack_count : 0)) + (item?.salesQty ? parseFloat(item?.salesQty) : 0)).toFixed(item?.decimal ? item?.decimal : 0);
            calc['totalQty'] = totalQty;
            // let netRate = netRatevalue && netRatevalue != "" ? netRatevalue : 0;
            let netRate = parseFloat((product?.attributes?.salesPrice) * (1 + (product?.attributes?.tax / 100))).toFixed(2);
            calc['netRate'] = netRate;
            let basicRate = netRate / (1 + (item?.taxPercentage ? item?.taxPercentage : product?.attributes?.tax ? product?.attributes?.tax : 0) / 100);
            calc['basicRate'] = basicRate;
            let basic = (totalQty ? totalQty : 0) * (basicRate ? basicRate : 0);
            calc['basicAmount'] = basic;
            let discAmount = (basic) * (parseFloat(item?.discount != "" && !isNaN(item?.discount) ? item?.discount : 0) / 100);
            calc['discAmount'] = discAmount;
            let totalDiscAmount = (discAmount ? discAmount : 0) + parseFloat(item?.lessRs != "" && !isNaN(item?.lessRs) ? item?.lessRs : 0);
            calc['totalDiscAmount'] = totalDiscAmount;
            let grossAmount = (basic - totalDiscAmount);
            calc['grossAmount'] = grossAmount;
            let taxAmount = (grossAmount * ((product?.attributes?.tax ? product?.attributes?.tax : 0) / 100));
            calc['taxAmount'] = taxAmount;
            let netSalesRate = ((basic - totalDiscAmount) + taxAmount) / totalQty;
            calc['netSalesRate'] = netSalesRate;
            let netAmount = (grossAmount + taxAmount);
            calc['netAmount'] = netAmount;
        }
        console.log("PriceLVL Calculation", calc);

        let data = {
            "name": item?.name,
            "id": item?.id,
            "quantity": item?.quantity,
            "salesQty": item?.salesQty,
            "netSalesRate": calc?.netSalesRate,
            "netAmount": calc?.netAmount,
            "pack_count": item?.pack_count,
            "tax_amount": calc?.taxAmount,
            "taxAmount": calc?.taxAmount,
            "taxPercentage": item?.taxPercentage,
            "grossAmount": calc?.grossAmount,
            "calculation": calc,
            "unit": item?.unit,
            "sales_unit_name": item?.sales_unit_name,
            "purchase_unit_name": item?.purchase_unit_name,
            "stock": item?.stock,
            "code": item?.code,
            "purchaseRate": calc?.basicRate,
            "discount": item?.discount,
            "lessRs": item?.lessRs,
            "mrp": item?.mrp,
            "product_price": item?.mrp,
            "decimal": item?.decimal,
            "lineId": item?.lineId,
            "description": item?.description,
        }
        return data;
    }

    const incrementNumber = (str) => {
        // Extract the numeric part of the string between '#' and '/'
        let prefix = str.substring(0, str.indexOf('/'));
        let numberPart = prefix.replace('', '');

        // Convert the extracted part to a number and increment it
        let incrementedNumber = (parseInt(numberPart, 10) + 1).toString().padStart(3, '0');

        // Return the new string with the incremented number
        return `${incrementedNumber}${str.substring(str.indexOf('/'))}`;
    }

    const capitalizeFirstLetter = (string) => {
        let val = string.toLowerCase();
        console.log(val.charAt(0).toUpperCase() + val.slice(1));
        return val.charAt(0).toUpperCase() + val.slice(1);
    }

    useEffect(() => {
        let date = moment(new Date()).format('YYYY-MM-DD');
        let purchaseData = { ...purchase };
        purchaseData["Date"] = date;
        purchaseData["orderDate"] = date;
        setPurchase(purchaseData);
    }, []);

    useEffect(() => {
        let data = channelData?.filter((item) =>
            item?.channelId == 1
        );
        console.log("Channeldata", data[0]?.attributes?.channelDescription);
        setChannel(data[0]?.attributes?.channelDescription);
    }, [channelData]);

    useEffect(() => {
        console.log("singleSale", singleSale);
        let purchaseData = { ...purchase };
        if (singleSale?.attributes) {
            purchaseData["invoice_Number"] = singleSale?.attributes?.invNo;
            purchaseData["suppliername"] = singleSale?.attributes?.customerName;
            purchaseData["suppliermobile"] = singleSale?.attributes?.customerMobile;
            purchaseData["supplieraddress"] = singleSale?.attributes?.customerAddress;
            purchaseData["suppliergstin"] = singleSale?.attributes?.customerRegNo;
            purchaseData["payment_Type"] = singleSale?.attributes?.paymentType;
            purchaseData["bill_Amount"] = singleSale?.attributes?.billAmount;
            purchaseData["decimal"] = singleSale?.attributes?.decimalPoints;
            purchaseData["orderDate"] = moment(singleSale?.attributes?.invDate).format('YYYY-MM-DD');
            setPaymentMethod({ label: singleSale?.attributes?.paymentType?.toUpperCase(), value: capitalizeFirstLetter(singleSale?.attributes?.paymentType) });
            setInvNo(singleSale?.attributes?.invNo);
            let customer = ledger.filter((item) => item?.id === singleSale?.attributes?.customerId);
            purchaseData["supplier"] = { label: customer[0]?.attributes?.ledgerName, value: customer[0]?.id };
            purchaseData["supplierId"] = customer[0]?.id;
            let newProduct = {};
            let cartItems = singleSale?.attributes?.sales2?.map((items) => {
                return {
                    name: items?.itemName,
                    id: items?.itemId,
                    quantity: items?.pack_count > 1 ? 0 : items?.qty,
                    salesQty: items?.pack_count > 1 ? items?.qty : 0,
                    netSalesRate: items?.netSalesRate,
                    netAmount: items?.netAmount,
                    pack_count: items?.pack_count,
                    tax_amount: items?.taxAmount,
                    taxAmount: items?.taxAmount,
                    taxPercentage: items?.tax,
                    grossAmount: items?.grossAmount,
                    calculation: {
                        totalQty: items?.qty,
                        basicAmount: items?.basicAmount,
                        discAmount: items?.discAmount,
                        totalDiscAmount: items.totalDiscAmount,
                        grossAmount: items?.grossAmount,
                        taxAmount: items?.taxAmount,
                        rateWithTax: items?.rateWithTax,
                        netAmount: items?.netAmount
                    },
                    unit: items?.salesUnitName,
                    sales_unit_name: items?.salesUnitName,
                    purchase_unit_name: items?.purchaseUnitName,
                    // stock: product?.stock,
                    // code: product?.code,
                    purchaseRate: items?.rate,
                    discount: items?.discPercent,
                    lessRs: items?.lessAmount,
                    mrp: items?.mrp,
                    product_price: items?.mrp,
                    decimal: items?.decimalPoints,
                    lineId: items?.slNo,
                }
            });
            console.log("CARTITEMS", cartItems);

            dispatch({ type: cartItem.CART_ITEMS, payload: [...cartItems] });
            setPurchase(purchaseData);
        }
        // cartitems.push(newProduct);
    }, [singleSale]);

    useEffect(() => {
        console.log("CART ITEMS", cartitems);
    }, [cartitems]);

    useEffect(() => {
        if (newCustomer != undefined) {
            let purchaseData = { ...purchase };
            let supplier = ledger?.filter((ledger) => ledger.id == newCustomer?.id);

            purchaseData["supplier"] = { label: (supplier[0]?.attributes?.salutation != "" ? (supplier[0]?.attributes?.salutation + ".") : "") + supplier[0]?.attributes?.ledgerName, value: supplier[0]?.id };
            // purchaseData["supplierId"] = supplier[0]?.id;
            purchaseData["suppliername"] = supplier[0]?.attributes?.ledgerName;
            purchaseData["supplieraddress"] = supplier[0]?.attributes?.address + " " + supplier[0]?.attributes?.area + " " + supplier[0]?.attributes?.city + ".";
            purchaseData["city"] = supplier[0]?.attributes?.city;
            purchaseData["state"] = supplier[0]?.attributes?.state;
            purchaseData["suppliermobile"] = supplier[0]?.attributes?.mobileNo1;
            purchaseData["suppliergstin"] = supplier[0]?.attributes?.regNo;
            setPurchase(purchaseData);
        }
    }, [ledger]);

    useEffect(() => {
        let date = moment(new Date()).format('YYYY-MM-DD');
        let purchaseData = { ...purchase };
        purchaseData["Date"] = date;
        purchaseData["orderDate"] = date;
        setPurchase(purchaseData);
    }, []);

    useEffect(() => {
        posAllProduct();
        dispatch({ type: cartItem.CART_ITEMS, payload: [] });
        let data = localStorage.getItem("loginUserArray");
        console.log(JSON.parse(data)['id']);
        setUserId(JSON.parse(data)['id']);
    }, []);

    const handleCombo = (e) => {
        console.log(e);
        let purchaseData = { ...purchase };
        if (typeof (e) === "object") {
            let supplier = ledger?.filter((item) => item.id == e.value);

            purchaseData["supplier"] = e;
            // purchaseData["supplierId"] = supplier[0]?.id;
            purchaseData["suppliername"] = supplier[0]?.attributes?.ledgerName;
            purchaseData["supplieraddress"] = supplier[0]?.attributes?.address + (supplier[0]?.attributes?.area ? ", " + supplier[0]?.attributes?.area : "");
            purchaseData["city"] = supplier[0]?.attributes?.city;
            purchaseData["state"] = supplier[0]?.attributes?.state;
            purchaseData["suppliermobile"] = supplier[0]?.attributes?.mobileNo1;
            purchaseData["suppliergstin"] = supplier[0]?.attributes?.regNo;
            if (paymentMethod?.value === "Credit") {
                let supplier = ledger?.filter((item) => item.id == e.value);
                purchaseData["supplierId"] = supplier[0]?.id;
            }
            setPurchase(purchaseData);
            console.log(purchase)
        } else {
            purchaseData["suppliername"] = e;
            setPurchase(purchaseData);
            console.log(purchase)
        }
    };

    const handleCustomer = (e, key) => {
        const { value } = e.target;
        let purchaseData = { ...purchase };
        // if (paymentMethod.value != "Credit") {
        purchaseData[key] = value;
        setPurchase(purchaseData);
        // }
    }


    const handleValidation = () => {
        let errorss = {};
        let isValid = false;
        const qtyCart = updateProducts.filter((a) => a.quantity === 0);
        if (!purchaseValue.date) {
            error['date'] = getFormattedMessage('globally.date.validate.label');
        } else if (!purchaseValue.warehouse_id) {
            errorss['warehouse_id'] = getFormattedMessage('purchase.select.warehouse.validate.label')
        } else if (!purchaseValue.supplier_id) {
            errorss['supplier_id'] = getFormattedMessage('purchase.select.supplier.validate.label')
        } else if (qtyCart.length > 0) {
            dispatch(addToast({
                text: getFormattedMessage('globally.product-quantity.validate.message'),
                type: toastType.ERROR
            }))
        } else if (updateProducts.length < 1) {
            dispatch(addToast({
                text: getFormattedMessage('purchase.product-list.validate.message'),
                type: toastType.ERROR
            }))
        } else if (!purchaseValue.status_id) {
            errorss['status_id'] = getFormattedMessage('globally.status.validate.label')
        } else {
            isValid = true;
        }
        setErrors(errorss);
        return isValid;
    };

  
    const statusFilterOptions = getFormattedOptions(saleStatusOptions)
    const statusDefaultValue = statusFilterOptions.map((option) => {
        return {
            value: option.id,
            label: option.name
        }
    })

    const prepareData = (prepareData) => {
        const formValue = {
            date: moment(prepareData.date).toDate(),
            warehouse_id: prepareData.warehouse_id.value ? prepareData.warehouse_id.value : prepareData.warehouse_id,
            supplier_id: prepareData.supplier_id.value ? prepareData.supplier_id.value : prepareData.supplier_id,
            discount: prepareData.discount,
            tax_rate: prepareData.tax_rate,
            tax_amount: calculateCartTotalTaxAmount(updateProducts, purchaseValue),
            purchase_items: updateProducts,
            shipping: prepareData.shipping,
            grand_total: calculateCartTotalAmount(updateProducts, purchaseValue),
            received_amount: '',
            paid_amount: '',
            payment_type: 0,
            notes: prepareData.notes,
            reference_code: '',
            status: prepareData.status_id.value ? prepareData.status_id.value : prepareData.status_id,
        }
        return formValue
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const valid = handleValidation();
        if (valid) {
            if (singlePurchase) {
                editPurchase(id, prepareData(purchaseValue), navigate);
            } else {
                addPurchaseData(prepareData(purchaseValue));
                setPurchaseValue(purchaseValue);
            }
        }
    };

    const onBlurInput = (el) => {
        if (el.target.value === '') {
            if (el.target.name === 'shipping') {
                setPurchaseValue({ ...purchaseValue, shipping: '0.00' })
            }
            if (el.target.name === 'discount') {
                setPurchaseValue({ ...purchaseValue, discount: '0.00' })
            }
            if (el.target.name === 'tax_rate') {
                setPurchaseValue({ ...purchaseValue, tax_rate: '0.00' })
            }
        }
    }

    const validation = () => {
        let isValid = true;
        if (products['suppliername'] == '') {
            isValid = false;
            dispatch(addToast({
                text: "Enter Supplier Name",
                type: toastType.ERROR
            }))
        }
        return isValid;
    };

    useEffect(() => {
        console.log("cartcalc", cartcalc);
    }, [cartcalc]);

    useEffect(() => {
        if (singleOrder?.configId && companyConfig?.attributes?.companyId) {
            fetchSalesOrderNo(companyConfig?.attributes?.companyId, singleOrder?.configId);
        }
    }, [singleOrder, companyConfig]);

    useEffect(() => {
        if (mode != "Edit") {

            let purchaseData = { ...purchase };
            purchaseData["orderNo"] = salesOrderNo?.attributes?.salesOrderNo;
            setPurchase(purchaseData);
        }
    }, [salesOrderNo])

    const onSubmitPurchase = async () => {
        let valid = validation();
        if (cartitems.length > 0 && valid) {
            let salesOrder = {
                "txNo": singleSalesOrder ? singleSalesOrder?.txNo : 0,
                "configId": singleOrder?.configId ? singleOrder?.configId : 1,
                "companyId": companyConfig?.attributes?.companyId ? companyConfig?.attributes?.companyId : 1,
                // "channelId": singleOrder?.attributes?.channelId ? singleOrder?.attributes?.channelId : 1,
                "channelId": 1,
                "orderDate": purchase?.orderDate ? purchase?.orderDate : moment().format('YYYY-MM-DD'),
                "orderStatusId": orderStatus ? orderStatus : 1,
                "customerId": purchase?.supplierId ? purchase?.supplierId : purchase?.supplier?.value,
                "customerName": purchase?.suppliername ? purchase?.suppliername : "",
                "address": purchase?.supplieraddress ? purchase?.supplieraddress : "",
                "city": purchase?.city ? purchase?.city : "",
                "state": purchase?.state ? purchase?.state : "",
                "mobileNo": purchase?.suppliermobile ? purchase?.suppliermobile : "",
                "gstNo": purchase?.suppliergstin ? purchase?.suppliergstin : "",
                "orderSubTotal": cartitems?.reduce((a, b) => a + (b?.netAmount || 0), 0),
                "less": cartcalc[0]?.less && !isNaN(parseFloat(cartcalc[0].less)) ? cartcalc[0]?.less : 0.00,
                "roundOff": cartcalc[0]?.round ? cartcalc[0]?.round : 0.00,
                "orderValue": cartcalc[0]?.bill ? cartcalc[0]?.bill : 0.00,
                "advanceAmount": purchase?.advanceAmount ? purchase?.advanceAmount : 0.00,
                "remarks": "",
                "orderBy": userId,
                "updatedBy": userId,
                "salesOrderNo": "",
                "priceLevelNo": priceLvlDef?.value ? priceLvlDef?.value : 0,
                "salesOrder2": cartitems?.map((items, ind) => {
                    return {
                        "txNo": 0,
                        "slno": 1,
                        "itemId": items?.id,
                        "mrp": parseFloat(items?.mrp).toFixed(2),
                        "batchNo": "",
                        "qty": items?.calculation?.totalQty,
                        "rate": parseFloat(items?.purchaseRate).toFixed(2),
                        "basicAmount": parseFloat(items?.calculation?.basicAmount).toFixed(2),
                        "discPercent": parseFloat(items?.discount).toFixed(2),
                        "discAmount": parseFloat(items?.calculation?.discAmount).toFixed(2),
                        "lessAmount": parseFloat(items?.lessRs).toFixed(2),
                        "totalDiscAmount": parseFloat(items?.calculation?.totalDiscAmount).toFixed(2),
                        "grossAmount": parseFloat(items?.calculation?.grossAmount).toFixed(2),
                        "tax": parseFloat(items?.taxPercentage).toFixed(2),
                        "taxAmount": parseFloat(items?.calculation?.taxAmount).toFixed(2),
                        "rateWithTax": parseFloat(items?.calculation?.netRate ? items?.calculation?.netRate : items?.calculation?.rateWithTax).toFixed(2),
                        "netSalesRate": parseFloat(items?.netSalesRate).toFixed(2),
                        "netAmount": parseFloat(items?.calculation?.netAmount).toFixed(2),
                        "itemDesc": items?.description,
                        "soldQty": 1.9
                    }
                }),
                "salesOrder4": [
                    {
                        "txNo": 0,
                        "slno": 1,
                        "paymentType": paymentDetails?.paymentMode ? paymentDetails?.paymentMode : "",
                        "refNo": paymentDetails?.refNumber ? paymentDetails?.refNumber : "",
                        "refDate": paymentDetails?.paymentDate ? paymentDetails?.paymentDate : "",
                        "amount": 0,
                    }
                ],
                "salesOrder5": [
                    {
                        "txNo": 0,
                        "slno": 1,
                        "imageUrl": "",
                        "updatedAt": moment().format('YYYY-MM-DD'),
                    }
                ],
                "xMode": singleSalesOrder && mode == "Edit" ? "u" : "s"
            }
            addSalesOrder(salesOrder, true, files);
            // console.log(res);
            // window.location.reload();
            console.log("SALES ORDER", salesOrder);
        } else {
            dispatch(
                addToast({
                    text: 'Cart is empty!.',
                    type: toastType.ERROR,
                })
            );
        }
    }

    useEffect(() => {
        console.log("SINGLE SALE ORDER", singleSalesOrder);
        let purchaseData = { ...purchase };
        if (singleSalesOrder?.attributes && mode == "Edit") {
            fetchSalesOrderLog(singleSalesOrder?.txNo, 1);
            purchaseData["orderNo"] = singleSalesOrder?.attributes?.orderNo;
            purchaseData["orderDate"] = moment(singleSalesOrder?.attributes?.orderDate).format('YYYY-MM-DD');
            purchaseData["suppliername"] = singleSalesOrder?.attributes?.customerName;
            purchaseData["supplierId"] = singleSalesOrder?.attributes?.customerId;
            purchaseData["suppliermobile"] = singleSalesOrder?.attributes?.mobileNo;
            purchaseData["supplieraddress"] = singleSalesOrder?.attributes?.address;
            purchaseData["state"] = singleSalesOrder?.attributes?.state;
            purchaseData["city"] = singleSalesOrder?.attributes?.city;
            purchaseData["suppliergstin"] = singleSalesOrder?.attributes?.gstNo;
            // purchaseData["advanceAmount"] = singleSalesOrder?.attributes?.advanceAmount;
            // setPaymentMethod({ label: singleSalesOrder?.attributes?.paymentType?.toUpperCase(), value: capitalizeFirstLetter(singleSalesOrder?.attributes?.paymentType) });
            // setInvNo(singleSalesOrder?.attributes?.invNo);
            let customer = ledger.filter((item) => item?.id === singleSalesOrder?.attributes?.customerId);
            purchaseData["supplier"] = { label: (customer[0]?.attributes?.salutation != "" ? (customer[0]?.attributes?.salutation + ".") : "") + customer[0]?.attributes?.ledgerName, value: customer[0]?.id };
            let pricelvl = priceLevel.filter((item) => item?.pricelevelno === singleSalesOrder?.attributes?.priceLevelNo);
            if (pricelvl.length > 0) {
                setPriceLvlDef({ label: pricelvl[0]?.attributes?.pricelevelname, value: pricelvl[0]?.pricelevelno });
                // posAllProduct(pricelvl[0]?.pricelevelno);
            } else {
                setPriceLvlDef({ label: "Regular", value: 0 });
            }
            purchaseData["supplierId"] = customer[0]?.id;
            let newProduct = {};
            let cartItems = singleSalesOrder?.attributes?.salesOrder2?.map((items) => {
                return {
                    name: items?.itemName,
                    id: items?.itemId,
                    description: items?.itemDesc,
                    quantity: items?.pack_count > 1 ? 0 : items?.qty,
                    salesQty: items?.pack_count > 1 ? items?.qty : 0,
                    netSalesRate: items?.netSalesRate,
                    netAmount: items?.netAmount,
                    pack_count: items?.pack_count,
                    tax_amount: items?.taxAmount,
                    taxAmount: items?.taxAmount,
                    taxPercentage: items?.tax,
                    grossAmount: items?.grossAmount,
                    calculation: {
                        totalQty: items?.qty,
                        basicAmount: items?.basicAmount,
                        discAmount: items?.discAmount,
                        totalDiscAmount: items.totalDiscAmount,
                        grossAmount: items?.grossAmount,
                        taxAmount: items?.taxAmount,
                        rateWithTax: items?.rateWithTax,
                        netAmount: items?.netAmount
                    },
                    unit: items?.salesUnitName,
                    sales_unit_name: items?.salesUnitName,
                    purchase_unit_name: items?.purchaseUnitName,
                    // stock: product?.stock,
                    // code: product?.code,
                    purchaseRate: items?.rate,
                    discount: items?.discPercent,
                    lessRs: items?.lessAmount,
                    mrp: items?.mrp,
                    product_price: items?.mrp,
                    decimal: items?.decimalPoints,
                    lineId: items?.slNo,
                }
            });

            setPaymentDetails({
                paymentMode: singleSalesOrder?.attributes?.salesOrder4[0]?.paymentType,
                refNumber: singleSalesOrder?.attributes?.salesOrder4[0]?.refNo,
                paymentDate: singleSalesOrder?.attributes?.salesOrder4[0]?.refDate,
                orderValue: cartcalc[0]?.bill,
                receivedAmount: singleSalesOrder?.attributes?.salesOrder4[0]?.amount
            })
            console.log("CARTITEMS", cartItems);
            setOrderStatus(singleSalesOrder?.attributes?.orderStatusId);
            dispatch({ type: cartItem.CART_ITEMS, payload: [...cartItems] });
            setPurchase(purchaseData);
            debugger
            // const convertToFiles = async () => {
            //     try {
            //         const filePromises = singleSalesOrder?.attributes?.salesOrder5.map(async (item) => {
            //             const response = await fetch(item.imageUrl);
            //             const blob = await response.blob();
            //             // Create a File object using the blob
            //             const file = new File(
            //                 [blob],
            //                 `SalesOrder_${item.txNo}_${item.slNo}.jpg`, // Use custom file name
            //                 { type: blob.type }
            //             );
            //             return file;
            //         });

            //         const resolvedFiles = await Promise.all(filePromises);
            //         setFiles(resolvedFiles);
            //     } catch (error) {
            //         console.error("Error converting image URLs to files:", error);
            //     }
            // };

            // if (singleSalesOrder?.attributes?.salesOrder5 && singleSalesOrder?.attributes?.salesOrder5?.length > 0) {
            //     convertToFiles();
            // }
        }
    }, [singleSalesOrder]);

    const handleWheel = (e) => {
        e.target.blur();
    };

    const mobilKeydown = (e) => {
        if (['e', 'E', '+', '-', '.'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const keyDown1 = (e, name) => {
        console.log(e.key);
        if (e.key === "ArrowDown" || e.key === "Enter" || e.key === "Tab") {
            e.preventDefault();
            if (name === "invoiceDate") {
                document.getElementById('payment-method').getElementsByTagName("input")[0].focus();
                document.getElementById('payment-method').getElementsByTagName("input")[0].select();
            }
            if (name === "payment-method") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "warehouse") {
                // document.getElementById('supplier').focus();
                if (paymentMethod.value != "Credit") {
                    document.getElementById('supplier_input').focus();
                    document.getElementById('supplier_input').select();
                } else {
                    document.getElementById('supplier').getElementsByTagName("input")[0].focus();
                }
            }
            if (name === "supplier") {
                document.getElementById('city').focus();
                document.getElementById('city').select();
            }
            if (name === "supplier_input") {
                document.getElementById('city').focus();
                document.getElementById('city').select();
            }
            if (name === "city") {
                document.getElementById('gst').focus();
                document.getElementById('gst').select();
            }
            if (name === "gst") {
                document.getElementById('mobile').focus();
                document.getElementById('mobile').select();
            }
            if (name === "mobile") {
                // document.getElementById('btnSubmit').focus();
            }
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            if (name === "payment-method") {
                document.getElementById('invoiceDate').focus();
                document.getElementById('invoiceDate').select();
            }
            if (name === "warehouse") {
                document.getElementById('payment-method').getElementsByTagName("input")[0].focus();
                document.getElementById('payment-method').getElementsByTagName("input")[0].select();
            }
            if (name === "supplier") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "supplier_input") {
                document.getElementById('warehouse').getElementsByTagName("input")[0].focus();
                document.getElementById('warehouse').getElementsByTagName("input")[0].select();
            }
            if (name === "city") {
                if (paymentMethod.value != "Credit") {
                    document.getElementById('supplier_input').focus();
                    document.getElementById('supplier_input').select();
                } else {
                    document.getElementById('supplier').getElementsByTagName("input")[0].focus();
                    document.getElementById('supplier').getElementsByTagName("input")[0].select();
                }
            }
            if (name === "gst") {
                document.getElementById('city').focus();
                document.getElementById('city').select();
            }
            if (name === "mobile") {
                document.getElementById('gst').focus();
                document.getElementById('gst').select();
            }
        }
    };

    const onCancel = () => {
        setBackAlert(false);
        setPriceLvlAlert(false);
    }

    const cancelKey = (e) => {
        if (e.key == "ArrowLeft") {
            e.preventDefault();
            document.getElementById('confirm-button').focus();
        } else if (e.key == "Enter") {
            console.log(document.activeElement.id);
            onCancel();
        }
    }

    const paymentKeyPress = (e) => {
        if (e.key == "Enter") {
            document.getElementById('confirm-button').focus();
        } else if (e.key == "ArrowRight") {
            document.getElementById('cancel-button').focus();
        }
    }

    const setCustomer = (item) => {
        console.log("new customer", item);
        setNewCustomer(item);
    }

    const setPayment = (item) => {
        console.log("new payment", item);
        setPaymentDetails(item);
    }

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const validFiles = selectedFiles.filter(file => {
            const isValidType = file.type === "application/pdf" || file.type.startsWith("image/");
            const isValidSize = file.size <= 2 * 1024 * 1024; // 2 MB limit
            return isValidType && isValidSize;
        });

        // Update file list, keeping only valid files
        setFiles(prevFiles => [...prevFiles, ...validFiles]);
    };

    const handleRemoveFile = (index) => {
        setFiles(files => files.filter((_, i) => i !== index));
    };

    useEffect(() => {
        console.log("FILES", files);
    }, [files]);

    const handlePriceLevel = (e) => {
        setPriceLvlDef(e);
        setPriceLvlAlert(true);
        // posAllProduct(e.value);
    }

    const priceLevelSubmit = () => {
        if (priceLvlDef?.value == 0) {
            posAllProduct();
        } else {
            posAllProduct(priceLvlDef?.value);
        }
        setPriceLvlAlert(false);
        setFlag(flag + 1);
    };

    return (
        <>
            <Loader />
            <div className="d-md-flex align-items-center justify-content-between mb-5">
                <h1 className="mb-0 create-title">Sales Order {"[" + channel + "]"}</h1>
                <div className="text-end mt-4 mt-md-0">
                    {!isView && <Button className="btn btn-outline-primary me-2 save-btn" style={{ width: mode === "Edit" ? '50%' : '46%' }} onClick={onSubmitPurchase}>{mode === "Edit" ? "Update" : "Save"}</Button>}
                    {/* <Link to="/app/salesOrder" onClick={() => { () => backClick() }}> */}
                    <button className="btn btn-outline-primary back-btn" onClick={() => {
                        setBackAlert(true);
                    }}>
                        {getFormattedMessage("Back")}
                    </button>
                    {/* </Link> */}
                </div>
            </div>
            <div className='row wholeSaleForm' style={{ background: 'white', padding: '2%', borderRadius: '8px' }}>
                <div className='col-8 right-content purchase' style={{ borderRight: '2px solid deepskyblue', borderRadius: '8px' }}>
                    <div className=" items-container">
                        <ProductSearchbar
                            purchase={true}
                            mode={'sales'}
                            module={"salesOrder"}
                            singleOrder={singleOrder}
                        />
                    </div>
                    <div className='left-content custom-card p-3' style={{ background: 'white' }}>
                        {/* <div className="main-table overflow-auto" style={{ height: "40%", minHeight: 'calc(100vh - 470px)', maxHeight: '523px' }}> */}
                        <div className="main-table overflow-auto" style={{ height: "40%", minHeight: '55%', maxHeight: '523px' }}>
                            <Table className="mb-0">
                                <thead className="position-sticky top-0">
                                    <tr>
                                        <th style={{ padding: '0px 10px' }}>
                                            {getFormattedMessage(
                                                "pos-product.title"
                                            )}
                                        </th>
                                        {companyConfig?.attributes?.mrpWiseStock && <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-mrp.title"
                                            )}
                                        </th>}
                                        <th
                                            className={
                                                "text-end"
                                            }
                                        >
                                            {getFormattedMessage(
                                                "pos-qty.title"
                                            )}
                                        </th>
                                        <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-rate.title"
                                            )}
                                        </th>
                                        <th className="text-end">
                                            Disc
                                        </th>
                                        <th className="text-end">
                                            Tax%
                                        </th>
                                        <th className="text-end">
                                            N/P
                                        </th>
                                        <th className="text-end">
                                            {getFormattedMessage(
                                                "pos-amount.title"
                                            )}
                                        </th>
                                        <th className="text-end">

                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="border-0">
                                    {cartitems && cartitems.length ? (
                                        cartitems.map(
                                            (updateProduct, index) => {
                                                return (
                                                    <ProductCartList
                                                        singleProduct={
                                                            updateProduct
                                                        }
                                                        key={index + 1}
                                                        index={index}
                                                        posAllProducts={
                                                            posAllProducts
                                                        }
                                                        newCost={newCost}
                                                        allConfigData={
                                                            allConfigData
                                                        }
                                                        setUpdateProducts={
                                                            setUpdateProducts
                                                        }
                                                        purchase={true}
                                                        sales={true}
                                                        mode={'sales'}
                                                        module={"salesOrder"}
                                                        singleOrder={singleOrder}
                                                        companyConfig={companyConfig}
                                                    />
                                                );
                                            }
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={10}
                                                className="custom-text-center text-gray-900 fw-bold py-5"
                                            >
                                                {getFormattedMessage(
                                                    "sale.product.table.no-data.label"
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <hr />
                        <CartItemMainCalculation
                            cartItems={cartitems}
                            // singleSale={singleSale}
                            singleSalesOrder={singleSalesOrder}
                            purchase={true}
                            mode={'salesOrder'}
                        />
                    </div>
                </div>
                <div className='col-4' >
                    <div>
                        <Form.Label style={{ color: '#28A745', fontWeight: '600', fontSize: '18px' }}>Order Details:</Form.Label>
                        <Form className='row'>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="city">
                                <label style={{ fontStyle: 'italic' }}>Order No</label>
                                <Form.Control type="text" placeholder="Order No" disabled value={purchase?.orderNo} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="invoiceDate">
                                <label style={{ fontStyle: 'italic' }}>Order Date</label>
                                <Form.Control type="date" placeholder="Order Date" value={purchase?.orderDate ? purchase?.orderDate : moment(new Date()).format('YYYY-MM-DD')} onChange={(e) => handleCustomer(e, "orderDate")} onKeyDown={(e) => keyDown1(e, "invoiceDate")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="">
                                <label style={{ fontStyle: 'italic' }}>Order Status</label>
                                <Select
                                    className='orderStatus'
                                    errors={errors["sales_unit_id"]}
                                    value={orderStatusOption?.find((obj) => obj?.value === orderStatus) ? orderStatusOption?.find((obj) => obj?.value === orderStatus) : orderStatusOption?.find((obj) => obj?.value === orderStatusData?.filter(item => item?.attributes?.statusName?.toLowerCase() === 'open')[0]?.statusId)}
                                    // options={[{ label: 'CASH', value: "Cash" }, { label: 'CREDIT', value: "Credit" }, { label: 'CARD', value: "Card" }, { label: 'UPI', value: "Upi" }]}
                                    options={orderStatusOption}
                                    id="payment-method"
                                    onChange={(e) => setOrderStatus(e?.value)}
                                    onKeyDown={(e) => keyDown1(e, "payment-method")}
                                    styles={colorStyles}
                                    isDisabled={singleSalesOrder?.attributes && !isView ? false : true}
                                // isDisabled={isView}
                                // onFocus={() => handleShowChangeStatus()}
                                />
                                {/* <input
                                        type="text"
                                        // onChange={handleFileChange}
                                        id="orderStatus"
                                        value={orderStatusOption?.find((obj) => obj?.value === orderStatus) ? orderStatusOption?.find((obj) => obj?.value === orderStatus) : orderStatusOption?.find((obj) => obj?.value === orderStatusData?.filter(item => item?.attributes?.statusName?.toLowerCase() === 'open')[0]?.statusId)}
                                        style={colorStyles}
                                    /> */}
                            </Form.Group>
                            {singleOrder?.attributes?.imageUpload && <Form.Group className="mb-3 col-lg-6 col-md-12 mt-5" controlId="">
                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*,application/pdf"
                                        onChange={handleFileChange}
                                        id="file-input"
                                        style={{ display: "none" }} // Hide the default input
                                    />
                                    {/* Label with upload icon */}
                                    <label htmlFor="file-input" style={{ cursor: "pointer", marginRight: "10px" }}>
                                        <FaUpload size={24} color="#555" />
                                    </label>
                                    <ul style={{
                                        listStyleType: "none",
                                        maxHeight: "50px",
                                        overflowY: "auto",
                                        border: "1px solid #ddd",
                                        padding: "5px",
                                        width: "200px",
                                        margin: "0",
                                        border: 'none'
                                    }}>
                                        {files.map((file, index) => (
                                            <li key={index} style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                whiteSpace: "nowrap",
                                                width: "120px" // Set fixed width for even length
                                            }}>
                                                <span style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    width: "90px" // Fixed width for file name display
                                                }}>
                                                    {file.name}
                                                </span>
                                                <button onClick={() => handleRemoveFile(index)} style={{ border: "none", background: "none", cursor: "pointer", color: "red" }}>
                                                    <FaTrash />
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Form.Group>}
                        </Form>
                    </div>
                    <hr style={{ color: '#eaeaea', height: '1px', opacity: '0.75' }}></hr>
                    <div>
                        <Form.Label style={{ color: '#28A745', fontWeight: '600', fontSize: '18px' }}>Customer Details:</Form.Label>
                        <Form className='row'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <label style={{ fontStyle: 'italic' }}>Customer Name</label>
                                <InputGroup className="mb-3">
                                    <Select
                                        className="position-relative"
                                        errors={errors["sales_unit_id"]}
                                        options={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "customers" && item?.attributes?.isActive).map((data) => ({ label: (data?.attributes?.salutation != "" ? (data?.attributes?.salutation + ".") : "") + data?.attributes?.ledgerName, value: data?.id }))?.sort((a, b) => {
                                            if (a.label < b.label) return -1;
                                            if (a.label > b.label) return 1;
                                            return 0;
                                        })}
                                        id="supplier"
                                        onChange={(e) => handleCombo(e)}
                                        onKeyDown={(e) => keyDown1(e, "supplier")}
                                        value={purchase?.supplier ? purchase?.supplier : null}
                                        placeholder="Select Customers"
                                        autoFocus

                                    />
                                    <Button variant="outline-secondary" id="button-addon1" style={{ color: 'white', backgroundColor: 'rgb(40 167 69)' }} onClick={() => setCustomerShow(true)}>
                                        +
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="city">
                                <label style={{ fontStyle: 'italic' }}>Address</label>
                                <Form.Control type="text" value={purchase?.supplieraddress} onChange={(e) => handleCustomer(e, "supplieraddress")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "city")} disabled />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="city">
                                <label style={{ fontStyle: 'italic' }}>City</label>
                                <Form.Control type="text" value={purchase?.city} onChange={(e) => handleCustomer(e, "city")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "city")} disabled />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="city">
                                <label style={{ fontStyle: 'italic' }}>State</label>
                                <Form.Control type="text" value={purchase?.state} onChange={(e) => handleCustomer(e, "state")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "city")} disabled />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="mobile" >
                                <label style={{ fontStyle: 'italic' }}>Mobile No.</label>
                                <Form.Control type="number" value={purchase?.suppliermobile} onChange={(e) => handleCustomer(e, "suppliermobile")} onWheel={(e) => handleWheel(e)} onKeyDown={(e) => { keyDown1(e, "mobile"); mobilKeydown(e) }} disabled />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="gst" >
                                <label style={{ fontStyle: 'italic' }}>GST No.</label>
                                <Form.Control type="text" value={purchase?.suppliergstin} onChange={(e) => handleCustomer(e, "suppliergstin")} autoComplete='off' onKeyDown={(e) => keyDown1(e, "gst")} disabled />
                            </Form.Group>
                            {singleOrder?.attributes?.showPriceLevel && <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="mobile" >
                                <label style={{ fontStyle: 'italic' }}>Price Level</label>
                                <Select
                                    className="position-relative"
                                    value={priceLvlDef ? priceLvlDef : { label: "Regular", value: "0" }}
                                    options={[
                                        { label: "Regular", value: "0" }, // Default option
                                        ...priceLevel
                                            ?.filter((item) => item?.attributes?.isActive)
                                            ?.map((priceLvl) => ({
                                                label: priceLvl?.attributes?.pricelevelname,
                                                value: priceLvl?.pricelevelno,
                                            })),
                                    ]}
                                    errors={errors["sales_unit_id"]}
                                    id="pricelevel"
                                    onChange={(e) => handlePriceLevel(e)}
                                    onKeyDown={(e) => keyDown1(e, "pricelevel")}
                                />
                            </Form.Group>}
                            <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="" >
                            </Form.Group>
                            {/* <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="gst" >
                                {paymentDetails?.paymentMode && <span style={{ fontStyle: 'italic', color: 'blue' }}>Advance ₹{paymentDetails?.receivedAmount} Recieved by {paymentDetails?.paymentMode}</span>}
                            </Form.Group>
                            {singleOrder?.attributes?.showAdvance && <Form.Group className="mb-3 col-lg-6 col-md-12" controlId="gst" >
                                <button id='payment-button' type='button' className="btn btn-success" style={{ float: "inline-end" }}
                                    disabled={cartitems.length === 0} 
                                    onClick={() => setPaymentModal(true)}>Payment</button>
                            </Form.Group>} */}
                        </Form>
                    </div>
                </div>
            </div >
            <CreateSalesCustomer show={customerShow} handleClose={() => setCustomerShow(false)} setCustomer={setCustomer} companyConfig={companyConfig} channel={channel} />
            <CreatePayment show={paymentModal} handleClose={() => setPaymentModal(false)} setPayment={setPayment} cartCalc={cartcalc} purchase={purchase} editdata={singleSalesOrder} />
            {
                backAlert && (
                    <SweetAlert
                        confirmBtnBsStyle='success mb-3 fs-5 rounded'
                        cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
                        confirmBtnCssClass='alert_yes'
                        confirmBtnText={"Yes"}
                        cancelBtnText={"No"}
                        title={"Back to Sales Order Listing Page.?"}
                        showCancel
                        focusCancelBtn
                        customButtons={
                            <>
                                <button id="cancel-button" onClick={() => onCancel()} className="btn btn-secondary" onKeyDown={(e) => cancelKey(e)}>
                                    No
                                </button>

                                <Link to={'/app/salesorder'} onClick={() => {
                                    dispatch({ type: cartItem.CART_ITEMS, payload: [] });
                                }} style={{ marginRight: "5%" }}
                                    onKeyDown={(e) => paymentKeyPress(e)}>
                                    <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} >
                                        Yes
                                    </button>
                                </Link>
                            </>
                        }
                    // customIcon={remove} 
                    />
                )
            }
            {priceLvlAlert && (
                <SweetAlert
                    confirmBtnBsStyle='success mb-3 fs-5 rounded'
                    cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
                    confirmBtnCssClass='alert_yes'
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={`Set ${priceLvlDef?.label} Price for all items?`}
                    // onConfirm={onConfirm}
                    // onCancel={onCancel}
                    showCancel
                    focusCancelBtn
                    customButtons={
                        <>
                            <button id="cancel-button" onClick={() => onCancel()} className="btn btn-secondary" onKeyDown={(e) => cancelKey(e)}>
                                No
                            </button>

                            <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} onClick={(e) => priceLevelSubmit()}>
                                Yes
                            </button>
                        </>
                    }
                // customIcon={remove} 
                />
            )}
            {/* <ChangeStatus show={show} handleClose={() => setShow(false)} salesOrderLog={salesOrderLog} singleSalesOrder={singleSalesOrder} /> */}
            {/* <ChangeStatus show={show} handleClose={() => setShow(false)} /> */}
        </>
    )
}
const mapStateToProps = (state) => {
    const { purchaseProducts, products, frontSetting, allConfigData, posAllProducts, cartValues, sales, salesInvoice, companyConfig, singleOrder, singleSalesOrder, fetchOrderStatus, salesOrderNo, salesOrderLog } = state;
    return { customProducts: purchaseProducts, products, frontSetting, allConfigData, posAllProducts, cartValues, sales, salesInvoice, companyConfig, singleOrder, singleSalesOrder, fetchOrderStatus, salesOrderNo, salesOrderLog }
};

export default connect(mapStateToProps, { editPurchase, fetchAllProducts, searchPurchaseProduct, posAllProduct, addPurchase, addSalesOrder, fetchSalesInvNo, fetchCompanyConfig, fetchSingleOrder, fetchOrderStatus, fetchSalesOrderNo, fetchPriceLevel, fetchChannels, fetchSalesOrderLog })(SalesItemForm);