import React from 'react';

const FooterLogin = (props) => {
    return (
        <footer className='border-top w-100 pt-4 d-flex justify-content-between footer-top'>
            <p className='mx-2'>for any assistance, +91 8220 760 340 | support@vcefe.com</p>
            <p className='mx-2'>Copyrights (C) VCEFE Tech Company</p>
            <p className='mx-2'></p>
        </footer>
    )
};

export default FooterLogin;
