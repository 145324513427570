import { connect } from "react-redux";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { addHsnCode } from "../../store/action/hsnCodeAction";
import HsnCodeForm from "./HsnCodeForm";
import { Filters } from "../../constants";

const CreateHsnCode = (props) => {

    const {show ,handleClose,addHsnCode} = props;

    const addHsnCodeData = (formValue,handleClose,handleHsnClose) => {
        //addHsnCode(formValue, handleClose, Filters.OBJ);
    }

    return (
        <div>
            <HsnCodeForm show={show} handleClose={handleClose} title={getFormattedMessage('Hsncode.create.title')} 
                         addHsnCodeData={addHsnCodeData}/>
        </div>
    )
}

export default connect(null,{addHsnCode})(CreateHsnCode)