import DataTable from "react-data-table-component";
import EmptyComponent from "../../components/empty-component/EmptyComponent";
import { getFormattedMessage } from "../sharedMethod";
import { useEffect, useState } from "react";

const CommonTable = (props) => {
  const {
    items,
    columns,
    isLoading,
    paginationRowsPerPageOptions = [10, 15, 25, 50, 100],
    totalRows,
    rowsPerPage,
    priceHistoryList,
    pagechange,
    rowsChange,
    currentPage,
  } = props;

  const [rows, setRows] = useState(rowsPerPage);

  const paginationComponentOptions = {
    rowsPerPageText: getFormattedMessage(
      "react-data-table.records-per-page.label"
    ),
  };

  // Update the rows when rowsPerPage changes
  useEffect(() => {
    setRows(rowsPerPage);
  }, [rowsPerPage]);

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    pagechange(page);
    console.log("Current Page:", page); // Logs the current page number
  };

  const handleRows = (rows) => {
    rowsChange(rows);
  }

  return (
    <>
      <DataTable
        key={rows} // Force re-render when rows change
        columns={columns}
        data={items}
        noDataComponent={<EmptyComponent isLoading={isLoading} />}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        paginationPerPage={rows} // Set the current pagination size
        pagination
        paginationDefaultPage={currentPage}
        paginationComponentOptions={paginationComponentOptions}
        paginationTotalRows={totalRows}
        defaultSortFieldId={5}
        defaultSortAsc={priceHistoryList ? false : true}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRows}
      />
    </>
  );
};

export default CommonTable;
