import {
    faEye,
    faFileExcel,
    faFilePdf,
    faFilter,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";
import { connect, useDispatch } from "react-redux";
import { fetchAcYear } from "../../../store/action/acYearAction";
import { fetchGstSummaryReport } from "../../../store/action/gstSummaryReportAction";
import Loader from "../../loader/Loader";
import { generatePDF, printPDF } from "../pdfUtil";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import moment from "moment";
import { addToast } from '../../../store/action/toastAction';


const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const formattedDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${day}-${month}-${year}  ${hours}:${minutes}:${seconds}`;
  };
  
  const defaultValuePdf = formattedDate(new Date());

const GstSummaryTab = (props) => {
    const { stockMovement, acYear, fetchAcYear, fetchGstSummaryReport, gstSummary, fetchCompanyConfig, companyConfig } = props;
    const today = new Date();
    const formattedDate = formatDate(today);
    const numOfDaysAdded = 0;
    const date = today.setDate(today.getDate() + numOfDaysAdded);
    const defaultValue = new Date(date).toISOString().split("T")[0]; // YYYY-MM-dd

    const formatDecimal = (cell, formatterParams, onRendered) => {
        const value = cell.getValue();

        return value?.toFixed(2);
    };
    const dispatch = useDispatch();
    const [filterGst, setFilterGst] = useState([]);
    const [fieldShow, setFieldShow] = useState(false);
    const [showDate, setShowDate] = useState(false);
    const handleFieldClose = () => setFieldShow(!fieldShow);
    const [showField, setShowField] = useState();
    const [minYear, setMinYear] = useState();
    const [maxYear, setMaxYear] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);
    const year = useMemo(() => acYear, [acYear]);
    const [label, setLabel] = useState(false);
    const [fetchRangeValue, setFetchRangeValue] = useState({
        category1Name: "",
        category2Name: "",
        category3Name: "",
        unitName: ""
    });
    const paySlipRef = useRef(null);
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearRange = `${currentYear}-${nextYear}`;
    const [selectedYearRange, setSelectedYearRange] = useState({
        value: yearRange,
        label: yearRange,
    });
    const [totalData, setTotalData] = useState({});
    const [range, setRange] = useState({
        category1Name: "",
        category2Name: "",
        category3Name: "",
        unitName: "",
    });
    const [showDates, setShowDates] = useState();
    const [dates, setDate] = useState({
        fromDate: defaultValue,
        toDate: defaultValue,
    });

    const userRole = localStorage.getItem("loginUserArray");
  const role = JSON.parse(userRole);
  const roleName = role?.role;
  const userName = role?.user_name

    const columns = [
        { title: "Tax %", field: "taxPercentage", headerSort: false },
        { title: "Taxable Value", field: "taxvalue", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "CGST (₹)", field: "cgst", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "SGST (₹)", field: "sgst", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "IGST (₹)", field: "igst", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "Total Tax", field: "Total", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
        { title: "Total Value", field: "TotalValue", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
    ];

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [inputValue, setInputValue] = useState(0);
    const companyDetails = {
        companyName: companyConfig?.companyName || 'Company Name',
        address: `${companyConfig?.attributes?.address1 || ''} , \n${companyConfig?.attributes?.address2 || ''}`,
        phoneNumber: companyConfig?.attributes?.phoneNo || 'Phone Number'
    };

    const fromDate = useRef();
    const toDate = useRef();

    const loadValues = (filter) => {
        return "?fromDate=" + filter + "&toDate=" + filter;
    }

    useEffect(() => {
        // fetchAcYear();
        fetchCompanyConfig();
        fetchGstSummaryReport(loadValues(defaultValue));
    }, []);

    const onChange = (e) => {
        const { name, value } = e.target;
        setDate({ ...dates, [name]: value });
    }

    const fetchData = () => {
        let values = "?fromDate=" + dates?.fromDate + "&toDate=" + dates?.toDate;
        if (moment(dates?.fromDate).isAfter(dates?.toDate)) {
            dispatch(addToast({ text: 'Invalid date range.!', type: 'error' }));
        } else {
            fetchGstSummaryReport(values);
            setShowDate(true);
            setShowDates(dates);
        }
    }

    useEffect(() => {
        console.log("GST Summary Report =>", gstSummary);
        setFilterGst(gstSummary);
    }, [gstSummary]);

    const itemsValue = filterGst?.length > 0 &&
        filterGst?.map(data => ({
            taxPercentage: data?.taxname,
            taxvalue: parseFloat(data?.taxableValue).toFixed(2) || 0,
            cgst: parseFloat(data?.cgst).toFixed(2) || 0,
            sgst: parseFloat(data?.sgst).toFixed(2) || 0,
            igst: parseFloat(data?.igst).toFixed(2) || 0,
            Total: parseFloat(data?.cgst + data?.sgst + data?.igst).toFixed(2) || 0,
            TotalValue: parseFloat(data?.taxableValue + (data?.cgst + data?.sgst + data?.igst)).toFixed(2) || 0,
            type: data?.type
        })) || [];

    const handlePrintPDF = () => {
        printPDF(paySlipRef, companyDetails, 'Portrait', 'GstSummary',"GST Summary From :"+ `${moment(dates?.fromDate).format("DD-MM-YYYY")}`+ 
        "  To : " + `${moment(dates?.toDate).format("DD-MM-YYYY")}`, itemsValue,columns,
        "Printed By : " +userName
    );
    };

    const handleExportToPDF = () => {
        generatePDF(paySlipRef, companyDetails, 'Portrait', 'GstSummary',"GST Summary From :"+ `${moment(dates?.fromDate).format("DD-MM-YYYY")}`+ 
        "  To : " + `${moment(dates?.toDate).format("DD-MM-YYYY")}`,itemsValue, columns,
        "Printed By : " +userName
    )
            .then(pdf => {
                pdf.save('GstSummary Report.pdf');
            })
            .catch(error => {
                console.error('Error generating PDF:', error);
            });
    };
    return (
        <>
            <Loader />
            <div style={{ backgroundColor: "white", height: "100%" }}>
                <div className="row">
                    <div className="col-md-5">
                        <div className="row" style={{ marginTop: "-10px" }}>
                            <div className="col-md-4">
                                <h4 className="mt-2">From Date</h4>
                                <input
                                    id1="dateInput"
                                    type="date"
                                    className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
                                    ref={fromDate}
                                    defaultValue={defaultValue}
                                    name="fromDate"
                                    onChange={onChange}
                                ></input>
                            </div>
                            <div className="col-md-4 mt-2">
                                <h4>To Date</h4>
                                <input
                                    id2="dateRequired2"
                                    type="date"
                                    className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                                    ref={toDate}
                                    defaultValue={defaultValue}
                                    name="toDate"
                                    onChange={onChange}
                                    min={dates?.fromDate}
                                ></input>
                            </div>
                            <div className="col-md-4 gstrGenerateBtn">
                                <button
                                    type="button"
                                    className="btn btn-success "
                                    onClick={() => fetchData()}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5"></div>
                    <div className="col-md-2 ">
                        <button
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                borderRadius: "10px",
                                width: "220px",
                                height: "60px",
                                gap: "13px",
                                background: "white",
                                marginTop: "-20px",
                                fontSize: "11px",
                                marginLeft: "-20px"
                            }}
                            ref={paySlipRef}
                        >
                            <FontAwesomeIcon
                                icon={faPrint}
                                className="fa-2x search-icon"
                                style={{ color: "black", paddingTop: "8px" }}
                                onClick={handlePrintPDF}
                            ></FontAwesomeIcon>

                            <FontAwesomeIcon
                                icon={faFileExcel}
                                className="fa-2x search-icon "
                                style={{
                                    color: "green",
                                    paddingLeft: "10px",
                                    paddingTop: "7px",
                                }}
                            //   onClick={exportToExcel}
                            ></FontAwesomeIcon>

                            <FontAwesomeIcon
                                icon={faFilePdf}
                                className="fa-2x search-icon"
                                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                                onClick={handleExportToPDF}
                            ></FontAwesomeIcon>
                        </button>
                    </div>
                </div>
                <h2 style={{
                    // color: "green",
                    fontSize: "17px",
                    fontWeight: "700"
                }}>
                    {showDate ? `GST Summary From ${moment(showDates?.fromDate).format("DD-MM-YYYY")} To ${moment(showDates?.toDate).format("DD-MM-YYYY")}` : `GST Summary From ${moment(defaultValue).format("DD-MM-YYYY")} To ${moment(defaultValue).format("DD-MM-YYYY")}`}
                </h2>
                <div className="row">
                    <div className="col-md-9"></div>
                    <div
                        className="col-md-3"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "-50px",
                        }}
                    >
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 w-100 tabulator-container gstrTable">
                        <ReactTabulator
                            columns={columns}
                            data={itemsValue}
                            // ref={pdfRef}
                            options={{
                                groupBy: function (data) {
                                    return `<span style="color: black;font-size: 16px;">${data.type}</span>`;
                                },
                                groupHeader: function (value, count, data, group) {
                                    const totalValue = data.reduce((total, value) => {
                                        return total + parseFloat(value.taxvalue);
                                    }, 0);
                                    const totalCgst = data.reduce((total, value) => {
                                        return total + parseFloat(value.cgst);
                                    }, 0);

                                    const totalSgst = data.reduce((total, value) => {
                                        return total + parseFloat(value.sgst);
                                    }, 0);

                                    const totalIgst = data.reduce((total, value) => {
                                        return total + parseFloat(value.igst);
                                    }, 0);

                                    const total = data.reduce((total, value) => {
                                        return total + parseFloat(value.Total);
                                    }, 0);

                                    const totalVal = data.reduce((total, value) => {
                                        return total + parseFloat(value.TotalValue);
                                    })
                                    return value + `<div class="" style="font-size: 16px;display: flex;/* justify-content: space-between; */width: 100%;margin-top: -20px;">
                                        <div class="gstrHead" style="width: 14.2857142857%;"></div>
                                        <div style="width: 14.2857142857%;/* float: inline-end; */ text-align: end; padding-right: 1%;" class="">${parseFloat(totalValue).toFixed(2)}</div>
                                        <div style="width: 14.2857142857%; text-align: end;padding-right: 1%;">${parseFloat(totalCgst).toFixed(2)}</div>
                                        <div style="width: 14.2857142857%; text-align: end;padding-right: 9px;">${parseFloat(totalSgst).toFixed(2)}</div>
                                        <div style="width: 14.2857142857%; text-align: end;padding-right: 8px;">${parseFloat(totalIgst).toFixed(2)}</div>
                                        <div style="width: 14.2857142857%; text-align: end;padding-right: 9px;">${parseFloat(total).toFixed(2)}</div>
                                        <div style="width: 14.2857142857%; text-align: end;">${parseFloat(totalValue + total).toFixed(2)}</div></div>`
                                },
                                // groupToggleElement: false,
                                columnHeaderVertAlign: "bottom",
                                layout: 'fitColumns',
                                placeholder: "No records found",
                                height: "420px",
                                // footerElement: `<div style='width:100%;text-align: left; padding: 10px; border: 1px solid rgb(99, 166, 77); border-radius: 5px; display: flex; justify-content: space-between; align-items: center;'>
                                //         <div style='padding-left: 10px;'>Total</div>
                                //         <div style='padding-right: 10px;'>
                                //         ${parseFloat(totalData?.totalValue).toFixed(2)}
                                //         </div>
                                // </div>`
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { stockMovement, acYear, gstSummary, companyConfig } = state;
    return { stockMovement, acYear, gstSummary, companyConfig }
}

export default connect(mapStateToProps, { fetchAcYear, fetchGstSummaryReport, fetchCompanyConfig })(GstSummaryTab);
