import apiConfig from "../../config/apiConfig";
import { apiBaseURL, orderConfigActionType, orderSingleActionType, priceLevelActionType, toastType } from "../../constants";
import { addToast } from "./toastAction";
import { setLoading } from "./loadingAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setLoader } from "./loaderAction";

export const fetchPriceLevel =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            
            dispatch(setLoader(true));
            if (isLoading) {
                dispatch(setLoading(true));
            }
            let url = apiBaseURL.PRICE_LEVEL;
            apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: priceLevelActionType.FETCH_PRICE_LEVEL,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        dispatch(setLoader(false));
                    } else {
                        dispatch(setLoader(false));
                    }
                    if (isLoading) {
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

export const addPriceLevelName = (data) => async (dispatch) => {
    // dispatch(setSavingButton(true));
    try {
        const response = await apiConfig.post(apiBaseURL.PRICE_LEVEL, data);
        
        dispatch({
            type: priceLevelActionType.ADD_PRICE_LEVEL,
            payload: response?.data?.data,
        });

        if (response?.data?.success === true) {
            dispatch(
                addToast({
                    text: getFormattedMessage("pricelevel.success.create.message"),
                })
            );
            dispatch(fetchPriceLevel());
        } else {
            dispatch(
                addToast({ text: response?.data?.message, type: toastType.ERROR })
            );
            dispatch(fetchPriceLevel());
        }
        return response;  // Return the response
    } catch (error) {
        // dispatch(setSavingButton(false));
        dispatch(
            addToast({ text: error.response?.data?.message, type: toastType.ERROR })
        );
        // dispatch(fetchOrderStatus());
        throw error;  // Re-throw the error so it can be caught in the calling function
    }
}

export const fetchPriceLevelListByFilter =
  (
    isLoading = true,
    filtered_brand,
    filtered_group,
    filtered_category,
    product_name,
    showFilterModal
  ) =>
    async (dispatch) => {
      console.log("Price List Action :: fetchPriceListByFilter");
      if (isLoading) {
        dispatch(setLoading(true));
      }
      const url =
        apiBaseURL.GET_ALL_PRICE_LEVEL_LIST +
        "?category1=" +
        filtered_brand +
        "&category2=" +
        filtered_category +
        "&category3=" +
        filtered_group +
        "&itemName=" +
        product_name;
      await apiConfig
        .get(url)
        .then((response) => {
          console.log("Price List Action response Filter ::", response);
          dispatch({
            type: priceLevelActionType.FETCH_PRICE_LEVEL,
            payload: response?.data?.data,
          });
          showFilterModal(false);
          if (isLoading) {
            dispatch(setLoading(false));
          }
        })
        .catch(({ response }) => {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
        });
    };