import React, { useCallback, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { getFormattedMessage } from "../sharedMethod";
import remove from "../../assets/images/toggle-active.png";

const ActiveModel = (props) => {
    const { onClickDeleteModel, deleteUserClick, name, payroll, componentName } = props;

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            onClickDeleteModel(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return (
        <SweetAlert
            custom
            confirmBtnBsStyle="danger mb-3 fs-5 rounded"
            cancelBtnBsStyle="secondary mb-3 fs-5 rounded text-white"
            confirmBtnText={getFormattedMessage("delete-modal.yes-btn")}
            cancelBtnText={getFormattedMessage("delete-modal.no-btn")}
            title={getFormattedMessage("activate-modal.title")}
            onConfirm={deleteUserClick}
            onCancel={onClickDeleteModel}
            showCancel
            focusCancelBtn
            customIcon={remove}
        >
            {payroll ?
                <span className="sweet-text">
                    {getFormattedMessage("delete-modal.msg.payroll")} {name} ?
                </span>
                :
                componentName == "PriceList" ? <span className="sweet-text">
                    {getFormattedMessage("delete-modal.msg.pricelist")}
                </span> : <span className="sweet-text">{getFormattedMessage("activate-modal.msg")} {name} ?</span>
            }
        </SweetAlert>
    );
};
export default ActiveModel;
