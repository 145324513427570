import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export const generatePDF = (
  paySlipRef,
  companyDetails,
  orientation,
  title,
  filter,
  userName,
  tableRef
) => {
  const { companyName, address, phoneNumber } = companyDetails;
  const isLandscape = orientation === "Landscape";

  // Format the date
  const formattedDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  const defaultValuePdf = formattedDate(new Date());

  // Create a new jsPDF instance
  const pdf = new jsPDF({
    orientation: isLandscape ? "landscape" : "portrait",
    unit: "mm",
    format: "a4",
  });

  const pdfWidth = pdf.internal.pageSize.getWidth();
  const topMargin = 20;
  const textSpacing = 3;

  // Add company and report details
  pdf.setFontSize(13);
  pdf.text(companyName, pdfWidth / 2, topMargin, { align: "center" });
  pdf.setFontSize(10);
  pdf.text(address, pdfWidth / 2, topMargin + textSpacing + 5, { align: "center" });
  pdf.text(`Phone: ${phoneNumber}`, pdfWidth / 2, topMargin + 3 * textSpacing + 10, {
    align: "center",
  });

  // Draw a line to separate header and content
  pdf.setLineWidth(0.2);
  pdf.line(10, topMargin + 3 * textSpacing + 15, pdfWidth - 10, topMargin + 3 * textSpacing + 15);

  // Add the report title
  const reportTitle = `${title} \n\n ${filter}`;
  pdf.setFontSize(12);
  pdf.text(reportTitle, 10, topMargin + 4 * textSpacing + 20);

  // Extract data from React Tabulator using `tableRef`
  if (tableRef.current) {
    const tableData = tableRef.current.getData();
    const tableColumns = tableRef.current.getColumns();

    // Prepare headers and data for jsPDF-autotable
    const headers = tableColumns.map((col) => col.getField());
    const data = tableData.map((row) => headers.map((header) => row[header]));

    // Render the table in the PDF using jsPDF-autotable
    pdf.autoTable({
      head: [headers],
      body: data,
      startY: topMargin + 10 * textSpacing + 25,
      theme: "grid",
      styles: {
        fontSize: 10,
        cellPadding: 2,
        halign: "center",
        valign: "middle",
      },
      headStyles: {
        fillColor: [13, 110, 253],
        textColor: [255, 255, 255],
      },
    });
  }

  // Add footer with printed information
  pdf.setFontSize(10);
  pdf.text(`Printed By: ${userName} on ${defaultValuePdf}`, 10, pdf.internal.pageSize.height - 10);

  return pdf;
};


export const printPDF = (
  paySlipRef,
  companyDetails,
  orientation,
  title,
  filter,
  userName,
  tableRef
) => {
  const pdf = generatePDF(
    paySlipRef,
    companyDetails,
    orientation,
    title,
    filter,
    userName,
    tableRef
  );

  // Open the PDF in a new window for printing
  const pdfBlob = pdf.output("blob");
  const pdfUrl = URL.createObjectURL(pdfBlob);
  const iframe = document.createElement("iframe");

  iframe.style.position = "absolute";
  iframe.style.width = "0";
  iframe.style.height = "0";
  iframe.style.border = "none";
  document.body.appendChild(iframe);

  iframe.onload = () => {
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  };

  iframe.src = pdfUrl;
};


// EXCEL ---------

// Excel generation for Closing Stock Report
export const generateClosingStockReportExcel = (
  companyDetails,
  reportDetails,
  itemsValue
) => {
  const { companyName, address, phoneNumber } = companyDetails;
  const { title, dateRange } = reportDetails;

  const workbook = XLSX.utils.book_new();
  let headers = [];

  // Determine the headers based on the report type
  if (title.includes("Closing Stock Report")) {
    headers = ["Code", "Product Name", "Stock", "Unit Name", "Cost", "Value"];
  } else if (title.includes("Nil Stock Report")) {
    headers = ["Code", "Product Name", "MRP", "Stock", "Unit Name"];
  } else if (title.includes("Low Stock Report")) {
    headers = [
      "Code",
      "Product Name",
      "Stock Alert",
      "Unit Name",
      "Stock Alert",
      "Unit Name",
      "Value",
    ];
  } else if (title.includes("Stock Movement Report")) {
    headers = [
      "Code",
      "Product Name",
      "Mrp",
      "Openings",
      "Unit Name",
      "InWard",
      "Unit Name",
      "OutWard",
      "Unit Name",
      "Closing",
    ];
  } else if (title.includes("Stock Register Report")) {
    headers = [
      "Date",
      "Description",
      "Ref Type",
      "Ref No",
      "InWard",
      "Unit Name",
      "OutWard",
      "Unit Name",
      "Closing",
    ];
  }

  // Create initial worksheet content with headers
  const worksheet = XLSX.utils.aoa_to_sheet([
    [companyName],
    [address],
    [phoneNumber],
    [],
    [`${title}`],
    [],
    headers, // Add the headers array here
  ]);

  // Add rows to worksheet based on report type
  itemsValue.forEach((item) => {
    if (title.includes("Closing Stock Report")) {
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            item.code,
            item.productName,
            item.stock,
            item.unitName,
            item.cost,
            item.value,
          ],
        ],
        { origin: -1 }
      );
    } else if (title.includes("Nil Stock Report")) {
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[item.code, item.productName, item.mrp, item.stock, item.unitName]],
        { origin: -1 }
      );
    } else if (title.includes("Low Stock Report")) {
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            item.code,
            item.productName,
            item.stockAlert,
            item.unitName,
            item.stock,
            item.unitName,
            item.value,
          ],
        ],
        { origin: -1 }
      );
    } else if (title.includes("Stock Movement Report")) {
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            item.code,
            item.productName,
            item.mrp,
            item.openings,
            item.unitName,
            item.inWard,
            item.unitName,
            item.outWard,
            item.unitName,
            item.closing,
          ],
        ],
        { origin: -1 }
      );
    } else if (title.includes("Stock Register Report")) {
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            item.date,
            item.description,
            item.refType,
            item.refNo,
            item.inWard,
            item.unitName,
            item.outWard,
            item.unitName,
            item.closing,
          ],
        ],
        { origin: -1 }
      );
    }
  });

  // Center align company details and title
  ["A1", "A2", "A3", "A5"].forEach((cell) => {
    if (worksheet[cell]) {
      worksheet[cell].s = { alignment: { horizontal: "center" } };
    }
  });

  // Calculate total value if needed
  if (title.includes("Closing Stock Report")) {
    const totalValue = itemsValue
      .reduce((acc, curr) => acc + (curr.value ? parseFloat(curr.value) : 0), 0)
      .toFixed(2);
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["", "", "", "", "Total", totalValue]],
      { origin: -1 }
    );
  } else if (title.includes("Nil Stock Report")) {
    const totalStock = itemsValue
      .reduce((acc, curr) => acc + (curr.stock ? parseFloat(curr.stock) : 0), 0)
      .toFixed(2);
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["", "", "", "Total Stock", totalStock]],
      { origin: -1 }
    );
  } else if (title.includes("Low Stock Report")) {
    const totalValue = itemsValue
      .reduce((acc, curr) => acc + (curr.value ? parseFloat(curr.value) : 0), 0)
      .toFixed(2);
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["", "", "", "", "", "Total ", totalValue]],
      { origin: -1 }
    );
  } else if (title.includes("Stock Movement Report")) {
    const totalCloging = itemsValue
      .reduce(
        (acc, curr) => acc + (curr.closing ? parseFloat(curr.closing) : 0),
        0
      )
      .toFixed(2);
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["", "", "", "", "", "", "Total ", totalCloging]],
      { origin: -1 }
    );
  } else if (title.includes("Stock Register Report")) {
    const totalCloging = itemsValue
      .reduce(
        (acc, curr) => acc + (curr.closing ? parseFloat(curr.closing) : 0),
        0
      )
      .toFixed(2);
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["", "", "", "", "", "", "", "Total ", totalCloging]],
      { origin: -1 }
    );
  }

  // Define column widths
  if (title.includes("Closing Stock Report")) {
    worksheet["!cols"] = [
      { wch: 15 }, // Code
      { wch: 30 }, // Product Name
      { wch: 15 }, // Stock
      { wch: 20 }, // Unit Name
      { wch: 15 }, // Cost
      { wch: 20 }, // Value
    ];
  } else if (title.includes("Nil Stock Report")) {
    worksheet["!cols"] = [
      { wch: 15 }, // Code
      { wch: 30 }, // Product Name
      { wch: 15 }, // MRP
      { wch: 20 }, // Stock
      { wch: 15 }, // Unit Name
    ];
  } else if (title.includes("Low Stock Report")) {
    worksheet["!cols"] = [
      { wch: 15 }, // Code
      { wch: 30 }, // Product Name
      { wch: 15 }, // Stock alert
      { wch: 20 }, // Unit Name
      { wch: 15 }, // stock
      { wch: 20 }, // Unit Name
      { wch: 20 }, // Value
    ];
  } else if (title.includes("Stock Movement Report")) {
    worksheet["!cols"] = [
      { wch: 15 }, // Code
      { wch: 40 }, // Product Name
      { wch: 15 }, // mrp
      { wch: 10 }, //Openings,
      { wch: 20 }, // Unit Name
      { wch: 20 }, // stock
      { wch: 20 }, // Unit Name
      { wch: 20 },
      { wch: 20 }, // stock
      { wch: 20 }, // Value
    ];
  } else if (title.includes("Stock Register Report")) {
    worksheet["!cols"] = [
      { wch: 20 }, // date
      { wch: 40 }, // description
      { wch: 15 }, // refType
      { wch: 10 }, //refno,
      { wch: 20 }, // stock
      { wch: 20 }, // Unit Name
      { wch: 20 },
      { wch: 20 }, // stock
      { wch: 20 }, // Value
    ];
  }

  // Append worksheet to workbook and save
  XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Report");

  // Generate the filename based on the report title
  const fileName = `${title.replace(/\s+/g, "_")}_Report.xlsx`; // Replace spaces with underscores for the filename

  // Save the workbook to a file
  XLSX.writeFile(workbook, fileName);
};
