import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchSingleOrder } from '../../store/action/orderConfigAction';
import TopProgressBar from '../../shared/components/loaders/TopProgressBar';
import MasterLayout from '../MasterLayout';
import SalesOrderForm from './SalesOrderForm';

const EditSalesOrder = ( props) => {
    const { fetchSingleOrder, singleOrder } = props;
    const { id } = useParams();

    const [formcode, setFormCode] = useState("T04");
    const navigate = useNavigate();
    useEffect(() => {
        
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            console.log("Parsed Form Data:", parsedFormData);
            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permisssionUpdate);
                console.log("Form Code Items:", formCodeItems);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);

    useEffect(() => {
        fetchSingleOrder(id, 1, 1);
    }, []);

    const itemsValue = singleOrder;

    return (
        <MasterLayout>
            <TopProgressBar />
            <SalesOrderForm singleOrder={itemsValue} />
        </MasterLayout>
    )
}

const mapStateToProps = (state) => {
    const { singleOrder } = state;
    return { singleOrder }
};

export default connect(mapStateToProps, { fetchSingleOrder })(EditSalesOrder);